<div class="container">
  <div class="nm-subheader">
    <div class="pt-5 nm-subheader-content">
      <div class="nm-subheader__icon">
        <img src="{{ departmentIcon }}" alt="" />
      </div>
      <div>
        <h4 class="nm-subheader__title nm-primary">{{ departmentName }}</h4>
        <!-- <p class="nm-subheader__subtitle nm-secondary">{{ subtitle }}</p> -->
      </div>
    </div>
    <img
      src="{{ bannerImg }}"
      class="img-fluid nm-subheader__bg"
      width="100%"
      alt="..."
    />
  </div>
</div>
