<!-- Heading -->
<div class="nm-page-header">
  <h2>AMBULANCE SERVICES</h2>
</div>

<!-- <div class="col-sm-12 text-center nm-bg-primary mb-3 text-white py-3">
  <h2 class="doctor"><b>AMBULANCE SERVICES</b></h2>
</div> -->

<!-- content -->
<div class="container p-3 mb-4">
  <div class="row no-gutters border rounded pb-4 bg-light">
    <div class="col-md-6 mb-md-0 p-md-4">
      <img src="assets/img/ambulance/1.jpg" class="w-100" alt="Ambulance" />
    </div>
    <div class="col-md-6 p-4 m-auto text-center">
      <h4 class="nm-primary">
        24 X 7 Ambulance Service Available in NIMS Medicity
      </h4>
      <h1><b>Contact No. 9946223542</b></h1>
    </div>
  </div>
</div>
