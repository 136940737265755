import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DoctorsListService } from 'src/app/_services/doctors-list.service';

@Component({
  selector: 'app-doctors-subcategory',
  templateUrl: './doctors-subcategory.component.html',
  styleUrls: ['./doctors-subcategory.component.scss'],
})
export class DoctorsSubcategoryComponent implements OnInit {
  doctor: any = [];
  doctordetails: any = [];
  pathFldr: any;

      constructor(
        private doctorService: DoctorsListService,
        private router: Router
      ) {}

      ngOnInit() {
        this.getDoctorsList() ;
      }

    
  getDoctorsList() {
    const pathFldr = this.router.url;
    console.log(pathFldr);
    this.doctorService.getDoctorsList().subscribe((data) => {
      this.doctordetails = data.data;
      console.log(this.doctordetails);
     
      for (var val of this.doctordetails) {
        for (var val2 of val.info) {
          // console.log(val2);
          if (pathFldr == val2.param) 
          {
            console.log(val2);
            this.doctordetails = val2;
            console.log(this.doctordetails);
          }
        }
      }
    });
  } 


  /* getDoctorsList() {
    const pathFldr = this.router.url;
    console.log(pathFldr);
    this.doctorService.getDoctorsList().subscribe((data) => {
      this.doctors = data.data;
      console.log(this.doctors);

      for (var val of this.doctors) {
        for (var val2 of val.info) {
          // console.log(val2);
          if (pathFldr == val2.param) {
            console.log(val2);
            this.doctordetails = val2;
            console.log(this.doctordetails);
          }
        }
      }
    });
  } */
}
