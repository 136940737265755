import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DoctorsListService } from 'src/app/_services/doctors-list.service';


@Component({
  selector: 'app-courses-subcategory',
  templateUrl: './courses-subcategory.component.html',
  styleUrls: ['./courses-subcategory.component.scss'],
})
export class CoursesSubcategoryComponent implements OnInit {
  wholedata: any = [];
  coursesubcategory: any = [];
  pathFldr: any;

  constructor(
    private CoursesubcategoryService: DoctorsListService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getCoursesubcategoryList();
  }

  getCoursesubcategoryList() {
    const pathFldr = this.router.url;
    console.log(pathFldr);
    this.CoursesubcategoryService.getCoursescategoryList().subscribe((data) => {
      this.wholedata = data.data;
      console.log(this.wholedata);
      for (var val of this.wholedata) {
        if (pathFldr == val.url) {
          console.log('checkign');
          this.coursesubcategory = val;
          console.log(this.coursesubcategory);
        }
      }
    });
  }
}
