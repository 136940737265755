<!-- Heading -->
<!-- <div class="col-sm-12 text-center nm-bg-primary mb-3 text-white py-3">
  <h2 class="doctor">
    <b>{{ coursesubcategory.coursename }}</b>
  </h2>
</div> -->
<!-- Heading -->
<div class="nm-page-header">
  <h2>{{ coursesubcategory.coursename }}</h2>
</div>
<!-- Faculties setting -->
<div class="container divstyle">
  <h3>{{ coursesubcategory.facultyhead }}</h3>
  <div *ngFor="let data of coursesubcategory.facultydetails">
    <h4>{{ data.name }}</h4>
    <h6>{{ data.qualification }}</h6>
  </div>
</div>

<!-- initial intro -->
<div
  class="container divstyle"
  *ngFor="let item of coursesubcategory.paragraph"
>
  <p class="parastyle">{{ item.para }}</p>
</div>

<!-- Courses Offers -->
<div class="container divstyle">
  <h4>{{ coursesubcategory.coursehead }}</h4>
  <div *ngFor="let item of coursesubcategory.courses">
    <h5>{{ item.coursesname }}</h5>
  </div>
</div>

<!-- Objectives -->

<div class="container divstyle">
  <h4>{{ coursesubcategory.objhead }}</h4>
  <div class="divstyle">
    <h5>
      {{ coursesubcategory.objdescription }}
    </h5>
  </div>
  <div *ngFor="let item of coursesubcategory.objlist">
    <h6>{{ item.list }}</h6>
  </div>
</div>
