<!-- Action bar -->

<div class="nm-bg-primary py-1">
  <div class="action-bar container">
    <div class="action-bar-left">
      <div>Follow Us on Social Media</div>
      <div class="social-media-icons">
        <a href="https://www.facebook.com/nimsmedicitytrivandrum/"
          ><i class="fab fa-facebook-f sm-style"></i
        ></a>
        <a href="https://instagram.com/nims_hospital?utm_medium=copy_link"
          ><i class="fab fa-instagram sm-style"></i
        ></a>
        <a href=""><i class="fab fa-twitter sm-style"></i></a>
        <a href=""><i class="fa fa-linkedin sm-style"></i></a>
        <a href=""><i class="fa fa-youtube-play sm-style"></i></a>
      </div>
    </div>
    <div class="action-bar-right px-2">
      <p class="other-pages">
        <a class="other-pages-link" href="#">Privacy policy</a>
      </p>
      <p class="other-pages">
        <a class="other-pages-link" href="#">Disclaimer</a>
      </p>
      <p class="other-pages">
        <a class="other-pages-link" href="#">Contact</a>
      </p>
    </div>
  </div>
</div>

<!-- header 1 -->
<div class="border-bottom nm-sticky-nav">
  <div class="container header1">
    <div class="logo d-flex d-sm-block justify-content-between">
      <a href="#"
        ><img style="width: 200px" src="assets\img\logonaac.png" alt=""
      />
    </a>

      <!-- Mobile screen Nav bar starts Here -->
     
      <nav class="navbar navbar-expand-lg navbar-light mx-2 d-inline d-sm-none">
        <button
          class="navbar-toggler"
          type="button"
          (click)="toggleNavbar()"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="collapse navbar-collapse justify-content-center"
          id="navbarNav"
          [ngClass]="{ show: Navbaropen }" 
        >
          <ul class="navbar-nav">
            <li class="nav-item active">
              <a class="nav-link" href="#"
                >HOME <span class="sr-only">(current)</span></a
              >
            </li>
            
            <!-- <li class="nav-item">
                <a class="nav-link" href="/about-us" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true"
                  >ABOUT US</a> -->
                  <!-- <button
                  type="button"
                  (click)="subtoggleNavbar()"
                  data-toggle="collapse"
                  data-target="#navbarCustom"
                  aria-controls="navbarCustom"
                  aria-expanded="false"
                  aria-label="Toggle navigationn">
                  <i class="fas fa-chevron-down fa-xs navbar-toggler" 
                  
                  ></i>
                  </button></a>
                <ul id="navbarCustom" [ngClass]="{ show: SubNavbaropen }" >
                  <li><a class="nav-link" href="/about-us"><span>About</span></a></li>
                  <li><a class="nav-link" href="/management"><span>Management</span></a></li>
                  <li><a class="nav-link" href="/ni-trust"><span>NI Trust</span></a></li>
                  <li><a class="nav-link" href="/pressrelease"><span>Press Release</span></a></li>
                  <li><a class="nav-link" href="/video-gallery"><span>Video Gallery </span></a></li>
                  <li><a class="nav-link" href="/image-gallery"><span>Image Gallery </span></a></li>
                  <li><a class="nav-link" href="/camp"><span>Camp </span></a></li>
                  <li><a class="nav-link" href="/blog"><span>Blog & Articles</span></a></li>
                  <li><a class="nav-link" href="/testimonials"><span>Testimonials </span></a></li>
                  <li><a class="nav-link" href="/research-development"><span>Research & Development </span></a></li>
                </ul> -->
                <!-- <li class="nav-item">
                  
                  <a class="nav-link" href="#" id="navbarDropdown" type="button" (click)="toggleNavbar()" data-target="#navbarNav"
                  aria-controls="navbarNav"  aria-label="Toggle navigation"aria-expanded="false">ABOUT US
                     <i class="fas fa-chevron-down fa-xs"></i></a>
                        <ul class="dropdown-menu show" aria-labelledby="navbarDropdown">
                          <li><a class="dropdown-item" href="/about-us"><span>About</span></a></li>
                          <li><a class="dropdown-item" href="/management"><span>Management</span></a></li>
                          <li><a class="dropdown-item" href="/ni-trust"><span>NI TRUST</span></a></li>
                          <li><a class="dropdown-item" href="/pressrelease"><span>Press Release </span></a></li>
                          <li><a class="dropdown-item" href="/video-gallery"><span>Video Gallery</span></a></li>
                          <li><a class="dropdown-item" href="/image-gallery"><span>Image Gallery </span></a></li>
                          <li><a class="dropdown-item" href="/camp"><span>Camp </span></a></li>
                          <li><a class="dropdown-item" href="/blog"><span>Blog & Articles </span></a></li>
                          <li><a class="dropdown-item" href="/testimonials"><span>Testimonials </span></a></li>
                          <li><a class="dropdown-item" href="/research-development"><span>Research & Development</span></a></li>
                        </ul>    
                 </li> -->
                      <li ng-repeat="menu in menus" class="nav-item dropdown">
                          <a href="#" class="dropdown-toggle nav-link" data-toggle="dropdown">ABOUT US</a>
                          <ul ng-if="menu.menus" class="dropdown-menu">
                              <li ng-repeat="submenu in menu.menus nav-link" class="mb-2">
                                  <a class="substyle" href="/about-us">About</a>
                              </li>
                              <li ng-repeat="submenu in menu.menus nav-link" class="mb-2">
                                <a class="substyle" href="/management">Management</a>
                            </li>
                            <li ng-repeat="submenu in menu.menus nav-link" class="mb-2">
                              <a class="substyle" href="/ni-trust">NI Trust</a>
                            </li>
                            <li ng-repeat="submenu in menu.menus nav-link" class="mb-2">
                              <a class="substyle" href="/pressrelease">Press Release</a>
                            </li>
                            <li ng-repeat="submenu in menu.menus nav-link" class="mb-2">
                              <a class="substyle" href="/video-gallery">Video Gallery</a>
                            </li>
                            <li ng-repeat="submenu in menu.menus nav-link" class="mb-2">
                              <a class="substyle" href="/image-gallery">Image Gallery</a>
                            </li>
                            <li ng-repeat="submenu in menu.menus nav-link" class="mb-2">
                              <a class="substyle" href="/camp">Camp</a>
                          </li>
                          <li ng-repeat="submenu in menu.menus nav-link" class="mb-2">
                            <a class="substyle" href="/blog">Blog & Articles</a>
                        </li>
                        <li ng-repeat="submenu in menu.menus nav-link" class="mb-2">
                          <a class="substyle" href="/testimonials">Testimonials</a>
                      </li>
                      <li ng-repeat="submenu in menu.menus nav-link" class="mb-2">
                        <a class="substyle" href="/research-development">Research & Development</a>
                    </li>
                          </ul>
                      </li>
                  
            <li class="nav-item">
              <a class="nav-link" href="/doctors">OUR DOCTORS</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/departments">DEPARTMENTS</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/courses">COURSES</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/news-events"
                >NEWS & EVENTS</a
              >
            </li>
            <li ng-repeat="menu in menus" class="nav-item dropdown">
              <a href="#" class="dropdown-toggle nav-link" data-toggle="dropdown">PATIENT CARE</a>
              <ul ng-if="menu.menus" class="dropdown-menu">
                <li ng-repeat="submenu in menu.menus nav-link" class="mb-2">
                  <a class="substyle" href="/nims-executive-checkups" >Executive Checkup</a>
                </li>
                <li ng-repeat="submenu in menu.menus nav-link" class="mb-2">
                  <a class="substyle" href="/corporate-insurance" >Corporate Insurance</a>
                </li>
                <li ng-repeat="submenu in menu.menus nav-link" class="mb-2">
                  <a class="substyle" href="/ambulance-services" >Ambulance Service</a>
                </li>
                <li ng-repeat="submenu in menu.menus nav-link" class="mb-2">
                  <a class="substyle" href="/medical-dictionary" >Medical Dictionary</a>
                </li>
              </ul>
            
            <li ng-repeat="menu in menus" class="nav-item dropdown">
              <a href="#" class="dropdown-toggle nav-link" data-toggle="dropdown">CONTACT US</a>
                <ul ng-if="menu.menus" class="dropdown-menu">
                  <li ng-repeat="submenu in menu.menus nav-link" class="mb-2">
                      <a class="substyle" href="/contact-us">Contact Us</a>
                  </li>
                  <li ng-repeat="submenu in menu.menus nav-link" class="mb-2">
                    <a class="substyle" href="/careers">Vacancies</a>
                  </li>
                </ul>
            </li>
            <li class="nav-item">
              <button type="button" class="btn btn-warning">
                <a class="nav-link" href="/spectrum">SPECTRUM</a>
              </button>
            </li>
          </ul>
        </div>
      </nav>
      <!-- Mobile screen Nav bar Ends Here -->
    </div>

    <div class="d-flex align-items-center justify-content-between px-2">
      <div class="certification-number">
        <a href="#"
          ><img class="nabh" src="assets\img\NABH-logo-1-1.png" alt=""
        /></a>
        <!-- <a href="#"><img class="nabh" src="assets\img\75x75.png" alt="" /></a> -->
        <div class="d-none hdr-help d-sm-flex itm-cntr txt-cntr">
          <a
            class="emrgcy d-flx flx-wrap itm-cntr jstfy-cntr cntnt-cntr "
            href="tel:0471 222 2115"
            title="Emergency"
          >
            Emergency<span>0471 222 2115</span>
          </a>
          <a
            class="liflin d-flx flx-wrap itm-cntr jstfy-cntr cntnt-cntr"
            href="tel:0471 2223542"
            title="Appointments"
          >
            Appointments
            <span>0471 2223542</span>
          </a>
          <span class="icon-24by7">
            <noscript>
              <img
                alt="24/7"
                width="42"
                height="30"
                data-src="https://cdn.apollohospitals.com/dev-apollohospitals/assets/images/icons/24by7.png"
                class="lazyload"
                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
              /><img
                src="https://cdn.apollohospitals.com/dev-apollohospitals/assets/images/icons/24by7.png"
                alt="24/7"
                width="42"
                height="30"
              />
            </noscript>
            <img
              class="lazyloaded"
              src="https://cdn.apollohospitals.com/dev-apollohospitals/assets/images/icons/24by7.png"
              data-src="https://cdn.apollohospitals.com/dev-apollohospitals/assets/images/icons/24by7.png"
              width="42"
              height="30"
            />
          </span>
        </div>
      </div>
      <div class="d-sm-none text-end emer-size">
        <p class="fw-bold fs-6 text-font">
          <a href="tel:0471 222 2115" title="Emergency" class="text-font"
            ><small> Emergency: &nbsp;0471 222 2115</small></a
          >
        </p>
        <p class="fw-bold fs-6 text-font">
          <a href="tel:0471 2223542" title="Appointments" class="text-font"
            ><small> Appointments:&nbsp;0471 2223542</small></a
          >
        </p>
      </div>
    </div>
  </div>
  <!-- Destop NavBar : Start -->
  <nav
    class="navbar navbar-expand-lg navbar-light mx-2 d-none d-sm-block border-top" id="desk-menu"
  >
    <div
      class="collapse navbar-collapse justify-content-between container"
      id="navbarNav"
    >
      <ul class="navbar-nav">
        <li class="nav-item ">
          <a class="nav-link" href="#"
            >HOME <span class="sr-only">(current)</span></a
          >
        </li>

        <!-- About US  -->
        <li ng-repeat="menu in menus" class="nav-item dropdown">
          <a href="#" class="dropdown-toggle nav-link" data-toggle="dropdown">
       <!--  <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="aboutnavbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          > -->
            ABOUT US
          </a>
          <div class="dropdown-menu" aria-labelledby="aboutnavbarDropdown">
            <a
              class="dropdown-item"
              routerLink="/about-us"
              routerLinkActive="active"
              >About</a
            >
            <div class="dropdown-divider"></div>
            <a
              class="dropdown-item"
              href="#"
              routerLink="/management"
              routerLinkActive="active"
              >Management</a
            >
            <div class="dropdown-divider"></div>
            <a
              class="dropdown-item"
              href="#"
              routerLink="/ni-trust"
              routerLinkActive="active"
              >NI Trust</a
            >
            <div class="dropdown-divider"></div>
            <a
              class="dropdown-item"
              href="#"
              routerLink="/pressrelease"
              routerLinkActive="active"
              >Press release</a
            >
            <!-- <div class="dropdown-divider"></div>
            <a
              class="dropdown-item"
              href="#"
              routerLink="/gallery"
              routerLinkActive="active"
              >Gallery</a
            > -->
            <div class="dropdown-divider"></div>
            <a
              class="dropdown-item"
              href="#"
              routerLink="/video-gallery"
              routerLinkActive="active"
              >Video gallery</a
            >
            <div class="dropdown-divider"></div>
            <a
              class="dropdown-item"
              href="#"
              routerLink="/image-gallery"
              routerLinkActive="active"
              >Image gallery</a
            >

            <div class="dropdown-divider"></div>
            <a
              class="dropdown-item"
              href="#"
              routerLink="/camp"
              routerLinkActive="active"
              >Camp</a
            >
            <div class="dropdown-divider"></div>
            <a
              class="dropdown-item"
              href="#"
              routerLink="/blog"
              routerLinkActive="active"
              >Blog & Article</a
            >
            <div class="dropdown-divider"></div>
            <a
              class="dropdown-item"
              href="#"
              routerLink="/testimonials"
              routerLinkActive="active"
              >Testimonials</a
            >
            <div class="dropdown-divider"></div>
            <a
              class="dropdown-item"
              href="#"
              routerLink="/research-development"
              routerLinkActive="active"
              >Research And Development</a
            >
          </div>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/doctors">OUR DOCTORS</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/departments">DEPARTMENTS</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/courses">COURSES</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/news-events"
            >NEWS & EVENTS</a
          >
        </li>
        <li ng-repeat="menu in menus" class="nav-item dropdown">
          <a href="#" class="dropdown-toggle nav-link" data-toggle="dropdown">
        <!-- <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="aboutnavbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          > -->
            PATIENT CARE
          </a>
          <div class="dropdown-menu" aria-labelledby="aboutnavbarDropdown">
            <a
              class="dropdown-item"
              routerLink="/nims-executive-checkups"
              routerLinkActive="active"
              >Executive Checkup</a
            >
            <div class="dropdown-divider"></div>
           
              <a
                class="dropdown-item"
                routerLink="/corporate-insurance"
                routerLinkActive="active"
                >Corporate Insurance</a
              >
            <div class="dropdown-divider"></div>
            <a
              class="dropdown-item"
              href="#"
              routerLink="/ambulance-services"
              routerLinkActive="active"
              >Ambulance services</a
            >
            <div class="dropdown-divider"></div>
            <a
              class="dropdown-item"
              href="#"
              routerLink="/medical-dictionary"
              routerLinkActive="active"
              >Medical Dictionary</a
            >
          </div>
        </li>
        <li ng-repeat="menu in menus" class="nav-item dropdown">
          <a href="#" class="dropdown-toggle nav-link" data-toggle="dropdown">
        <!-- <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="aboutnavbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          > -->
            CONTACT
          </a>
          <div class="dropdown-menu" aria-labelledby="aboutnavbarDropdown">
            <a
              class="dropdown-item"
              routerLink="/contact-us"
              routerLinkActive="active"
              >Contact Us</a
            >
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#" routerLink="/" routerLinkActive=""
              >Our Institutions</a
            >
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#" routerLink="/careers">Vacancies</a>
          </div>
        </li>

        <li class="nav-item">
          <button type="button" class="btn btn-warning">
            <a class="nav-link" routerLink="/spectrum">SPECTRUM</a>
          </button>
        </li>
      </ul>
    </div>
  </nav>
  <!-- Destop NavBar : End -->
</div>
