import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DoctorsListService } from 'src/app/_services/doctors-list.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Component({
  selector: 'app-news-eventssubcategory',
  templateUrl: './news-eventssubcategory.component.html',
  styleUrls: ['./news-eventssubcategory.component.scss'],
})
export class NewsEventssubcategoryComponent implements OnInit {
  newsdata: any = [];
  newssubcategory: any = [];
  pathFldr: any;

  //latest

  custom_newsdata: any = [];
  custom_newssubcategory: any = [];
  custom_pathFldr: any;

  constructor(
    private newssubcategoryService: DoctorsListService,
    private router: Router,
    private http: HttpClient
  ) {}

  ngOnInit() {
    console.log("ok");
    //this.getNewsubcategoryList();
    this.getCustomNewsEventSub();
  }
  ret = this.router.url.replace('news-events/','');
  
  getCustomNewsEventSubUrl ='https://nims.prismaticsoft.com/api/resource/Recent_News'+this.ret
  getCustomNewsEventSubList(){
    return this.http.get<any>(this.getCustomNewsEventSubUrl);
  }



  /* getNewsubcategoryList() {
    const pathFldr = decodeURIComponent(this.router.url);
    console.log(pathFldr);
    this.newssubcategoryService.getnewseventList().subscribe((data) => {
      this.newsdata = data.data;
      console.log(this.newsdata);
      for (var val of this.newsdata) {
        if (pathFldr == val.url) {
          this.newssubcategory = val;
          console.log(this.newssubcategory);
        }
      }
    });
  } */

  
  getCustomNewsEventSub(){
    const custom_pathFldr = decodeURIComponent(this.router.url);
    console.log(this.ret);
    this.getCustomNewsEventSubList().subscribe((data) => {
      this.custom_newsdata = data.data;
      console.log(this.custom_newsdata);
      
    });
  }
}
