<div class="nm-page-header">
  <h2>DIABETIC CHECK UP</h2>
</div>

<div class="container">
  <div style="text-align:center">
    <h4>DIABETIC HEALTH CHECK UP – PANEL 1</h4>
    <h4>Amount:1500/-</h4>
    <h4>14 TESTS & 2 CONSULTATIONS</h4>
  </div>
  <div class="row py-3">
    <div class="col-sm">
      <h5>BIOCHEMISTRY</h5>
      <div class="p-2">
        <ul>
          <li>FBS (FASTING BLOOD SUGAR)</li>
          <li>PPBS (POST PRANDIAL BLOOD SUGAR)</li>
          <li>HbA1C (GLYCATED HEMOGLOBIN)</li>
          <li>UACR</li>
        </ul>
      </div>
    </div>
    <div class="col-sm">
      <h5>LIPID PROFILE</h5>
      <div class="p-2">
        <ul>
          <li>S.CHOLESTEROL</li>
          <li>HDL CHOLESTEROL</li>
          <li>LDL CHOLESTEROL</li>
          <li>VLDL CHOLESTEROL</li>
          <li>S.TRIGLYCERIDES</li>
        </ul>
      </div>
    </div>
    <div class="col-sm">
      <h5>LIVER PROFILE</h5>
      <div class="p-2">
        <ul>
          <li>SGOT</li>
          <li>SGPT</li>
        </ul>
      </div>
      <h5>RENAL PROFILE</h5>
      <div class="p-2">
        <ul>
          <li>S. CREATINE</li>
          <li>URIC ACID</li>
        </ul>
      </div>
    </div>
    
    <div class="col-sm">
      <h5>ECG</h5>
      <h5>CONSULTATIONS</h5>
      <div class="p-2">
        <ul>
          <li>ENDOCRINOLOGY</li>
          <li>DIETARY ADVICE</li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="container">
  <div style="text-align:center">
    <h4>DIABETIC CHECK UP – PANEL 2</h4>
    <h4>Amount:2500/-</h4>
    <h4>21 TESTS & 2 CONSULTATIONS</h4>
  </div>
  <div class="row py-3">
    <div class="col-sm">
      <h5>BIOCHEMISTRY</h5>
      <div class="p-2">
        <ul>
          <li>FBS (FASTING BLOOD SUGAR)</li>
          <li>PPBS (POST PRANDIAL BLOOD SUGAR)</li>
          <li>HbA1C (GLYCATED HEMOGLOBIN)</li>
          <li>UACR</li>
          <li>EGFR</li>
        </ul>
      </div>
      <h5>LIPID PROFILE</h5>
      <div class="p-2">
        <ul>
          <li>TOTAL CHOLESTEROL</li>
          <li>HDL CHOLESTEROL</li>
          <li>LDL CHOLESTEROL</li>
          <li>TRIGLYCERIDES</li>
        </ul>
      </div>
    </div> 
   
      
    
    <div class="col-sm">
      <h5>LIVER PROFILE</h5>
      <div class="p-2">
        <ul>
          <li>SGOT</li>
          <li>SGPT</li>
        </ul>
      </div>
      <h5>RENAL FUNCTION PROFILE</h5>
      <div class="p-2">
        <ul>
          <li>S. CREATINE</li>
          <li>URIC ACID</li>
        </ul>
      </div>
      <h5>ELECTROLYTES</h5>
      <div class="p-2">
        <ul>
          <li>S.SODIUM</li>
          <li>S.POTASSIUM</li>
        </ul>
      </div>
      
    </div>
    
    <div class="col-sm">
      <h5>IMMUNOLOGY</h5>
      <div class="p-2">
        <ul>
          <li>TSH</li>
        </ul>
      </div>
      <h5>VPT</h5>
      <h5>CANS</h5>
      <h5>ECG</h5>
      <h5>PODIOSCAN</h5>
      <h5>CONSULTATIONS</h5>
      <div class="p-2">
        <ul>
          <li>ENDOCRINOLOGY</li>
          <li>DIETARY ADVICE</li>
        </ul>
      </div>
   
    
     
     
    </div>
  </div>
</div>


<div class="container">
  <div style="text-align:center">
    <h4>DIABETIC CHECK UP – PANEL 3</h4>
    <h4>Amount:6500/-</h4>
    <h4>56 TESTS & 2 CONSULTATIONS</h4>
  </div>  
  <div class="row py-3">
    <div class="col-sm">
          <h5>HAEMATOLOGY</h5>
          <br>
          <h6>COMPLETE BLOOD COUNT</h6>
              <div class="p-3">
                <ul>
                  <li>HAEMOGLOBIN</li>
                  <li>TOTAL COUNT</li>
                </ul>
              </div>
      
      
          <h5>DIFFERENTIAL COUNT</h5>
          <div class="p-3">
            <ul>
              <li>POLYMORPHS</li>
              <li>LYMPHOCYTES</li>
              <li>MONOCYTES</li>
              <li>EOSINOPHILS</li>
              <li>BASOPHILS</li>
              <li>PLATELET COUNT</li>
              <li>RBC</li>
              <li>PCV</li>
              <li>MCV</li>
              <li>MCH</li>
              <li>MCHC</li>
              <li>ESR</li>
              <li>RED CELL DISTRIBUTION</li>
              <li>ABSOLUTE EOSINOPHIL COUNT</li>
              <li>ABSOLUTE LYMPHOCYTE COUNT</li>
              <li>ABSOLUTE NEUTROPHIL COUNT</li>
              <li>MEAN PLATELET VOLUME</li>
            </ul>
          </div>
        </div>  
      <div class="col-sm">    
        <h5>BIOCHEMISTRY</h5>
        <div class="p-2">
          <ul>
            <li>FBS (FASTING BLOOD SUGAR)/RANDOM BLOOD SUGAR</li>
            <li>PPBS (POST PRANDIAL BLOOD SUGAR)</li>
            <li>HbA1C (GLYCATED HEMOGLOBIN)</li>
          </ul>
        </div>
        <h5>LIPID PROFILE</h5>
        <div class="p-2">
          <ul>
            <li>S.CHOLESTEROL</li>
            <li>HDL CHOLESTEROL</li>
            <li>LDL CHOLESTEROL</li>
            <li>VLDL CHOLESTEROL</li>
            <li>S.TRIGLYCERIDES</li>
          </ul>
        </div>
        <h5>RENAL PROFILE</h5>
        <div class="p-2">
          <ul>
            <li>BLOOD UREA NITROGEN</li>
            <li>S.CREATINE</li>
            <li>URIC ACID</li>
          </ul>
        </div>
        <h5>ELECTROLYTES</h5>
          <div class="p-2">
            <ul>
              <li>S.SODIUM</li>
              <li>S.POTASSIUM</li>
            </ul>
          </div>
      </div>

    <div class="col-sm">
      <h5>LIVER FUNCTION TEST</h5>
      <div class="p-2">
        <ul>
          <li>BILIRUBIN-TOTAL</li>
          <li>BILIRUBIN-DIRECT</li>
          <li>BILIRUBIN-INDIRECT</li>
          <li>SGOT/AST</li>
          <li>SGPT/ALT</li>
          <li>ALKALINE PHOSPHATASE</li>
          <li>TOTAL PROTEIN</li>
          <li>ALBUMIN</li>
          <li>GLOBULIN</li>
          <li>ALBUMIN/GLOBULIN RATIO</li>
        </ul>
      </div>
      <br>
      <h5>S. CALCIUM</h5>
      <br>
      <h5>EGFR</h5>
      <br>
      <h5>UACR</h5>
    </div>
    
    <div class="col-sm">
      <h5>IMMUNOLOGY</h5>
      <h6>VITAMIN D LEVELS</h6>
      <h5>THYROID PROFILE</h5>
      <div class="p-2">
        <ul>
          <li>T3</li>
          <li>T4</li>
          <li>TSH</li>
        </ul>
      </div>
      <h5>ECG</h5>
      <br>
      <h5>ECHO</h5>
      <br>
      <h5>TREADMILL TEST (TMT)</h5>
      <br>
      <h5>USG ABDOMEN & PELVIS</h5>
      <br>
      <h5>VPT</h5>
      <br>
      <h5>CANS</h5>
      <br>
      <h5>PODIOSCAN</h5>
      <br>
      <h5>CONSULTATIONS</h5>
      <div class="p-2">
        <ul>
          <li>ENDOCRINOLOGY</li>
          <li>DIETARY ADVICE</li>
        </ul>
      </div>
    <h6 class="pt-1">COMPLIMENTARY BREAKFAST</h6>
    </div>
  </div>
</div>




