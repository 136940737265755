import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})

export class HeaderComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
  Navbaropen = false;
  toggleNavbar(){
    this.Navbaropen = !this.Navbaropen;
  }
  SubNavbaropen = false;
  subtoggleNavbar(){
    this.SubNavbaropen = !this.SubNavbaropen;
  }
  
}
