import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pressrelease',
  templateUrl: './pressrelease.component.html',
  styleUrls: ['./pressrelease.component.scss']
})
export class PressreleaseComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
