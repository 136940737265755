<div class="container">
  <div class="row" style="padding: 2%">
    <div class="col-sm-3">
      <img
        src="{{ doctordetails.profileImg }}"
        alt="Image of Doctor"
        class="imgsize"
      />
    </div>
    <div class="col-sm-6 container centre">
      <h1>
        <b>{{ doctordetails.name }}</b>
      </h1>
      <h3>{{ doctordetails.designation }}</h3>
      <h4>{{ doctordetails.qualification }}</h4>
      <h5>{{ doctordetails.timing }}</h5>
      <h5>{{ doctordetails.timing1 }}</h5><br>
      <h6 class="text-justify">{{ doctordetails.descrip }}</h6><br>
      <h6 class="text-justify">{{ doctordetails.descrip1 }}</h6><br>
      <h6 class="text-justify">{{ doctordetails.descrip2 }}</h6>

    </div>
    <div class="col-sm-3 centre container">
      <button class="btn btn-success">Book Appointment</button>
    </div>
  </div>
</div>
