<app-department-subheader></app-department-subheader>

<div *ngIf="pathFldr === '/departments/anaesthesiology'">
  <div class="container category-section">
    <div class="container">
      <div class="row">
        <div class="col-sm-8 cardiology-text">
          <p class="text-padd-content">
            Anesthesia is a mysterious concept to most of us, even if we have
            been anesthetized before. Anesthesia, essentially a reversible
            condition induced by drugs, is intended to result in one or more
            different states of being. It can relieve pain, give you amnesia to
            knock out your memory of the procedure or how it felt, reduce
            anxiety and paralyze your muscles.
          </p>
          <p class="text-padd-content">
            NIMS Department of Anesthesiology is one of the leading super
            specialty department in South Kerala providing round the clock
            assistance. The department is well equipped with preoperative and
            pain management services to patients with a broad spectrum of
            surgical, medical and diagnostic procedures. All intensive teams are
            shed under this key unit. The seven state of the art Operation
            Theaters, Anaesthesia machines, Anaesthesia monitors, Ventilators,
            Safety gadgets, Continuous drug delivery systems, Warmers,
            Bronchoscope, etc operated with our dynamic, trained, skilled and
            experienced anesthesiologists, specially trained staffs and OT
            technicians ensure that the patients receive anesthesia plan
            tailored to their needs, providing maximum patient safety and
            comfort. General and regional anesthesia are provided for all
            surgical procedures.
          </p>
        </div>
        <div class="col-sm-4">
          <img
            src="assets\img\departments\cardiology\heart-web.png"
            class="img-fluid cardio-img rounded mx-auto d-block"
            style="width: 57%"
            alt="..."
          />
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid services-cardiology">
    <header class="text-center pt-5">
      <h3 class="mb-1">Services</h3>
      <h5 class="mb-3">Department of Anaesthesiology Services</h5>
    </header>
  </div>
  <div class="container">
    <div class="container">
      <div class="row">
        <div class="col-sm">
          <div class="invasive-list">
            <h4 style="color: #2f905b; font-weight: 800">
              Anaesthesiology Services
            </h4>
          </div>
          <ul class="invasive-list-item">
            <li>Maquet designed Ortho OT</li>
            <hr />
            <li>Transplant OT</li>
            <hr />
            <li>Datex Ohmeda 9100C machines</li>
            <hr />
            <li>Acute and chronic pain management</li>
            <hr />
            <li>Labor Analgesia</li>
            <hr />
            <li>Intensive Care Services</li>
            <hr />
            <li>Emergency and Resuscitation Services</li>
          </ul>
          <br />
        </div>
        <div class="col-sm"></div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="pathFldr === '/departments/ayurveda'">
  <div class="container p-3">
    <h4>
      NIMS department of Ayurveda has been one of the leading names in the field
      of Ayurvedic therapy in Kerala
    </h4>
    <p>
      NIMS Department of Ayurveda has been one of the leading names in the field
      of Ayurvedic therapy in Kerala. Ayurveda is the ancient Indian holistic
      medical science which deals with the body, mind, and soul in health and
      disease.NIMS Department of Ayurveda brings exclusive, holistic and
      personalized treatment programme for a host of diseases including
      Arthritis and Spine disorders to restore optimal health. It is based on
      the principles of Panch-Mahabhoota (Space, Air, Fire, Water and Earth) and
      Tri-Doshas i.e. 3 biological humors- Vata, Pitta and Kapha (air, fire and
      water) theories.
    </p>
  </div>
  <div class="container-fluid services-cardiology">
    <header class="text-center pt-5">
      <h3 class="mb-1">Services</h3>
      <h5 class="mb-3">Department of Ayurveda Services</h5>
    </header>
  </div>
  <div class="container p-3">
    <div class="row">
      <div class="col-6">
        <h4 class="invasive-list" style="color: #2f905b; font-weight: 800">
          KARKITAKA CHIKILSA
        </h4>
        <img
          src="assets\img\departments\ayurveda\chikilsa.jpeg"
          class="img-responsive fitimage"
          alt=""
        />
      </div>
      <div class="col-sm">
        <div class="invasive-list">
          <h4 style="color: #2f905b; font-weight: 800">Ayurveda Features</h4>
        </div>
        <ul class="invasive-list-item1">
          <li>Natural and herbal health care system</li>
          <hr />
          <li>Totally safe.</li>
          <hr />
          <li>Body, mind, spirit health care system for long creative life.</li>
          <hr />
          <li>Focal segmental glomerulosclerosis</li>
          <hr />
          <li>
            Treats the whole person and eliminates the cause of the disease.
          </li>
          <hr />
          <li>Emphasis in preventive medicine.</li>
          <hr />
          <li>Effective treatments to detoxify the body.</li>
          <hr />
          <li>Highly perfected rejuvenation treatments.</li>
          <hr />
          <li>Herbal cure for the diseases.</li>
          <hr />
          <li>Preventive as well as curative treatments.</li>
          <hr />
          <li>Basis of Ayurvedic health care.</li>
          <hr />
          <li>
            Human being is a microcosm (small universe) that is part of the
            macrocosm.
          </li>
          <hr />
          <li>
            Rhythm or life is intimately connected with the rhythm of the
            universe.
          </li>
          <hr />
          <li>
            Diseases are due to improper eating and lifestyle ignoring one’s
            special body type, environment and age.
          </li>
          <hr />
          <li>
            By establishing a perfect balance between inner and outer world
            body, mind and spirit would be in perfect health.
          </li>
          <hr />
          <li>The Human body has the power to heal itself.</li>
        </ul>
        <br />
      </div>
    </div>
    <div class="row">
      <div class="col-sm">
        <div class="invasive-list">
          <h4 style="color: #2f905b; font-weight: 800">
            Treatment for cancer is the highlight of NIMS Ayurveda
          </h4>
        </div>
        <ul class="invasive-list-item1">
          <li>
            Surgery is not possible due to inaccessible sight of mass lesions
          </li>
          <hr />
          <li>
            Due to any other comorbidity such as age factor ,inappropriate
            Heamogram
          </li>
          <hr />
          <li>Socio economic aspect.</li>
        </ul>
        <br />
        <p class="p-3">
          When a complete cure is not possible, and of course to save money when
          life cannot be saved even after surgery and chemotherapy and
          radiation. As it is comparatively less expensive. Atleast can save
          their economic status for the sake of the rest of the family.
        </p>
        <ul class="invasive-list-item1">
          <li>To reduce complications of chemotherapy and radiation</li>
          <hr />
          <li>To avoid reccurance and metastatasis as much as possible</li>
          <hr />
          <li>
            Palliative aspect to symptomatic relief .some time weaning from
            pethedin
          </li>
          <hr />
          <li>
            Relief of symptoms possible always and some times cure is possible.
          </li>
          <hr />
          <li>
            Atleast possible to add life to years when not able to add years to
            life.
          </li>
        </ul>
      </div>
      <div class="col-sm">
        <div class="invasive-list">
          <h4 style="color: #2f905b; font-weight: 800">
            Eight Specialized Branches
          </h4>
        </div>
        <ul class="invasive-list-item1">
          <li>Kaya Chikilsa</li>
          <hr />
          <li>Kaumara Bhritya</li>
          <hr />
          <li>Graha Chikilsa</li>
          <hr />
          <li>Shalakya Tantra</li>
          <hr />
          <li>Shalya Tantra</li>
          <hr />
          <li>Agada Tantra</li>
          <hr />
          <li>Rasayana Chikilsa</li>
          <hr />
          <li>Vajeekarana Chikilsa</li>
        </ul>
        <br />
        <p>
          through diet, exercise, yoga, massage, aromas, tantras, mantras and
          meditation.
        </p>
      </div>
    </div>
  </div>
  <div class="container-fluid services-cardiology">
    <header class="text-center pt-5">
      <h3 class="mb-1">Ayruvedic Treatments</h3>
    </header>
  </div>
  <div class="container p-3">
    <h3 class="text-center">Panchakarma</h3>
    <div class="p-3">
      <p>
        Panchakarma is a Sanskrit word that means “five actions” or “five
        treatments.” This is a process used to clean the body of toxic materials
        left by disease and poor nutrition. Ayurveda says that imbalanced doshas
        create waste matter. This waste matter is called Ama in Ayurveda. Ama is
        a foul-smelling, sticky, noxious substance that needs to be evacuated
        from the body as thoroughly as possible.
      </p>
      <p>
        Ayurveda Emphasizes Preventative And Healing Therapies Along With
        Various Methods Of Purification And Rejuvenation. Ayurveda Is More than
        A Mere Healing System, It Is A Science And Art Of Appropriate Living
        Which Helps To Achieve Longevity. It Can Also Guide Every Individual In
        The Prevention Of Disease And Long Term Maintenance Of Health. To
        Achieve This Balanced State Of Body, Mind and Consciousness, Ayurveda
        Prescribes Panchakarma Therapy For The Cleansing Of Body Toxins.
      </p>
      <p>
        Panchakarma will stick out the excess doshas (or imbalances in the
        dosha) along with the sticky Ama out of your system by means of the body
        waste evacuation channels such as sweat glands, urinary tract,
        intestines, etc. Panchakarma is, thus, a balancing operation. It
        involves daily massages and oil baths and is an extremely pleasant
        experience. Ayurveda recommends Panchakarma as a seasonal treatment for
        toning your mind body system. (This is like a tune-up for the car.)
      </p>
    </div>
    <div>
      
    </div>
  </div>
  <div class="container">
    <h4>Therapeutic vomiting (Vamana)</h4>
    <p class="p-2">
      This treatment is used when there is congestion in the lungs causing
      repeated attacks of bronchitis, cough, cold or asthma. The objective of
      the therapy is to induce vomiting to get rid of the mucus causing excess
      kapha. A drink consisting of licorice and honey, or calamus root tea is
      given to the patient. (Other substances used include salt, and cardamom)
      Vomiting is induced by rubbing on the tongue. 4-8 vomiting is the target.
      After vomiting the patient will feel very comfortable; most of the
      congestion, wheezing and breathlessness will disappear along with the
      clearing of the sinus.
    </p>
    <p class="p-2">
      Therapeutic vomiting is used for cough, cold, symptoms of asthma, fever,
      nausea, loss of appetite, anemia, poisoning, skin diseases, diabetes,
      lymphatic obstruction, chronic indigestion, edema (swelling), epilepsy
      (between attacks), chronic sinus problems, and for repeated attacks of
      tonsillitis.
    </p>
    <h4>
      Vamana, Panchakarma, Purgation Therapy (Vireka, Virechan, herbal laxative
      therapy).
    </h4>
    <p class="p-2">
      Virechan is the cleansing of the pitta and the purification of the blood
      toxins. Generally, it is administered three days after the Vamana
      treatment. If Vamana therapy is not needed, Virechan can be administered
      directly. Virechan cleanses the sweat glands, small intestine, colon,
      kidneys, stomach, liver, and spleen. A number of fine herbs are used as a
      laxative. These include senna, prune, bran, flaxseed husk, dandelion root,
      psyllium seed, cow’s milk, salt, castor oil, raisins and mango juice. When
      taking these laxatives, it is important to adhere to restricted diet.
      Vireka is used for treatment of skin diseases, chronic fever, piles,
      abdominal tumors, worms, gout, jaundice, gastrointestinal problems,
      constipation, and irritable bowel syndrome.
    </p>
    <h4>Basti</h4>
    <p class="p-2">
      Medicated enemas are used for various specific reasons. In general, this
      treatment is used to flush the loosened doshas out through the intestinal
      tract. There are over 100 specific enemas listed in Ayurveda
    </p>
    <p class="p-2">
      Basti involves introducing medicinal substances such as sesame oil,
      calamus oil, or other herbal decoctions in a liquid medium into the
      rectum. Basti is especially good for vata disorders. It alleviates
      constipation, distention, chronic fever, the common cold, sexual
      disorders, kidney stones, heart pain, vomiting, backache, neck pain and
      hyper acidity. Such vata disorders as sciatica, arthritis, rheumatism, and
      gout can also be treated by Basti. There are about 80 vata related
      disorders in Ayurveda. About 80 percent of them can be treated with
      medicated enemas.
    </p>
    <p class="p-2">
      Since vata is mainly located in the colon and bones, the medication is
      administered rectally.
    </p>
    <h4>Nasal Administration Nasya (herbal inhalation therapy)</h4>
    <p class="p-2">
      This treatment involves inhaling vapor from medicinal herbs that have been
      infused in boiling water. It is used mostly to eliminate kapha-oriented
      problems, of ear, eyes, nose, and throat disorders such as migraine,
      sinusitis, catarrh, and bronchitis.
    </p>
    <p class="p-2">
      The nose is the gateway to the brain and to consciousness. Prana, or life
      energy, enters the body through breath taken in through the nose. Nasal
      administration of medication helps to correct the disorders of prana
      affecting the higher cerebral, sensory and motor functions. Nasya is
      indicated for dryness of the nose, sinus congestion, hoarseness, migraine
      headache, convulsions and certain eye and ear problems.
    </p>
    <h4>Blood Letting (Rakta Moksha)</h4>
    <p class="p-2">
      Blood letting is used to eliminate toxins that are absorbed into the
      bloodstream through the gastrointestinal tract. This process purifies the
      blood. It is used for disorders such as repeated attacks of skin disorders
      such as urticaria, rash, eczema, acne, scabies, leucoderma, chronic
      itching, and hives. It was also found effective for enlarged liver and
      spleen, and for gout.
    </p>
    <p class="p-2">
      Bloodletting, which should only be administered by a qualified physician,
      is useful to relieve several pitta disorders such as acne and rash. If
      administered properly, it stimulates the antitoxic substances in the
      bloodstream, thus developing the immune mechanism in the blood system.
    </p>
    <p class="p-2">
      Do not administer bloodletting in cases of anemia, edema, weakness or to
      very old and very young persons.
    </p>
    <p class="p-2">
      Ayurvedic treatments and medicinal massages are offered at Sunrise Health
      Resort. Highly motivated and committed team of doctors and practitioners
      ensure the highest levels in the quality of treatment and medicinal
      preparations. The special health and beauty care packages are specifically
      designed to treat modern-day maladies.
    </p>
   
    <h4>Sweat Treatments (Swedana)</h4>
    <p class="p-2">
      Swedana Ayurveda oil massage is often followed by a herbal steam bath with
      specially selected medicinal herbs. This loosens the vessels and thereby
      helps the detoxification of the entire body system. The Swedana therapy
      includes many different forms of heat treatment. The heat to the body can
      be applied dry or damp, locally or to the whole body.
    </p>
    <p class="p-2">
      Swedana is the Ayurveda therapy for revitalising the skin. Herbal mixtures
      are prepared with various herbs and medicated powder. Your entire body is
      massaged with these specially for you made mixtures after they are warmed
      in medicated oils. This promotes circulation and increases perspiration of
      the body thus resulting the skin to eliminate the wastesand so the skin
      tone improves. After the application of medicated oil, your entire body is
      made to perspire by the external application of medicated rice packs in
      the form of small bags. This ayurveda treatment vitalize your skin and
      makes it glow. A gentle application of warm special herbal oil is first
      applied to the skin. The massage of the body helps eliminate toxins from
      the skin’s surface while the oil protects the pores from becoming too
      loose during the Ayurveda steam treatment. The individual therapy taker is
      made to perspire intensively inside a steam chamber that lets out herbal
      steam from aromatic herbs that promote cohesion between cells. Through
      this Ayurveda application the steam will open up the very small pores of
      the skin thereby removing all wastes. An excellent therapy for toning the
      skin and giving it a special long term glow.
    </p>
    <h4>Snehana Or Abhyanga</h4>
    <p class="p-2">
      This is a gentle but firm whole body Ayurveda massage from head to toe
      using warm medicated oil. Oils are chosen by the Ayurveda expert according
      to the prakruti (psychosomatic constitution) and the illness. The Abhyanga
      massage uses herbal essences and choice ingredients specially put together
      for the individual patient by our Ayurveda expert after having diagnosed
      the individual body type.The ingredients for the Abhyanga body Massage are
      prepared together with soothing oils.
    </p>
    <p class="p-2">
      The massage is done in a soft rhythmic way with one or two persons
      massaging at the same time for forty five to sixty minutes. Ladies are
      treated by a female therapists; gentlemen by male therapists. After the
      massage a steam bath or hot shower is recommended by the Ayurveda Doctors.
      Abhyanga massage is a deeply relaxing, rejuvenating experience as well as
      used as Ayurveda treatment in certain illness.
    </p>
    <p class="p-2">
      A regular Abhyanga Ayurveda Massage protects from stress, anxiety,
      exhaustion and Vata disorder. Abhyanga Ayurveda Massage nourishes the
      body, extends the life span, provides good sleep, improves the skin
      texture, improves the vision and provides better physical stability.
    </p>
    <p class="p-2">
      This rhythmical and deeply relaxing warm sesame oil rejuvenation treatment
      helps remove stagnant energy and move the “Prana” or life force to
      stimulate your body’s vital energy. Your nerves are soothed as you drift
      into a deep state of bliss and joy!
    </p>
    <p class="p-2">
      Abhyanga Ayurveda treatment is the best way to control and balance Vata in
      the body. Vata is the predominant entity in the sense of touch and skin is
      the organ of touch. Abhyanga Ayurveda Massage has been considered a
      traditional Ayurvedic therapy to create a sense of inner peace.
    </p>
    <p class="p-2">
      This total Ayurveda body massage with herbal oils tones up your skin and
      rejuvenates and strengthens all the tissues so as to achieve ideal health
      and longevity. Abhyanga Massage also increases primary vitality and there
      by increases the resistance of your body. In addition to being beneficial
      for your eyes. Abhyanga makes you have a sound sleep.
    </p>
    <h4>Pizhichil</h4>
    <p class="p-2">
      Pizhichil is a special form of treatment which is a combination of oil
      massage and heat treatment. This therapy is also known as treatment for
      aristocrats as in the olden days the Maharajas or Kings used to have this
      treatment.
    </p>
    <p class="p-2">
      A gentle, synchronized massage is carried out under a constant flow of
      warm herbal oil. This Ayurveda treatment also involves squeezing a cloth
      soaked in oil over the body. During this treatment the patient is made to
      sit on a Ayurveda chair specially made for this purpose and the medicated
      oils are then applied over the head and body. Ayurveda therapists are all
      the time present for the observation of the treatment and if needed at the
      same time the Shriodhara is also undertaken.
    </p>
    <p class="p-2">
      A cloth is tied over the forehead of the therapy taker to prevent oil from
      getting into the eyes. The warm, medicated oil is then poured in a
      continually flowing single stream, by squeezing a clean cloth dipped in
      oil over the body. The treatment starts and ends with the patient in a
      sitting position. In the duration in-between, the patient is made to lie
      flat while the application of Shirodhara goes on.
    </p>
    <p class="p-2">
      This Ayurveda treatment of Pizhichil has been highly valued by all our
      guests and produces good results in case of joint problems and muscle
      tension. The duration of Pizhichil is decided by the Ayurveda experts
      after the body constitution of the individual has been determined.
    </p>
    <p class="p-2">
      Pizhichil Treatment increases the blood circulation, nourishes muscles,
      nerves tissues and sexual ability. Pizhichil Ayurveda treatment is also
      effective for Paraplegia, Hemiplegia and general debility.
    </p>
    <h4>Shirovasthi</h4>
    <p class="p-2">
      Shirovasthi is the process of retaining medicinal oil over the head with
      the help of a suitable cap to provide nourishment to brain cells to
      prevent brain cell damage.
    </p>
    <p class="p-2">
      Certain mixture of the lukewarm herbal oils which are prescribed by the
      Ayurveda Physician according to the individual body type are poured into a
      cap fitted on the head for some time per day according to the patient’s
      conditions for the advised period.
    </p>
    <p class="p-2">
      A cylindrical leather cap of about ten inches height and both ends open is
      fixed in position by means of a cloth belt two or three inches in width
      tied around the head and by black- gram paste used for plugging the boles
      and filling the gap. Suitable medicated oil heated to a bearable degree is
      poured with in and allowed to remain for 15 to 60 minutes.
    </p>
    <p class="p-2">
      Shirovasthi is the best treatment to cure variety of conditions like
      insomnia, stress, tension, and different mental conditions. In the past
      this therapy was prescribed only for people with mental illness.
    </p>
    <p class="p-2">
      The stress associated with modern lifestyle has created several problems
      and shirovasthi is redesigned as an Ayurveda therapy to cure conditions
      such as lack of memory, inability to concentrate, lack of willpower, etc.
      It is also a good therapy for general good feeling. This treatment is
      highly effective for facial paralysis, dryness of nostrils, mouth and
      throat, severe headaches, burning sensation of head.
    </p>
    <h4>Netra Tarpana: Ayurvedic Eyes Rejuvenation</h4>
    <p class="p-2">
      Netra Tarpana is a special Ayurvedic treatment for the eyes to get them
      relieved from tiredness and it also improve the eyesight. It is suggested
      therapy for the professionals who regularly works at Computers, Stone
      Carving machines, Jewellery Designing and other machines where intensive
      eye sight work is needed .
    </p>
    <p class="p-2">
      This Ayurveda Treatment for eyes is carried out in combination with face
      massage and is experienced by our guests as very enjoyable and relaxing. A
      special treatment in which the eyes are bathed in pure medicated cow’s
      ghee (clear home made butter). This therapy takes around 15 minutes.
      Ayurvedic Netra Tarpana, where freshly made dough rings filled with
      fragrant oils are placed around the eyes and one has to keep the eyes open
      so the oils run into them. It is very effective in diseases of eye and
      poor vision. A small pit is made around the eyes and it is filled up with
      medicated clear butter up till the eye lashes are covered. As mentioned
      earlier highly effective Ayurveda therapy for people who wok a lot with
      computers !
    </p>
    <h4>Kizhi Ayurvedic Treatment</h4>
    <p class="p-2">
      Kizhi (a term from the Malayalam language from South India) Ayurvedic
      Treatment is a complete rejuvenation therapy comprising of synchronized
      special massage, Herbal Steam Bath, Njavarakizhi etc. This carefully
      planned health Program is directed towards improving vigor and vitality.
      It also goes a long way to tone up the skin and improve complexion i.e. it
      is a return to the bliss of eternal youth.
    </p>
    <p class="p-2">
      Kizhi Ayurvedic treatment involves application of special Njavara rice
      paste. The body is massaged gently with linen bags filled with Njavara
      rice cooked well in herbal decoction. Herbal leaves and herbal powders are
      applied to the whole body in boluses with hot medicated oils for 45
      minutes per day for the needed number of days which is decided by the
      Ayurveda experts. This treatment is suggested for Osteoarthritis,
      Arthritis with swelling, Spondilosis, sports injuries etc.
    </p>
    <h4>Pinda Sveda Ayurvedic Treatment</h4>
    <p class="p-2">
      During Pinda Sveda Ayurveda treatment small linen bags filled with a
      specially prepared mixture are applied locally to relieve the muscles. An
      additional major role in this therapy is played by the highly effective
      herbal oils mixture which are specially selected by the ‘Ayurveda doctors’
      for the parts of the body where the treatment is required. The effect of
      the herbal extracts is to relieve the pain if there is any and to relax
      and build up the body tissues of the application area.
    </p>
    <p class="p-2">
      During Pinda Sveda Ayurveda therapy massaging is done by trained and
      experienced Ayurveda technicians, using a bag filled with specially
      medicated special rice which is very useful to remove body pains, improve
      digestion, cures sclerosis. The oil massage is often followed by a herbal
      steam bath with specially selected medicinal herbs. This widen the vessels
      and thereby assists the detoxification of the body system. After a gentle
      massage on the body, specially prepared warm herbal oil is poured over the
      lower back and kept inside an herbal paste boundary for 50 minutes. The
      healing properties of herbal oils used for this massage deeply cleans and
      enriches the blood, builds and maintains strong muscle and connective
      tissues and lubricates the joints keeping them flexible and pain free.
    </p>
    <h4>Udvarthanam Ayurvedic Treatment</h4>
    <p class="p-2">
      A stimulating massage using special herbal powder to reduce cellulite
      accumulations, improve circulation and enhance your skin’s texture and
      appearance. We thoroughly exfoliate your body using a powerful mixture of
      Ayurvedic powders which will break up fatty deposits and energize blood
      circulation. A delightful body treatment to remove excess heat from your
      body, enhancing circulation and drawing fresh energy to the surface of the
      skin.
    </p>
    <p class="p-2">
      This remarkable treatment consists of natural nourishing ingredients such
      as Almond Oil, Chickpea Flour, Turmeric Powder and Fenugreek Powder.
      Almond Oil, containing proteins and minerals, is excellent for relaxing
      muscles and ligaments while lubricating the skin. Turmeric Powder
      strengthens the skin, relieves kapha (mucus) and vata (wind) and works
      great as an antiseptic.
    </p>
    <p class="p-2">
      While you indulge yourself in deep relaxation, the Ubtan Massage will
      remove excess heat from your body, enhancing circulation and drawing fresh
      energy to the surface of the skin. This is a wonderful treatment for those
      seeking to achieve a perfectly smooth, blemish-free, gleaming body.
    </p>
    <h4>Bashpaswedanam (Ayurvedic Medicated Steam Bath)</h4>
    <p class="p-2">
      Vashpaswedanam is a unique Ayurveda treatment. Under this treatment leaves
      of medicinal plants are boiled and resulting steam is given to the whole
      body.This Ayurveda treatment is helpful in to remove the impurities from
      the body, reducing fat and also helpful to recover from some skin
      diseases. This Ayurvedic Therapy is a part of the Panchakarma procedures.
    </p>
    <p class="p-2">
      Vashpaswedaman is to be understood as herbal steam bath which is an
      amazingly refreshing experience – the herbal mix stimulates and
      invigorates. The herbal steam bath is an age-old tradition, which, like
      massage, has been passed down through generations of skilled practitioners
      of Ayurveda. Originally, inhaled steam vapor – made from a mixture of
      fresh and dried essential herbs boiled in water – was used to treat skin
      ailments, muscle stress, and respiratory problems.
    </p>
    <h4>Urovasthi (Ayurveda Treatment For Muscular Chest Pain)</h4>
    <p class="p-2">
      Under this Ayurveda therapy a gentle massage is applied all over the body,
      a mixture of specially prepared warm herbal oil is poured over the chest
      and kept inside an herbal paste boundary for the advised time duration by
      the Ayurveda Physician. The healing properties of herbal oils used for
      this massage enriches the blood and help the muscle and connective tissues
      to get stronger. A very stimulating and effective treatment for muscular
      chest pain. This treatment is helpful not only for chest pain, but also
      for other diseases like asthma and other respiratory problems.
    </p>
    <p class="p-2">
      It is a beautiful experience that boosts the neuro-muscular system and
      connective tissues. By this Ayurveda therapy elasticity of the skin is
      also restored.
    </p>
  </div>
  <div class="container p-3">
    <h3 class="text-center p-1">Body Care</h3>
    <h4>
      Prime Body Care In Ayurveda By Rejuvenation Therapy (Also Know As Rasayana
      Chikitsa)
    </h4>
    <p class="p-2">
      This treatment includes body massage with hand and foot by two masseurs
      with medicated oil and cream, Ayurveda herbs for internal rejuvenation and
      medicated steam bath. This therapy is given from 90 minutes to up to 2
      hours or as per the duration and number of days which is decided by our
      Ayurveda Expert.
    </p>
    <p class="p-2">
      This Ayurveda therapy rejuvenate mind, body and soul, tone up skin,
      strengthen all systems in the body so as to achieve ideal health and
      longevity.
    </p>
    <p class="p-2">
      Ayurveda Body purification or Shodhan Chikitsa is a process of purifying
      the whole body to attain proper balance based on the panchkarma theory of
      Ayurveda.
    </p>
    <h4>Spine And Neck Care Porgramme</h4>
    <p class="p-2">
      This is a special treatment to keep your spine and neck healthy and to
      cure the problems related with it. This programme includes special
      massages & special exercises done under the observation of our Ayurveda
      Physicians.
    </p>
    <h4>Beauty Care Programme</h4>
    <p class="p-2">
      In this programme herbal face pack, herbal cream massage, herbal oil
      massage, intake of herbal tea and steam bath are recommended . This
      treatment increases the skin complexion, skin tone and beautifies the body
      figure and makes you glow for the long term !
    </p>
    <h4>Mukhalepam (Ayurvedic Face Treatment)</h4>
    <p class="p-2">
      This is a herbal face pack with specially made herbal powder and fresh
      herbal cream. The application of the mixture on your face prevents and
      avoids wrinkles and improves the skin tone. The Ayurveda face treatment is
      also considered good for the eyes.
    </p>
    <h4>Ayurveda Slimming Programme</h4>
    <ol class="list-group list-group-numbered p-1">
      <li class="list-group-item">
        Slimming through Ayurveda is achieved under this programme through
        different massages.
      </li>
      <li class="list-group-item">Medicated herbal powder massage</li>
    </ol>
    <h4>Medicated Herbal Oil Massage</h4>
    <p class="p-2">
      These two Ayurveda massages are given for 28 days or as recommended by the
      Ayurveda physician with the combination of medicated steam bath, intake of
      herbal juices and herbal tea. A very special vegetarian Ayurvedic diet is
      advised during the treatment period.
    </p>
    <h4>Karnapooranam (Ayurveda Ear Care)</h4>
    <p class="p-2">
      In this Ayurveda process a mixture of herb fumes are applied to the ears
      for 5 to 10 minutes. This process helps to clean the ear and avoids
      ailments associated to the ear.
    </p>
    <h4>Ayurveda Hair Care</h4>
    <p class="p-2">
      The secret to Indians’ lush, glossy hair is the hair oil treatment
      prescribed in the ancient Ayurvedic texts. The oil is highly reputed for
      hair growth. A scalp massage with the oil helps to improve circulation in
      the scalp and stimulate the hair follicles which leads to healthier hair.”
      At the Ayurveda hair therapy we use a mixture of some of the natural
      contents which look like: sesame oil; milk; herbal extracts of eclipta
      alba, indigo, balloon vine, gooseberry, coconut, lavender, rosemary, and
      liquorice.
    </p>
    <p class="p-2">
      indigo makes hair to shine and also has also a the same time
      anti-bacterial properties to help maintain a healthy scalp. Eclipta alba
      and gooseberry promote hair growth and coconut oil prevents premature
      graying.
    </p>
    <p class="p-2">
      After the treatment offered by us you will see and feel an immediate
      benefit – your hair will be felt soft, silky and shiny after the first
      time you use the hair oil recommended for your particular type of hair.
    </p>
  </div>
  <div class="container">
    <p>
      NIMS experienced doctors, trained therapists, and hospitality crew
      concentrate in making the guest mentally and physically fresh and fit to
      meet the challenges of the time. We have a full- fledged department
      equipped with modern scientific devices that aims at promotion of positive
      health, prevention and cure of diseases
    </p>
  </div>
</div>
<div *ngIf="pathFldr === '/departments/bariatric-surgery'">
  <div class="container category-section">
    <div class="container">
      <div class="row">
        <div class="col-sm-8 cardiology-text">
          <p class="text-padd-content">
            The NIMS Center of Excellence in Bariatric Surgery is one of the
            largest groups of Obesity & Metabolic surgery institutes in the
            country, with all its tertiary care centers performing varieties of
            weight loss surgeries including revisional surgeries with success
            rates comparable to international standards. The commitment of our
            consultants and allied specialists in the treatment of Morbid
            Obesity has led to the achievement of better outcomes and improved
            quality of life for hundreds of obese patients who visit us each
            year.
          </p>
          <br />
          <h5 class="mb-1">INFRASTRUCTURE</h5>
          <br />
          <p class="text-padd-content">
            Bariatric patients needs are different from normal patients because
            of their excess weight and therefore they need wider cots, transport
            chairs and stronger toilet systems. Bearing this in mind, our
            hospital rooms have been redesigned to provide them all possible
            comfort during their stay with us. A state-of-the-art operating room
            with the latest integrated laparoscopic system and special
            instruments are used to aid in safe surgery.
          </p>
          <p class="text-padd-content">
            Bariatric surgery or (weight loss surgery) includes a variety of
            procedures performed on people who have obesity. Weight loss is
            achieved by reducing the size of the stomach with gastric band or
            through removal of a portion of the stomach (sleeve gastrectomy or
            biliopancreatic diversion with duodenal switch) or by resecting and
            re- routing the small intestine to a small stomach pouch (gastric
            bypass surgery).
          </p>
        </div>
        <div class="col-sm-4">
          <img
            src="assets\img\departments\cardiology\heart-web.png"
            class="img-fluid cardio-img rounded mx-auto d-block"
            style="width: 79%"
            alt="..."
          />
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="container">
      <div class="row">
        <div class="col-sm">
          <div class="invasive-list">
            <h4 style="color: #2f905b; font-weight: 800">Types</h4>
          </div>
          <ul class="invasive-list-item">
            <li>Bilio pancreatic diversion</li>
            <hr />
            <li>Jejunoileal bypass</li>
            <hr />
            <li>Endoluminal sleeve</li>
            <hr />
            <li>Vertical banded gastroplasty</li>
            <hr />
            <li>Adjustable gastric band</li>
            <hr />
            <li>Sleeve gastrectomy</li>
            <hr />
            <li>Intra gastric balloon</li>
            <hr />
            <li>Stomach folding</li>
            <hr />
            <li>Gastric bypass surgery</li>
            <hr />
            <li>Sleeve gastrectomy with duodenal switch</li>
            <hr />
            <li>Implantable gastric stimulation.</li>
          </ul>
          <br />
        </div>
        <div class="col-sm"></div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="pathFldr === '/departments/cardiology'">
  <div class="container category-section">
    <div class="container">
      <div class="row">
        <div class="col-sm-8 cardiology-text">
          <p class="text-padd-content">
            Welcome to NIMS Heart Foundation, the leading medical institution operating under the Noorul Islam Educational Trust. Nestled in a serene and lush 24-acre landscape along the Thiruvananthapuram, Neyyattinkara National Highway, we provide a healing environment conducive to recovery.
          </p>
          <p class="text-padd-content">
            Our mission at NHF is to deliver high-quality, state-of-the-art care at an affordable cost. Every patient who walks through our doors receives personalized attention and compassionate care. We understand the financial burden imposed by the widespread prevalence of Coronary Artery Disease, but our commitment to top-notch infrastructure remains unwavering. Equipped with the latest advancements in medical technology, we ensure precise diagnosis and treatment, adhering to the highest standards of ethical medical care.
          </p>
          <p class="text-padd-content">
            For a decade now, NIMS Heart Foundation has been dedicated to offering accessible and advanced cardiac care. We are proud to have achieved significant success in fulfilling our goal. Today, we stand as one of the premier cardiac centers in South Kerala, attracting patients from near and far on a regular basis. The trust placed in us by our patients is a testament to our excellence.

          </p>
          <p class="text-padd-content">
            At our center, we adhere to international guidelines in the treatment of various cardiac conditions. Our state-of-the-art "Coronary Care Unit" is fully equipped to handle all types of cardiac emergencies. With over 5,000 cardiac interventions performed and an in-hospital mortality rate of less than 1%, we rank among the best in the world. Our routine procedures include complex coronary and peripheral angioplasties, valvuloplasties, pacemaker implantations, and device closure of congenital heart diseases. We specialize in 24/7 primary angioplasty for heart attacks, which yields excellent results and is considered one of the most rewarding medical interventions. Our hospital infrastructure keeps pace with technological advancements, featuring additions such as Fractional Flow Reserve (FFR) to avoid unnecessary stenting procedures, Intra-Vascular Ultrasound (IVUS) and Stent Boost Technology for optimal stent placement, and the Rotablator for angioplasty of highly calcified vessels. To ensure our equipment remains up to date, we recently completed a comprehensive makeover on our 10th anniversary, including the addition of the cutting-edge VIVID E95 Echocardiography machine with live 3D imaging. Furthermore, we have installed a brand-new Cardiac Cath Lab - the Innova IGS 520 with Valve Assist technology - to offer our patients the very best in technological advancements. Over the past decade, we have come closer to realizing our dream of becoming the most sought-after cardiac center in the region. We express our heartfelt gratitude to all our well-wishers, and we pledge to work even harder to achieve perfection and ensure that everyone who seeks our care leaves with a smile.
          </p>
        </div>
        <div class="col-sm-4">
          <img
            src="assets\img\departments\cardiology\heart-web.png"
            class="img-fluid cardio-img rounded mx-auto d-block"
            style="width: 74%"
            alt="..."
          />
          <img
            src="assets\img\departments\cardiology\heart1.jpeg"
            class="img-fluid cardio-img rounded mx-auto d-block"
            style="width: 74%"
            alt="..."
          />
          <img
            src="assets\img\departments\cardiology\heart2.jpeg"
            class="img-fluid cardio-img rounded mx-auto d-block"
            style="width: 74%"
            alt="..."
          />
        </div>
      </div>
    </div>
    <div class="container">
      <header class="text-center pt-5">
        <h3 class="mb-1">About Us</h3>
        <h5 class="mb-3">Know More About Our Cardiology Department</h5>
      </header>
      <div class="container cardiology-aboutus">
        <div class="row">
          <div class="col-sm">
            <p class="text-padd-content">NIMS Heart Foundation is the flagship of the Medical Institutions under the Noorul Islam Educational Trust. NIMS spreads across 24 acres greenery on the Thiruvananthapuram, Neyyattinkara National Highway, blessed with a serene ambience congenial to healing.</p>
              <p class="text-padd-content">The motto of NIMS Heart Foundation is ‘Quality Hi Tech Care at Affordable Cost’. A personalized attention and care to each patient coming here is assured. All this comes at an affordable rate as we at NIMS Heart Foundation, are well aware of the financial implications, the epidemic of Coronary Artery Disease is having. But this doesn’t take anything away from our commitment to the infrastructure. The latest and most advanced machines are at our disposal to ensure that precise diagnosis and treatment is ensured – in line with the best practices of ethical medical care.
            </p>
            <!-- <img
              src="assets\img\departments\cardiology\heart-web.png"
              class="img-fluid"
              alt="..."
            /> -->
          </div>
          <div class="col-sm aboutus-list">
            <ul>
              <li>Nearing 20 years.</li>
              <li>
                Established as a primier Heart Care Center in South Kerala.
              </li>
              <li>
                Completed more than 10000 invasive procedures including 3750
                cardiac interventions with less than 0.5% mortality- comparable
                to the best hospital in the world.
              </li>
              <li>Safe and Ethical heart treatment</li>
              <li>
                Advanced Equipments to treat even the most difficult cases-FFR
                to avoid un-necessary angioplasty, stent Boost to ensure stents
                are deployed well, IVUS- for imaging the heart vessels,
                Rotablator to treat calcific vessels which can not be dilated
                with balloons.
              </li>
              <li>
                Complex angioplasty, pacemakers, devices for congenital heart
                disease etc done routinely.
              </li>
              <li>
                Non surgical treatment for Fibroid in women (uterine artery
                embolization) is being done.
              </li>
              <li>Established trust worthiness among patients.</li>
              <li>
                Referral center for points from kanyakumari to kollam as well as
                from neighboring countries-Maldives and Middle East
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid services-cardiology">
    <header class="text-center pt-5">
      <h3 class="mb-1">Services</h3>
      <h5 class="mb-3">
        NIMS Heart Foundation is One of The Biggest Heart Hospital in South
        India
      </h5>
    </header>
  </div>
  <div class="container">
    <div class="container">
      <div class="row">
        <div class="col-sm">
          <div class="invasive-list">
            <h4 style="color: #2f905b; font-weight: 800">
              Non – Invasive Cardiology
            </h4>
          </div>
          <ul class="invasive-list-item">
            <li>Echo / Doppler</li>
            <hr />
            <li>Transesophageal Echo</li>
            <hr />
            <li>Tread Mill Test</li>
            <hr />
            <li>Holter Monitoring</li>
            <hr/>
            <li>Fractional Flow Reserve(FFR)</li>
            <hr/>
            <li>Intra Vascular Ultrasound (IVUS)</li>
            <hr/>
            <li>VIVID E95 Echocardiography </li>
            <hr/>
            <li>Innova IGS 520 Cardiac Cath Lab</li>
          </ul>
          <div class="invasive-content">
            <p>
              The first State-of-the-art Flat Detector Philips Cath Lab in
              Kerala was installed in NIMS Heart Foundation. All invasive
              procedures including complex angioplasties – Left Main, Chronic
              Total Occlusion, Bifurcations etc are routinely preformed here. We
              have an Active Primary Angioplasty program for Heart Attack
              patients – 24/7.
            </p>
          </div>
        </div>
        <div class="col-sm">
          <div class="invasive-list">
            <h4 style="color: #2f905b; font-weight: 800">
              Invasive Cardiology
            </h4>
          </div>
          <ul class="invasive-list-item">
            <li>Coronary Angiography</li>
            <hr />
            <li>Coronary Angioplasty</li>
            <hr />
            <li>VATS – minimally invasive thoracic surgery</li>
            <hr />
            <li>Peripheral Angioplasty including Carotid Stenting</li>
            <hr />
            <li>Dilatation / Stenting of Coarctation of Aorta</li>
            <hr />
            <li>Pacemakers / Implants</li>
            <hr />
            <li>Valvuloplasty – Mitral / Aortic / Pulmonary</li>
            <hr />
            <li>DeviceDevice Closure of PDA / ASD etc</li>
            <hr />
            <li>Trans Catheter Aortic Valve Implantation (TAVI) </li>
            <hr/>
            <li>Optical coherence tomography (OCT)</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-sm">
          <div class="invasive-list">
            <h4 style="color: #2f905b; font-weight: 800">ICCU</h4>
          </div>
          <ul class="invasive-list-item">
            <li>11 bedded CCU with monitors / ventilators</li>
            <hr />
            <li>Specialised trained Nurses</li>
          </ul>
        </div>
        <div class="col-sm">
          <div class="invasive-content2">
            <p>
              The ICU is well equipped with intensivists and highly trained
              nursing staff round the clock. Bedside echocardiography,
              multichannel monitoring, including IABP (Intra Aortic Balloon
              Pump) facilities and sophisticated respirators are available. A
              Cardiac ambulance for quick transport of patients with highly
              trained personnel to manage all complications is available round
              the clock..
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="pathFldr === '/departments/cardiothoracic-vascular-surgery'">
  <div class="container category-section">
    <div class="container">
      <div class="row">
        <div class="col-sm-8 cardiology-text">
          <p class="text-padd-content">
            The Department of Cardiovascular and Thoracic surgery at NIMS Heart
            Foundation was started 9 years back with an idea to undertake and do
            open heart surgeries to provide advanced cardiac care to this
            particular segment of 2 states Kerala and Tamilnadu. The management
            of NIMS Medicity has liberally and conscientiously contributed to
            the organization of the department so as to achieve national and
            international standards for the theatres, Intensive Care Nursing and
            provide State of the art ancillary facilities. Our cardiac surgical
            team includes Cardiac Surgeons, Cardiac Anesthetist, Perfusionist,
            OT Nurses, ICU Nurses, Physiotherapists and other supporting staff.
          </p>
          <p class="text-padd-content">
            In the last 11 years we have successfully completed more than 1000
            open heart surgical procedure at nominal cost affordable to the
            common man. We have also done lot of free surgeries in the Heart to
            Heart programme . </p>
            <p class="text-padd-content"> Dr Asher Ennis Nayagam is a well known cardiothoracic surgeon who practices in Trivandrum Currently he heads the cardiothoracic Department at NIMS Medicity, Trivandrum.
            He graduated in Medicine from MIMSR Medical Collage, Maharashtra. He completed his post graduation in general surgery from Government Medical collage, Trivandrum. He did super-speciality training at the Southern Railway Hospital, Perambur Chennai from where he completed his DNB in cardiothoracic surgery.
             Later, Dr Asher had the opportunity to undergo training  in Uniportal VATS Lung Surgery from Shangai  Pulmonary Hospital, Shangai, China. In fact he had performed the first successful Uniportal VATS Lobectomy in the state of Kerala, India on 25/02/2018.
              Dr Asher underwent training in Lung Transplantation from the world famous Toronto Lung Transplantation Unit, Toronto General Hospital, Canada. Following this, he started the Lung Transplantation Programme in the NIMS Medicity, Trivandrum.
              He acquired special expertise in the administration of ECMO and is the team leader of the ECMO Programme in NIMS Medicity.
              Dr Asher is skilled in performing Minimally Invasive Cardiac Surgery (MICS) and Video Assisted Thoracoscopic Surgery (VATS) which he has used effectively in the surgical management of a number of different cardiac and pulmonary pathologies. </p>
             <p>We would like to solicit your active and whole hearted co operation in referring patients for the
            benefits provided by this hospital. It is needless to point out that
            us most care and ardent sincerity will form the basis of our
            approach to the cardiac problems in the patients referred to us.</p>
         
          <p class="text-padd-content">
            We wish to assure that the cardiac surgery and other related
            procedures will be undertaken at an affordable cost which will be a
            great help to the patients in this region. We expect your whole
            hearted co-operation and are happy to place our unstiated services
            to the patients in this region.
          </p>
          <p class="text-padd-content">
            The division of Thoracic surgery is concerned with the surgical
            management of Lung Disease. Chest Trauma, Chest wall Tumours and
            Mediastinal Tumours. Most of our work is done by minimally invasive.
          </p>
          <div class="col-sm aboutus-list">
            <h4>Cardiac Surgery Procedures</h4>
            <ul>
              <li>Coronary Artery Bypass Grafting(CABG)</li>
              <li>
                Atrial Septal Defect Closure(ASD)
              </li>
              <li>
               Ventricular Septal Defect Closure(VSD)
              </li>
              <li>Mitral Valve Replacement(MVR)</li>
              <li>
                Aortic Valve Replacement(AVR)
              </li>
              <li>
                Double Valve Replacement(DVR)
              </li>
              <li>
                Minimally Invasive Direct Coronary Arytery Bypass(MIDCAB)
              </li>
              <li>Minimally Invasive Cardiac Surgery(MICS)</li>
              <li>
               Vascular Surgeries and Interventions
              </li>
              <li>
                AV Fistula Creations
              </li>
            </ul>
          </div>
        
          <br />
        </div>
        <div class="col-sm-4">
          <img
            src="assets\img\departments\cardiology\heart-web.png"
            class="img-fluid cardio-img rounded mx-auto d-block"
            style="width: 85%"
            alt="..."
          />
          <img
          src="assets\img\departments\cardiology\cardiothorasic1.jpeg"
          class="img-fluid cardio-img rounded mx-auto d-block"
          style="width: 74%"
          alt="..."
        />
        <img
        src="assets\img\departments\cardiology\cardiothorasic2.jpeg"
        class="img-fluid cardio-img rounded mx-auto d-block"
        style="width: 74%"
        alt="..."
      />
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid services-cardiology">
    <header class="text-center pt-5">
      <h3 class="mb-1">METHODS OF OPERATION</h3>
      <h5 class="mb-3">
        We wish to offer the best possible surgical treatment for chest disease
        with using state of the art technology at an affordable cost to all our
        patients.
      </h5>
    </header>
  </div>
  <div class="container">
    <div class="container">
      <div class="row">
        <div class="col-sm">
          <div class="invasive-list">
            <h4 style="color: #2f905b; font-weight: 800">Open Thoracotomy</h4>
          </div>
          <ul class="invasive-list-item">
            <li>
              15cm long incision, muscles cut, ribs spreading causes fractures
            </li>
            <hr />
            <li>More blood transfusion</li>
            <hr />
            <li>More post-operative pain</li>
            <hr />
            <li>Longer Hospital stay</li>
            <hr />
            <li>2-3 months recovery time</li>
          </ul>
        </div>
        <div class="col-sm">
          <div class="invasive-list">
            <h4 style="color: #2f905b; font-weight: 800">
              VATS(Key-Hole Surgery)- Video Assisted Thoracoscopic Surgery
            </h4>
          </div>
          <ul class="invasive-list-item">
            <li>No rib fractures</li>
            <hr />
            <li>Less pain and complications</li>
            <hr />
            <li>Fewer blood transfusion</li>
            <hr />
            <li>Shorter hospital stay</li>
            <hr />
            <li>Better cosmesis</li>
            <hr />
            <li>Easy return to work</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid services-cardiology">
    <header class="text-center pt-5">
      <h3 class="mb-1">PROCEDURE DONE THROUGH VATS</h3>
      <h5 class="mb-3">
        We wish to offer the best possible surgical treatment for chest disease
        with using state of the art technology at an affordable cost to all our
        patients.
      </h5>
    </header>
  </div>
  <div class="container">
    <div class="container">
      <div class="row">
        <div class="col-sm">
          <div class="invasive-list">
            <h4 style="color: #2f905b; font-weight: 800">Diagnostic</h4>
          </div>
          <ul class="invasive-list-item">
            <li>Indeterminate pleural effusion (Bening vs malignancy)</li>
            <hr />
            <li>Tissue diagnosis of pleural masses</li>
            <hr />
            <li>Lung biopsy-diffuse interstitial lung disease.</li>
            <hr />
            <li>Indeterminate peripheral pulmonary nodule.</li>
            <hr />
            <li>Mediastinal lymph node biopsy.</li>
            <hr />
            <li>Mediastinal mass biopsy</li>
          </ul>
          <br/>
          <br/>
          <h4> Lung Transplantation Unit </h4>
          <br/>
          <p class="text-padd-content">
            Our department of Lung transplantation surgery is dedicated to provide 
            comprehensive peri operative care to patients with end stage Lung diseases. 
            Lung transplantation is now the well   acceptance treatment option for a 
            variety of selected end stage Lung disease which improves survival rates and quality of life.
          </p>
        </div>
        <div class="col-sm">
          <div class="invasive-list">
            <h4 style="color: #2f905b; font-weight: 800">Therapeutic Vats Lung Surgery</h4>
          </div>
          <ul class="invasive-list-item">
            <li>Decortication for empyema.</li>
            <hr />
            <li>Pleurodesis-pleural effusion and pneumothorax</li>
            <hr />
            <li>Bullous disease resection and pleurectomy </li>
            <hr />
            <li>Wedge resection for early lung cancers</li>
            <hr />
            <li>Metastasectomy</li>
            <hr />
            <li>Lobectomy, Segmentectomy and Pneumonectomy for lung cancers</li>
            <hr />
            <li>Destroyed lung-tuberculosis,Aspergilloma,brochiectasis</li>
            <hr/>
            <li>Lung volume reduction surgery</li>
            <hr/>
            <li>Thymectomy for myasthenia gravis and thymoma</li>
            <hr/>
            <li>Resection of benign mediastinal tumours</li>
            <hr/>
            <li>Drainage of pericardial effusion</li>
            <hr/>
            <li>Chest Trauma and chest wall tumours</li>
            
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="pathFldr === '/departments/chemotherapy-unit'">
  <div class="container category-section">
    <div class="container">
      <div class="row">
        <div class="col-sm-8 cardiology-text">
          <p class="text-padd-content">
            Our Chemotherapy Unit is a day unit with capacity to treat the
            patients at any time. The unit has a combination of beds and chairs
            for the comfort of our patients. Our staff work as a team and are
            dedicated to ensure the highest quality of holistic patient care. We
            endeavour to treat each patient as an individual and act as the
            patients’ advocate, in conjunction with family and significant
            others.
          </p>
          <p class="text-padd-content">
            Chemotherapy is the use of any drug to treat any disease. But to
            most people, the word chemotherapy means drugs used for cancer
            treatment. It’s often shortened to “chemo.”
          </p>
          <p class="text-padd-content">
            Surgery and radiation therapy remove, kill, or damage cancer cells
            in a certain area, but chemo can work throughout the whole body.
            This means chemo can kill cancer cells that have spread
            (metastasized) to parts of the body far away from the original
            (primary) tumor.
          </p>
        </div>
        <div class="col-sm-4">
          <img
            src="assets\img\departments\cardiology\heart-web.png"
            class="img-fluid cardio-img rounded mx-auto d-block p-3"
            style="width: 57%"
            alt="..."
          />
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <h5>Goals of chemotherapy treatment</h5>
    <p class="pt-2">
      If doctor has recommended chemotherapy to treat cancer, it’s important to
      understand the goals of treatment when making treatment decisions. There
      are three main goals for chemotherapy (chemo) in cancer treatment:
    </p>
    <ol class="pt-1">
      <li>Cure</li>
      <li>Control</li>
      <li>Palliation</li>
    </ol>
  </div>
  <div class="container">
    <h4>Planning chemotherapy treatments</h4>
    <p class="pt-2">
      The doctor decide what drug or combination of drugs you will get. Doctor
      will choose the doses, how the drugs will be given, and how often and how
      long will get treatment. All of these decisions will depend on the type of
      cancer, where it is, how big it is, and how it affects the normal body
      functions and overall health.
    </p>
    <p class="pt-2">
      Cancer can be treated with a single chemo drug, but often several drugs
      are used in a certain order or in certain combinations (called combination
      chemotherapy). Different drugs that work in different ways can work
      together to kill more cancer cells. This can also help lower the chance
      that the cancer may become resistant to any one chemo drug.
    </p>
    <p class="pt-2">
      Sometimes chemo is the only treatment you need. More often, chemo is used
      with surgery or radiation therapy or both. Here’s why:
    </p>
    <ul class="p-3">
      <li>
        Chemo may be used to shrink a tumor before surgery or radiation therapy.
        Chemo used in this way is called neoadjuvant
      </li>
      <li>
        It may be used after surgery or radiation therapy to help kill any
        remaining cancer cells. Chemo used in this way is called adjuvant
      </li>
      <li>It may be used with other treatments if your cancer comes back.</li>
    </ul>
  </div>
</div>
<div *ngIf="pathFldr === '/departments/clinical-psychology'"></div>
<div *ngIf="pathFldr === '/departments/covid-care-centre'">
  <div class="container category-section">
    <div class="container">
      <div class="row">
        <div class="col-sm-8 cardiology-text">
          <p class="text-padd-content">
            NIMS Medicity has commenced the operation of COVID Care Centre under
            NIMS AYUSH Project (The name “Ayush” is derived from the word Ayush
            mentioned in the Atharva veda and Rig veda meaning ‘life, duration
            of life or long life’.)
          </p>
          <p class="text-padd-content">
            NIMS AYUSH is a 140 bedded facility which includes Intensive care
            units (ICU) with ventilator support, High dependency Units (HDU),
            and General wards with dedicated well trained Intensivist, Resident
            medical officers, Nursing Staff, Paramedical Staff and Supporting
            Staff,
          </p>
          <p class="text-padd-content">
            NIMS Ayush is an independent separate block inside NIMS Medicity
            premise with separate entry and exits, which is 400 mtr away from
            general hospital block. Triaging of fever patients has been
            undertaken to prevent them from mixing with general patients.
          </p>
          <p class="text-padd-content">
            After quarantining and the suspected patients are subjected to COVID
            19 testing(True NAT RTPCR and Antigen test) at COVID Testing Centre,
            Which is the first private hospital laboratory to be authorized to
            perform COVID-19 testing in South Kerala by Indian Council of
            Medical Research (ICMR) and accredited by National Accreditation
            Board for Testing and Calibration Laboratories (NABL).
          </p>
          <p class="text-padd-content">
            If tested positive, the patient shall undergo treatment and
            management at the COVID CARE CENTRE in NIMS Medicity, which has been
            designated as an authorized COVID-19 Treatment Centre by Government
            of Kerala for treating and managing COVID-19 patients.
          </p>
          <p class="text-padd-content">
            COVID Testing Centre of NIMS Medicity has also been empanelled by
            Pure Health Medical Supplies L.L.C. Dubai as the authorized centre
            for COVID 19 testing for individuals intending to travel to United
            Arab Emirates (UAE). (Pure Health Medical Supplies L.L.C. Dubai has
            been the sole organization worldwide to be authorized by Government
            of UAE for screening the individuals who intend to travel to UAE).
          </p>
        </div>
        <div class="col-sm-4">
          <img
            src="assets\img\departments\cardiology\heart-web.png"
            class="img-fluid cardio-img rounded mx-auto d-block p-3"
            style="width: 57%"
            alt="..."
          />
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="container">
      <div class="row">
        <div class="col-sm"></div>
        <div class="col-sm">
          <div class="invasive-list">
            <h4 style="color: #2f905b; font-weight: 800">
              FEATURES OF AYUSH BLOCK
            </h4>
          </div>
          <ul class="invasive-list-item">
            <li>
              24 hours dialysis for outpatient service (Covid Positive) first
              initiative of private sector in Kerala
            </li>
            <hr />
            <li>Category A,B & C admission facility</li>
            <hr />
            <li>Full time intensivist</li>
            <hr />
            <li>Round the clock doctor availability</li>
            <hr />
            <li>Ventilator facility</li>
            <hr />
            <li>11 bedded ICU</li>
            <hr />
            <li>10 bedded HDU</li>
            <hr />
            <li>High flow oxygenator facility</li>
            <hr />
            <li>Physiotherapy facility for bedridden</li>
            <hr />
            <li>Telemedicine facility available</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="container cardiology-aboutus">
    <div class="row">
      <div class="col-sm aboutus-list">
        <ul>
          <li>
            Post Covid Clinic- Neuro medicine, Respiratory Medicine, General
            Medicine, Occupation Therapy, Physiotherapy, Dietician consultation
            & Naturopathy package.
          </li>
          <li>For further details please contact: 9745493735, 8547707221</li>
          <li>
            RT-PCR testing for Covid 19 is available at NIMS medicity .For
            further details please contact +91 97454 93735
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div *ngIf="pathFldr === '/departments/dermatology'">
  <div class="container category-section">
    <div class="container">
      <div class="row">
        <div class="col-sm-8 cardiology-text">
          <p class="text-padd-content">
            Being the body’s largest organ, ensuring that your skin is healthy
            is a vital component of maintaining the body’s overall condition.
            Our Dermatology department offers both disease prevention and
            treatments as well as cosmetic programs to ensure that our patients
            are taken care of, whatever their need may be.
          </p>
          <p class="text-padd-content">
            The Department of Dermatology offers comprehensive treatment for
            clinical and cosmetic problems related to skin, hair and nails. We
            provide treatment for dermatological disorders like acne, psoriasis,
            eczema, hair loss, keloids, fungal nail infections etc.
          </p>
          <p class="text-padd-content">
            The department also provides a wide range of cosmetic services for
            patients of all age group and uses lasers for removal of tattoos
            scars.
          </p>
        </div>
        <div class="col-sm-4">
          <img
            src="assets\img\departments\cardiology\heart-web.png"
            class="img-fluid cardio-img rounded mx-auto d-block p-3"
            style="width: 57%"
            alt="..."
          />
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="pathFldr === '/departments/developmental-medicine'">
  <div class="container category-section">
    <div class="container">
      <div class="row">
        <div class="col-sm-8 cardiology-text">
          <p class="text-padd-content">
            The Developmental Medicine is a broad speciality division of NIMS
            SPECTRUM-Child Development Research Centre providing;
            Neuro-developmental follow-up and early stimulation for neonatal
            intensive care nursery (NICU) graduates from any hospital,
            specifically pre-term-low birth weight babies and others with risk
            factors like prolonged NICU stay, hypoxia, hypoglycemia, septic
            shock, hyper-bilirubinemia, etc. Evaluation and early intervention
            for children with Developmental delay, Language delay, communication
            delay and social deprivation Specific therapy for ten
            neuro-developmental disabilities like Autism Spectrum Disorders,
            Visual Impairment, Hearing Impairment, Speech and Language
            impairment, Intellectual Disability, Attention Deficit Hyperactive
            Disorder, Learning Disability, Epilepsy, Cerebral Palsy and
            Neuro-muscular disorders. Specific therapy units mainly include;
            Developmental Therapy,Physiotherapy, Audiology, Speech Therapy, Behaviour Therapy, Special
            Education, Occuaptional Therapy, Sensory Integration Therapy, etc. Referral services of
            medical super specialists in paediatrics like; neurology,
            endocrinology, ophthalmology, ENT, orthopaedics, clinical genetics,
            Gynecology in the same campus. Counselling services for parents of
            children with delay/disorder/disability and for adolescents and
            young adults are also provided regularly.
          </p>
          <p class="pt-2">
            <b
              >Consultation with prior appointments, please call: 97455 86411
              between 9.00am to 4.00pm</b
            >
          </p>
        </div>
        <div class="col-sm-4">
          <img
            src="assets\img\departments\cardiology\heart-web.png"
            class="img-fluid cardio-img rounded mx-auto d-block p-3"
            style="width: 57%"
            alt="..."
          />
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <h4 class="pt-2">“NIMS-SPECTRUM – CHILD DEVELOPMENT RESEARCH CENTRE”</h4>
    <p class="pt-2">
      In a highly competitive school environment as that exists in Kerala,
      children with disabilities – physical, mental, emotional and social – face
      tremendous odds. They are often stigmatised and confined to the margins,
      in ways that can truly curtail healthy development into adulthood. Thus,
      disabled children are worse off when they belong to economically or
      socially deprived families; girls who are disabled may be worse off than
      boys, and so on. According to the State Plan of Action for the Child in
      Kerala (SPACK) 2004 document, the main goal of the action plan is to
      provide equal opportunity and conducive environment for the physical,
      mental, emotional, aesthetic, spiritual and social development of all
      children.
    </p>
    <p class="pt-2">
      This then calls for increased focus on compulsory elementary education,
      special education for the needy, eradication of illiteracy, education for
      women’s equality and a special focus on the education of the tribal
      population. Comprehensive coverage of all the groups in education will
      have long-standing implications in child welfare and at present
      Samagra Shiksha Abhiyan (SSA) offers the best opportunity for inclusive
      education of the disabled child. In the Kerala context, it appears that
      reduction of low birth weight should be the central point of our thoughts
      and actions, whether it is for reduction of mortality, morbidity,
      childhood disability or poor scholastic performance. All these cannot be
      achieved without a public-private partnership.
    </p>
    <h5 class="pt-2">
      The burden of Developmental Delay/Disorder/Disability in Kerala
    </h5>
    <p class="pt-2">
      On a projection based on a Kerala ICDS block data, at a given time, there
      would be nearly one lakh children with developmental delay/disability
      among under-5s in Kerala, with developmental delay (14000), speech delay
      (22000), vision & hearing problems (15000), orthopaedic related (20000),
      intellectual disability (6000) and others (3000). The State Initiative on
      Disability (SID) Kerala, is an ambitious project of the Government of
      Kerala with provision for early detection of developmental delay using
      Trivandrum Developmental Screening chart (TDSC)0 – 6 years and Language
      Evaluation Scale Trivandrum (LEST) 0 – 6 years. Under the Rashtriya Bal
      Swasthya Karyakram(RBSK) project, there would be District Early
      Intervention Centres (DEICs) established with appropriate therapy
      facilities. The question is, is it enough considering the fact that nearly
      70% of health care in Kerala is provided by the private sector.
    </p>
    <h5 class="pt-2">School Child – School Readiness, Learning Difficulty</h5>
    <p class="pt-2">
      Pre-school years lay the foundation for optimum growth and development in
      the areas of health, hygiene, nutrition, language development, personality
      building and social adjustments, besides the physical, emotional and
      intellectual development. As these form the base for formal education, it
      is important that school readiness is tested before enrolling the child to
      Class-1 at 6 years. In order to reduce the burden of learning problems,
      children with scholastic backwardness or poor school performance need to
      be assessed for intelligence, environmental deprivation, specific subject
      problem, poor fit with the school or the teacher or for early evidence of
      mental illness. Improving study habit has been found to be most useful,
      but we need a facility in the private sector also, that provide supportive
      counselling services.
    </p>
    <h5 class="pt-2">
      Young People – Growth, Reproductive and Mental Health Counselling
    </h5>
    <p class="pt-2">
      Many of the nutritional, reproductive and mental health needs of the
      adolescent population are required to be addressed and can be addressed in
      the primary-care paediatric setting itself if the current system of
      health-care can be re-organized as per Rashtriya Kishor Swasthya Karyakram
      (RKSK) guidelines. This restructuring will be more effective for this
      population if adolescent-friendly approaches, public-private partnership
      and policy as well as the sectoral linkage between the National Health
      Mission (NHM) and other national programs are achieved. The dramatic
      increase in physical growth and development puts greater pressure on the
      need for nutrients. As both undernutrition (38%) and overweight / obesity
      (13%) could be present among adolescents, they should be recognized as a
      priority target group for nutrient supplementation in conjunction with
      healthy eating habits and physical activity. On the other hand, obesity is
      associated with the polycystic ovarian syndrome (PCOS) and screening
      should be done for obesity, menstrual irregularity and signs of clinical
      hyperandrogenism for early diagnosis of PCOS in an effort to prevent
      infertility and lifestyle diseases later on.
    </p>
    <p class="pt-2">
      A life cycle approach to child development is a philosophy that
      encompasses all actions essential for preparing for future motherhood,
      joyful pregnancy and safe delivery and optimal growth and development of
      children and adolescents that in turn should lead to responsible
      parenthood. This, in essence, means caring for the most critical nine
      months of intrauterine growth, the vulnerable first six years of life and
      the most neglected adolescent period and the same has adequate policy
      endorsement. This approach becomes even more important for at-risk LBW
      babies with a chance for impairment (biological) that lead on to
      disability (functional) to handicap (social). We wish to achieve the same
      through a comprehensive plan of establishing the one and only
      “NIMS-SPECTRUM-Child Development Research Centre (NIMS-SPECTRUM-CDRC)”
      with three key divisions namely;
    </p>
    <h6 class="pt-2">
      I. Clinical Child Development Division for Prevention, Early detection and
      Early intervention for Developmental disorders with a focus on;
    </h6>
    <p class="pt-1 ps-2">
      Neuro-developmental follow-up and early intervention for LBW babies
    </p>
    <p class="pt-1 ps-2">
      Assessment and early intervention for developmental delay (0 – 6 yrs)
    </p>
    <p class="pt-1 ps-2">
      Assessment and early language intervention (0 – 6 yrs)
    </p>
    <p class="pt-1 ps-2">
      Assessment and early intervention for Autism (0 – 6 yrs)
    </p>
    <p class="pt-1 ps-2">
      Assessment and intervention for Preschool children (4 – 6 yrs)
    </p>
    <p class="pt-1 ps-2">
      Assessment and intervention for Primary school children (6 – 9 yrs)
    </p>
    <h6 class="pt-2">
      II. Specialised Therapy Division for Developmental delay
      /disorders/disability
    </h6>
    <p class="pt-1 ps-2">1. Early stimulation unit for high-risk newborns</p>
    <p class="pt-1 ps-2">
      2. Developmental therapy unit for developmental delay
    </p>
    <p class="pt-1 ps-2">
      3. Audiology & Speech therapy unit for speech and hearing problems
    </p>
    <p class="pt-1 ps-2">
      4. Paediatric Physiotherapy unit for Cerebral Palsy and other
      neuromuscular disorders in children
    </p>
    <p class="pt-1 ps-2">
      5. Sensory integration unit for autism children and special education for
      the needy.
    </p>
    <p class="pt-1 ps-2">
      6. Occupational therapy unit promoting activities of daily living and
      appropriate special education techniques for the needy.
    </p>
    <p class="pt-1 ps-2">
      7. Learning disability Lab for children with learning problems.
    </p>
    <h6 class="pt-2">
      III. Comprehensive Health Care Division for Differently abled children
    </h6>
    <p class="pt-1 ps-2">
      1. Paediatric Neurology unit – neurological evaluation and medication
    </p>
    <p class="pt-1 ps-2">
      2. Paediatric Ophthalmology Unit – ROP Screening and ophthalmic evaluation
    </p>
    <p class="pt-1 ps-2">3. Paediatric ENT Unit – Cochlear implant surgery</p>
    <p class="pt-1 ps-2">
      4. Paediatric Orthopaedic Unit – Rehabilitation surgery
    </p>
    <p class="pt-1 ps-2">
      5. Paediatric Genetics diagnosis unit – Special genetic investigations
    </p>
    <p class="pt-1 ps-2">
      6. Paediatric Counselling/Education unit – Psychological interventions
    </p>
    <p class="pt-1 ps-2">
      7. Paediatric Dental Care unit – Dental care and hygiene education
    </p>
    <p class="pt-1 ps-2">
      8. Paediatric Nutrition & Dietetics unit – Management of specific feeding
      problems
    </p>
    <p class="pt-1 ps-2">
      9. Rehabilitation Nursing Unit – Comprehensive care counselling
    </p>
    <p class="pt-1 ps-2">
      10. Ayurveda & Naturopathy – Alternate systems interventions
    </p>
    <p class="pt-1 ps-2">
      11. Prevention of disability – Health of young adults unit – PCOS clinic
    </p>
    <p class="pt-2">
      The NIMS-SPECTRUM – CHILD DEVELOPMENT RESEARCH CENTRE at NIMS Medicity,
      Neyyattinkara, Thiruvananthapuram, is being established under the guidance
      of Prof.(Dr.) M. K. C. Nair D.Sc., the founder Director of CDC Kerala and
      formerly the Vice-Chancellor, Kerala University of Health Sciences. It is
      visualised as a comprehensive project, utilizing the already existing
      infrastructure and health care professionals of NIMS, and consolidating
      the 18 established assessment and therapy units under one umbrella. The
      established mission of NIMS Medicity to provide high quality,
      cost-effective, state-of-the-art services to all needy without any
      discrimination based on paying capacity will be maintained at
      NIMS-SPECTRUM-CDRC.
    </p>
  </div>
</div>
<div *ngIf="pathFldr === '/departments/dietary'">
  <div class="container category-section">
    <div class="container">
      <div class="row">
        <div class="col-sm-8 cardiology-text">
          <p class="text-padd-content">
            The philosophy of the Department of Dietetics in our Group is to
            establish and encourage good nutritional practices and standards as
            an integral part of the health care provided. Being patient-centric
            is not a goal, it is the core of our existence.
          </p>
        </div>
        <div class="col-sm-4">
          <img
            src="assets\img\departments\cardiology\heart-web.png"
            class="img-fluid cardio-img rounded mx-auto d-block p-3"
            style="width: 57%"
            alt="..."
          />
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid services-cardiology">
    <header class="text-center pt-5">
      <h3 class="mb-1">Objectives</h3>
      <h5 class="mb-3">Department of Dietary Services</h5>
    </header>
  </div>
  <div class="container">
    <div class="container">
      <div class="row">
        <div class="col-sm">
          <div class="invasive-list">
            <h4 style="color: #2f905b; font-weight: 800">Objectives</h4>
          </div>
          <ul class="invasive-list-item">
            <li>
              To provide the highest quality nutrition care and dietetic
              services
            </li>
            <hr />
            <li>
              To advancing practice of clinical nutrition including developing
              specialist dietetic teams within the department
            </li>
            <hr />
            <li>
              To develop effective therapies and new treatments, using
              evidence-based practice and
            </li>
            <hr />
            <li>
              To educate future Dietitians and other healthcare providers.
            </li>
          </ul>
        </div>
        <div class="col-sm"></div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="pathFldr === '/departments/emergency-medicine'">
  <div class="container category-section">
    <div class="container">
      <div class="row">
        <div class="col-sm-8 cardiology-text">
          <p class="text-padd-content">
            NIMS Medicity is a pioneer of modern day emergency care in India. It
            set up the National networks of emergency. Services to provide
            emergency care of uniform quality standards across the country. Its
            24 hrs emergency and trauma care is geared to meet all medical and
            surgical emergencies, including polytrauma.
          </p>
        </div>
        <div class="col-sm-4">
          <img
            src="assets\img\departments\cardiology\heart-web.png"
            class="img-fluid cardio-img rounded mx-auto d-block p-3"
            style="width: 57%"
            alt="..."
          />
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid services-cardiology">
    <header class="text-center pt-5">
      <h3 class="mb-1">Services</h3>
      <h5 class="mb-3">Department of Emergency Medicine Services</h5>
    </header>
  </div>
  <div class="container">
    <div class="container">
      <div class="row">
        <div class="col-sm">
          <div class="invasive-list">
            <h4 style="color: #2f905b; font-weight: 800">Services</h4>
          </div>
          <ul class="invasive-list-item">
            <li>Endotracheal intubation</li>
            <hr />
            <li>CPR</li>
            <hr />
            <li>Dressing</li>
            <hr />
            <li>Suturing</li>
            <hr />
            <li>Defibrillator</li>
            <hr />
            <li>POP</li>
          </ul>
        </div>
        <div class="col-sm">
          <div class="invasive-list">
            <h4 style="color: #2f905b; font-weight: 800">
              Inter Relationship with
            </h4>
          </div>
          <ul class="invasive-list-item">
            <li>OT</li>
            <hr />
            <li>ICU</li>
            <hr />
            <li>Blood bank</li>
            <hr />
            <li>Laboratory</li>
            <hr />
            <li>OPD</li>
            <hr />
            <li>Radiology</li>
            <hr />
            <li>Endoscopy</li>
            <hr />
            <li>CCU</li>
            <hr />
            <li>Mortuary</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="pathFldr === '/departments/endocrinology-and-diabetology'">
  <div class="container-fluid services-cardiology">
    <header class="text-center pt-5">
      <h3 class="mb-1">Services</h3>
      <h5 class="mb-3">Department of Endocrinology & Diabetology Services</h5>
    </header>
  </div>
  <div class="container">
    <div class="container">
      <div class="row">
        <div class="col-sm">
          <div class="invasive-list">
            <h4 style="color: #2f905b; font-weight: 800">
              Our facilities and services
            </h4>
          </div>
          <ul class="invasive-list-item">
            <li>
              All treatment process and medical facilities under the same roof.
            </li>
            <hr />
            <li>
              Well-equipped modern ICU, Laboratories and Operation Theatres.
            </li>
            <hr />
            <li>A branch of NIMS medicity.</li>
            <hr />
            <li>Adherence to international standards and guidelines.</li>
            <hr />
            <li>Dietician on call.</li>
            <hr />
            <li>Yoga classes and meditation.</li>
            <hr />
            <li>Weight control and reduction treatments.</li>
            <hr />
            <li>Telephonic treatment for needy patients.</li>
          </ul>
        </div>
        <div class="col-sm"></div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="pathFldr === '/departments/ent'">
  <div class="container category-section">
    <div class="container">
      <div class="row">
        <div class="col-sm-8 cardiology-text">
          <p class="text-padd-content">
            The department of ENT is one of the most well equipped departments
            with experienced faculty and infrastructure. The department has
            adjusted well with the advancement of medical technology.
          </p>
        </div>
        <div class="col-sm-4">
          <img
            src="assets\img\departments\cardiology\heart-web.png"
            class="img-fluid cardio-img rounded mx-auto d-block p-3"
            style="width: 57%"
            alt="..."
          />
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid services-cardiology">
    <header class="text-center pt-5">
      <h3 class="mb-1">Services</h3>
      <h5 class="mb-3">Department of ENT Services</h5>
    </header>
  </div>
  <div class="container">
    <div class="container">
      <div class="row">
        <div class="col-sm">
          <div class="invasive-list">
            <h4 style="color: #2f905b; font-weight: 800">Services</h4>
          </div>
          <ul class="invasive-list-item">
            <li>Endoscopic sinus surgery</li>
            <hr />
            <li>
              Otology and Rhinology Division – dealing with ear and nose
              disorders
            </li>
            <hr />
            <li>Paediatric Otolaryngology</li>
            <hr />
            
            <li>
              Comprehensive management of other endocrine disorders in both
              adults and children
            </li>
            <hr />
            <li>Audiometry/Tympanometry/OAE/BERA</li>
          </ul>
        </div>
        <div class="col-sm"></div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="pathFldr === '/departments/gastroenterology'">
  <div class="container category-section">
    <div class="container">
      <div class="row">
        <div class="col-sm-8 cardiology-text">
          <p class="text-padd-content">
            NIMS Institute of Liver Studies and Gastroenterology aims to provide
            advanced care for patients with GI and Liver disorders at affordable
            cost in the region. Here you will find a dedicated team of doctors
            and staff you can count on as well as a caring and comfortable
            environment. We strive to provide exceptional health care to
            patients in a friendly and professional manner.
          </p>
          <p class="text-padd-content">
            We pride ourselves on our state of art endoscopic services with
            innovative approach to disease management. We specialize in the
            prevention, diagnosis and treatment of disorders of the pancreas,
            liver, gallbladder, esophagus, stomach, small intestine and colon.
          </p>
          <p class="text-padd-content">
            We have an entire new block (SS memorial Block) dedicated to
            Gastroenterology services including OP Clinics, Endoscopy suites,
            Liver ICU, Gastro pay wards.
          </p>
          <p class="text-padd-content">
            There are various diseases that affect the human gastrointestinal
            tract involving the organs namely oesophagus, stomach, pancreas,
            liver, gall bladder and biliary tract, small and large intestine,
            rectum and anus. Surgical Gastroenterology is a subspecialty that
            deals with surgeries and management of these diseases.
          </p>
        </div>
        <div class="col-sm-4">
          <img
            src="assets\img\departments\cardiology\heart-web.png"
            class="img-fluid cardio-img rounded mx-auto d-block p-3"
            style="width: 57%"
            alt="..."
          />
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <h4>ENDOSCOPY</h4>
    <div class="p-3">
      <ul>
        <li>
          During 2011 January the endoscopic department became fully functional
          and marked upto the international standards.
        </li>
      </ul>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm">
        <h4>OUR SPECIALITIES</h4>
        <div class="p-3">
          <ul>
            <li>Fibroscan</li>
            <li>Capsule Endoscopy</li>
            <li>Breath Test</li>
            <li>PH Metry</li>
            <li>ERCP (Endoscopic Retrograde Cholangio Pancreatography)</li>
            <li>APC (Argon Plasma Coagulation)</li>
          </ul>
        </div>
        <h4>EQUIPMENTS</h4>
        <div class="p-3">
          <ul>
            <li>UGI Scope (Upper Gastrointestinal Scope)</li>
            <li>LGI Scope (Lower Gastrointestinal Scope)</li>
            <li>Side Viewing Scope</li>
            <li>Cautery with APC (Argon Plasma Coagulation)</li>
            <li>C-Arm</li>
            <li>Capsule Machine</li>
            <li>Heliprobe Machine</li>
            <li>Gastrolyzer</li>
            <li>Gastrograph</li>
            <li>Fibroscan Machine</li>
          </ul>
        </div>
      </div>
      <div class="col-sm">
        <h4>OTHER COMMON PROCEDURES</h4>
        <div class="p-3">
          <ul>
            <li class="d-flex justify-content-between">
              <div class="ms-2 me-auto">
                <div class="fw-bold">Endoscopy</div>
                <div class="ps-3">
                  <ul>
                    <li>EVL (Endoscopic Varicial Ligation)</li>
                    <li>Dilatation — Pneumatic</li>
                    <li>Dilatation — SG (Savery Gilliard)</li>
                    <li>Dilatation – CRE Balloon</li>
                  </ul>
                </div>
              </div>
            </li>
            <li>Percutaneous Endoscopic Gastrostomy (PEG)</li>
            <li>Foreign body removal</li>
            <li>Haemoclipping</li>
            <li class="d-flex justify-content-between">
              <div class="ms-2 me-auto">
                <div class="fw-bold">Endoscopy</div>
                <div class="ps-3">
                  <ul>
                    <li>Polypectomy</li>
                    <li>Colonic dilatation</li>
                  </ul>
                </div>
              </div>
            </li>
            <li>Schlerotherapy</li>
            <li>SEMS (Self Expanding Metallic Stent)</li>
            <li class="d-flex justify-content-between">
              <div class="ms-2 me-auto">
                <div class="fw-bold">
                  ERCP (Endoscopic Retrograde Cholangio Pancreatography)
                </div>
                <div class="ps-3">
                  <ul>
                    <li>Stenting</li>
                    <li>Stone Removal</li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="pathFldr === '/departments/general-medicine'">
  <div class="container category-section">
    <div class="container">
      <div class="row">
        <div class="col-sm-8 cardiology-text">
          <p class="text-padd-content">
            Internal Medicine department is a specialty that provides expertise
            in the diagnosis and treatment for a wide range of medical
            disorders, including diabetes, endocrine, gastrointestinal,
            respiratory, rheumatologic, neurological disorders as well as
            infectious disease. Endoscopic procedures both diagnostic and
            therapeutic are also available.
          </p>
        </div>
        <div class="col-sm-4">
          <img
            src="assets\img\departments\cardiology\heart-web.png"
            class="img-fluid cardio-img rounded mx-auto d-block p-3"
            style="width: 57%"
            alt="..."
          />
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid services-cardiology">
    <header class="text-center pt-5">
      <h3 class="mb-1">Services</h3>
      <h5 class="mb-3">Department of General Medicine Services</h5>
    </header>
  </div>
  <div class="container">
    <div class="container">
      <div class="row">
        <div class="col-sm">
          <div class="invasive-list">
            <h4 style="color: #2f905b; font-weight: 800">Services</h4>
          </div>
          <ul class="invasive-list-item">
            <li>Pre diabetes diagnosis and care</li>
            <hr />
            <li>Diabetes care</li>
            <hr />
            <li>Diabetes complication management</li>
            <hr />
            <li>Pituitary gland disorders and other gland care</li>
            <hr />
            <li>Management of Lupus erythramatous</li>
            <hr />
            <li>Management of Rheumatoid arthritis & other joints</li>
            <hr />
            <li>Obesity management</li>
            <hr />
            <li>Management of Thyroid nodules</li>
            <hr />
            <li>Management of Thyroid hyper functioning</li>
            <hr />
            <li>Management of Thyroid hypo functioning</li>
            <hr />
            <li>Pre hypertension care</li>
            <hr />
            <li>Hypertension management</li>
            <hr />
            <li>Lipid abnormalities</li>
            <hr />
            <li>Management of Malaria, Brucellosis</li>
            <hr />
            <li>Management of Tuberculosis, HIV, Pneumonia</li>
          </ul>
        </div>
        <div class="col-sm"></div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="pathFldr === '/departments/general-surgery'">
  <div class="container category-section">
    <div class="container">
      <div class="row">
        <div class="col-sm-8 cardiology-text">
          <p class="text-padd-content">
            NIMS Department of General Surgery has achieved remarkable and
            consistent results in the field of surgeries. We provide
            state-of-the-art facilities which deals with wide varieties of
            surgical problems. The Department of General Surgery forms the basis
            of surgical support system of NIMS Medicity and it is considered as
            the mother of all surgical specialties. We render services to both
            in patients and out patients.
          </p>
          <p class="text-padd-content">
            Our General Surgery Department specializes in treatment of all the
            general surgeries which includes minor, intermediate, major, supra
            major and high risk complex surgeries. A patient centered approach
            to care is provided. The department has the best infrastructure to
            deal with high risk and double high risk surgeries with the support
            of I.C.C.U, with excellent intensivists, who deliver post operative
            management with excellent skill and provide effective and ultimate
            care to their patients.
          </p>
        </div>
        <div class="col-sm-4">
          <img
            src="assets\img\departments\cardiology\heart-web.png"
            class="img-fluid cardio-img rounded mx-auto d-block p-3"
            style="width: 57%"
            alt="..."
          />
        </div>
        <p style="text-align: justify">
          We have a special bias with extensive experience and expertise in
          surgeries of Cancer, Breast, Thyroid, Vascular, Trauma Surgery,
          Gastroenterology, Pancreas, Endocrine, Oncology, Pediatric, Bariatric
          and Basic Cosmetic Surgeries.NIMS specialize in all open Abdominal
          surgeries, surgery for all the varieties of Hernias and all the other
          diseases.
        </p>
        <p style="text-align: justify">
          Our department is staffed with very competent and renowned faculties.
          The faculty team consists of well experienced doctors who have
          in-depth knowledge and skill in surgical procedures. These faculties
          had helped to achieve the proud reputation that the department now
          possesses. Working closely with colleagues in a multi-disciplinary
          medical setting, our board-certified staffs prioritizes collaboration
          with referring physicians as essential to optimal patient care.
        </p>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="container">
      <div class="row">
        <div class="col-sm">
          <div class="invasive-list">
            <h4 style="color: #2f905b; font-weight: 800">Services</h4>
          </div>
          <ul class="invasive-list-item">
            <li>
              Minimal Invasive Procedure for Hemorrhoid (MIPH) / Stapled
              Hemorrhoidectomy which is a pain free instant cure for piles
              superior to laser and cryosurgery and colorectal surgery.
            </li>
            <hr />
            <li>
              Perform comprehensive surveillance for healthcare-associated
              infections and epidemiologically significant organisms
            </li>
            <hr />
            <li>
              Hi-tech Colour Doppler navigation surgery for varicose veins
            </li>
            <hr />
            <li>Thyroid surgery</li>
            <hr />
            <li>CKeyhole Pediatric Orchidopexy for congenital anomalies</li>
            <hr />
            <li>Laparoscopic Urachal Cystectomy</li>
            <hr />
            <li>
              Early diagnosis and cosmetically pleasing surgeries for hernias,
              appendix, hydrocoeles and varicoeles. Flap repairs for pilonidal
              sinus Biopsies Excision / Incision / CT Scan and U/S guided.
            </li>
          </ul>
        </div>
        <div class="col-sm"></div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="pathFldr === '/departments/gynaecology'">
  <div class="container category-section">
    <div class="container">
      <div class="row">
        <div class="col-sm-8 cardiology-text">
          <p class="text-padd-content">
            Department of Obstetrics & Gynaecology is well equipped with
            Cardiotocographic Fetal Monitors, Baby Resuscitation equipments,
            Portable Ultrasound Machine, and well laid out waiting room.
          </p>
          <p class="text-padd-content">
            With an experienced team of Gynaecologists, well trained Endoscopic
            Surgeon and the Department offers excellent services in the field of
            Obstetrics and Gynaecology.
          </p>
          <p class="text-padd-content">
            The department also offers a Menopause and Well Woman Clinic, High
            Risk Pregnancy and Infertility Clinic. Epidural Anaesthesia for
            painless labour is available for asking round the clock, under the
            professional guidance of best team of Anaesthesiologists.
          </p>
        </div>
        <div class="col-sm-4">
          <img
            src="assets\img\departments\cardiology\heart-web.png"
            class="img-fluid cardio-img rounded mx-auto d-block p-3"
            style="width: 57%"
            alt="..."
          />
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="container">
      <div class="row">
        <div class="col-sm">
          <ul class="invasive-list-item">
            <li>Adhesiolysis</li>
            <hr />
            <li>cervical biopsy</li>
            <hr />
            <li>colporrhaphy</li>
            <hr />
            <li>colposcopy</li>
            <hr />
            <li>dilation and curettage (D&C)</li>
            <hr />
            <li>endometrial ablation</li>
            <hr />
            <li>endometrial or uterine biopsy</li>
            <hr />
            <li>fluid-contrast ultrasound (FCUS)</li>
            <hr />
            <li>hysterectomy</li>
            <hr />
            <li>hysterosalpingography</li>
            <hr />
            <li>hysteroscopy</li>
            <hr />
            <li>myomectomy</li>
            <hr />
            <li>oophorectomy</li>
            <hr />
            <li>pelvic ultrasound</li>
            <hr />
            <li>pelviscopy (pelvic laparoscopy)</li>
            <hr />
            <li>selective salpingography</li>
            <hr />
            <li>uterine suspensions</li>
            <hr />
            <li>vaginal vault</li>
            <hr />
            <li>toluidine blue dye test</li>
            <hr />
            <li>trachelectomy</li>
            <hr />
            <li>tubal ligation</li>
            <hr />
            <li>uterine (artery) fibroid embolization (UFE) and vulvectomy.</li>
            <hr />
            <li>Colposcopy</li>
            <hr />
            <li>Diagnostic Hysteroscopy</li>
            <hr />
            <li>Endometrial Ablation</li>
            <hr />
            <li>Female Sterilization</li>
            <hr />
            <li>Laparoscopic Hysterectomy</li>
            <hr />
            <li>Laparoscopic Supracervical Hysterectomy</li>
            <hr />
            <li>Laparoscopic Treatment of Ovarian Cysts</li>
          </ul>
        </div>
        <div class="col-sm">
          <ul class="invasive-list-item">
            <li>Pelvic Surgery</li>
            <hr />
            <li>Reconstructive Pelvic Surgery</li>
            <hr />
            <li>Surgery for Endometriosis</li>
            <hr />
            <li>Therapeutic Hysteroscopy</li>
            <hr />
            <li>Uterine Fibroid Embolization</li>
            <hr />
            <li>Vagina Reconstructive Surgery</li>
            <hr />
            <li>Fibroid removal (myomectomy)</li>
            <hr />
            <li>Ovarian cyst removal</li>
            <hr />
            <li>Cystoscopy</li>
            <hr />
            <li>Sacrocolpopexy – prolapse correction</li>
            <hr />
            <li>Adhesion lysis</li>
            <hr />
            <li>Diagnostic laparoscopy</li>
            <hr />
            <li>Sterilization</li>
            <hr />
            <li>Endometrial ablation</li>
            <hr />
            <li>Polypectomy</li>
            <hr />
            <li>Myomectomy/Myosure</li>
            <hr />
            <li>Diagnostic hysterectomy</li>
            <hr />
            <li>Anterior posterior repair / Posterior repair</li>
            <hr />
            <li>TOT/TVT sling</li>
            <hr />
            <li>LEEP</li>
            <hr />
            <li>IUD insertion</li>
            <hr />
            <li>
              Management of High Risk Pregnancy and excellent Neonatal care
            </li>
            <hr />
            <li>Nexplanon</li>
            <hr />
            <li>Cervical polyp removal</li>
            <hr />
            <li>Polycystic Ovarian Disease</li>
            <hr />
            <li>Recurrent Miscarriage</li>
            <hr />
            <li>Uterine Fibroids</li>
            <hr />
            <li>Endometriosis</li>
            <hr />
            <li>Irregular Cycle</li>
          </ul>
        </div>
        <div class="col-sm">
          <ul class="invasive-list-item">
            <li>Hirsutism</li>
            <hr />
            <li>Hyperprolactinemia</li>
            <hr />
            <li>Permimenopause</li>
            <hr />
            <li>Premature Ovarian Failure</li>
            <hr />
            <li>Anal Sphincteroplasty</li>
            <hr />
            <li>Bladder Instillation</li>
            <hr />
            <li>Botulinum Toxin Injections</li>
            <hr />
            <li>Excision (removal) of endometriosis</li>
            <hr />
            <li>Gynecologic Reconstructive Surgery</li>
            <hr />
            <li>Pelvic Floor Reconstruction</li>
            <hr />
            <li>Sacral Nerve Stimulation</li>
            <hr />
            <li>Sling Procedures for Urinary Incontinence</li>
            <hr />
            <li>Urethral Bulking Agent Injections</li>
            <hr />
            <li>Urodynamic Testing</li>
            <hr />
            <li>Vestibulectomy</li>
            <hr />
            <li>Breast Clinic</li>
            <hr />
            <li>Antenatal Clinic</li>
            <hr />
            <li>Post natal and Family planning</li>
            <hr />
            <li>Menopause Clinic</li>
            <hr />
            <li>High risk obstetrics</li>
            <hr />
            <li>Endoscopic facility</li>
            <hr />
            <li>Birth suite</li>
            <hr />
            <li>
              Prolapse Surgery or Surgical Repair of Pelvic Organ Prolapse
            </li>
            <hr />
            <li>Transportation facility and care</li>
            <hr />
            <li>Continuous feotal monitoring</li>
            <hr />
            <li>Pain less Labour</li>
            <hr />
            <li>IUI</li>
            <hr />
            <li>Caescarian section</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="pathFldr === '/departments/infectious-diseases'">
  <div class="container category-section">
    <div class="container">
      <div class="row">
        <div class="col-sm-8 cardiology-text">
          <p class="text-padd-content">
            The department focuses on the biological, immunological,
            epidemiological, and ecological aspects of viral, bacterial, and
            protozoan diseases of animals and humans, including the vectors that
            transmit infectious agents. Infectious disease includes HIV/AIDS,
            Tuberculosis, Chagas, Malaria, Pneumonia, Enteric diseases,
            inflammatory bowel, and autoimmune diseases. The department
            emphasizes basic pathogens mechanisms that may lead to better
            diagnostic tools, the development of vaccines and other
            interventions for prevention and control of infection and disease
            and the identification of new targets for antiviral and
            antiparasitic drugs.
          </p>
          <p>
            The mission of the department of hospital infection control is to
            support the institution’s tripartite mission of research, teaching
            and patient care; to promote patient safety by reducing the risk of
            acquiring and transmitting infections, and to be a leader in
            healthcare epidemiology and infection control.
          </p>
        </div>
        <div class="col-sm-4">
          <img
            src="assets\img\departments\cardiology\heart-web.png"
            class="img-fluid cardio-img rounded mx-auto d-block p-3"
            style="width: 57%"
            alt="..."
          />
        </div>
      </div>
    </div>
  </div>
  <div class="container cardiology-aboutus">
    <div class="row">
      <div class="col-sm aboutus-list">
        <h5 style="color: #2f905b; font-weight: 800">
          <b> Departmental Functions</b>
        </h5>
        <ul>
          <li>
            The ICU of the hospital has always been where the most vulnerable
            patients are kept , so they can be closely monitored and treated
            often with a one to one ratio of nurse to patient, for the most
            scrupulous of care
          </li>

          <li>
            Perform comprehensive surveillance for healthcare-associated
            infections and epidemiologically significant organisms
          </li>

          <li>Identify and investigate clusters or outbreaks of infection</li>

          <li>Analyze procedure and device associated infections</li>

          <li>
            Create evidence-based interventions to prevent healthcare-associated
            infections
          </li>

          <li>
            Evaluate methods and technologies to reduce transmission of
            pathogens within the institution
          </li>

          <li>Isolated Dialysis unit for +ve patients (Hepatitis B &C etc)</li>

          <li>Create and maintain appropriate infection control policies</li>

          <li>
            Develop and maintain educational programs regarding infection
            control for all hospital employees, physicians, and trainees
          </li>
          <li>
            Provide consultation to health care providers in the assessment and
            management of patients and employees with communicable diseases.
          </li>
          <li>
            Provide input for the content and scope of occupational health and
            safety programs related to infection control and prevention
          </li>
          <li>
            Advise senior leadership on issues related to the reduction of
            infection risks and regulatory requirements
          </li>
          <li>
            Administer ongoing programs and initiatives for continuous quality
            assessment, quality improvement, and infection risk reduction.
          </li>
          <li>
            Good infection prevention and control is essential to ensure that
            patients who undergo any surgical procedure within the operating
            theatre receive safe and effective care.
          </li>
        </ul>
      </div>
      <div class="col-sm">
        <!-- <img
              src="assets\img\departments\cardiology\heart-web.png"
              class="img-fluid"
              alt="..."
            /> -->
      </div>
    </div>
  </div>
</div>
<div *ngIf="pathFldr === '/departments/intensive-care'">
  <div class="container category-section">
    <p>
      Our intensivist’s follow the evidence-based guidelines for intensive care
      by there are well-documented benefits for the patients that include:
    </p>
    <div class="p-3">
      <ul>
        <li>Improved patient outcomes, including survival rates</li>
        <li>Reduced complications</li>
        <li>Shorter lengths of stay in the ICU</li>
        <li>Enhanced medication safety</li>
      </ul>
    </div>
    <p class="pt-2">
      Generally these patients are severely ill, and require intensive,
      round-the-clock care from a highly trained group of doctors, nurses, and
      other healthcare professionals
    </p>
  </div>
  <div class="container">
    <h4>For Families</h4>
    <p>
      Families play a very important role for patients in the MDICU. At the same
      time, our patients require rest to regain their strength and enhance
      healing. We will do everything to make your visits beneficial for you and
      your loved one. We ask that you:
    </p>
    <div class="p-3">
      <ul>
        <li>Assist us with encouraging rest for the patient</li>
        <li>Avoid visiting if you are sick</li>
        <li>
          Discuss visiting by children in advance with the ICU Nurse or
          intensivist
        </li>
        <li>
          Refrain from eating or drinking and using cell phones in the ICU
        </li>
      </ul>
    </div>
    <p class="pt-2">
      We also provide family support for those that need it. The physician, RN,
      and other regular MDICU staff are available along with social workers,
      patient representatives and clergy.
    </p>
    <p class="pt-2">
      <b>Infection Control:</b> Please help us prevent infections by observing
      precautions posted on doors and walls, following nurses’ instructions, and
      washing your hands before a visit.
    </p>
  </div>
  <div class="container">
    <h4>SEPSIS</h4>
    <p class="pt-2">
      Sepsis develops when a high number of infectious agents enter the blood
      stream, often from a burn or open wound, pneumonia, or a urinary tract
      infection. Symptoms of sepsis include fever, pale skin color, changes in a
      mental status, and increased heart rate. Untreated, sepsis can be fatal.
    </p>
    <p class="pt-2">
      The sepsis reduction program is now being implemented throughout the Mount
      Sinai Health System. The program uses a clinical decision support model
      that involves data-driven, multidisciplinary protocols to quickly identify
      and treat cases of sepsis. As part of the program, nurses receive
      additional training to recognize warning signs and to call the sepsis
      reduction team if they determine that a patient is at risk.
    </p>
  </div>
  <div class="container">
    <h4>SHOCK</h4>
    <p class="pt-2">
      Shock occurs when inadequate blood supply threatens multiple organs. Its
      causes range from congestive heart failure to sepsis. Symptoms of shock
      include weakness, cool and clammy skin, low blood pressure, dilated
      pupils, and altered mental status. Treatment of this life-threatening
      condition includes breathing assistance, blood transfusions, and
      intravenous medications.
    </p>
  </div>
  <div class="container">
    <h4>Respiratory Failure</h4>
    <p class="pt-2">
      Respiratory failure occurs when the lungs fail to pass enough oxygen to
      the blood (thereby depriving the organs of oxygen) or fail to remove
      carbon dioxide from the blood (thereby damaging the organs). Respiratory
      failure may be acute or chronic, with symptoms ranging from shortness of
      breath and rapid breathing to a bluish skin color and mental confusion.
      Depending on the severity of the condition, treatments may include an
      oxygen therapy mask, a tracheotomy, or use of a ventilator.
    </p>
  </div>
  <div class="container">
    <h4>ICU Glossary</h4>
    <p class="pt-2">
      Here are brief explanations of terms you may hear in the ICU:
    </p>
    <p class="pt-2">
      <b>Arterial Blood Gas:</b> Blood test on a sample of blood from the artery
      that helps evaluate how well the patient is breathing by measuring the
      amounts of oxygen and carbon dioxide.
    </p>
    <p class="pt-2">
      <b>Arterial Line:</b> Small plastic catheter placed in an artery and
      attached to a monitor screen to measure blood pressure and obtain blood
      samples.
    </p>
    <p class="pt-2">
      <b>Attending Physician:</b> Senior physician member of the team that
      oversees the patient’s care.
    </p>
    <p class="pt-2">
      <b>Cardiac Monitor (heart monitor):</b> Screen that displays the patient’s
      vital signs (heart rate, blood pressure, oxygen levels, breathing rate,
      etc.). Various wires, cable and electrodes connect the patient to this
      monitor.
    </p>
    <p class="pt-2">
      <b>Catheter (line):</b> Small plastic tube placed in a blood vessel (vein
      or artery) or elsewhere (e.g., bladder).
    </p>
    <p class="pt-2">
      <b>Central Line:</b> Special IV catheter placed in a large vein (usually
      near the neck or collar bone) to give intravenous fluids, medications or
      nutrition, and/or to measure pressures.
    </p>
    <p class="pt-2"><b>EKG:</b> Monitors and displays the heartbeat.</p>
    <p class="pt-2">
      <b>Endotracheal Tubes (ET Tubes):</b> Breathing tube that is placed in the
      trachea through the mouth or nose (or through the neck as tracheotomy).
      This tube is attached to a ventilator or another oxygen source to help the
      patient breathe.
    </p>
    <p class="pt-2">
      <b>Face Mask (Oxygen or O2 mask):</b> Plastic mask placed over the nose
      and mouth. Attached to plastic tubing that delivers oxygen (tank or wall
      source) to help the patient breathe.
    </p>
    <p class="pt-2">
      <b>Foley Catheter (Foley):</b> Soft tube inserted into the bladder to
      drain urine.
    </p>
    <p class="pt-2">
      <b>Isolation:</b> System to prevent the spread of infection to and from
      patients. May require staff and visitors to wear mask, gown, and/or gloves
      in the patient’s room.
    </p>
    <p class="pt-2">
      <b>Intensivist:</b> Physician or nurse with special training to care for
      critically ill patients and their families.
    </p>
    <p class="pt-2">
      <b>Intravenous Catheter (IV, IV line):</b> Small plastic tube placed into
      a vein to give fluids, medications, or nutrition, and/or measure
      pressures.
    </p>
    <p class="pt-2">
      <b>Nasal Cannula (cannula):</b> Plastic tube that fits around the head
      with two short prongs into nostrils. Delivers oxygen from an oxygen tank
      or wall source.
    </p>
    <p class="pt-2">
      <b>Nasogastric or Orogastric Tube (NG, OG):</b> A tube placed through the
      nose or mouth into the stomach. Used to give medications and nutrition,
      and/or to drain stomach contents.
    </p>
    <p class="pt-2">
      <b>Procedure:</b> Test, treatment, or insertion of catheter or device to
      prevent, treat, or diagnose a medical problem.
    </p>
    <p class="pt-2">
      <b>Pulse Oximeter (pulse ox, O2 sat monitor):</b> Device with a light
      sensor placed on finger, toe or ear lobe to monitor levels of oxygen in
      the blood.
    </p>
    <p class="pt-2">
      <b>Restraints:</b> Cloth cuffs placed to protect patients by limiting
      patient movement.
    </p>
    <p class="pt-2">
      <b>Sequential Compression Devices (SCD, venodynes):</b> Inflate and
      deflate to massage the legs to prevent blood clots.
    </p>
    <p class="pt-2">
      <b>Suction:</b> Removal of secretions or drainage from the patient’s
      airway (trachea, lungs) or other body cavities (e.g., stomach).
    </p>
    <p class="pt-2">
      <b>Tracheotomy:</b> Surgical opening in the neck for insertion of
      breathing (endotracheal) tube into the patient’s airway. This tube is
      attached to a ventilator or another oxygen source to help the patient
      breathe.
    </p>
    <p class="pt-2">
      <b>Vasoactive Drugs (vasopressors, pressors, drips):</b> Medications that
      are given through an IV to help increase or decrease blood pressure and
      heart function.
    </p>
    <p class="pt-2">
      <b
        >Ventilator/Respirator (vent, breathing machine, mechanical
        ventilation):</b
      >
      Machine attached to the patient through an ET tube (in mouth, nose or
      neck) to help the patient breathe.
    </p>
  </div>
</div>
<div *ngIf="pathFldr === '/departments/medical-genetics'">
  <div class="container category-section">
    <div class="container">
      <div class="row">
        <div class="col-sm-8 cardiology-text">
          <p class="text-padd-content">
            We have newly started the department of Medical Genetics in NIMS
            Medicity. Genetic disorders are those disorders with onset from
            birth or later in life and many of them could be seen in multiple
            family members. Genetic disorders can affect any body system. These
            disorders are seen in 2-3% of our population. With early detection
            and with proper treatment plans we can easily prevent them from
            developing into major disabilities in the future. We could also
            prevent the transmission of these disorders in the future generation
            by prenatal testing, provided we do thorough evaluation of the
            affected patient. Here in this department a trained Geneticist will
            do clinical evaluation of those with concerns of familial/
            hereditary or congenital disorders, provide accurate diagnostic
            services. Based on the diagnosis appropriate genetic counselling
            will be provided to the family for measures. Chromosomal
            abnormalities, which cause birth defects, mental retardation,
            stunting and disabilities in babies. After proper genetic evaluation
            of the affected child, we could prevent recurrence of the same
            disorder in future pregnancies. Single gene disorders such as
            muscular dystrophy, Huntington’s disease, thalassemia, sickle cell
            disease, spinal muscular atrophy, are found very commonly in our
            population. If the cause is identified through proper evaluation,
            other family members could do targeted testing for the same disorder
            and find out if they are affected or not. In future the genetic
            reasons of common life style disorders like diabetes and coronary
            heart disease could be identified, warranting the need for more
            precise genetic evaluation on population basis. Testing for genetic
            factors that affect drug prescribing (Pharmacogenomics) will be an
            important activity soon. In fact, the future of medicine is going to
            be genetics and epigenetics.
          </p>
        </div>
        <div class="col-sm-4">
          <img
            src="assets\img\departments\cardiology\heart-web.png"
            class="img-fluid cardio-img rounded mx-auto d-block p-3"
            style="width: 57%"
            alt="..."
          />
        </div>
        <p>
          We in NIMS Medicity, provide supportive therapies for all genetic
          disorders. We could also arrange various curative therapies for some
          rare genetic disorders which include advanced gene therapy, RNA
          interference therapy and Splicing modulation therapy as per the
          requirement of the patient.
        </p>
      </div>
    </div>
  </div>
</div>
<div *ngIf="pathFldr === '/departments/microbiology'">
  <!-- <p>microbiology</p> -->
</div>
<div *ngIf="pathFldr === '/departments/naturopathy'">
  <div class="container category-section">
    <div class="container">
      <div class="row">
        <div class="col-sm-8 cardiology-text">
          <p class="text-padd-content">
            Naturopathy is a natural healing science using the healing powers of
            nature. The principle of Naturopathy is that the accumulation of
            toxins is the root cause of all diseases. Elimination of the toxins
            in our body is is the route to health. Treatments in Naturopathy are
            based on the 5 great elements of nature that have immense healing
            properties.
          </p>
          <p class="text-padd-content">
            According to Indian mythology there are five main elements nature
            comprises of, which are Jal (Water), Vayu (Air), Akash (Sky), Agni
            (Fire) and Prithvi (Earth). These together are also known as
            Panchamahabhuta, which are also one of the chief attributes that
            form our body. In India, we use these elements in nature for the
            treatments.
          </p>
        </div>
        <div class="col-sm-4">
          <img
            src="assets\img\departments\cardiology\heart-web.png"
            class="img-fluid cardio-img rounded mx-auto d-block p-3"
            style="width: 57%"
            alt="..."
          />
        </div>
      </div>
    </div>
  </div>
  <div class="container pb-3">
    <h3>THE FIVE GREAT ELEMENTS OF NATURE</h3>
    <div class="row pt-1">
      <ul class="invasive-list-item">
        <li>Earth (Mud baths, Mud packs)</li>
        <hr />
        <li>
          Water (Hydrotherapeutic methods in the form of
          Baths,Jets,Douches,Packs,Compresses)
        </li>
        <hr />
        <li>Air ( Breathing exercises, Outdoor walking, Open air baths)</li>
        <hr />
        <li>
          Fire – Sun baths( Thermoleum baths, Magnetised water, Colour charged
          oils / water)
        </li>
        <hr />
        <li>Ether (Fasting therapy, Meditation)</li>
      </ul>
    </div>
  </div>
  <div class="container-fluid services-cardiology">
    <header class="text-center pt-5">
      <h3 class="mb-1">TREATMENTS AT NIMS NATUROPATHY</h3>
    </header>
  </div>
  <div class="container p-3">
    <p>
      At NIMS Naturopathy we combine Naturopathy & Yoga with Ayurveda treatments
      or Medicines. Our well qualified team of Doctors also make use of the
      Modern Diagnostic failities we have at NIMS. Senior Consultants in all
      departments are also available for advices in bringing down the dosage of
      medicines the patients use. So we are able to provide you fast and
      complete recovery in a natural way with out harming your body. This method
      of treatment will help you to get rid 0f all the impurities in your body
      and mind, and also rejuvenate your body..
    </p>
    <h4>TREATMENTS PROVIDED AT NIMS MEDICITY ARE:</h4>
    <div class="p-3">
      <ul>
        <li>Yoga</li>
        <li>Reflexology, Acupressure</li>
        <li>Food and Nutrition</li>
        <li>Hydrotherapy</li>
        <li>Mud Therapy</li>
        <li>Chromotherapy</li>
        <li>Massages and beauty therapies</li>
        <li>Ayurveda Panchakarma</li>
      </ul>
    </div>
    <p class="pt-2">
      This multi disciplinary approach uses the healing power of natural
      resources like foods, herbs, earth, water, air, sun and magnets to allow
      the body to heal itself. It helps in degenerative and chronic conditions
      like asthma and arthritis, gastro-intestinal problems and hypertension..
    </p>
  </div>
  <div class="container pt-3">
    <h3>YOGA</h3>
    <p class="pt-2">
      The root for the word Yoga comes from the Sanskrit word ‘Yuj’ which means
      the Union. Here it means the Union of the Mind, Body and Spirit, also the
      Union of Oneself with the Universal Self. This holistic science emphasises
      that this Union is necessary for a person seeking good health and
      well-being. Sage Patanjali is considered as the Father of Yoga. He
      formulated the Astanga Yoga – the Yoga with 8 paths which is widely
      practised today. They are:.
    </p>
    <div class="p-3">
      <ul>
        <li>Yama- meaning social restraints or ethical values</li>
        <li>Niyama- personal observance of purity, tolerance and study</li>
        <li>Asanas- physical exercise</li>
        <li>Pranayama- breath control</li>
        <li>Pratyahara- sense withdrawal for meditation</li>
        <li>Dharana- regulates concentration</li>
        <li>Samadhi- Transconsciousness/Ecstacy</li>
      </ul>
    </div>
  </div>
  <div class="container pt-3">
    <h3>MUD THERAPY</h3>
    <p class="pt-2">
      Of the five elements of nature, mud represents Earth and has tremendous
      impact on the maintenance of health and prevention of diseases. Minerals
      and trace elements present in the mud are known for its renowned effects
      and healing properties. Mud also has the remarkable property of holding
      moisture for a long time, which has a cooling effect on the part of the
      body applied.
    </p>
    <div class="p-3">
      <ul>
        <li>Helps improve circulation and relax the muscles</li>
        <li>Improves the digestive activity and sets right the metabolism</li>
        <li>
          Local application helps relieve inflammations, swellings and reduces
          pain
        </li>
        <li>Excellent in skin conditions without open lesions</li>
        <li>Helps bring down blood pressure</li>
        <li>Nourishes the skin</li>
        <li>Conditions the hair</li>
        <li>Specific kind of application relieves the stiffness of joints</li>
      </ul>
    </div>
    <h4>MUD PACKS</h4>
    <p class="pt-2">
      Frequent application of mud helps in improving the complexion of the skin
      by getting rid of spots and patches, which appear in various skin
      disorders. Mud baths are also generally recommended for all skin diseases,
      including psoriasis, leucoderma and urticaria and other allergic
      conditions of the skin.
    </p>
    <h4>BODY WRAPS</h4>
    <p class="pt-2">
      Using special Natural Muds high in natural minerals and salts the pack is
      applied on the entire body except the head.
    </p>
  </div>
  <div class="container pt-3">
    <h3>HYDROTHERAPY</h3>
    <p class="pt-2">
      This uses the therapeutic properties of water. This medium was made use in
      therapeutics hundreds of years ago. Water has great healing properties and
      exhibits different properties at different temperatures. The temperature
      of the water for any treatment depends on the effect desired. Kellogg is
      considered to be the Father of Hydrotherapy.
    </p>
    <p class="pt-2">
      Water is used internally and externally in all its forms- steam, liquid or
      ice, to cleanse and restore health. It is used in the form of Baths,
      Jets/Douches, Packs, Compresses and Immersions to name a few.
    </p>
    <p class="pt-2">
      Drinking a specific amount of water also has therapeutic effects:
    </p>
    <div class="p-3">
      <ul>
        <li>Helps maintain hydration of the body</li>
        <li>Helps in proper secretion of body fluids and maintenance of Ph.</li>
        <li>Aids digestion and prevents constipation</li>
        <li>Improves skin condition and nourishes it</li>
        <li>Maintains the flexibility of joints/li></li>
        <li>Helps in detoxification</li>
        <li>
          Excellent for relieving the numbness and tingling in the extremities
          as in diabetes mellitus
        </li>
      </ul>
    </div>
    <h4>HIP BATH</h4>
    <div class="p-3">
      <ul>
        <li>
          Stimulates the circulation towards the pelvic and digestive organs
          relieves constipation, flatulence and indigestion.
        </li>
        <li>
          Helps set right the menstrual disorders like dysmenorrhoea,
          menorrhagia, leucorrhoea
        </li>
        <li>
          Helps strengthen the hips and lumbar sacral spine and the coccyx and
          relieves pain if any
        </li>
        <li>Beneficial in relieving haemorrhoids</li>
      </ul>
    </div>
    <h4>SPINAL BATH / ANTI-STRESS BATH</h4>
    <div class="p-3">
      <ul>
        <li>Stimulates the spinal nerves</li>
        <li>Relaxes the paraspinal muscles</li>
        <li>Helps maintain the blood pressure</li>
        <li>Helps in regulating the moods like anxiety or depression</li>
        <li>Relaxes irritable nerves</li>
      </ul>
    </div>
    <h4>STEAM BATH</h4>
    <p class="pt-2">
      Exposing the entire body with the exception of the head to a superheated
      atmosphere helps in many ways. Steam inhalation helps decongest the
      respiratory tract
    </p>
    <div class="p-3">
      <ul>
        <li>Medicated ayurvedic or herbal steam helps rejuvenate the body</li>
        <li>Helps open up the skin pores</li>
        <li>Helps remove the toxins of the skin through sweating</li>
        <li>elps the skin maintain its natural moisture and softness</li>
      </ul>
    </div>
    <h4>WET PACKS</h4>
    <p class="pt-2">
      This treatment allows an in-depth detoxification of the areas which are
      swollen, blocked or sensitive and helps to restore the vitality of the
      skin and the body. There are several packs like abdomen, knee, trunk,
      shoulder, ankle and neck packs.
    </p>
    <div class="p-3">
      <ul>
        <li>Relieves inflammation and swellings</li>
        <li>Improves circulation</li>
        <li>Relieves the stiffness and spasms of muscles and joints</li>
      </ul>
    </div>
    <h4>COMPRESSES</h4>
    <p class="pt-2">
      This is the application of water in the form of a pack wrung out in water
      of different temperatures.
    </p>
    <div class="p-3">
      <ul>
        <li>
          Helps in detoxification of areas which are swollen, blocked or
          sensitive
        </li>
        <li>Excellent for tight sore muscles, edema, cellulite</li>
        <li>Stimulates the blood and lymph stream</li>
        <li>Gives soothing, refreshing, calming and decongesting qualities</li>
      </ul>
    </div>
  </div>
  <div class="container pt-3">
    <h3>REFLEXOLOGY</h3>
    <p class="pt-2">
      An Oriental healing technique used to relax the nerves and release the
      trapped energy. There are energy zones that run throughout the body and
      reflex areas in the feet that correspond to all the major organs, glands
      and parts of the body. Based on the principle that reflex points in the
      feet and palms correlate to individual organs and parts of the body,
      manipulations on these reflexes help stimulate the organs. It helps those
      suffering from insomnia, menstrual problems and pains.
    </p>
    <div class="p-3">
      <ul>
        <li>Reduces stress and induces deep relaxation</li>
        <li>Improves circulation.</li>
        <li>Cleanses the body of toxins and impurities</li>
        <li>Brings the whole system into balance</li>
        <li>Vitalises energy</li>
        <li>A preventive healthcare by enhancing the immune system</li>
        <li>Helps encourage healing in combination with specific treatments</li>
      </ul>
    </div>
  </div>
  <div class="container pt-3">
    <h3>ACUPRESSURE</h3>
    <p class="pt-2">
      This natural Oriental healing technique uses applied pressure to specific
      points on the body to relax trapped nerves and release blockages. It is
      used both as a preventive and curative therapy. It helps alleviate pain,
      fatigue and addresses musculoskeletal problems..
    </p>
    <div class="p-3">
      <ul>
        <li>Induces relaxation and restores balance</li>
        <li>Stimulates the circulatory and lymphatic system.</li>
        <li>
          The therapeutic touch stimulates certain chemicals in the brain that
          boost the immune system, lifts depression and relieves stress
        </li>
      </ul>
    </div>
  </div>
  <div class="container pt-3">
    <h3>NUTRITION AND DIETETICS</h3>
    <p class="pt-2">
      Using food as medicine, an individually devised, wholesome and natural
      diet is aimed at balancing nutritional deficiencies, combating allergies,
      digestive disorders and building resistance to disease. Medical nutrition
      therapy and specific diet strategies are advised for chronic conditions,
      illnesses, or injuries.
    </p>
    <h6 class="pt-2">MASSAGE THERAPY</h6>
    <p class="pt-1">
      There are different types of massages dependent on the materials and
      techniques used. To add to the experience we have soft soothing music and
      aromatic oils to stimulate the senses..
    </p>
  </div>
  <div class="container pt-3">
    <h3>NATURAL WEIGHT LOSS CLINIC @ NIMS IN TRIVANDRUM</h3>
    <p class="pt-2">
      Do you Want to Look 5-10 Years Younger Than Your Body Age???
    </p>
    <p class="pt-2">Here is the Answer…</p>
    <p class="pt-2">
      In this perplexed scenario of life style disorder overweight and obesity
      are two terms of a bit concern overweight is supposed to be a minimal
      weight gain of 3-5 kg more than is normal, while obesity is all cases
      above the overweight parameter. Since these two factors are the
      predisposing elements of all life style disorders like Diabetics,
      Hypertension, Hypercholesterolemia, IVDP, Osteoarthritis, Infertility,
      Polycystic ovary, Hypothyroidism weight reduction in absolutes essential
      or considered to be the need of the hour is every aspect. But it should be
      by all means in a pure and natural way giving a mental boosting of
      positive energy and a total mental system. Connection and relaxation both
      physically as well as mentally.
    </p>
    <p class="pt-2">
      The basic concept is importance of our NIMS Natural at lose clinic lies
      here.
    </p>
    <p class="pt-2">
      Cleansing, Detoxifying & Purification of Body with the help of Five
      elements from which your body is made up of. There is no role of any
      medication here, both internally as well as externally. The total therapy
      consists of
    </p>
    <div class="p-3">
      <ul>
        <li>Yama- meaning social restraints or ethical values</li>
        <li>Niyama- personal observance of purity, tolerance and study</li>
        <li>Asanas- physical exercise</li>
        <li>Pranayama- breath control</li>
        <li>Pratyahara- sense withdrawal for meditation</li>
        <li>Dharana- regulates concentration</li>
        <li>Samadhi- Transconsciousness/Ecstacy</li>
      </ul>
    </div>
    <p class="pt-2">
      The package encloses accommodation, food, different types of therapies,
      yoga, and even outing to the picnic spots etc.
    </p>
    <p class="pt-2">
      The rates vary from Rs. 35,000-Rs. 70000 for a 10 day package. Depends on
      the various disorders and type of stay.
    </p>
  </div>
</div>
<div *ngIf="pathFldr === '/departments/neonatology'">
  <div class="container category-section">
    <div class="container">
      <div class="row">
        <div class="col-sm-8 cardiology-text">
          <p class="text-padd-content">
            To provide state of art new born care at the hospital; we also take
            our new born services to the community to make people aware about
            the essential new born care . We would like to bring up our unit as
            a perinatal network centre working in collaboration with level-1 &
            level-2 NICUS of other hospitals.
          </p>
        </div>
        <div class="col-sm-4">
          <img
            src="assets\img\departments\cardiology\heart-web.png"
            class="img-fluid cardio-img rounded mx-auto d-block p-3"
            style="width: 57%"
            alt="..."
          />
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="container">
      <div class="row">
        <div class="col-sm">
          <ul class="invasive-list-item">
            <li>Surfactant therapy for respiratory distress syndrome</li>
            <hr />
            <li>Ventilation</li>
            <hr />
            <li>CPAP</li>
            <hr />
            <li>Phototherapy</li>
            <hr />
            <li>Blood and Blood product transfusion</li>
            <hr />
            <li>Peritoneal dialysis</li>
            <hr />
            <li>Lumbar Puncture</li>
          </ul>
        </div>
        <div class="col-sm">
          <ul class="invasive-list-item">
            <li>PICC line insertion</li>
            <hr />
            <li>TPN</li>
            <hr />
            <li>USG Head</li>
            <hr />
            <li>ROP evaluation & Laser treatment</li>
            <hr />
            <li>Neonatal Transport</li>
            <hr />
            <li>Exchange transfusion</li>
            <hr />
            <li>Catheterization of bladder/Suprapubic sampling</li>
          </ul>
        </div>
        <div class="col-sm">
          <ul class="invasive-list-item">
            <li>Umbilical artery catheterization</li>
            <hr />
            <li>Umbilical vein catheterization</li>
            <hr />
            <li>Ventricular or Subdural tap</li>
            <hr />
            <li>ICD insertion</li>
            <hr />
            <li>Arterial line</li>
            <hr />
            <li>Partial exchange</li>
            <hr />
            <li>Care of extreme pre term</li>
            <hr />
            <li>LEVEL 3 NICU</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="pathFldr === '/departments/nephrology'">
  <div class="container category-section">
    <div class="container">
      <div class="row">
        <div class="col-sm-8 cardiology-text">
          <p class="text-padd-content">
            The patient care services provided by the division of Nephrology at
            NIMS cover all aspects of our clinical discipline including
            preventive nephrology, acute kidney injury, glomerulonephritis,
            vasculitis, electrolyte and acid-base disorders, hypertension,
            genetic kidney diseases, renal complications of pregnancy, kidney
            stone disease, chronic kidney disease (CKD), hemodialysis,
            peritoneal dialysis and renal osteodystrophy. We provide primary
            care to a large group of patients with end-stage renal disease
            (ESRD) treated with hemodialysis. We provide a complete range of
            consultative, diagnostic and treatment services for patients with
            kidney diseases. The scope of care encompasses all stages of kidney
            disease, from earliest detectable changes in kidney function through
            end-stage kidney disease, as well as people with Diabetes Mellitus
            Type 1 & 2, high blood pressure, kidney stones, and other
            kidney-related problems.Our Renal Transplantation 
            facility have people coming from all over Kerala and other states.
          </p>
          <h5><b>DIALYSIS UNIT</b></h5>
          <p class="text-padd-content">
            The Dialysis facility at NIMS is a hospital-based unit that has 11
            hemodialysis stations. The unit also provides continuous ambulatory
            peritoneal dialysis (CAPD) treatment to a diverse group of adult
            patients who have chronic (long-term) kidney failure. The unit also
            has an affordable program where ordinary people can rely on our
            dialysis unit which is 24X7 fully equipped and functional.
          </p>
          <p class="text-padd-content">
            Dialysis performs the work of the kidneys by cleansing the blood and
            removing wastes and excess water. The two types of dialysis are
            hemodialysis and peritoneal dialysis. In hemodialysis, the patient’s
            blood is passed through an artificial kidney machine and this
            procedure is performed in a hospital or similar facility. In
            peritoneal dialysis, the blood is filtered within the patient’s
            abdomen and the dialysis fluid there is exchanged through a
            catheter. CAPD can be done at home.
          </p>
          <p class="text-padd-content">
            Dialysis care at NIMS is provided by a team of professionals
            including a nephrologist, registered nurses, certified hemodialysis
            technicians, registered dieticians and a clinical social worker. The
            unit’s goal is to maintain and improve the overall health and
            quality of life for patients with End Stage Renal Disease (ESRD). We
            continuously monitor patient satisfaction and clinical indicators in
            order to measure and improve the quality of patient care and to
            maintain our high standards of operation.
          </p>
          <h5><b>RENAL TRANSPLANTATION UNIT</b></h5>
          <p class="text-padd-content">
           Kidney transplantation is the most effective treatment option for ESRD.It has become the most successful
           and widespread organ transplantation perfomed today.The department of kidney transplant surgery is dedicated 
           to provide comprehensive perioperative care to patients with End Stage Renal Disease(ESRD).
          </p>
        </div>
        <div class="col-sm-4">
          <img
            src="assets\img\departments\cardiology\heart-web.png"
            class="img-fluid cardio-img rounded mx-auto d-block p-3"
            style="width: 57%"
            alt="..."
          />
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid services-cardiology">
    <header class="text-center pt-5">
      <h3 class="mb-1">SPECIALITY</h3>
      <h5 class="mb-3">Department of Nephrology Services</h5>
    </header>
  </div>
  <div class="container">
    <div class="container">
      <div class="row">
        <div class="col-sm">
          <div class="invasive-list">
            <h4 style="color: #2f905b; font-weight: 800">SERVICES</h4>
          </div>
          <ul class="invasive-list-item">
            <li>CAPD</li>
            <hr />
            <li>Temporary Catheter Insertion (24X7)</li>
            <hr />
            <li>SLED</li>
            <hr />
            <li>ICU Dialysis for High Risk or Extremily Sick Patients</li>
            <hr />
            <li>Renal Biopsy</li>
            <hr />
            
            <li>
              Isolated Dialysis unit for +ve patients (Hepatitis B &C etc)
            </li>
            <hr />
            <li>Plasmapheresis</li>
            <hr />
            <li>Hemoperfusion</li>
            <hr />
            <li>Permanent catheter insertion</li>
            <hr />
            <li>AV Fistula Creation</li>
          </ul>
        </div>
       
      </div>
    </div>
  </div>
</div>
<div *ngIf="pathFldr === '/departments/neuro-surgery-and-neurology'">
  <div class="container category-section">
    <div class="container">
      <div class="row">
        <div class="col-sm-8 cardiology-text">
          <p class="text-padd-content">
            The Department of Neurosurgery at NIMS Medicity is a specialized
            unit supported by neurosurgeons with rich experience of more than 20
            years.
          </p>
          <p class="text-padd-content">
            We offer 24*7 comprehensive and advanced treatment and care for
            patients with stroke, autoimmune encephalopathies, epilepsy,
            headache, multiple sclerosis, neuromuscular disorders, dementia
            disorders, movement disorders and other neurodegenerative disorders,
            as well as critical care neurology, endovascular procedures, neuro
            intensive care, advanced neuroimaging, immunotherapy and
            multidisciplinary rehabilitation in collaboration with other
            departments.
          </p>
          <p class="text-padd-content">
            TIt deliver comprehensive surgical care for patients with brain,
            spinal cord, and peripheral nerve diseases. Our specialists combine
            a sincere interest in the welfare of their patients with cutting
            edge surgical skills and technology.
          </p>
          <p class="text-padd-content">
            Neurological surgery maintains dedicated programs in brain, skull
            base and pituitary tumors, spinal surgery, vascular neurosurgery,
            movement disorders, epilepsy, facial pain, complex spinal and cancer
            pain, pediatric neurosurgery, craniofacial disorders, hydrocephalus,
            spina bifida, spasticity, peripheral nerve disorders v and nervous
            system trauma.
          </p>
        </div>
        <div class="col-sm-4">
          <img
            src="assets\img\departments\cardiology\heart-web.png"
            class="img-fluid cardio-img rounded mx-auto d-block p-3"
            style="width: 57%"
            alt="..."
          />
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid services-cardiology">
    <header class="text-center pt-5">
      <h3 class="mb-1">Services</h3>
      <h5 class="mb-3">Department of Neuro Surgery & Neurology Services</h5>
    </header>
  </div>
  <div class="container">
    <div class="container">
      <div class="row">
        <div class="col-sm">
          <div class="invasive-list">
            <h4 style="color: #2f905b; font-weight: 800">SERVICES</h4>
          </div>
          <ul class="invasive-list-item">
            <li>Brain Tumors</li>
            <hr />
            <li>Spine Care</li>
            <hr />
            <li>
              Image-guided diagnostic and therapeutic procedures, including
              nerve blocks, functional discography and vertebroplasties
            </li>
            <hr />
            <li>Cerebrovascular Diseases</li>
            <hr />
            <li>
              Aneurysmal surgery, surgery for AVMs, Carotid endarterectomy,
              stenting and Decompressive craniectomy
            </li>
            <hr />
            <li>Aneurysm coiling.</li>
            <hr />
            <li>Functional Neurosurgery</li>
            <hr />
            <li>
              Surgery for temporal lobe epilepsy, Extra-temporal epilepsy,
              functional hemispherectomies, resection of hypothalamic hamartoma
              and corpus callosotomy.
            </li>
            <hr />
            <li>
              Parkinson’s disease, essential tremor and Dystonia. It includes
              Deep Brain Stimulation (DBS) for patients with advanced
              Parkinson’s disease, Essential Tremor or dystonia.
            </li>
            <hr />
            <li>Neurotrauma</li>
          </ul>
        </div>
        <div class="col-sm">
          <div class="invasive-list">
            <h4 style="color: #2f905b; font-weight: 800">
              Pediatric Neurosurgery
            </h4>
          </div>
          <ul class="invasive-list-item">
            <li>
              Tumors, hydrocephalus, vascular Disorders, head and spine
              Injuries, myelomeningocele, other Congenital Malformations and
              epilepsy surgery.
            </li>
          </ul>
        </div>
        <div class="container cardiology-aboutus">
          <div class="row">
            <div class="col-sm aboutus-list">
              <ul>
                <li>Out patient services in morning and evening on all days</li>
                <li>Pediatric Indoor admissions</li>
                <li>
                  Care of sick children and consultant on call 24 hours a day.
                </li>
                <li>
                  Systematic vaccination program of on all days including
                  Sundays.
                </li>
                <li>
                  Well baby clinic- nutritional assessment, growth monitoring
                  and development assessment
                </li>
                <li>Pediatric asthma and allergy clinic</li>
                <li>
                  Clinic for musculoskeletal disorders of children on all
                  Saturday.
                </li>
                <li>
                  Child and adolescent counseling by psychiatrist and
                  psychologist.
                </li>
                <li>SysManagement of pediatric kidney diseases.</li>
                <li>Morning and evening OP by child friendly pediatrician.</li>
                <li>Development clinic for high risk children.</li>
                <li>Provision of help in behavioral and learning disorder.</li>
                <li>
                  Cerebral palsy clinic run by an expert physiatrist and
                  developmental therapist on Wednesday and Saturday.
                </li>
                <li>Full fledged physiotherapy department</li>
                <li>24 hours lab and blood bank facilities.</li>
                <li>Pediatric surgery twice a week Monday and Friday.</li>
                <li>
                  Multi Speciality cross consultation possible from the
                  department of Cardiology, Neurology, Gastroenterology,
                  Nephrology, Pulmonology, Infectious disease, Ophthalmology,
                  ENT, Skin …..
                </li>
              </ul>
            </div>
            <div class="col-sm">
              <!-- <img
              src="assets\img\departments\cardiology\heart-web.png"
              class="img-fluid"
              alt="..."
            /> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="pathFldr === '/departments/oncology'">
  <div class="container category-section">
    <div class="container">
      <div class="row">
        <div class="col-sm-8 cardiology-text">
          <p class="text-padd-content">
            When dealing with as sensitive of an area as cancer, it is important
            to have doctors who know the value of emotional support as well as
            advanced medical degrees. At NIMS Hospitals, our Oncologists are
            fully aware of the emotional toll involved in the diagnosis and
            treatment of this disease and as such go the extra mile to ensure
            that their patients feel safe and supported throughout the process.
          </p>
        </div>
        <div class="col-sm-4">
          <img
            src="assets\img\departments\cardiology\heart-web.png"
            class="img-fluid cardio-img rounded mx-auto d-block p-3"
            style="width: 57%"
            alt="..."
          />
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid services-cardiology">
    <header class="text-center pt-5">
      <h3 class="mb-1">Services</h3>
      <h5 class="mb-3">Department of Oncology Services</h5>
    </header>
  </div>
  <div class="container">
    <div class="container">
      <div class="row">
        <div class="col-sm">
          <div class="invasive-list">
            <h4 style="color: #2f905b; font-weight: 800">SERVICES</h4>
          </div>
          <ul class="invasive-list-item">
            <li>Chemotherapy</li>
          </ul>
        </div>
        <div class="col-sm"></div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="pathFldr === '/departments/ophthalmology'">
  <div class="container category-section">
    <div class="container">
      <div class="row">
        <div class="col-sm-8 cardiology-text">
          <p class="text-padd-content">
            We offer both out-patient services and in-patient services at our
            hospitals. We have a dedicated team of Specialist Ophthalmologists,
            nursing staff, administrative staff and support staff to ensure that
            all our patients receive excellent treatment.NIMS Ophthalmology
            Department uses the state-of-the-art equipments and most current
            procedures. When your eyes need special treatment, you can turn to
            our Outpatient Ophthalmology Clinic for convenient and personalized
            care provided by our well experienced ophthalmic consultants.
          </p>
        </div>
        <div class="col-sm-4">
          <img
            src="assets\img\departments\cardiology\heart-web.png"
            class="img-fluid cardio-img rounded mx-auto d-block p-3"
            style="width: 57%"
            alt="..."
          />
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid services-cardiology">
    <header class="text-center pt-5">
      <h3 class="mb-1">Services</h3>
      <h5 class="mb-3">Department of Ophthalmology Services</h5>
    </header>
  </div>
  <div class="container">
    <div class="container">
      <div class="row">
        <div class="col-sm">
          <div class="invasive-list">
            <h4 style="color: #2f905b; font-weight: 800">SERVICES</h4>
          </div>
          <ul class="invasive-list-item">
            <li>Eye Lasik Refractive Packages</li>
            <hr />
            <li>Lasik Eye Surgery</li>
            <hr />
            <li>Eye Care Treatment</li>
            <hr />
            <li>Lasik Treatment</li>
            <hr />
            <li>Refractive Surgery</li>
            <hr />
            <li>Laser Refractive Surgery</li>
            <hr />
            <li>Refractive Eye Correction Package</li>
            <hr />
            <li>Vision and Eye Care</li>
            <hr />
            <li>Eye Refractive Care Packages</li>
            <hr />
            <li>Cataract Surgery</li>
          </ul>
        </div>
        <div class="col-sm"></div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="pathFldr === '/departments/oral-and-maxillofacial-surgery'">
  <div class="container category-section">
    <div class="container">
      <div class="row">
        <div class="col-sm-8 cardiology-text">
          <p class="text-padd-content">
            The Department of Oral and Maxillofacial Surgery is an advanced
            surgical speciality. It deals with any emergency management of the
            orofacial region. The oral surgeons also perform surgeries in
            association with other medical specialities and super specialities
            like Department of Neurosurgery, Surgical Oncology, ENT, General
            Surgery and Ophthalmology.
          </p>
          <p class="text-padd-content">
            The variety of challenging cases, state of the art facility,
            well-equipped operation theatres and well-experienced staff makes
            this a unique department for both teaching and learning the art of
            maxillofacial surgery.
          </p>
        </div>
        <div class="col-sm-4">
          <img
            src="assets\img\departments\cardiology\heart-web.png"
            class="img-fluid cardio-img rounded mx-auto d-block p-3"
            style="width: 57%"
            alt="..."
          />
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid services-cardiology">
    <header class="text-center pt-5">
      <h3 class="mb-1">Features & Facilities</h3>
      <h5 class="mb-3">
        Department of oral and maxillofacial surgery Services
      </h5>
    </header>
  </div>
  <div class="container">
    <div class="container">
      <div class="row">
        <div class="col-sm">
          <div class="invasive-list">
            <h4 style="color: #2f905b; font-weight: 800">
              Features of the department
            </h4>
          </div>
          <ul class="invasive-list-item">
            <li>Only Surgical department in dentistry</li>
            <hr />
            <li>Consists of teaching and clinical activities</li>
            <hr />
            <li>
              Involves the treatment of dental infection, maxillofacial trauma,
              pathology, facial deformity and facial pain
            </li>
            <hr />
            <li>Management of medical emergency in dental chair</li>
            <hr />
            <li>Bridge between dentistry and Medicine</li>
            <hr />
            <li>Bridge between dentistry and Medicine</li>
          </ul>
        </div>
        <div class="col-sm">
          <div class="invasive-list">
            <h4 style="color: #2f905b; font-weight: 800">Other facilities</h4>
          </div>
          <ul class="invasive-list-item">
            <li>Dental extractions</li>
            <hr />
            <li>Minor Oral surgery</li>
            <hr />
            <li>Management of trauma</li>
            <hr />
            <li>
              Management of medical emergency in dental chairOrthognathic
              Surgery
            </li>
            <hr />
            <li>Orthognathic Surgery</li>
            <hr />
            <li>Management of Oral Cancer</li>
            <hr />
            <li>Tumour Resection and reconstruction</li>
            <hr />
            <li>Salivary Gland Disorders</li>
            <hr />
            <li>Temporomandibular Disorders</li>
            <hr />
            <li>Atypical Orofacial Pain Dysfunctions</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="pathFldr === '/departments/orthopaedics'">
  <div class="container category-section">
    <div class="container">
      <div class="row">
        <div class="col-sm-8 cardiology-text">
          <p class="text-padd-content">
            All major orthopaedic surgeries related to trauma and high velocity
            injuries including routine surgical fixation of fractures,
            pelvi-acetabular fracture reconstruction, spine surgeries, minimally
            invasive fracture fixation ( with minimal scar) like interlocking
            nailing and minimally invasive plate Osteosynthesis, pediatric
            trauma surgeries, arthroscopic intervention( key hole surgeries) for
            sports related trauma,post trauma deformity correction, and
            surgeries for post trauma complications ( like fracture nonunion or
            infection) .
          </p>
          <p class="text-padd-content">
            Facilities for free flap grafting( for open fractures or degloving
            injuries) and microvascular surgery.
          </p>
          <p class="text-padd-content">
            Hand picked doctors in this department are professionally trained in
            all aspects of latest techniques of trauma and related surgeries.
            They patently listen and work up your problem to deliver most
            efficient and acceptable outcome and clarify your doubts.
          </p>
          <p class="text-padd-content">
            Experienced consultants are available round the clock.
          </p>
          <p class="text-padd-content">
            Well-equipped operation theaters with state of the art facilities
            and all high end equipmentsto provides quality treatment of
            international standards in a most competent and professional manner
            for all types of trauma related orthopedic problems.
          </p>
        </div>
        <div class="col-sm-4">
          <img
            src="assets\img\departments\cardiology\heart-web.png"
            class="img-fluid cardio-img rounded mx-auto d-block p-3"
            style="width: 57%"
            alt="..."
          />
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="pathFldr === '/departments/paediatric-neurology'">
  <!-- <p>paediatric-neurology</p> -->
</div>
<div *ngIf="pathFldr === '/departments/paediatric-surgery'">
  <div class="container category-section">
    <div class="container">
      <div class="row">
        <div class="cardiology-text">
          <p class="text-padd-content">
            Our Paediatric Surgery Department is a sub-specialty of our General
            Surgery department which involves the surgery of foetuses, infants,
            children, adolescents and young adults.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="pathFldr === '/departments/paediatrics'">
  <!-- <div class="container category-section">
    <div class="container">
      <div class="row">
        <div class="cardiology-text">
          <p class="text-padd-content">
            Our Paediatric Surgery Department is a sub-specialty of our General
            Surgery department which involves the surgery of foetuses, infants,
            children, adolescents and young adults.
          </p>
        </div>
      </div>
    </div>
  </div> -->
  <div class="container cardiology-aboutus">
    <div class="row">
      <div class="col-sm aboutus-list">
        <ul>
          <li>Out patient services in morning and evening on all days</li>
          <li>Pediatric Indoor admissions</li>
          <li>Care of sick children and consultant on call 24 hours a day.</li>
          <li>
            Systematic vaccination program of on all days including Sundays.
          </li>
          <li>
            Well baby clinic- nutritional assessment, growth monitoring and
            development assessment
          </li>
          <li>Pediatric asthma and allergy clinic</li>
          <li>
            Clinic for musculoskeletal disorders of children on all Saturday.
          </li>
          <li>
            Child and adolescent counseling by psychiatrist and psychologist.
          </li>
          <li>SysManagement of pediatric kidney diseases.</li>
          <li>Morning and evening OP by child friendly pediatrician.</li>
          <li>Development clinic for high risk children.</li>
          <li>Provision of help in behavioral and learning disorder.</li>
          <li>
            Cerebral palsy clinic run by an expert physiatrist and developmental
            therapist on Wednesday and Saturday.
          </li>
          <li>Full fledged physiotherapy department</li>
          <li>24 hours lab and blood bank facilities.</li>
          <li>Pediatric surgery twice a week Monday and Friday.</li>
          <li>
            Multi Speciality cross consultation possible from the department of
            Cardiology, Neurology, Gastroenterology, Nephrology, Pulmonology,
            Infectious disease, Ophthalmology, ENT, Skin …..
          </li>
        </ul>
      </div>
      <div class="col-sm">
        <!-- <img
              src="assets\img\departments\cardiology\heart-web.png"
              class="img-fluid"
              alt="..."
            /> -->
      </div>
    </div>
  </div>
  <div class="container-fluid services-cardiology">
    <header class="text-center pt-5">
      <h3 class="mb-1">Services</h3>
      <h5 class="mb-3">Department of Paediatrics Services</h5>
    </header>
  </div>
  <div class="container">
    <div class="container">
      <div class="row">
        <div class="col-sm">
          <div class="invasive-list">
            <h4 style="color: #2f905b; font-weight: 800">SCOPE OF SERVICES</h4>
          </div>
          <ul class="invasive-list-item">
            <li>Congenital malformations</li>
            <hr />
            <li>Cleft lip and palate</li>
            <hr />
            <li>Esophageal atresia</li>
            <hr />
            <li>Tracheoesophageal fistula</li>
            <hr />
            <li>Hypertrophic pyloric stenosis</li>
            <hr />
            <li>Abdominal wall defects</li>
            <hr />
            <li>Chest wall deformities</li>
            <hr />
            <li>Childhood tumors</li>
          </ul>
        </div>
        <div class="col-sm"></div>
      </div>
    </div>
  </div>
  <div class="container p-3">
    <h4 class="text-center">Best Pediatrician in Trivandrum</h4>
    <p class="py-2">
      The coastal Trivandrum city of Kerala is well-known as a centre of medical
      excellence and is famous for medical tourism. Here you will find the best
      of doctors in every field of medicine and surgery. And with the presence
      of numerous multi-speciality hospitals, finding the best doctors for your
      loved ones is becoming easier by the minute!
    </p>
    <p class="py-2">
      Children are not only the future of this world but also your biggest
      assets. They deserve the best of care that is required for them during
      their growing years; and for this, parents are always on the lookout for
      the best paediatrician in Trivandrum. Right from birth until they become
      young adults, children’s bodies undergo numerous changes and face a
      battery of challenges and diseases. For this reason, you want to be sure
      you have access to the best paediatrician in Trivandrum and help is never
      too far away with NIMS Medicity also located here.
    </p>
    <p class="py-2">
      Some parents are unaware that children have different needs from adults
      and need to consult paediatricians only. Why not go to NIMS Medicity where
      you will find a number of highly-experienced and best paediatricians in
      Trivandrum. This hospital offers the best pediatric facilities including
      pediatric cardiology, pediatric gastroenterology, pediatric surgery,
      pediatric ICU (12 beds) as well as pediatric respiratory and asthma
      management clinics. Using the facilities offered by NIMS Medicity you can
      access the best in the quality primary to tertiary care by some of the
      very best paediatricians in Trivandrum. The pediatric surgery department
      here is a sub-speciality of the department of surgery and takes care of
      children right from the fetal stage through childhood, adolescence, till
      they become young adults. Problems ranging from birth defects to cancers
      can all be treated by the department of pediatric surgery and the best
      paediatricians in Trivandrum found in NIMS Medicity hospital.
    </p>
  </div>
</div>
<div *ngIf="pathFldr === '/departments/pathology'">
  <div class="container category-section">
    <div class="container">
      <div class="row">
        <div class="col-sm-8 cardiology-text">
          <p class="text-padd-content">
            The pathology department runs a full fledged laboratory providing a
            complete range of diagnostic services in the various subspecialty of
            surgical pathology, cytology including FNAC, bone marrow aspiration.
            The department has the expertise for evaluating renal, liver and
            cardiac transplant-related biopsies. The department providing a rich
            experience of a wide variety of disorders seen in the tropics. The
            department has experienced and dedicated technical staff.
          </p>
        </div>
        <div class="col-sm-4">
          <img
            src="assets\img\departments\cardiology\heart-web.png"
            class="img-fluid cardio-img rounded mx-auto d-block p-3"
            style="width: 57%"
            alt="..."
          />
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="pathFldr === '/departments/physiatry-and-physiotherapy'">
  <div class="container category-section">
    <div class="container">
      <div class="row">
        <div class="col-sm-8 cardiology-text">
          <p class="text-padd-content">
            Children thought to be at risk of delayed development and disabled
            children, receive help in order to reach their full potential.
          </p>
          <p class="text-padd-content">
            For children and teenagers, we give advice on avoiding back
            problems.
          </p>
          <p class="text-padd-content">
            Many of the conditions which can make life a trial in middle age are
            improved by physiotherapy.
          </p>
          <p class="text-padd-content">
            Physiotherapy led exercise programme can prevent falls and help
            elder ones to maintain and independence in their own homes or in
            retirement villages.
          </p>
        </div>
        <div class="col-sm-4">
          <img
            src="assets\img\departments\cardiology\heart-web.png"
            class="img-fluid cardio-img rounded mx-auto d-block p-3"
            style="width: 57%"
            alt="..."
          />
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="container">
      <div class="row">
        <div class="col-sm"></div>
        <div class="col-sm">
          <div class="invasive-list">
            <h4 style="color: #2f905b; font-weight: 800">
              Physiotherapy helps people at any age to get a life
            </h4>
          </div>
          <ul class="invasive-list-item">
            <li>
              Time bound orthopedics rehabilitation unit with orthotic and
              prosthetic training
            </li>
            <hr />
            <li>Spinal cord injury rehabilitation.</li>
            <hr />
            <li>
              Full fledged neuro rehabilitation unit with periodic reassessment
              help patients get back to their normal life.
            </li>
            <hr />
            <li>
              Fast track cadiac rehabilitation including pre-operative
              counseling with power point presentation and post operative
              management with regular follow up
            </li>
            <hr />
            <li>Hand rehabilitation</li>
            <hr />
            <li>
              We handle ante-natal, pre-natal, post-natal & other gynecological
              conditions
            </li>
            <hr />
            <li>Weight reduction</li>
            <hr />
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="container cardiology-aboutus">
    <div class="row">
      <div class="col-sm aboutus-list">
        <ul>
          <li>
            Our department is well equipped with modern therapeutic equipments
            with highly skilled physiotherapists from different specialties.
          </li>
          <li>
            Treatments aims first to relieve pain, then to start a self
            management plan for preventing further problems
          </li>
          <li>
            Physiotherapists assist people to recover their independence after
            accidents
          </li>
          <li>
            Obesity and Diabetes, may be tackled by exercise programmes tailored
            to the individual
          </li>
          <li>
            Our rehabilitation unit helps people getting out of bed, and moving,
            and back to independent living after an surgery or stroke.
          </li>
        </ul>
      </div>
      <!-- <div class="col-sm">
        <img
              src="assets\img\departments\cardiology\heart-web.png"
              class="img-fluid"
              alt="..."
            />
      </div> -->
    </div>
  </div>
  <div class="container-fluid services-cardiology">
    <header class="text-center pt-5">
      <h3 class="mb-1">Our Ojectives, Areas and Services</h3>
      <h5 class="mb-3">Department of physiatry and physiotherapy Services</h5>
    </header>
  </div>
  <div class="container">
    <div class="container">
      <div class="row">
        <div class="col-sm">
          <div class="invasive-list">
            <h4 style="color: #2f905b; font-weight: 800">Our objectives</h4>
          </div>
          <ul class="invasive-list-item">
            <li>To enable the disabled</li>
            <hr />
            <li>To relieve pain and prevent its recurrence</li>
            <hr />
            <li>Improve body movements and function</li>
            <hr />
            <li>Provide pre and post surgical care</li>
            <hr />
            <li>Maintain optimal cardio respiratory function</li>
            <hr />
            <li>Promote psychological and social well being</li>
          </ul>
        </div>
        <div class="col-sm">
          <div class="invasive-list">
            <h4 style="color: #2f905b; font-weight: 800">Areas we deal in</h4>
          </div>
          <ul class="invasive-list-item">
            <li>Orthopaedics</li>
            <hr />
            <li>Neurology</li>
            <hr />
            <li>Cardiac Rehabilitation</li>
            <hr />
            <li>Critical Care</li>
            <hr />
            <li>Paediatrics</li>
            <hr />
            <li>Geriatrics</li>
            <hr />
            <li>Obstetrics & Gynaecology</li>
            <hr />
            <li>Physical Fitness</li>
            <hr />
            <li>Sports Medicine</li>
            <hr />
            <li>Burns</li>
            <hr />
            <li>Obesity</li>
          </ul>
        </div>
        <div class="col-sm">
          <div class="invasive-list">
            <h4 style="color: #2f905b; font-weight: 800">Specialties</h4>
          </div>
          <ul class="invasive-list-item">
            <li>Spinal manipulation</li>
            <hr />
            <li>MET</li>
            <hr />
            <li>TAPING</li>
            <hr />
            <li>PILATES</li>
            <hr />
            <li>Counselling</li>
            <hr />
            <li>Psychotherapy</li>
            <hr />
            <li>Pharmacotherapy</li>
            <hr />
            <li>Psychometric test</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="pathFldr === '/departments/plastic-surgery'">
  <div class="container category-section">
    <div class="container">
      <div class="row">
        <div class="col-sm-8 cardiology-text">
          <p class="text-padd-content">
            Humanity’s search for ways to enhance physical beauty is as old as
            mankind itself. The beginnings of aesthetic surgery go right back to
            the beginnings of Medicine. Women as well as men from all walks of
            life regularly have liposuction, breast enlargements or reductions,
            hair transplants, facelifts, Blepharoplasty, Botox injections, use
            of fillers and laser treatment.
          </p>
          <p class="text-padd-content">
            Data announced by ISAPS, shows that Cosmetic Surgery is popular in
            emerging economies, such as India and ranks among top five nations
            for aesthetic surgical and non-surgical procedure. A thriving
            industry has emerged including its own tourism branch, offering
            special scalpel safaris to India as part of budget operations to
            foreigners.
          </p>
          <p class="text-padd-content">
            Earlier in India, Rhinoplasty(Nose Job) was he most popular Cosmetic
            procedure done even from the days of Sushrutha(2000 BC). Recently it
            as shifted to body contouring procedures like liposuction were fat
            is removed surgically from body using small cannulas. Different
            types of liposuction being Suction assisted, Power assisted,
            Ultrasonic and Laser lipolysis. In a single sitting usually about 5
            litres of fat is being removed by placing small incisions less than
            1 cm at two or three places. The areas commonly addressed are
            submental area for double chin appearance, neck, breast, abdomen,
            arms, buttocks, thigh etc. Following the procedure you need to wear
            pressure garments inside your clothing for another 6 weeks for skin
            to get re-draped and newly contoured.
          </p>
        </div>
        <div class="col-sm-4">
          <img
            src="assets\img\departments\cardiology\heart-web.png"
            class="img-fluid cardio-img rounded mx-auto d-block p-3"
            style="width: 57%"
            alt="..."
          />
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="cardiology-text">
        <p class="text-padd-content">
          Other popular procedures include Abdominoplasty(Tummy Tuck), Breast
          Augmentation, Breast lift, Reduction Mammoplasty, Body lift, Fat
          injection techniques etc. Usual implants used for breasts are either
          of Saline or Silicone type, now a days fat injections into breast is
          deferred. The fifth generation Silicone high profile cohesive gel
          implants cost around 25000 Rs/- per pair.
        </p>
        <p class="text-padd-content">
          In younger age group ear deformity correction like bat and cup ear are
          performed in pre-school ages. Other common procedure in early
          adolescent period is liposuctioning or excision of gynecomastia(male
          breast). There is a quantum leap in number of clients for hair
          transplant especially with automated machines by follicular unit
          extraction technique. Its known as lunch time surgery as it can be
          performed during afternoon break. In US its called Neograft while
          Europe its known by term Punch Hair Matic or Omnigraft. In a session
          around 2000 follicles is placed over a period of 3 hours
        </p>
        <p class="text-padd-content">
          A new concept has evolved with anti-aging medicine were 35 to 55 years
          old individuals are put on hormone replacement therapy, aspirin,
          statins to reverse the effect of aging. Recently non-surgical
          procedures have become popular like Botox A injections and Hyaluronic
          acid preparations as Fillers to treat wrinkles over face. One Botox
          vial of 100 units will cost around 12000 I.C. When the effects of
          aging are more marked surgical procedures like endoscopic brow lift,
          mid face lift, eyelid surgeries, neck lift are performed. Use of laser
          in plastic surgery includes- use in hair removal, treatment of
          hemangiomas, Nd:YAG laser for skin resurfacing, CO2 laser for acne
          scar treatment, tattoo removal etc. Other facilities available are
          chemical peeling, dermabrasion, milk peels, facials under one roof.
          Various genital surgeries done include Phalloplasty, and intimacy
          surgeries.
        </p>
      </div>
    </div>
  </div>
  <div class="container cardiology-aboutus">
    <div class="row">
      <div class="col-sm aboutus-list">
        <p class="text-padd-content">
          Before concluding aesthetic surgery is not a magic, nor a luxury
          medicine. Its no longer the prerogative of the rich.
        </p>
        <ul>
          <li>
            Liposuction, breast enlargements or reductions, hair transplants,
            facelifts, Blepharoplasty, Botox injections, use of fillers and
            laser treatment.
          </li>
          <li>
            Abdominoplasty(Tummy Tuck), Breast Augmentation, Breast lift,
            Reduction Mammoplasty, Body lift, Fat injection techniques etc.
            Usual implants used for breasts are either of Saline or Silicone
            type, now a days fat injections into breast is deferred
          </li>
          <li>Hormone replacement therapy</li>
          <li>
            Surgical procedures like endoscopic brow lift, mid face lift, eyelid
            surgeries, neck lift are performed. Use of laser in plastic surgery
            includes- use in hair removal, treatment of hemangiomas, Nd:YAG
            laser for skin resurfacing, CO2 laser for acne scar treatment,
            tattoo removal etc. Other facilities available are chemical peeling,
            dermabrasion, milk peels, facials under one roof
          </li>
        </ul>
      </div>
      <div class="col-sm">
        <!-- <img
              src="assets\img\departments\cardiology\heart-web.png"
              class="img-fluid"
              alt="..."
            /> -->
      </div>
    </div>
  </div>
</div>
<div *ngIf="pathFldr === '/departments/prosthodontics'">
  <div class="container category-section">
    <div class="container">
      <div class="row">
        <div class="col-sm-8 cardiology-text">
          <p class="text-padd-content">
            Prosthodontics is a branch of Dentistry which facilitates artificial
            replacement of missing teeth. Department of Prosthodontics is
            involved in teaching all five years of Under Graduate. The
            department of Prosthodontics of the Noorul Islam College of Dental
            Science caters to the needs of patients needing replacement of
            missing natural teeth. The treatments range from replacing
            tooth/teeth by a removable/fixed denture to complicated procedures
            like replacement of missing maxillofacial structures (eye, nose,
            ear) with artificial substitutes and placement of dental implants.
          </p>
        </div>
        <div class="col-sm-4">
          <img
            src="assets\img\departments\cardiology\heart-web.png"
            class="img-fluid cardio-img rounded mx-auto d-block p-3"
            style="width: 57%"
            alt="..."
          />
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="pathFldr === '/departments/psychiatry'">
  <div class="container category-section">
    <div class="container">
      <div class="row">
        <div class="col-sm-8 cardiology-text">
          <p class="text-padd-content">
            The Department of Psychiatry provides a comprehensive integrated
            multi-disciplinary service in the management of patients with
            psychological and psychiatric disorders. Backed by a
            multi-disciplinary team comprising psychiatrists, psychologists,
            nurses, social workers and occupational therapists, the department
            offers a full range of specialty services to meet the individual
            needs of our patients.
          </p>
        </div>
        <div class="col-sm-4">
          <img
            src="assets\img\departments\cardiology\heart-web.png"
            class="img-fluid cardio-img rounded mx-auto d-block p-3"
            style="width: 57%"
            alt="..."
          />
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid services-cardiology">
    <header class="text-center pt-5">
      <h3 class="mb-1">Services</h3>
      <h5 class="mb-3">Department of Psychiatry Services</h5>
    </header>
  </div>
  <div class="container">
    <div class="container">
      <div class="row">
        <div class="col-sm">
          <div class="invasive-list">
            <h4 style="color: #2f905b; font-weight: 800">Service</h4>
          </div>
          <ul class="invasive-list-item">
            <li>Counselling</li>
            <hr />
            <li>Psychotherapy</li>
            <hr />
            <li>Pharmacotherapy</li>
            <hr />
            <li>Psychometric test</li>
          </ul>
          <br />
        </div>
        <div class="col-sm"></div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="pathFldr === '/departments/pulmonology'">
  <div class="container category-section">
    <div class="container">
      <div class="row">
        <div class="col-sm-8 cardiology-text">
          <p class="text-padd-content">
            The Pulmonology Department deals with disease of the lungs and
            bronchial tubes which involves evaluation of the upper and lower
            respiratory tracts. The Department is well supported by the Thoracic
            Surgery Department which offers facilities for the surgical
            treatment of lung problem.
          </p>
        </div>
        <div class="col-sm-4">
          <img
            src="assets\img\departments\cardiology\heart-web.png"
            class="img-fluid cardio-img rounded mx-auto d-block p-3"
            style="width: 57%"
            alt="..."
          />
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid services-cardiology">
    <header class="text-center pt-5">
      <h3 class="mb-1">Services</h3>
      <h5 class="mb-3">Department of Pulmonology Services</h5>
    </header>
  </div>
  <div class="container">
    <div class="container">
      <div class="row">
        <div class="col-sm">
          <div class="invasive-list">
            <h4 style="color: #2f905b; font-weight: 800">Scope of Service</h4>
          </div>
          <ul class="invasive-list-item">
            <li>Allergy clinic</li>
            <hr />
            <li>TB Clinic</li>
            <hr />
            <li>Lung Function Study (PFT)</li>
            <hr />
            <li>Bronchoscopy</li>
            <hr />
            <li>Occupational Lung Disease Clinic</li>
            <hr />
            <li>COPD Clinic</li>
            <hr />
            <li>ECG</li>
            <hr />
            <li>Smoking Cessation Clinic</li>
          </ul>
          <br />
        </div>
        <div class="col-sm"></div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="pathFldr === '/departments/radiology'">
  <div class="container-fluid services-cardiology">
    <header class="text-center pt-5">
      <h3 class="mb-1">Services</h3>
      <h5 class="mb-3">Department of Radiology Services</h5>
    </header>
  </div>
  <div class="container">
    <div class="container">
      <div class="row">
        <div class="col-sm">
          <div class="invasive-list">
            <h4 style="color: #2f905b; font-weight: 800">Service Available</h4>
          </div>
          <ul class="invasive-list-item">
            <li>Whole body Spiral CT Scan</li>
            <hr />
            <li>Color Doppler Scan</li>
            <hr />
            <li>Ultra Sound Scan</li>
            <hr />
            <li>Digital X-ray Machine (Ge HF630)</li>
            <hr />
            <li>300mA X-ray unit</li>
            <hr />
            <li>EEG</li>
            <hr />
            <li>ECG</li>
            <hr />
            <li>EMG</li>
            <hr />
            <li>Gastric bypass surgery</li>
            <hr />
            <li>Nerve Conduction Study</li>
          </ul>
          <br />
        </div>
        <div class="col-sm"></div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="pathFldr === '/departments/renal-transplantation-unit'">
  <div class="container category-section">
    <div class="container">
      <div class="row">
        <div class="col-sm-8 cardiology-text">
          <div class="invasive-list">
            <h4 style="color: #2f905b; font-weight: 800">
              Centre of Excellence
            </h4>
          </div>
          <p class="text-padd-content">
            The Kidney Transplant Inpatient Unit at Nims medicity 4 post
            operative bed unit with a dedicated and experienced staff who
            specialize in the care of patients who are preparing for a kidney
            transplant or who have had a kidney transplant.
          </p>
          <p class="text-padd-content">
            To accommodate significant growth in patient volumes the transplant
            unit was developed to improve the continuum of care between pre- and
            post-transplant patients.Many members of the interdisciplinary
            health care team may be involved in care including transplant unit
            nurses, transplant surgeons/urologists, physiotherapists,
            pharmacists, diabetes specialists, dieticians and community care
            access center staff. The goal of the program is to provide
            transplant patients a continuum of specialized care and assist them
            in every way possible throughout the transplant journey.
          </p>
          <div class="invasive-list">
            <h4 style="color: #2f905b; font-weight: 800">
              Kidney Transplantation
            </h4>
          </div>
          <P class="text-padd-content">
            Renal transplantation is the organ transplant of a kidney into a
            patient with end-stage renal disease. Kidney transplantation is
            typically classified as deceased-donor (formerly known as cadaveric)
            or living-donor transplantation depending on the source of the donor
            organ.</P
          >
          <P class="text-padd-content"
            >Living-donor renal transplants are further characterized as
            genetically related (living-related) or non-related
            (living-unrelated) transplants, depending on whether a biological
            relationship exists between the donor and recipient.</P
          >
        </div>
        <div class="col-sm-4">
          <img
            src="assets\img\departments\cardiology\heart-web.png"
            class="img-fluid cardio-img rounded mx-auto d-block"
            style="width: 57%"
            alt="..."
          />
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="container">
      <div class="row">
        <div class="col-sm">
          <div class="invasive-list">
            <h4 style="color: #2f905b; font-weight: 800">Indications</h4>
          </div>
          <ul class="invasive-list-item">
            <li>End-stage renal disease (ESRD)</li>
            <hr />
            <li>Malignant hypertension, Infections,</li>
            <hr />
            <li>Diabetes mellitus</li>
            <hr />
            <li>Focal segmental glomerulosclerosis</li>
            <hr />
            <li>Polycystic kidney disease</li>
            <hr />
            <li>Lupus.</li>
          </ul>
          <br />
        </div>
        <div class="col-sm">
          <div class="invasive-list">
            <h4 style="color: #2f905b; font-weight: 800">Contraindications</h4>
          </div>
          <ul class="invasive-list-item">
            <li>Cardiac and pulmonary insufficiency</li>
            <hr />
            <li>
              Cardiovascular disease, terminal infectious diseases and cancer
            </li>
            <hr />
            <li>Mental illness and/or significant on-going</li>
            <hr />
            <li>Substance abuse issues may be excluded.</li>
            <hr />
            <li>Polycystic kidney disease</li>
          </ul>
          <br />
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="pathFldr === '/departments/rheumatology'">
  <!-- <p>Hi</p> -->
</div>
<div *ngIf="pathFldr === '/departments/urology'">
  <div class="container category-section">
    <div class="container">
      <div class="row">
        <div class="col-sm-8 cardiology-text">
          <p class="text-padd-content">
            Department of Urology at NIMS Medicity is a national leader in
            urological treatments and research. Our department is dedicated in
            providing state-of-the-art medical complete range of facilities to
            provide comprehensive services in the management of urological
            conditions which includes diagnosis and treatment of kidney stone,
            ureteric stone, incontinence, infertility, impotence, female
            urology, pediatric urology, prostatic surgery.
          </p>
          <p class="text-padd-content">
            We also provide screening, staging and the most up-to-date treatment
            of all uro-genital tumors including kidney, ureter, bladder and
            prostate. Our department comprises of well experienced doctors who
            have in depth knowledge in the field of urology.
          </p>
        </div>
        <div class="col-sm-4">
          <img
            src="assets\img\departments\cardiology\heart-web.png"
            class="img-fluid cardio-img rounded mx-auto d-block"
            style="width: 57%"
            alt="..."
          />
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid services-cardiology">
    <header class="text-center pt-5">
      <h3 class="mb-1">Services</h3>
      <h5 class="mb-3">Department of Urology</h5>
    </header>
  </div>
  <div class="container p-3">
    <h4>TURP</h4>
    <p class="py-2">
      Transurethral Resection of the Prostate (TURP) is a surgical procedure to
      deal with patients with an enlarged, benign (non cancerous) prostate,
      called Benign Prostate Hyperplasia or BPH.
    </p>
    <h4>INFERTILITY</h4>
    <p class="py-2">
      NIMS offers a complete range of facilities for investigation of male
      infertility. Female infertility investigation by gynecologists with
      special training in infertility is also provided. A full fledged
      laboratory is well designed for the entire range of studies.
    </p>
    <h4>URS (URETERO RENOSCOPY) WITH LITHOTRIPSY</h4>
    <p class="py-2">
      In this surgery small scopes are passed through the urethra (from where
      one passes urine) and stones are removed after breaking into smaller
      pieces. Patients can be discharged within 24 to 48 hours after this
      surgery.
    </p>
    <h4>BLADDER TUMOR</h4>
    <p class="py-2">
      A bladder tumor is an excess growth of cells that line the inside of the
      bladder. A tumor may be either cancerous or non cancerous. NIMS has the
      facility to diagnose and treat bladder tumors.
    </p>
    <h4>PROSTATE DISEASE (SPECIALTY CLINIC)</h4>
    <p class="py-2">
      We have a specialty clinic within our hospital for the diagnosis and
      treatment of prostate diseases. The prostate is a sex gland in men. Men
      after the age of 50 years suffer from urinary problems like going to
      toilet frequently, poor control of urine or difficulty of passing urine,
      etc. It is because of enlargement of the prostate, which can be removed
      safely by endoscopic method.
    </p>
    <h4>PEDIATRIC UROLOGY</h4>
    <p class="py-2">
      Peadiatric Urology involves the diagnosis and treatment of all urological
      problems encountered in children. A specialty clinic is facilitated for
      this purpose.
    </p>
    <h4>UROENDOSCOPY</h4>
    <p class="py-2">
      Uroendoscopy is one of the services offered in our hospital. It is a
      device consisting of a tube and optical system for observing the inside of
      a hollow organ or cavity. This observation may be done through a natural
      body opening or a small incision.
    </p>
    <h4>IMPOTENCE</h4>
    <p class="py-2">
      A problem of over 10% of adult population can be fully investigated and
      treated. NIMS provides specialized facilities for pharmacotherapy,
      cavernosometry and cavernosography, in addition to Doppler studies for
      assessment of blood flow.
    </p>
  </div>
  <div class="container">
    <h4 class="text-center">Urologist in Trivandrum</h4>
    <p class="py-2">
      There are a variety of urological problems related to the genitor-urinary
      system that can affect both men and women. While such problems can be
      really discomforting and to an extent, even embarrassing for the patient,
      it’s essential that you find an experienced and understanding urologist
      urgently.
    </p>
    <p class="py-2">
      The best place to be when looking for professional urologists in
      Trivandrum is in the NIMS Medicity where you not only have access to some
      of the best expert urologists in Trivandrum but also the availability of
      modern pieces of equipment and techniques that are sure to put you back on
      the path of health again.
    </p>
    <p class="py-2">
      If you have issues that relate to recurrent urinary infections, kidney
      problems, incontinence, infertility, erectile dysfunction or related
      prostate tumours and cancers, etc, it’s essential you get in touch
      immediately with a specialist urologist in Trivandrum. The
      multi-speciality NIMS Medicity hospital located in a peaceful sprawling
      campus in Neyyattingara is noted as a national leader for its success rate
      in the treatment of a wide-ranging variety of urological problems. Come to
      NIMS Medicity when you or a loved one needs the attention of a urologist
      in Trivandrum and its vicinities.
    </p>
  </div>
</div>
<app-department-docdetail></app-department-docdetail>
