<div class="nm-page-header">
  <h2>Corporate Insurance</h2>
</div>

<div class="container pb-3">
  <div class="row">
    <div class="col-sm">
      <h3>Corporates list</h3>
      <div class="p-3">
        <ul>
          <li>Prime minister’s national relief fund</li>
          <li>
            Chief minister’s comprehensive health insurance scheme(govt of
            Tamilnadu)
          </li>
          <li>ECHS</li>
          <li>MEDISEP</li>
          <li>Tamilnadu government employees pensioner’s scheme</li>
          <li>ESIcorporation (ip)</li>
          <li>ESIC employees scheme (op/ip)</li>
          <li>BSNL (ip)</li>
          <li>VSCC(op/ip)</li>
          <li>Aa sandha(govt. Of maldives) (op/ip)</li>
          <li>Tamilnad government employees scheme (ip)</li>
          <li>Hindustan latex( ip)</li>
          <li>Travancore titanium (ip)</li>
          <li>
            National institute for interdisciplinary science and
            technology(nist)
          </li>
          <li>Attukal bhagavathy temple trust</li>
        </ul>
      </div>
    </div>
    <div class="col-sm">
      <h3>List of TPAs</h3>
      <div class="p-3">
        <ul>
          <li>Axa assistance</li>
          <li>Medi assist india TPA</li>
          <li>Md india healthcare TPA</li>
          <li>Medicare TPA</li>
          <li>Good health plan TPA(ghpl)</li>
          <li>Health india TPA</li>
          <li>Ttk healthcare TPA</li>
          <li>Family health plan tpa(fhpl)</li>
          <li>Vipul med corp TPA</li>
          <li>E-meditek tpa services ltd.</li>
          <li>Ericson TPA</li>
          <li>Reksha</li>
          <li>Heritage</li>
          <li>United health care parekh</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm">
      <h3>List of Insurers</h3>
      <div class="p-3">
        <ul>
          <li>Bajaj allianz general insurance</li>
          <li>Iffco tokio general insurance co ltd</li>
          <li>ICICI</li>
          <li>Star health and allied insurance</li>
          <li>Bajaj allianz life insurance</li>
          <li>Apollo munich health insurance company ltd</li>
          <li>Max bupa</li>
          <li>Hdfc ergo</li>
          <li>Cholamandalam general insurance co ltd</li>
          <li>Religare</li>
          <li>Karunya</li>
        </ul>
      </div>
    </div>
    <div class="col-sm">
      <h3>Dedicated Healthcare(DHS)</h3>
      <div class="p-3">
        <ul>
          <li>National insurance</li>
          <li>Oriental insurance</li>
          <li>United india</li>
          <li>New india assurance</li>
          <li>Life insurance corporation</li>
          <li>Birla sun life</li>
          <li>Met life insurance</li>
          <li>SBI life insurance</li>
          <li>Aviva life insurance</li>
          <li>Reliance insurance company</li>
          <li>Universal soupo</li>
        </ul>
      </div>
    </div>
  </div>
  <h4>
    Empaneled under the chief minister’s comprehensive health insurance
    scheme(government of tamil nadu) We are also empanelled under the prime
    minister’s relief fund.
  </h4>
</div>
