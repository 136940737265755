import { Component, OnInit } from '@angular/core';
import { DoctorsListService } from 'src/app/_services/doctors-list.service';


@Component({
  selector: 'app-news-events',
  templateUrl: './news-events.component.html',
  styleUrls: ['./news-events.component.scss'],
  
})
export class NewsEventsComponent implements OnInit 
{
 /*  newsevent: any = []; */
  newsevents: any =[];
  constructor(private Newseventlist: DoctorsListService) {}

    ngOnInit() 
    {
      //this.getnewseventList();
      this.getCustomNewsEventList() ;
      
    }

 /*  getnewseventList() 
    {
      this.Newseventlist.getnewseventList().subscribe((data) => {
        this.newsevent = data.data;
        console.log(this.newsevent);
      });
    
    } */

    
    getCustomNewsEventList() 
  {
    this.Newseventlist.getCustomNewsEventList().subscribe((data) => {
      this.newsevents = data.data;
      console.log(this.newsevents);
    });
  }
}
