import { Component, OnInit } from '@angular/core';
import { DoctorsListService } from 'src/app/_services/doctors-list.service';


@Component({
  selector: 'app-camp',
  templateUrl: './camp.component.html',
  styleUrls: ['./camp.component.scss'],
})
export class CampComponent implements OnInit {
  camp: any = [];
  carddetails: any = [];

  constructor(private CampService: DoctorsListService) {}

  ngOnInit() {
    this.getcampList();
  }

  getcampList() {
    this.CampService.getcampList().subscribe((data) => {
      this.camp = data.data;
      console.log(this.camp);
      // for (var val of this.camp) {
      //   for (var details of val.carddetails) {
      //     this.carddetails = details;
      //     console.log(this.carddetails);
      //   }
      // }
    });
  }
}
