import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DoctorsListService } from 'src/app/_services/doctors-list.service';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-hero-section',
  templateUrl: './hero-section.component.html',
  styleUrls: [
    './hero-section.component.scss',
    '../../components/service-card/service-card.component.scss',
  ],
})
export class HeroSectionComponent implements OnInit {
  constructor(
    private httpClient: HttpClient,
    private doctorService: DoctorsListService
  ) {}
  services: any = [];
  services1: any = [];
  speciality: any = [];
  num: any = [0, 1];
  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 100,
    navText: [
      '<i class="fas fa-arrow-left"></i>',
      '<i class="fas fa-arrow-right"></i>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 4,
      },
    },
    nav: true,
  };

  ngOnInit(): void {
    // this.httpClient.get('assets/data/servicecard.json').subscribe((data) => {
    //   this.services = data.speciality;
    //   console.log(this.services);
    // });
    // let items = document.querySelectorAll(
    //   '#carouselServiceControl .carousel-item'
    // );
    // items.forEach((el) => {
    //   const minPerSlide = 4;
    //   let next = el.nextElementSibling;
    //   for (var i = 1; i < minPerSlide; i++) {
    //     if (!next) {
    //       // wrap carousel by using first child
    //       next = items[0];
    //     }
    //     let cloneChild = next.cloneNode(true);
    //     // el.appendChild(cloneChild.children[0]);
    //     next = next.nextElementSibling;
    //   }
    // });
    this.getSpecialityList();
  }

  getSpecialityList() {
    this.doctorService.getSpecialityList().subscribe((data) => {
      this.speciality = data.data;
      console.log(this.speciality);
      this.services = this.speciality[0].speciality1;
      this.services1 = this.speciality[1].speciality2;
    });
  }
}
