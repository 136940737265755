import { Component, OnInit } from '@angular/core';
import { DoctorsListService } from 'src/app/_services/doctors-list.service';


@Component({
  selector: 'app-research-development',
  templateUrl: './research-development.component.html',
  styleUrls: ['./research-development.component.scss'],
})
export class ResearchDevelopmentComponent implements OnInit {
  researchdata: any = [];
  constructor(private researchlist: DoctorsListService) {}

  ngOnInit() {
    this.getresearchlist();
  }

  getresearchlist() {
    this.researchlist.getResearchList().subscribe((data) => {
      this.researchdata = data.data;
      console.log(this.researchdata);
    });
  }
}
