import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DepartmentListService {
  departmentUrl: any;
  getDDepartmentUrl = 'assets/data/departments.json';

  constructor(private http: HttpClient) {}

  getDDepartmentList() {
    return this.http.get<any>(this.getDDepartmentUrl);
  }
  setDepartmentUrl(data) {
    this.departmentUrl = data;
  }
  getDepartmentUrl() {
    return this.departmentUrl;
  }
}
