<!-- Heading -->
<!-- <div class="col-sm-12 text-center nm-bg-primary mb-3 text-white py-3">
  <h2 class="news">
    <b>{{ newssubcategory.head }}</b>
  </h2>
</div> -->
<div class="nm-page-header">
  <h2>{{ custom_newsdata.title }}</h2>
</div>

<!-- content 1  -->
<div class="container">
  <div
    class="row "
    
  >
    <div class="col-sm-6 p-3 centralisation">
      <div class="card"><img src="https://nims.prismaticsoft.com{{ custom_newsdata.image }}" /></div>
    </div>
    <div class="col-sm-6 p-3 centralisation textjustify">
    {{custom_newsdata.col_description}}
    </div>
    <div
      class=" textjustify"
      
    >
     
      <p>
        {{custom_newsdata.row_description}}
      </p>
     <!--  <div
        class="{{ lists.liclass }}"
        *ngFor="let lists of second.innercontent"
      >
        <em>{{ lists.subheading }}</em> {{ lists.description }}
      </div> -->
    </div>
    <!-- <div class="{{ item.class1 }} ">
      <img src="{{ item.img1 }}" />
    </div> -->
  </div>
</div>

<!-- Image Gallery -->
<div class="container">
  <div class="row">
    <div class="col-sm-3 p-3 imgsizethumbnail" *ngFor="let item of custom_newsdata.thumbnail_section">
      <img class="img-thumbnail" src="https://nims.prismaticsoft.com/{{ item.thumb_image }}" style="height: 100%" />
    </div>
  </div>
</div>
