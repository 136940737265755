import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DoctorsListService } from 'src/app/_services/doctors-list.service';

@Component({
  selector: 'app-testimonials-subcategory',
  templateUrl: './testimonials-subcategory.component.html',
  styleUrls: ['./testimonials-subcategory.component.scss'],
})
export class TestimonialsSubcategoryComponent implements OnInit {
  wholedata: any = [];
  sample: any = [];
  testimonialsubcategory: any = [];
  pathFldr: any;

  constructor(
    private testimonialcategoryService: DoctorsListService,
    private router: Router
  ) {}

  ngOnInit() {
    this.gettestimonialpageList();
  }

  gettestimonialpageList() {
    var pathFldr = decodeURIComponent(this.router.url);
    console.log(pathFldr);
    this.testimonialcategoryService.gettestimonialList().subscribe((data) => {
      this.wholedata = data.data;
      console.log(this.wholedata);
      for (var val of this.wholedata) {
        if (pathFldr == val.url) {
          console.log('checking');
          this.wholedata = val;
          this.testimonialsubcategory = val.paragraph[0];
          console.log(this.testimonialsubcategory);
        }
      }
    });
  }
}
