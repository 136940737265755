import { Component, OnInit } from '@angular/core';
import { DoctorsListService } from 'src/app/_services/doctors-list.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss'],
})
export class BlogComponent implements OnInit {
  blog: any = [];

  constructor(private BlogService: DoctorsListService) {}

  ngOnInit() {
    this.getblogList();
  }

  getblogList() {
    this.BlogService.getblogList().subscribe((data) => {
      this.blog = data.data;
      console.log(this.blog);
    });
  }
}
