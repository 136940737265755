<div class="nm-page-header">
  <h2>research and development</h2>
</div>
<div class="container">
  <div class="container">
    <div class="row py-2">
      <div class="col-sm-6">
        <div class="card" *ngFor="let item of researchdata">
          <img
            src="{{ item.image }}"
            class="card-img-top"
            style="height: auto"
            alt="..."
          />
          <div class="card-body">
            <h3 class="text-center">{{ item.name }}</h3>
            <hr />
            <p class="card-text">
              {{ item.content }}
            </p>
            <div class="text-center py-2">
              <button
                type="button"
                class="btn btn-dark center btn"
                routerLink="{{ item.url }}"
              >
                Read More
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
