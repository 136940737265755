<div class="backgroundstyle">
  <!-- Heading -->
  <!-- <div class="col-sm-12 text-center head">
    <h2><b>Doctors And Appointments</b></h2>
  </div> -->
  <div class="nm-page-header">
    <h2>Doctors And Appointments</h2>
  </div>

  <!-- Content -->
  <div class="container content">
    <div class="row">
      <div class="col-sm-4 content" *ngFor="let item of doctordetails">
        <div class="card mb-3" style="max-width: 540px">
          <div class="row g-0 doccard">
            <div class="col-md-4 content">
              <img
                src="{{ item.profileImg }}"
                class="img-fluid rounded-start"
                alt="doctor image"
              />
            </div>
            <div class="col-md-8 text-center">
              <div class="card-body">
                <h5 class="card-title">{{ item.name }}</h5>
                <p class="card-text">{{ item.qualification }}</p>
              </div>
            </div>
          </div>
          <div class="appointbook">
            <div class="text-center content">
              <button class="btn btn-success">BOOK APPOINTMENT</button>
            </div>
          </div>
        </div>
        <div>
          <div class="text-center time">
            <h6>{{ item.timing }}</h6>
            <h6>{{ item.timing1 }}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
