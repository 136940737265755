<div class="nm-page-header">
  <h2>RESPIRATORY EXECUTIVE CHECK UP</h2>
</div>

<div class="container">
  <div style="text-align:center">
    <h4>RESPIRATORY EXECUTIVE HEALTH CHECK UP</h4>
    <h4>Amount:4000/-</h4>
    <h4>48 TESTS & 2 CONSULTATIONS</h4>
  </div>
  <br>
  <br>
  <div class="row py-3">
    <div class="col-sm">
      <h5>HAEMATOLOGY</h5>
      <br>
      <h6>COMPLETE BLOOD COUNT</h6>
        <div class="p-3">
          <ul>
            <li>HAEMOGLOBIN</li>
            <li>TOTAL COUNT</li>
          </ul>
        </div>
    
    
      <h5>DIFFERENTIAL COUNT</h5>
        <div class="p-3">
          <ul>
            <li>POLYMORPHS</li>
            <li>LYMPHOCYTES</li>
            <li>MONOCYTES</li>
            <li>EOSINOPHILS</li>
            <li>BASOPHILS</li>
            <li>PLATELET COUNT</li>
            <li>RBC</li>
            <li>PCV</li>
            <li>MCV</li>
            <li>MCH</li>
            <li>MCHC</li>
            <li>ESR</li>
            <li>RED CELL DISTRIBUTION</li>
            <li>ABSOLUTE EOSINOPHIL COUNT</li>
            <li>ABSOLUTE LYMPHOCYTE COUNT</li>
            <li>ABSOLUTE NEUTROPHIL COUNT</li>
            <li>MEAN PLATELET VOLUME</li>
          </ul>
        </div>
    </div>
    
    <div class="col-sm">
      <h5>BIOCHEMISTRY</h5>
      <br>
      <h6>FASTING BLOOD SUGAR/RANDOM BLOOD SUGAR</h6>
      <br>
      <h6>HbA1C</h6>
      <br>
      <h6>S.IgE</h6>
      <br>
      <h5>LIPID PROFILE</h5>

      <div class="p-2">  
        <ul>
          <li>S.CHOLESTEROL</li>
          <li>S.TRYGLYCERIDES</li>
          <li>HDL.CHOLESTROL</li>
          <li>LDL.CHOLESTROL</li>
          <li>VLDL.CHOLESTROL</li>
        </ul>
      </div> 
      
    
 
      <h5>LIVER PROFILE</h5>
      <div class="p-2">
        <ul>
          <li>BILIRUBIN-TOTAL</li>
          <li>BILIRUBIN-DIRECT</li>
          <li>BILIRUBIN-INDIRECT</li>
          <li>SGOT/AST</li>
          <li>SGPT/ALT</li>
          <li>ALKALINE PHOSPHATASE</li>
        
          <li>TOTAL PROTEIN</li>
          <li>ALBUMIN</li>
          <li>GLOBULIN</li>
          <li>ALBUMIN/GLOBULIN RATIO</li>
        </ul>
      </div>
    </div>
    
   
    <div class="col-sm">
      <h5>RENAL FUNCTION TEST</h5>
      <div class="p-2">
        <ul>
          <li>BLOOD UREA NITROGEN</li>
          <li>S.CREATINE</li>
          <li>URIC ACID</li>
        </ul>
      </div>
      <h5>IMMUNOLOGY</h5>
      <div class="p-2">
        <ul>
          <li>VITAMIN D LEVELS</li>
          <li>TSH</li>
        </ul>
      </div>
      
      <h5>ECG</h5>
      <br>
      <h5>ECHO</h5>
      <br>
      <h5>CHEST XRAY</h5>
      <br>
      <h5>PULMONORY FUNCTION TEST (PFT)</h5>
      <br>
      <h5>AUDIOGRAM</h5>
      <br>
      <h5>CONSULTATIONS</h5>
      <div class="p-2">
        <ul>
          <li>PULMONOLOGY</li>
          <li>DIETARY ADVICE</li>
        </ul>
      </div>
      <h6 class="pt-3">COMPLIMENTARY BREAKFAST</h6>
    </div>
    
  </div>
</div>
