<!-- Heading -->
<!-- <div class="text-center nm-bg-primary mb-3 text-white py-3">
  <h2 class=""><b>Press Release</b></h2>
</div> -->
<!-- Heading -->
<div class="nm-page-header">
  <h2>Press Release</h2>
</div>
<div class="container nm-press-release">
  <figure>
    <img
      src="https://i0.wp.com/www.nimshospital.com/wp-content/uploads/2018/12/hindustantimes.jpg?resize=150%2C150&ssl=1"
      alt="Trulli"
    />
    <figcaption class="fw-bold fs-4 border-top">NIUSAT</figcaption>
  </figure>
  <figure>
    <img
      src="https://i0.wp.com/www.nimshospital.com/wp-content/uploads/2018/12/hindustantimes.jpg?resize=150%2C150&ssl=1"
      alt="Trulli"
    />
    <figcaption class="fw-bold fs-4 border-top pt-2">NIUSAT</figcaption>
  </figure>
  <figure>
    <img
      src="https://i0.wp.com/www.nimshospital.com/wp-content/uploads/2018/12/hindustantimes.jpg?resize=150%2C150&ssl=1"
      alt="Trulli"
      style="width: 100%"
    />
    <figcaption class="fw-bold fs-4 border-top pt-2">NIUSAT</figcaption>
  </figure>
  <figure>
    <img
      src="https://i0.wp.com/www.nimshospital.com/wp-content/uploads/2018/12/hindustantimes.jpg?resize=150%2C150&ssl=1"
      alt="Trulli"
    />
    <figcaption class="fw-bold fs-4 border-top pt-2">NIUSAT</figcaption>
  </figure>
  <figure>
    <img
      src="https://i0.wp.com/www.nimshospital.com/wp-content/uploads/2018/12/hindustantimes.jpg?resize=150%2C150&ssl=1"
      alt="Trulli"
    />
    <figcaption class="fw-bold fs-4">NIUSAT</figcaption>
  </figure>
</div>
