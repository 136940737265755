import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DoctorsListService } from 'src/app/_services/doctors-list.service';


@Component({
  selector: 'app-research-developmentsub',
  templateUrl: './research-developmentsub.component.html',
  styleUrls: ['./research-developmentsub.component.scss'],
})
export class ResearchDevelopmentsubComponent implements OnInit {
  researchdata: any = [];
  researchsubcategory: any = [];
  pathFldr: any;

  constructor(
    private researchlist: DoctorsListService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getResearchsubcategoryList();
  }

  getResearchsubcategoryList() {
    const pathFldr = decodeURIComponent(this.router.url);
    console.log(pathFldr);
    this.researchlist.getResearchList().subscribe((data) => {
      this.researchdata = data.data;
      console.log(this.researchdata);
      for (var val of this.researchdata) {
        if (pathFldr == val.url) {
          this.researchsubcategory = val;
          console.log(this.researchsubcategory);
        }
      }
    });
  }
}
