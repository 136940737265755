import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DoctorsSubcategoryComponent } from './_pages/doctors-subcategory/doctors-subcategory.component';
import { DoctorsComponent } from './_pages/doctors/doctors.component';
import { HomeComponent } from './_pages/home/home.component';
import { AboutusComponent } from './_pages/aboutus/aboutus.component';
import { ManagementComponent } from './_feature-components/management/management.component';
import { PressreleaseComponent } from './_feature-components/pressrelease/pressrelease.component';
import { GalleryComponent } from './_feature-components/gallery/gallery.component';
import { NiTrustComponent } from './_feature-components/ni-trust/ni-trust.component';
import { DepartmentDocdetailComponent } from './_feature-components/department-docdetail/department-docdetail.component';
import { ServicespageComponent } from './_pages/servicespage/servicespage.component';
import { CoursesComponent } from './_pages/courses/courses.component';
import { CoursesSubcategoryComponent } from './_pages/courses-subcategory/courses-subcategory.component';
import { NewsEventsComponent } from './_pages/news-events/news-events.component';
import { NewsEventssubcategoryComponent } from './_pages/news-eventssubcategory/news-eventssubcategory.component';
import { AmbulanceServicesComponent } from './_pages/ambulance-services/ambulance-services.component';
import { VideoGalleryComponent } from './_pages/video-gallery/video-gallery.component';
import { TestimonialsComponent } from './_pages/testimonials/testimonials.component';
import { CampComponent } from './_pages/camp/camp.component';
import { TestimonialsSubcategoryComponent } from './_pages/testimonials-subcategory/testimonials-subcategory.component';
import { MedicaldictionaryComponent } from './_pages/medicaldictionary/medicaldictionary.component';
import { BlogComponent } from './_pages/blog/blog.component';
import { BlogSubcategoryComponent } from './_pages/blog-subcategory/blog-subcategory.component';
import { OurDepartmentMainComponent } from './_pages/departments/our-department-main/our-department-main.component';
import { OurDepartmentsSubComponent } from './_pages/departments/our-departments-sub/our-departments-sub.component';
import { DepartmentSubheaderComponent } from './_feature-components/department-subheader/department-subheader.component';
import { ContactUsComponent } from './_pages/contact-us/contact-us.component';
import { ResearchDevelopmentComponent } from './_pages/research-development/research-development.component';
import { ResearchDevelopmentsubComponent } from './_pages/research-developmentsub/research-developmentsub.component';
import { SpectrumComponent } from './_pages/spectrum/spectrum.component';
import { CorporateInsuranceComponent } from './_pages/corporate-insurance/corporate-insurance.component';
import { DifferentlyAbledComponent } from './_pages/differently-abled/differently-abled.component';
import { RehabilitationComponent } from './_pages/rehabilitation/rehabilitation.component';
import { ExecutiveCheckupComponent } from './_pages/executive-checkup/executive-checkup.component';
import { BasicExecutiveCheckupComponent } from './_pages/basic-executive-checkup/basic-executive-checkup.component';
import { NormalExecutiveCheckupComponent } from './_pages/normal-executive-checkup/normal-executive-checkup.component';
import { KidneyExecutiveCheckupComponent } from './_pages/kidney-executive-checkup/kidney-executive-checkup.component';
import { WellWomenCheckupComponent } from './_pages/well-women-checkup/well-women-checkup.component';
import { PediatricExecutiveCheckupComponent } from './_pages/pediatric-executive-checkup/pediatric-executive-checkup.component';
import { CardiacExecutiveCheckupComponent } from './_pages/cardiac-executive-checkup/cardiac-executive-checkup.component';
import { DiabeticCheckupComponent } from './_pages/diabetic-checkup/diabetic-checkup.component';
import { RespiratoryExecutiveCheckupComponent } from './_pages/respiratory-executive-checkup/respiratory-executive-checkup.component';
import { ComprehensiveBasicExecutiveCheckupComponent } from './_pages/comprehensive-basic-executive-checkup/comprehensive-basic-executive-checkup.component';
import { ComprehensiveMaleCheckupComponent } from './_pages/comprehensive-male-checkup/comprehensive-male-checkup.component';
import { ComprehensiveFemaleCheckupComponent } from './_pages/comprehensive-female-checkup/comprehensive-female-checkup.component';
import { LiverExecutiveCheckupComponent } from './_pages/liver-executive-checkup/liver-executive-checkup.component';
import { PostcovidExecutiveCheckupComponent } from './_pages/postcovid-executive-checkup/postcovid-executive-checkup.component';
import { CareerComponent } from './_pages/career/career.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'services',
    component: ServicespageComponent,
  },
  {
    path: 'courses',
    component: CoursesComponent,
  },
  {
    path: 'medical-dictionary',
    component: MedicaldictionaryComponent,
  },
  {
    path: 'research-development',
    component: ResearchDevelopmentComponent,
  },
  {
    path: 'research-development/genetics-and-molecular-diagnostics-laboratory',
    component: ResearchDevelopmentsubComponent,
  },
  {
    path: 'research-development/spectrum-comprehensive-rehabilitation',
    component: ResearchDevelopmentsubComponent,
  },
  {
    path: 'camp',
    component: CampComponent,
  },
  {
    path: 'blog',
    component: BlogComponent,
  },
  {
    path: 'contact-us',
    component: ContactUsComponent,
  },
  {
    path: 'spectrum',
    component: SpectrumComponent,
  },
  {
    path: 'basic-executive-checkup',
    component: BasicExecutiveCheckupComponent,
  },
  {
    path: 'normal-executive-checkup',
    component: NormalExecutiveCheckupComponent,
  },
  {
    path: 'kidney-executive-checkup',
    component: KidneyExecutiveCheckupComponent,
  },
  {
    path: 'nims-executive-checkups',
    component: ExecutiveCheckupComponent,
  },
  {
    path: 'post-covid-executive-checkup',
    component: PostcovidExecutiveCheckupComponent,
  },
  {
    path: 'well-women-checkup',
    component:  WellWomenCheckupComponent,
  },
  {
    path: 'diabetic-checkup',
    component: DiabeticCheckupComponent,
  },
  {
    path: 'cardiac-executive-checkup',
    component: CardiacExecutiveCheckupComponent,
  },
  {
    path: 'respiratory-executive-checkup',
    component: RespiratoryExecutiveCheckupComponent,
  },
  {
    path: 'pediatric-executive-checkup',
    component: PediatricExecutiveCheckupComponent,
  },

  {
    path: 'liver-executive-checkup',
    component: LiverExecutiveCheckupComponent,
  },
  {
    path: 'comprehensive-basic-executive-checkup',
    component:  ComprehensiveBasicExecutiveCheckupComponent,
  },
  {
    path: 'comprehensive-male-checkup',
    component:  ComprehensiveMaleCheckupComponent,
  },
  {
    path: 'comprehensive-female-checkup',
    component:  ComprehensiveFemaleCheckupComponent,
  },
  {
    path: 'blog/the-charitable-projects-of-nims-medicity',
    component: BlogSubcategoryComponent,
  },
  {
    path: 'corporate-insurance',
    component: CorporateInsuranceComponent,
  },
  {
    path: 'nims-department-of-rehabilitation',
    component: RehabilitationComponent,
  },
  {
    path: 'differently-abled-friendly-hospital',
    component: DifferentlyAbledComponent,
  },
  {
    path: 'blog/inherited-diseases',
    component: BlogSubcategoryComponent,
  },
  {
    path: 'blog/why-are-women-more-vulnerable-to-stress',
    component: BlogSubcategoryComponent,
  },
  {
    path: 'blog/types-of-dermatological-treatments',
    component: BlogSubcategoryComponent,
  },

  {
    path: 'blog/cosmetic-dermatology-treatments-of-the-face',
    component: BlogSubcategoryComponent,
  },
  {
    path: 'blog/common-symptoms-of-cardiac-diseases',
    component: BlogSubcategoryComponent,
  },
  {
    path: 'blog/vats-surgical-procedure',
    component: BlogSubcategoryComponent,
  },
  {
    path: 'blog/pcos-a-common-condition-in-women',
    component: BlogSubcategoryComponent,
  },
  {
    path: 'blog/facts-about-bariatric-surgery',
    component: BlogSubcategoryComponent,
  },
  {
    path: 'blog/consequences-of-excessive-smoking-and-tips-to-quit-smoking',
    component: BlogSubcategoryComponent,
  },
  {
    path: 'blog/high-risk-pregnancies',
    component: BlogSubcategoryComponent,
  },
  {
    path: 'blog/ways-to-boost-your-metabolism',
    component: BlogSubcategoryComponent,
  },
  {
    path: 'blog/obesity-and-its-after-effects',
    component: BlogSubcategoryComponent,
  },
  {
    path: 'blog/thyroid-hypothyroidism-hyperthyroidism-and-goiter',
    component: BlogSubcategoryComponent,
  },
  {
    path: 'blog/migraine-its-causes-and-why-its-more-common-in-women',
    component: BlogSubcategoryComponent,
  },
  {
    path: 'blog/what-is-depression',
    component: BlogSubcategoryComponent,
  },
  {
    path: 'blog/sinusitis-causes-and-prevention',
    component: BlogSubcategoryComponent,
  },
  {
    path: 'blog/differences-between-depression-and-stress-disorder',
    component: BlogSubcategoryComponent,
  },
  {
    path: 'blog/phobias-claustrophobia',
    component: BlogSubcategoryComponent,
  },
  {
    path: 'blog/exercises-for-knee-replacement-surgeries',
    component: BlogSubcategoryComponent,
  },
  {
    path: 'blog/a-closer-look-at-nims-medicity',
    component: BlogSubcategoryComponent,
  },
  {
    path: 'blog/how-to-prevent-muscle-loss',
    component: BlogSubcategoryComponent,
  },
  {
    path: 'blog/things-to-avoid-during-pregnancy',
    component: BlogSubcategoryComponent,
  },
  {
    path: 'blog/consequences-of-alcohol-consumption',
    component: BlogSubcategoryComponent,
  },
  {
    path: 'blog/cures-for-insomnia',
    component: BlogSubcategoryComponent,
  },
  {
    path: 'blog/inherited-heart-diseases',
    component: BlogSubcategoryComponent,
  },
  {
    path: 'blog/what-are-the-effects-of-excessive-internet-usage',
    component: BlogSubcategoryComponent,
  },
  {
    path: 'blog/what-are-the-signs-of-anorexia',
    component: BlogSubcategoryComponent,
  },
  {
    path: 'blog/tips-on-overcoming-alcohol-addiction',
    component: BlogSubcategoryComponent,
  },
  {
    path: 'blog/joint-replacement-surgeries',
    component: BlogSubcategoryComponent,
  },
  {
    path: 'blog/how-often-should-a-pregnant-woman-have-check-ups-and-scans',
    component: BlogSubcategoryComponent,
  },
  {
    path: 'blog/fibrosis-a-silent-attacker',
    component: BlogSubcategoryComponent,
  },
  {
    path: 'blog/different-types-of-anesthesia',
    component: BlogSubcategoryComponent,
  },
  {
    path: 'blog/vaccinations-required-by-teenage-boys-and-girls',
    component: BlogSubcategoryComponent,
  },
  {
    path: 'blog/the-vaccinations-needed-by-your-child',
    component: BlogSubcategoryComponent,
  },
  {
    path: 'blog/the-golden-60-minutes-after-an-accident',
    component: BlogSubcategoryComponent,
  },
  {
    path: 'blog/take-care-of-your-health-this-monsoon-to-avoid-serious-diseases',
    component: BlogSubcategoryComponent,
  },
  {
    path: 'blog/how-healthy-should-a-donor-be-to-donate-blood-or-organ',
    component: BlogSubcategoryComponent,
  },
  {
    path: 'blog/know-your-hormones',
    component: BlogSubcategoryComponent,
  },
  {
    path: 'blog/facilities-at-the-nims-medicity',
    component: BlogSubcategoryComponent,
  },
  {
    path: 'courses/noorul-islam-college-of-dental-science',
    component: CoursesSubcategoryComponent,
  },
  {
    path: 'courses/nims-college-of-nursing',
    component: CoursesSubcategoryComponent,
  },
  {
    path: 'courses/dnb-programe-in-cardiologyproposed',
    component: CoursesSubcategoryComponent,
  },
  {
    path: 'news-events',
    component: NewsEventsComponent,
  },
  {
    path: 'news-events/apj-award-2021-insituted-by-noorul-islam-deemed-university-and-nims-medicity-was-presented',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/respectful-homage-to-sunderlal-bahuguna',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/apj-award-2020-instituted-by-noorul-islam-university-was-presented',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/planting-a-tree-sapling-at-nims-medicity-on-world-environment-day-june-5-2020',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/training-program-at-nims-medicity-on-5th-february-2020',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/visit-of-h-e-mr-faisal-naseem-hon-vice-president-of-republic-of-maldives-to-nims-medicity-on-05-12-2019',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/nims-college-of-nursing-conducted-school-health-programme-at-st-josephs-lps-school-balaramapuram-on-27-01-2020',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/shri-s-gopinathan-nair-ips-retd-former-igp-delivered-the-republic-day-message-to-the-students-of-noorul-islam-civil-service-academy',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/neyyattinkara-municipality-and-nims-medicity-nims-harithakom-organized-a-cleaning-drive-as-part-of-the-republic-day-celebrations',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/nims-spectrum-one-day-training-program',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/nims-spectrum-comprehensive-rehabilitation-services-for-the-differently-abled-children-was-inaugurated-at-nims-medicity-on-3rd-december-2019',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/12th-anniversary-of-nims-heart-foundation-world-heart-day-celebrations-day-three-on-29-09-2019',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/12th-anniversary-of-nims-heart-foundation-world-heart-day-celebrations-day-two-on-28-09-2019',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/12th-anniversary-of-nims-heart-foundation-world-heart-day-celebrations-kick-started-at-the-nims-medicity-auditorium-on-27th-september-2019',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/onam-celebration-2019-for-the-differently-abled-children',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/the-first-batch-of-trainees-of-nims-hgk-biotechnology-finishing-school',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/noorul-islam-civil-service-academy-entrance-examination-for-admission-to-the-8th-batch',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/dr-a-p-j-abdul-kalam-remembrance-day-at-nims-medicity',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/super-speciality-medical-camp-at-nims-medicity',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/scope-and-prospects-of-biomedical-engineering-and-molecular-genetics-at-malayala-manorama',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/commemoration-of-sri-k-e-mammen-at-nims-medicity',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/free-aids-and-appliances-to-more-than-2000-differently-abled-persons',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/honouring-the-students-who-secured-outstanding-grades-in-the-sslc-and-plus-two-examinations',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/world-thyroid-day-was-commemorated-at-nims-medicity',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/nims-medicity-congratulates-the-bsc-nursing-students-of-malidivian-national-university-maldives',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/nims-anne-sullivan-centre-was-inaugurated',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/tobacco-alcohol-and-drug-awareness-programme',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/7th-convocation-ceremony-of-2013-2019-bds-batch-on-5-05-2019-at-bm-convention-centreambalathara',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/free-camp-special-education-therapy',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/special-environmental-workshop-for-school-students',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/shri-k-e-mammen-was-commemorated-at-nims-medicity-campus-today',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/the-3rd-remembrance-day-of-bharat-ratna-dr-a-p-j-abdul-kalam-was-commemorated-at-nims-medicity-today-27-07-18',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/excellency-shri-kummanam-rajasekharan-today-inaugurated-the-11th-anniversary-celebrations-of-nims-heart-foundation',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/inaugurated-the-new-transplantation-complex-and-launched-5-new-projects',
    component: NewsEventssubcategoryComponent,
  },
 
  {
    path: 'news-events/what-you-need-to-know-about-organ-donation',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/honouring-ceremony',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/ഒരു-കുഞ്ഞു-ഒരു-മരം',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/യുത്ത്-റെഡ്-ക്രോസ്സ്-യുണിറ്റ്',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/:id',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/ലോക-നഴ്‌സ്-ദിനം',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/energy-week',
    component: NewsEventssubcategoryComponent,
  },
  
  {
    path: 'news-events/വളർച്ചയുടെ-ആദ്യ-ആയിരം-ദിവസങ്ങൾ',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/നിംസ്-മെഡിസിറ്റി-ലൈബ്രറിയിൽ-ആരംഭിച്ച-സാഹിത്യ-ശാഖ-രചന',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/പതിനാറാമത്-ബാച്ച്-ബി.ഡി.എസ്-പ്രവേശനോത്സവം',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/Transcatheter-Aortic-Valve-Implantation-procedure-was-performed-successfully',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/ശ്രീ-അഷ്‌റഫ്‌-താമരശ്ശേരിക്ക്-നിംസ്-കുടുംബത്തിന്റെ-ആദരം',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/NIMS-Khadi-Movement',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/visit-by-sri-o-rajagopal',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/visit-by-sri-thushar-gandhi-great-grand-son-of-mahatma-gandhi',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/shri-pannian-ravindran-visits',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/vivekanandha-youth-award-from-dr-shashi-tharoor-mp',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/mobile-cardiac-camp-flagged-off-by-dr-murli-manohar-joshi',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/super-speciality-medical-camp-at-nims-medicity-inaugurated-by-sri-m-m-hassan',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/സാമൂഹിക-വിപത്തായ-ലഹരിക്കെതിരെ-പ്രതിരോധം-ശക്തമാക്കണം-വി-ഡി-സതീശൻ',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/nims-medicity-was-inaugurated-by-former-governor-sri-vakkam-purushothaman',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/honourable-justice-p-sathasivam-visit',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/annual-convocation-of-noorul-islam-deemed-university-dec2021',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/sri-jagadeesh-and-smt-chippy-renjith-inaugurated-nims-nano-clinic',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/shri-a-k-antony-visited-nims-medicity',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/dr-kandeh-k-yumkella-under-secretary-general',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/dr-g-madhavan-nair-visit-at-nims-gi-bleed-unit-inauguration',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/നിംസ്-മെഡിസിറ്റിയിൽ-ജീവനക്കാരുടെയും-അഭ്യുദയകാംക്ഷികളുടേയും-സംഗമം-സംഘടിപ്പിച്ചു',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/നിംസും-കെ.എസ്.ആർ.റ്റി.സിയും-കൈകോർത്തു-ഭിന്നശേഷി-കുട്ടികൾക്ക്-ചികിത്സ-സൗജന്യ-യാത്ര-ഒരുക്കുന്നു',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/shri-v-s-shiva-kumar-inaugurating-sri-sathya-sai-dialysis-care-project',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/shri-oommen-chandy-visits-asias-first-green-energy-cardiac-catheterization-lab',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/visit-by-padmashree-dr-g-bakthavathsalam-naidu-padma-vibhushan-shri-g-madhavan-nair',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/sri-maniyanpilla-raju-inaugurating-nims-nano-clinic',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/nims-medicity-world-heart-day-2016',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/world-aids-day-is-celebrated',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/nims-medicity-nicer-celebrated',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'news-events/noorul-islam-university',
    component: NewsEventssubcategoryComponent,
  },
  {
    path: 'ambulance-services',
    component: AmbulanceServicesComponent,
  },
  {
    path: 'video-gallery',
    component: VideoGalleryComponent,
  },
  {
    path: 'testimonials',
    component: TestimonialsComponent,
  },
  {
    path: 'testimonials/നിറകണ്ണുകളുമായെത്തിയ-ഭർത്താവിന്-നിംസിൽ-പുനർ-ജന്മം',
    component: TestimonialsSubcategoryComponent,
  },
  {
    path: 'testimonials/Babu',
    component: TestimonialsSubcategoryComponent,
  },
  {
    path: 'testimonials/Prathapan-Nair',
    component: TestimonialsSubcategoryComponent,
  },
  {
    path: 'testimonials/Sajitha',
    component: TestimonialsSubcategoryComponent,
  },
  {
    path: 'testimonials/Martin-Nelson',
    component: TestimonialsSubcategoryComponent,
  },
  {
    path: 'testimonials/M-M-Hassan-KPCC-President',
    component: TestimonialsSubcategoryComponent,
  },
  {
    path: 'testimonials/Ahmed-Mohamed-Ambassador-of-Maldives-to-India',
    component: TestimonialsSubcategoryComponent,
  },
  {
    path: 'testimonials/Ayilla-Amaramma',
    component: TestimonialsSubcategoryComponent,
  },
  {
    path: 'testimonials/Rejitha-S-Babu',
    component: TestimonialsSubcategoryComponent,
  },
  {
    path: 'testimonials/പ്രീജിത്-ഇനി-ബഹളം-വെക്കാനില്ല',
    component: TestimonialsSubcategoryComponent,
  },
  {
    path: 'testimonials/ഹർഷൻ-ഇനി-പുതിയ-ലോകത്ത്',
    component: TestimonialsSubcategoryComponent,
  },
  {
    path: 'testimonials/സവിത-ആഹ്ലാദ-ലോകത്തിൽ',
    component: TestimonialsSubcategoryComponent,
  },
  {
    path: 'testimonials/ആദിരാജ്-ഇപ്പോൾ-ഇരിക്കാൻ-തുടങ്ങി',
    component: TestimonialsSubcategoryComponent,
  },
  {
    path: 'testimonials/ബിമലിനു-ഓർമ്മ-തിരിച്ചു-കിട്ടി',
    component: TestimonialsSubcategoryComponent,
  },
  {
    path: 'testimonials/താരയുടെ-രക്ഷിതാക്കൾ',
    component: TestimonialsSubcategoryComponent,
  },
  {
    path: 'testimonials/Annu-Tiwari',
    component: TestimonialsSubcategoryComponent,
  },
  {
    path: 'testimonials/Ramachandran',
    component: TestimonialsSubcategoryComponent,
  },
  {
    path: 'testimonials/Leenu-Babu',
    component: TestimonialsSubcategoryComponent,
  },
  {
    path: 'testimonials/Jensha',
    component: TestimonialsSubcategoryComponent,
  },
  {
    path: 'testimonials/Selvakumari',
    component: TestimonialsSubcategoryComponent,
  },
  {
    path: 'testimonials/V-Balakrishnan-Thampi',
    component: TestimonialsSubcategoryComponent,
  },
  {
    path: 'testimonials/D-L-Lijin',
    component: TestimonialsSubcategoryComponent,
  },
  {
    path: 'testimonials/B-Radhamma',
    component: TestimonialsSubcategoryComponent,
  },
  {
    path: 'testimonials/Sareena-Nazar',
    component: TestimonialsSubcategoryComponent,
  },
  {
    path: 'testimonials/Aju-Khan-M',
    component: TestimonialsSubcategoryComponent,
  },
  {
    path: 'testimonials/Soumya',
    component: TestimonialsSubcategoryComponent,
  },
  {
    path: 'testimonials/Melbin-J-Reena',
    component: TestimonialsSubcategoryComponent,
  },
  {
    path: 'testimonials/Madhu-B',
    component: TestimonialsSubcategoryComponent,
  },
  {
    path: 'testimonials/Greeshma-G-Rajeev',
    component: TestimonialsSubcategoryComponent,
  },
  {
    path: 'testimonials/Shyam-Kumar-S',
    component: TestimonialsSubcategoryComponent,
  },
  {
    path: 'testimonials/Habeeb',
    component: TestimonialsSubcategoryComponent,
  },
  {
    path: 'testimonials/J-P-Anantha-Prasad',
    component: TestimonialsSubcategoryComponent,
  },
  {
    path: 'testimonials/R-Sasidhara-Kurup',
    component: TestimonialsSubcategoryComponent,
  },
  {
    path: 'testimonials/Rajeev-Cheril',
    component: TestimonialsSubcategoryComponent,
  },
  {
    path: 'testimonials/Revd-Vinod-Victor',
    component: TestimonialsSubcategoryComponent,
  },
  {
    path: 'about-us',
    component: AboutusComponent,
  },
  {
    path: 'ni-trust',
    component: NiTrustComponent,
  },
  {
    path: 'management',
    component: ManagementComponent,
  },
  {
    path: 'pressrelease',
    component: PressreleaseComponent,
  },
  {
    path: 'image-gallery',
    component: GalleryComponent,
  },
  {
    path: 'doctors',
    component: DoctorsComponent,
  },
  {
    path: 'doctors/Dr-Biswas-P-S',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Aksar-A-M',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Haris-Azeez',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Hameem-Meeran-Pillai',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Aravinthan-M',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Jannathul-Firdouse',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-N-Venugopal',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Lakshmi-V-S',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Koshy',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Sooraj',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Madhu-Sreedharan',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Kiran-Gopinath',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Priya-N',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-P-S-Sreejith',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Binija-R',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Mahadevan-R',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-E-Asher-Ennis-Nayagam',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Rose-Maria-John',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Ganesh-Kumar-Nair',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Veena-Vijay',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Aswani-Syam',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Divya-S-Vijayan',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Ciji-J-I',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Shebin-S-E',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Vimal-Kumar-S-V',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-G-L-Reshma',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Jannu-Sandeep',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Sisra-S',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Rajagopalan-M',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Vidya-B',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-M-K-C-Nair',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Aiswarya-R-V',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Reshma-S-V',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Neethu-U-Vince',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Gayathri-G-R',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Arya-C-Babu',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Suji-M-S',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Jijin-J-U',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Vaishakh-L-R',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Dhanya-Sivanandan',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Sasikumar-V',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Sreejith-N-Kumar',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Somarajan-P-K',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Nayanthara-Ramesh',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Muraleedharan-Nair-K',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Amal-P-Jose',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Sabareesh-K',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Rini-A',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Jayakumar-D',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Abhisek-Sasidharan',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Abhisha-Levi-S',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Fahaduzzaman-P',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Thriloknath-A-R',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-E-Velayudhan',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-E-Caezar-Ennis',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Jim-Litton-J',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Saleen-Joel',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Suji-M-S',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Biju-B-Nair',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Karthikeyan-C',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Indira-Amma-J',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Azeem-Mohamed-Basheer',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Stephen-Raj-J-R',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Lekshmi-Balakrishnan-Unni',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Jhansi-K-P',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Jyotsna-Nalinan',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Vishnupriya-R-S',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Aswathy-Senan-C-R',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Maya-Mohandas',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Neethu-S-L',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Shareek-P-S',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Alwin-Lal-S',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Aneesh-Raj',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Nishanth-Babu',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Viswa-Silpi-V-J',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Arun-M-V',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Saritha-S-Nair',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Veena-V-P',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Dr-Jijo-Martin-N-S',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Lekshmi-S-Nair',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Harshan-K-H',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Lalitha-Appukuttan',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Shafeek-Mohammed-Shajahan',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-M-Rajee-Kumar',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Hazeena-K-R',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Ashmiya-M-Varghese',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Anjana-B-Ajay',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Mereena-Darwin',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Guljit-Singh',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Roshith-J-Kumar',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Manju-Thampi',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Sona-Y-S',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-C-Kathirvel',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Sivadasan-R',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Raeez-Mohammed-Basheer',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Vidya-Subhash',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Rose-Maria-John',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Ganesh-Kumar-Nair',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Saju-Kurian-George',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-K-Kanchana',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Sadique-Hussain-M',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Achuthan-Nair',
    component: DoctorsSubcategoryComponent,
  },

  {
    path: 'doctors/Dr-Anand-Sekhar',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Nabeela-Amreen',
    component: DoctorsSubcategoryComponent,
  },

  {
    path: 'doctors/Dr-Anup-Kumar-G',
    component: DoctorsSubcategoryComponent,
  },

  {
    path: 'doctors/Dr-Cherian-Jacob',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Keerthi-Mohan',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Prasoon-Anil',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Rajsankar-N-R',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Vinod-Krishnan-V',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Ajith-Achuthan-Nair',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-P-A-Mohammadu-Kunju',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Rajendran-S',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Ashwin-C-V-Mareesh',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Nisha-M',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Jayadevan-V',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Vinu-Kumar-V',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-M-A-Ameen-Sait',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Jim-Gopalakrishnan',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Vinodh-Kumar',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Arun-Kumar-G',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Mahesh-B',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Sajna-Oommen',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Ebinu-A',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Abdul-Bari-A',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Beena-V-S',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Vipin-P-V',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Joseph-Thomas',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Akshara-Vijayan',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Vipin-Jacob',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Jithin-Mohan-R',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Sivaramakrishanan',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-M-Nazeer-Hussain',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Sreeja-S-Nair',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Glaxon-Alex',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'doctors/Dr-Naveen-K',
    component: DoctorsSubcategoryComponent,
  },
  
 
 
  
  {
    path: 'doctors/Dr-Shashikala',
    component: DoctorsSubcategoryComponent,
  },
 
  
  {
    path: 'doctors/Dr-Aneesh Nair',
    component: DoctorsSubcategoryComponent,
  },
 
  
  
  {
    path: 'doctors/Dr-G-S-Jeevan',
    component: DoctorsSubcategoryComponent,
  },
  
  
  {
    path: 'doctors/Dr-Merlin-Sheema',
    component: DoctorsSubcategoryComponent,
  },
  {
    path: 'departments',
    component: OurDepartmentMainComponent,
  },
  {
    path: 'departments/anaesthesiology',
    component: OurDepartmentsSubComponent,
  },
  {
    path: 'departments/ayurveda',
    component: OurDepartmentsSubComponent,
  },
  {
    path: 'departments/bariatric-surgery',
    component: OurDepartmentsSubComponent,
  },
  {
    path: 'departments/cardiology',
    component: OurDepartmentsSubComponent,
  },
  {
    path: 'departments/cardiothoracic-vascular-surgery',
    component: OurDepartmentsSubComponent,
  },
  {
    path: 'departments/chemotherapy-unit',
    component: OurDepartmentsSubComponent,
  },
  {
    path: 'departments/clinical-psychology',
    component: OurDepartmentsSubComponent,
  },
  {
    path: 'departments/covid-care-centre',
    component: OurDepartmentsSubComponent,
  },
  {
    path: 'departments/dermatology',
    component: OurDepartmentsSubComponent,
  },
  {
    path: 'departments/developmental-medicine',
    component: OurDepartmentsSubComponent,
  },
  {
    path: 'departments/dietary',
    component: OurDepartmentsSubComponent,
  },
  {
    path: 'departments/emergency-medicine',
    component: OurDepartmentsSubComponent,
  },
  {
    path: 'departments/endocrinology-and-diabetology',
    component: OurDepartmentsSubComponent,
  },
  {
    path: 'departments/ent',
    component: OurDepartmentsSubComponent,
  },
  {
    path: 'departments/gastroenterology',
    component: OurDepartmentsSubComponent,
  },
  {
    path: 'departments/general-medicine',
    component: OurDepartmentsSubComponent,
  },
  {
    path: 'departments/general-surgery',
    component: OurDepartmentsSubComponent,
  },
  {
    path: 'departments/gynaecology',
    component: OurDepartmentsSubComponent,
  },
  {
    path: 'departments/infectious-diseases',
    component: OurDepartmentsSubComponent,
  },
  {
    path: 'departments/intensive-care',
    component: OurDepartmentsSubComponent,
  },
  {
    path: 'departments/medical-genetics',
    component: OurDepartmentsSubComponent,
  },
  {
    path: 'departments/microbiology',
    component: OurDepartmentsSubComponent,
  },
  {
    path: 'departments/naturopathy',
    component: OurDepartmentsSubComponent,
  },
  {
    path: 'departments/neonatology',
    component: OurDepartmentsSubComponent,
  },
  {
    path: 'departments/nephrology',
    component: OurDepartmentsSubComponent,
  },
  {
    path: 'departments/neuro-surgery-and-neurology',
    component: OurDepartmentsSubComponent,
  },
  {
    path: 'departments/oncology',
    component: OurDepartmentsSubComponent,
  },
  {
    path: 'departments/ophthalmology',
    component: OurDepartmentsSubComponent,
  },
  {
    path: 'departments/oral-and-maxillofacial-surgery',
    component: OurDepartmentsSubComponent,
  },
  {
    path: 'departments/orthopaedics',
    component: OurDepartmentsSubComponent,
  },
  {
    path: 'departments/paediatric-neurology',
    component: OurDepartmentsSubComponent,
  },
  {
    path: 'departments/paediatric-surgery',
    component: OurDepartmentsSubComponent,
  },
  {
    path: 'departments/paediatrics',
    component: OurDepartmentsSubComponent,
  },
  {
    path: 'departments/pathology',
    component: OurDepartmentsSubComponent,
  },
  {
    path: 'departments/physiatry-and-physiotherapy',
    component: OurDepartmentsSubComponent,
  },
  {
    path: 'departments/plastic-surgery',
    component: OurDepartmentsSubComponent,
  },
  {
    path: 'departments/prosthodontics',
    component: OurDepartmentsSubComponent,
  },
  {
    path: 'departments/psychiatry',
    component: OurDepartmentsSubComponent,
  },
  {
    path: 'departments/pulmonology',
    component: OurDepartmentsSubComponent,
  },
  {
    path: 'departments/radiology',
    component: OurDepartmentsSubComponent,
  },
  {
    path: 'departments/renal-transplantation-unit',
    component: OurDepartmentsSubComponent,
  },
  {
    path: 'departments/rheumatology',
    component: OurDepartmentsSubComponent,
  },
  {
    path: 'departments/urology',
    component: OurDepartmentsSubComponent,
  },
  {
    path: 'careers',
    component: CareerComponent,
  },
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
