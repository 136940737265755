import { Component, OnInit } from '@angular/core';
import { DoctorsListService } from 'src/app/_services/doctors-list.service';

@Component({
  selector: 'app-servicespage',
  templateUrl: './servicespage.component.html',
  styleUrls: ['./servicespage.component.scss'],
})
export class ServicespageComponent implements OnInit {
  services: any = [];
  canvas: any = [];
  constructor(private Servicelist: DoctorsListService) {}

  ngOnInit() {
    var x = 'A';
    this.getServiceList(x);
  }

  getServiceList(x) {
    this.Servicelist.getServiceList().subscribe((data) => {
      this.services = data.data;
      console.log(x);
      for (var val of this.services) {
        if (val.id == x) {
          this.canvas = this.services;
          console.log(this.canvas);
        }
      }
    });
  }
}
