import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import AOS from 'aos';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title =
    'Best Hospital in Trivandrum | Top Hospitals in Kerala | NIMS Medicity';

  constructor(private titleService: Title, private metaService: Meta) {}
  ngOnInit() {
    AOS.init();

    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      { name: 'keywords', content: 'Angular, Universal, Example' },
      {
        name: 'description',
        content:
          'NIMS Medicity is among the pioneers in the health care centres in Kerala & Tamil Nadu with a team of medical experts in the major fields of medicine.',
      },
    ]);
  }
}
