import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class DoctorsListService {
  getDoctorsUrl = 'assets/data/doctors-list.json';
  getSpecialityUrl = 'assets/data/servicecard.json';
  getcoursesUrl = 'assets/data/courses.json';
  getcoursescategoryUrl = 'assets/data/coursesubcategory.json';
  getnewseventUrl = 'assets/data/newsevent.json';
  gettestimonialUrl = 'assets/data/testimonial.json';
  getcampUrl = 'assets/data/camp.json';
  getblogUrl = 'assets/data/blog.json';
  getserviceUrl = 'assets/data/services.json';
  getdictionaryUrl = 'assets/data/dictionary.json';
  getresearchUrl = 'assets/data/research.json';
  getimageUrl = 'assets/data/image.json';

  getCustomDepartmentUrl ='https://nims.prismaticsoft.com/api/resource/Departments?limit_page_length=10000&fields=[%22idx%22,%22name%22,%22enable%22]&order_by=idx%20asc&filters=[[%22idx%22,%22!=%22,%220%22],[%22enable%22,%22=%22,%221%22]]'
  getCustomDoctorsUrl = 'https://nims.prismaticsoft.com/api/resource/Doctor?fields=[%22name%22,%22department%22,%22enable_home%22,%22profileimage%22,%22qualification%22,%22time_description%22]&limit_page_length=10000'
  getCustomNewsEventUrl='https://nims.prismaticsoft.com/api/resource/Recent_News?limit_page_length=10000&fields=[%22title%22,%22col_description%22,%22row_description%22,%22image%22,%22date%22,%22url%22]&order_by=date%20desc'
  getRecentNewsUrl='https://nims.prismaticsoft.com/api/resource/Recent_News?limit_page_length=10000&fields=[%22title%22,%22col_description%22,%22image%22,%22date%22,%22show_in_home%22]&filters=[[%22show_in_home%22,%22=%22,%221%22]]&order_by=date%20desc'
  getCareerUrl='https://nims.prismaticsoft.com/api/resource/career?limit_page_length=10000&fields=[%22post%22,%22qualification%22,%22date%22,%22contact%22]&order_by=date%20desc'
  getDoctorsHomeUrl='https://nims.prismaticsoft.com/api/resource/Doctor?fields=[%22name%22,%22department%22,%22enable_home%22,%22profileimage%22,%22qualification%22,%22url%22]&filters=[[%22enable_home%22,%22=%22,%221%22]]&limit_page_length=10'
  message: string;

  constructor(private http: HttpClient,private router: Router) {}
  
  getCustomDepartment(){
    return this.http.get<any>(this.getCustomDepartmentUrl);
  }
  getCustomDoctorsList(){
    return this.http.get<any>(this.getCustomDoctorsUrl);
  }
  getCustomNewsEventList(){
    return this.http.get<any>(this.getCustomNewsEventUrl);
  }
  getDoctorsList() {
    return this.http.get<any>(this.getDoctorsUrl);
  }
  getDoctorsHomeList(){
    return this.http.get<any>(this.getDoctorsHomeUrl);
  }
  getRecentNews()
  {
    return this.http.get<any>(this.getRecentNewsUrl);
  }
  getSpecialityList() {
    return this.http.get<any>(this.getSpecialityUrl);
  }

  getCoursesList() {
    return this.http.get<any>(this.getcoursesUrl);
  }
getCareerList()
{
  return this.http.get<any>(this.getCareerUrl);
}
  getCoursescategoryList() {
    return this.http.get<any>(this.getcoursescategoryUrl);
  }

  getnewseventList() {
    return this.http.get<any>(this.getnewseventUrl);
  }
  getcampList() {
    return this.http.get<any>(this.getcampUrl);
  }

  gettestimonialList() {
    return this.http.get<any>(this.gettestimonialUrl);
  }

  getblogList() {
    return this.http.get<any>(this.getblogUrl);
  }

  setMessage(data) {
    this.message = data;
  }
  getMessage() {
    return this.message;
  }
  getServiceList() {
    return this.http.get<any>(this.getserviceUrl);
  }

  getDictionaryList() {
    return this.http.get<any>(this.getdictionaryUrl);
  }
  getResearchList() {
    return this.http.get<any>(this.getresearchUrl);
  }

  getimageList() {
    return this.http.get<any>(this.getimageUrl);
  }
}
