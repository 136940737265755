import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DepartmentListService } from 'src/app/_services/department-list.service';

@Component({
  selector: 'app-our-departments-sub',
  templateUrl: './our-departments-sub.component.html',
  styleUrls: ['./our-departments-sub.component.scss'],
})
export class OurDepartmentsSubComponent implements OnInit {
  pathFldr: any;
  departments: any = [];
  departmentsData: any;

  constructor(
    private router: Router,
    private departmentList: DepartmentListService
  ) {}

  ngOnInit() {
    this.pathFldr = this.router.url;
    console.log(this.pathFldr);
    this.getDepartmentList();
  }
  getDepartmentList() {
    this.departmentList.getDDepartmentList().subscribe((data) => {
      this.departments = data.data;
    });
  }
}
