<div class="nm-page-header">
  <h2>WELL WOMEN CHECK UP</h2>
</div>

<div class="container">
  <div style="text-align:center">
    <h4>WELL WOMEN EXECUTIVE HEALTH CHECK UP</h4>
    <h4>Amount:6000/-</h4>
    <h4>77 TESTS & 3 CONSULTATIONS</h4>
  </div>
  <div class="row py-3">
    <div class="col-sm">
      <h5>HAEMATOLOGY</h5>
      <h6>BLOOD GROUP & RH FACTOR</h6>
      <br>
      <h6>COMPLETE BLOOD COUNT</h6>
        <div class="p-3">
          <ul>
            <li>HAEMOGLOBIN</li>
            <li>TOTAL COUNT</li>
          </ul>
        </div>
    
      <h5>DIFFERENTIAL COUNT</h5>
        <div class="p-3">
          <ul>
            <li>POLYMORPHS</li>
            <li>LYMPHOCYTES</li>
            <li>MONOCYTES</li>
            <li>EOSINOPHILS</li>
            <li>BASOPHILS</li>
            <li>PLATELET COUNT</li>
            <li>RBC</li>
            <li>PCV</li>
            <li>MCV</li>
            <li>MCH</li>
            <li>MCHC</li>
            <li>ESR</li>
            <li>RED CELL DISTRIBUTION</li>
            <li>ABSOLUTE EOSINOPHIL COUNT</li>
            <li>ABSOLUTE LYMPHOCYTE COUNT</li>
            <li>ABSOLUTE NEUTROPHIL COUNT</li>
            <li>MEAN PLATELET VOLUME</li>
          </ul>
        </div>
      <h5>BIOCHEMISTRY</h5>
      <div class="p-2">
        <ul>
          <li>FBS (FASTING BLOOD SUGAR)</li>
          <li>PPBS (POST PRANDIAL BLOOD SUGAR)</li>
          <li>HbA1C</li>
        </ul>
      </div>
      <h5>LIPID PROFILE</h5>
      <div class="p-2">
        <ul>
          <li>S.CHOLESTEROL</li>
          <li>HDL CHOLESTEROL</li>
          <li>LDL CHOLESTEROL</li>
          <li>VLDL CHOLESTEROL</li>
          <li>S.TRIGLYCERIDES</li>
        </ul>
      </div>
    </div>
    <div class="col-sm">
      <h5>RENAL PROFILE</h5>
      <div class="p-2">
        <ul>
          <li>BLOOD UREA NITROGEN</li>
          <li>CREATINE</li>
          <li>URIC ACID</li>
        </ul>
      </div>
      <h5>ELECTROLYTES</h5>
      <div class="p-2">
        <ul>
          <li>S.SODIUM</li>
          <li>S.POTASSIUM</li>
        </ul>
      </div>
     
      <h5>LIVER PROFILE</h5>
      <div class="p-2">
        <ul>
          <li>S. BILIRUBIN (TOTAL)</li>
          <li>S. BILIRUBIN (DIRECT)</li>
          <li>S. BILIRUBIN (INDIRECT)</li>
          <li>SGOT/AST</li>
          <li>SGPT/ALT</li>
          <li>ALKALINE PHOSPHATE</li>
          <li>TOTAL PROTEIN</li>
          <li>ALBUMIN</li>
          <li>GLOBULIN</li>
          <li>ALBUMIN/GLOBULIN RATIO</li>
        </ul>
      </div>
      <h6>CGT</h6>
      <h6>S.CALCIUM</h6>
      <h6>RHEUMATOID FACTOR</h6>
      <br>
      <h5>IMMUNOLOGY</h5>
      <div class="p-2">
        <ul>
          <li>VITAMIN D LEVELS</li>
          <li>CA-125</li>
          <li>T3</li>
          <li>T4</li>
          <li>TSH</li>
        </ul>
      </div>
      <h6>CYTOLOGY</h6>
      <div class="p-3">
        <ul>
          <li>PAP SMEAR</li>
        </ul>
      </div>
    </div>
    <div class="col-sm">
      <h5>CLINICAL PATHOLOGY</h5>
      <h6>URINE ROUTINE EXAMINATION</h6>   
      <div class="p-2">
        <ul>
          <li>COLOUR</li>
          <li>URINE REACTION</li>
          <li>APPEARANCE</li>
          <li>SPECIFIC GRAVITY-URINE</li>
          <li>URINE ALBUMIN</li>
          <li>URINE SUGAR</li>
          <li>ACETONE-URINE</li>
          <li>BILE SALT</li>
          <li>BILE PIGMENT</li>
          <li>UROBILINOGEN</li>
        </ul>
      </div>
      <h5>URINE MICROSCOPIC EXAMINATION</h5>
      <div class="p-2">
        <ul>
          <li>MICRO-PUS CELLS</li>
          <li>MICRO-RBC</li>
          <li>MICRO-EPITHELIAL CELLS</li>
          <li>MICRO-NONSQUAMOUS EPITH CELLS</li>
          <li>MICRO-HYALIN CAST</li>
          <li>MICRO-PATHOLOGICAL CAST</li>
          <li>MICRO-CRYSTALS</li>
          <li>MICRO-BACTERIA</li>
          <li>MICRO-MUCUS STRANDS</li>
          <li>MICRO-YEAST</li>
        </ul>
      </div>
      <h6>ECG</h6>
      <br>
      <h6>CHEST XRAY</h6>
      <br>
      <h6>USG ABDOMEN</h6>
      <br>
      <h6>AUDIOGRAM</h6>
      <br>
      <h6>CONSULTATIONS</h6>
      <div class="p-2">
        <ul>
          <li>GENERAL MEDICINE</li>
          <li>GYNEACOLOGY</li>
          <li>DIETARY ADVICE</li>
        </ul>
      </div>
      <h6 class="pt-3">COMPLIMENTARY BREAKFAST</h6>
    </div>
  </div>
</div>

