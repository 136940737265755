<div class="nm-page-header">
  <h2>VACANCIES</h2>
</div>
<div class="text-center">
  <h3>Current Openings</h3>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-6 p-3">
      <div class="p-3 border shadow-lg bg-body rounded">
        <div class="row">
          <div class="col-sm-8">
            <h4>
              <b>{{career[0]['post']}}</b>
              <br />
              <small class="text-muted">{{career[0]['qualification']}}</small>
            </h4>
          </div>
          <div class="col-sm-4">
            <h5>
              Contact No : <br />
              +91 {{career[0]['contact']}}
            </h5>
            <footer>Posted on {{career[0]['date']}}</footer>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 p-3">
      <div class="p-3 border shadow-lg bg-body rounded">
        <div class="row">
          <div class="col-sm-8">
            <h4>
              <b>{{career[1]['post']}}</b>
              <br />
              <small class="text-muted">{{career[1]['qualification']}}</small>
            </h4>
          </div>
          <div class="col-sm-4">
            <h5>
              Contact No : <br />
              +91 {{career[1]['contact']}}
            </h5>
            <footer>Posted on {{career[1]['date']}}</footer>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 p-3">
      <div class="p-3 border shadow-lg bg-body rounded">
        <div class="row">
          <div class="col-sm-8">
            <h4>
              <b>{{career[2]['post']}}</b>
              <br />
              <small class="text-muted">{{career[2]['qualification']}}</small>
            </h4>
          </div>
          <div class="col-sm-4">
            <h5>
              Contact No : <br />
              +91 {{career[2]['contact']}}
            </h5>
            <footer>Posted on {{career[2]['date']}}</footer>
          </div>
        </div>
      </div>
      
    </div> 
    <div class="col-sm-6 p-3">
      <div class="p-3 border shadow-lg bg-body rounded">
        <div class="row">
          <div class="col-sm-8">
            <h4>
              <b>{{career[3]['post']}}</b>
              <br />
              <small class="text-muted">{{career[3]['qualification']}}</small>
            </h4>
          </div>
          <div class="col-sm-4">
            <h5>
              Contact No : <br />
              +91 {{career[3]['contact']}}
            </h5>
            <footer>Posted on {{career[3]['date']}}</footer>
          </div>
        </div>
      </div>
    </div> 
  </div>
</div>
