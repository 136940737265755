<div class="nm-footer pt-5">
  <div class="container">
    <div class="nm-footer__content">
      <div>
        <a><img style="width: 200px" src="assets\img\logo.png" alt="" /></a>
      </div>
      <div class="d-md-flex justify-content-between pt-5">
        <div class="nm-footer__section">
          <p class="mb-2"><a href="#" class="text-white fw-bold">Home</a></p>
          <p class="mb-2">
            <a href="#" class="text-white fw-bold">about us</a>
          </p>
          <p class="mb-2">
            <a href="#" class="text-white fw-bold">services</a>
          </p>
          <p class="mb-2"><a href="#" class="text-white fw-bold">career</a></p>
          <p class="mb-2">
            <a href="#" class="text-white fw-bold">contact us</a>
          </p>
        </div>
        <div class="nm-footer__section">
          <p class="fw-bold">Departments</p>
          <ul class="">
            <li class="nm-footer__secondary my-2"><a href="">cardiology</a></li>
            <li class="nm-footer__secondary my-2">
              <a href="">covid care center</a>
            </li>
            <li class="nm-footer__secondary my-2"><a href="">ENT</a></li>
            <li class="nm-footer__secondary my-2"><a href="">Nephrology</a></li>
            <li class="nm-footer__secondary my-2"><a href="">oncology</a></li>
            <li class="nm-footer__secondary my-2"><a href="">MORE ...</a></li>
          </ul>
        </div>
        <div class="nm-footer__section">
          <p class="fw-bold">Doctors</p>
          <ul class="">
            <li class="nm-footer__secondary my-2">
              <a href="">Dr. Madhu Sreedharan</a>
            </li>
            <li class="nm-footer__secondary my-2">
              <a href=""> Dr. Manju Thampi</a>
            </li>
            <li class="nm-footer__secondary my-2">
              <a href="">Dr. Lekshmi Balakrishnan Unni</a>
            </li>
            <li class="nm-footer__secondary my-2">
              <a href="">Prof.(Dr.) M.K.C. Nair</a>
            </li>
            <li class="nm-footer__secondary my-2">
              <a href="">Dr. Lekshmi S. Nair</a>
            </li>
          </ul>
        </div>

        <div class="nm-footer__section">
          <p class="my-2">
            <span class="nm-footer__secondary me-2">Email Id</span>
            <span class="mx-2" style="text-transform: lowercase;">info@nimsmedicity.org</span>
          </p>
          <p class="my-2 d-flex align-item-start">
            <span class="nm-footer__secondary me-2">Address</span>

            <span class="mx-2"
              >NIMS Medicity , Aralummoodu, <br />
              Neyyathinkkara , Thiruvananthapuram</span
            >
          </p>
          <p class="my-2">
            <span class="nm-footer__secondary me-2">contact</span>
            <span class="mx-2">0471 2222010</span>
          </p>
        </div>
      </div>
    </div>
    <div class="nm-footer__socials pt-2 pb-4">
      <ul class="list-unstyled d-flex w-100 text-end">
        <li>
          <a
            href="https://www.facebook.com/nimsmedicitytrivandrum/"
            class="fa fa-facebook"
          ></a>
        </li>
        <li>
          <a
            href="https://instagram.com/nims_hospital?utm_medium=copy_link"
            class="fa fa-instagram"
          ></a>
        </li>
        <li>
          <a
            href="https://youtube.com/channel/UCUWvJ4LsRyCJGZT1ZU2KaVw"
            class="fa fa-youtube"
          ></a>
        </li>
      </ul>
    </div>
  </div>
  <!-- <div class="nm-footer__copyright text-center py-2">
    copyright @ NIMS hospital 2021
  </div> -->
  <div class="footer-copyright text-center py-3">
    © {{ currentYear }} Copyright:
    <a class="footerlink2" href="https://aptnotions.com/">NIMS MEDICITY</a>.
    Developed and Managed by<a
      class="footerlink2"
      href="https://aptnotions.com/"
    >
      Aptnotions Infotech</a
    >
  </div>
</div>
