<!-- <div *ngFor="let item of departments">
  <div [routerLink]="item.url">{{ item.departmentname }}</div>
</div> -->
<!-- Heading -->
<!-- <div class="col-sm-12 text-center nm-bg-primary mb-3 text-white py-3">
  <h2 class=""><b>OUR DEPARTMENTS</b></h2>
</div> -->
<div class="nm-page-header">
  <h2>OUR DEPARTMENTS</h2>
</div>
<!-- <div class="container my-5">
  <div class="d-flex flex-wrap">
    <div
      class="nm-list-card mb-3"
      [routerLink]="item.url"
      *ngFor="let item of departments"
    >
      <img src="{{ item.departmenticon }}" alt="" />
      <div>
        <h6 class="fw-bolder">{{ item.departmentname }}</h6>
      </div>
    </div> -->
<!-- <app-list-card
      [card.title]="item.departmentname"
      [routerLink]="item.url"
    ></app-list-card> -->
<!-- </div>
</div>  -->

<div class="container">
  <div class="container">
    <div class="row">
      <div class="col-sm-6 pb-3" *ngFor="let item of departments">
        <div class="card">
          <img src="{{ item.departmenticon }}" class="card-img-top" alt="..." />
          <div class="card-body">
            <h3 class="text-center">{{ item.departmentname }}</h3>
            <p class="card-text text-center">
              {{ item.subtext }}
            </p>
            <div class="text-center py-2">
              <button
                type="button"
                class="btn btn-dark center btn"
                routerLink="{{ item.url }}"
              >
                Know More
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <ul class="list-group">
  <li class="list-group-item">Anaesthesiology</li>
  <li class="list-group-item">Ayurveda</li>
  <li class="list-group-item">Bariatric Surgery</li>
  <li class="list-group-item">Cardiology</li>
  <li class="list-group-item">Cardiothoracic Vascular Surgery</li>
  <li class="list-group-item">Chemotherapy Unit</li>
  <li class="list-group-item">CLINICAL PSYCHOLOGY</li>
  <li class="list-group-item">Covid Care Centre</li>
  <li class="list-group-item">Dermatology</li>
  <li class="list-group-item">Developmental Medicine</li>
  <li class="list-group-item">Dietary</li>
  <li class="list-group-item">Emergency Medicine</li>
  <li class="list-group-item">Endocrinology & Diabetology</li>
  <li class="list-group-item">ENT</li>
  <li class="list-group-item">Gastroenterology</li>
  <li class="list-group-item">General Medicine</li>
  <li class="list-group-item">General Surgery</li>
  <li class="list-group-item">Gynaecology</li>
  <li class="list-group-item">Infectious diseases</li>
  <li class="list-group-item">Intensive Care</li>
  <li class="list-group-item">MEDICAL GENETICS</li>
  <li class="list-group-item">Microbiology</li>
  <li class="list-group-item">Naturopathy</li>
  <li class="list-group-item">Neonatology</li>
  <li class="list-group-item">Nephrology</li>
  <li class="list-group-item">Neuro Surgery & Neurology</li>
  <li class="list-group-item">Oncology</li>
  <li class="list-group-item">Ophthalmology</li>
  <li class="list-group-item">Oral and maxillofacial surgery</li>
  <li class="list-group-item">Orthopaedics</li>
  <li class="list-group-item">Paediatric neurology</li>
  <li class="list-group-item">Paediatric surgery</li>
  <li class="list-group-item">Paediatrics</li>
  <li class="list-group-item">Pathology</li>
  <li class="list-group-item">Physiatry & Physiotherapy</li>
  <li class="list-group-item">Plastic Surgery</li>
  <li class="list-group-item">Prosthodontics</li>
  <li class="list-group-item">Psychiatry</li>
  <li class="list-group-item">Pulmonology</li>
  <li class="list-group-item">Radiology</li>
  <li class="list-group-item">Renal Transplantation Unit</li>
  <li class="list-group-item">Rheumatology</li>
  <li class="list-group-item">Urology</li>
</ul> -->
