<div class="nm-spectrum">
  <div class="nm-spectrum__header">
    <header>SPECTRUM</header>
  </div>

  <div class="nm-spectrum__content">
    <div>
      <img
        src="assets/img/spectrum/about_image_1.jpg"
        alt=""
        class="image-one"
      />
      <div class="text-center">
        <p class="fs-4 nm-primary mb-2">
          <strong
            >Comprehensive Rehabilitation Services for the differently-abled
            children</strong
          >
        </p>
      </div>
      <div class="m-2 p-4">
        <p class="mb-3">
          NIMS Medicity, Aralumoodu, Neyyattinkara, Thiruvananthapuram, Kerala,
          India, popularly known as NIMS Medicity, is a 350 bedded Super
          speciality Hospital, which is one of the largest health care centres
          in South Kerala and South Tamilnadu. Its 40 departments include 20
          specialities and 20 Super Specialties. NIMS Medicity offers free heart
          surgery project, free dialysis project, rehabilitation for HIV
          patients, free medical camps etc to the needy people.
        </p>
        <p class="mb-3">
          The Managing Director of NIMS Medicity is also the patron of PADS
          (Parents Association of Disabled Students), which takes care of the
          education and healthcare needs of the mentally challenged students.
          Realizing the fact that rehabilitation and lifestyle training is the
          most important part of the care to be given to the mentally challenges
          students, we initiated free outpatient service for the inmates of
          PADS.
        </p>
        <p class="mb-3">
          NIMS Medicity has established its own audiology and speech
          rehabilitation centre named as “NIMS-Anne Sullivan Centre for
          specially-abled children” at Neyyattinkara. NIMS also established
          Kerala’s first speech and audiology rehabilitation centre “without a
          bill counter” at Centre for Rehabilitation of the differently-abled
          (CAD), LMS Polio Home, Thiruvananthapuram which has more than 500
          inmates.
        </p>
        <p class="mb-3">
          Besides, NIMS is also providing free rehabilitation services to the
          needy students of “SAKSHAMA, Nemom, Trivandrum, KARUNYA MEDICAL
          MISSION, Neyyattinkara etc. we have also signed MOU with
          rehabilitation centres such as Gerdi Gutperle Agasthiyar Muni Child
          CARE centre, Vellamadam, K.K. District, Shanthi Nilayam, Paruthivila,
          K.K. District, Jyothi Nilayam, Palapallam, Karungal, K.K. District.
        </p>
        <p class="mb-3">
          It is estimated that India has around 80 million people with
          disability, which may be age-related, accident-related or due to some
          medical conditions. According to the World Bank, one in every 12
          households in India has a person living with a disability.
        </p>
        <p class="mb-3">
          It was our Endeavour to transform NIMS Medicity into a disabled
          friendly zone offering unparalleled facilities with the objective of
          also caring for our special brothers and sisters, which would be the
          first hospital of its kind in Kerala. Now we are all set to be
          declared as “Disabled Friendly Hospital”, which enables people with
          disabilities to freely and safely move and access facilities within
          the hospital, which provides an environment of independence to
          individuals with a disability, which is their right granted under
          Persons with disability act, 2016. We realize the importance to have a
          barrier-free hospital environment so that people with disabilities can
          carry out their basic activities independently.
        </p>
        <p class="mb-3">
          Realizing the fact that rehabilitating the suffering ones to help them
          restore the health or normal life through training and therapy is the
          most prime human service to the society, we aim at providing the
          following comprehensive rehabilitation services for the
          differently-abled persons, all in one campus.
        </p>
      </div>
    </div>
    <div>
      <img
        src="assets/img/spectrum/about_image_1.jpg"
        alt=""
        class="image-two"
      />
    </div>
  </div>
  <div class="nm-bg-primary border-bottom">
    <div class="container">
      <h3 class="text-center fw-bolder py-3 text-white">KEY SERVICES</h3>
      <div class="d-lg-flex py-3">
        <div class="border rounded bg-dark p-3 m-2 text-white">
          <p class="fw-bold mb-2 pb-1 border-bottom">
            Clinical Child Development Division
          </p>
          <p>
            Neuro-developmental follow-up and early intervention for LBW babies
          </p>
        </div>

        <div class="border rounded bg-dark p-3 m-2 text-white">
          <p class="fw-bold mb-2 pb-1 border-bottom">
            Clinical Child Development Division
          </p>
          <p>Assessment and early intervention for children 0 – 5 years with</p>
        </div>
        <div class="border rounded bg-dark p-3 m-2 text-white">
          <p class="fw-bold mb-2 pb-1 border-bottom">
            Clinical Child Development Division
          </p>
          <p>Assessment and intervention for Preschool children (4 – 6 yrs)</p>
        </div>
        <div class="border rounded bg-dark p-3 m-2 text-white">
          <p class="fw-bold mb-2 pb-1 border-bottom">
            Specialised Therapy Division
          </p>
          <p>Audiology & speech therapy and Paediatric Physiotherapy unit</p>
        </div>
        <div class="border rounded bg-dark p-3 m-2 text-white">
          <p class="fw-bold mb-2 pb-1 border-bottom">
            Specialised Therapy Division
          </p>
          <p>
            Sensory integration unit for Autism, Occupational therapy unit and
            Learning disability Lab
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <h3 class="mb-1 text-center fw-bolder pt-5 mt-3 nm-primary">ACTIVITIES</h3>
    <p class="nm-secondary text-center mb-1">
      <span class="">“The sole meaning of life is to serve humanity.”</span>
      &nbsp;– <b>Leo Tolstoy</b>
    </p>
    <p class="nm-secondary text-center pb-3">
      <span class=""
        >“The purpose of human life is to serve, and to show compassion and the
        will to help others.”
      </span>
      &nbsp;– <b>Albert Schweitzer</b>
    </p>
    <div class="d-lg-flex">
      <img
        src="https://i2.wp.com/www.nimshospital.com/wp-content/uploads/2020/12/IMG-20201130-WA0038.jpg?zoom=1.25&fit=1280%2C853&ssl=1"
        class="nm-spectrum-gallery"
        alt=""
      />
      <img
        src="https://i0.wp.com/www.nimshospital.com/wp-content/uploads/2020/12/IMG-20201130-WA0039.jpg?zoom=1.25&fit=1280%2C720&ssl=1"
        alt=""
        class="nm-spectrum-gallery"
      />
      <img
        src="https://i0.wp.com/www.nimshospital.com/wp-content/uploads/2020/12/IMG-20201130-WA0040.jpg?zoom=1.25&fit=1280%2C853&ssl=1"
        alt=""
        class="nm-spectrum-gallery"
      />
      <img
        src="https://i1.wp.com/www.nimshospital.com/wp-content/uploads/2020/12/IMG-20201130-WA0041.jpg?zoom=1.25&fit=1280%2C853&ssl=1"
        alt=""
        class="nm-spectrum-gallery"
      />
    </div>
    <h3 class="text-center fw-bolder my-4 py-5">SPECIALITIES AVAILABLE</h3>
  </div>
</div>
