<div class="nm-page-header">
  <h2>KIDNEY EXECUTIVE CHECKUP</h2>
</div>

<div class="container">
  <div style="text-align:center">
    <h4>KIDNEY EXECUTIVE HEALTH CHECK UP</h4>
    <h4>Amount:2000/-</h4>
    <h4>57 TESTS & 2 CONSULTATIONS</h4>
  </div>
  <div class="row py-3">
    <div class="col-sm">
      <h5>HAEMATOLOGY</h5>
      
      <h6>COMPLETE HAEMOGRAM</h6>
        <div class="p-3">
          <ul>
            <li>HAEMOGLOBIN</li>
            <li>TOTAL COUNT</li>
          </ul>
        </div>
      <h5>DIFFERENTIAL COUNT</h5>
        <div class="p-3">
          <ul>
            <li>POLYMORPHS</li>
            <li>LYMPHOCYTES</li>
            <li>MONOCYTES</li>
            <li>EOSINOPHILS</li>
            <li>BASOPHILS</li>
            <li>PLATELET COUNT</li>
            <li>RBC</li>
            <li>PCV</li>
            <li>MCV</li>
            <li>MCH</li>
            <li>MCHC</li>
            <li>ESR</li>
            <li>RED CELL DISTRIBUTION</li>
            <li>ABSOLUTE EOSINOPHIL COUNT</li>
            <li>ABSOLUTE LYMPHOCYTE COUNT</li>
            <li>ABSOLUTE NEUTROPHIL COUNT</li>
            <li>MEAN PLATELET VOLUME</li>
          </ul>
        </div>
    </div>
    <div class="col-sm">
      <h5>BIOCHEMISTRY</h5>
      <div class="p-2">
        <ul>
          <li>FASTING BLOOD SUGAR/RANDOM BLOOD SUGAR</li>
          <li>HbA1c</li>
          <li>POST PRANDIAL BLOOD SUGAR</li>
          <li>S.CALCIUM</li>
          <li>S.PHOSPHORUS</li>
          <li>URINE MICRO ALBUMIN</li>
          <li>UACR</li>
        </ul>
      </div>
      <h5>LIPID PROFILE</h5>
      <div class="p-2">
        <ul>
          <li>S.CHOLESTROL</li>
          <li>S.TRIGLYCERIDES</li>
          <li>HDL CHOLESTROL</li>
          <li>LDL CHOLESTROL</li>
          <li>VLDL CHOLESTROL</li>
        </ul>
      </div>
  
      <h5 class="pt-3">ELECTROLYTES</h5>
      <div class="p-2">
        <ul>
          <li>SODIUM</li>
          <li>POTASSIUM</li>
        </ul>
      </div>
      
    </div>
    
    <div class="col-sm">
      <h5>CLINICAL PATHOLOGY</h5>
      <h6>URINE ROUTINE EXAMINATION</h6>
      <div class="p-2">
        <ul>
          <li>COLOUR</li>
          <li>URINE REACTION</li>
          <li>APPEARANCE</li>
          <li>SPECIFIC GRAVITY-URINE</li>
          <li>URINE ALBUMIN</li>
          <li>URINE SUGAR</li>
          <li>ACETONE-URINE</li>
          <li>BILE SALT</li>
          <li>BILE PIGMENT</li>
          <li>UROBILINOGEN</li>
        </ul>
      </div>
      <h5 class="pt-3">RENAL PROFILE</h5>
      <div class="p-2">
        <ul>
          <li>BLOOD UREA NITROGEN</li>
          <li>S.CREATINE</li>
          <li>URIC ACID</li>
        </ul>
      </div>
    </div>
    <div class="col-sm">
      <h5>URINE MICROSCOPIC EXAMINATION</h5>
      <div class="p-2">
        <ul>
          <li>MICRO-PUS CELLS</li>
          <li>MICRO-RBC</li>
          <li>MICRO-EPITHELIAL CELLS</li>
          <li>MICRO-NONSQUAMOUS EPITH CELLS</li>
          <li>MICRO-HYALIN CAST</li>
          <li>MICRO-PATHOLOGICAL CAST</li>
          <li>MICRO-CRYSTALS</li>
          <li>MICRO-BACTERIA</li>
          <li>MICRO-MUCUS STRANDS</li>
          <li>MICRO-YEAST</li>
        </ul>
      </div>
      <h5>USG ABDOMEN AND PELVIS</h5>
      <br>
      <h5>CONSULTATIONS</h5>
        <div class="p-2">
          <ul>
            <li>NEPHROLOGY</li>
            <li>DIETARY ADVICE</li>
          </ul>
        </div>
        
      <h5 class="pt-3">COMPLIMENTARY BREAKFAST </h5>
    </div>
  </div>
</div>
