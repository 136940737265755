import { Component, OnInit } from '@angular/core';
import { DoctorsListService } from 'src/app/_services/doctors-list.service';


@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent implements OnInit {
  image: any = [];

  constructor(private ImageService: DoctorsListService) {}

  ngOnInit() {
    this.getimageList();
  }

  getimageList() {
    this.ImageService.getimageList().subscribe((data) => {
      this.image = data.data;
      console.log(this.image);
    });
  }
}
