import { Component, OnInit } from '@angular/core';
import { DoctorsListService } from 'src/app/_services/doctors-list.service';


@Component({
  selector: 'app-recent-activites',
  templateUrl: './recent-activites.component.html',
  styleUrls: ['./recent-activites.component.scss']
})
export class RecentActivitesComponent implements OnInit {

  
    /*  newsevent: any = []; */
     recentnews: any =[];
     constructor(private Recentnewslist: DoctorsListService) {}
   
       ngOnInit() 
       {
         //this.getnewseventList();
         this.getRecentNews();
         
       }
   
    /*  getnewseventList() 
       {
         this.Newseventlist.getnewseventList().subscribe((data) => {
           this.newsevent = data.data;
           console.log(this.newsevent);
         });
       
       } */
   
       
       getRecentNews() 
     {
       this.Recentnewslist.getRecentNews().subscribe((data) => {
         this.recentnews= data.data;
         console.log(this.recentnews);
       });
     }
   }