<!-- Heading -->
<!-- <div class="text-center nm-bg-primary mb-3 text-white py-3">
  <h2 class=""><b>NIMS Group</b></h2>
</div> -->
<div class="nm-page-header">
  <h2>NIMS Group</h2>
</div>

<div class="container my-5">
  <div class="row">
    <app-list-card
      *ngFor="let item of trusts"
      [card]="item"
      class="col-md-4 col-sm-6"
    ></app-list-card>
  </div>
</div>
