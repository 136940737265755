import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-diabetic-checkup',
  templateUrl: './diabetic-checkup.component.html',
  styleUrls: ['./diabetic-checkup.component.scss']
})
export class DiabeticCheckupComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
