<div class="nm-page-header">
  <h2>BASIC EXECUTIVE CHECKUP</h2>
</div>
<div class="container">
  <div style="text-align:center">
    <h4>BASIC EXECUTIVE CHECK UP</h4>
    <h4>Amount:1250/-</h4>
    <h4>53 TESTS & 1 CONSULTATION</h4>
  </div>
 <br>
 <br>
  <div class="row py-3">
    <div class="col-sm">
      <h5>HAEMATOLOGY</h5>
      <h6>BLOOD GROUP & RH FACTOR</h6>
      <br>
      <h6>COMPLETE HAEMOGRAM</h6>
        <div class="p-3">
          <ul>
            <li>HAEMOGLOBIN</li>
            <li>TOTAL COUNT</li>
          </ul>
        </div>
    
    
      <h5>DIFFERENTIAL COUNT</h5>
        <div class="p-3">
          <ul>
            <li>POLYMORPHS</li>
            <li>LYMPHOCYTES</li>
            <li>MONOCYTES</li>
            <li>EOSINOPHILS</li>
            <li>BASOPHILS</li>
            <li>PLATELET COUNT</li>
            <li>RBC</li>
            <li>PCV</li>
            <li>MCV</li>
            <li>MCH</li>
            <li>MCHC</li>
            <li>ESR</li>
            <li>RED CELL DISTRIBUTION</li>
            <li>ABSOLUTE EOSINOPHIL COUNT</li>
            <li>ABSOLUTE LYMPHOCYTE COUNT</li>
            <li>ABSOLUTE NEUTROPHIL COUNT</li>
            <li>MEAN PLATELET VOLUME</li>
          </ul>
        </div>
    </div>
    <div class="col-sm">
      <h5>BIOCHEMISTRY</h5>
      <div class="p-2">
        <ul>
          <li>FASTING BLOOD SUGAR/RANDOM BLOOD SUGAR</li>
          <li>HbA1c</li>
          <li>TOTAL CHOLESTEROL</li>
          <li>SGOT</li>
          <li>SGPT</li>
          <li>BLOOD UREA NITROGEN</li>
          <li>S.CREATINE</li>
          <li>URIC ACID</li>
          <li>S.SODIUM</li>
          <li>S.POTASSIUM</li>
        </ul>
      </div>
      <h5>IMMUNOLOGY</h5>
      <div class="p-2">
        <ul>
          <li>TSH</li>
        </ul>
      </div>
    </div>
  
    <div class="col-sm">
      <h5>CLINICAL PATHOLOGY</h5>
      <h6>URINE ROUTINE EXAMINATION</h6>
      <div class="p-2">
        <ul>
          <li>COLOUR</li>
          <li>URINE REACTION</li>
          <li>APPEARANCE</li>
          <li>SPECIFIC GRAVITY-URINE</li>
          <li>URINE ALBUMIN</li>
          <li>URINE SUGAR</li>
          <li>ACETONE-URINE</li>
          <li>BILE SALT</li>
          <li>BILE PIGMENT</li>
          <li>UROBILINOGEN</li>
        </ul>
      </div>
    </div>
    <div class="col-sm">
      <h5>URINE MICROSCOPIC EXAMINATION</h5>
      <div class="p-2">
        <ul>
          <li>MICRO-PUS CELLS</li>
          <li>MICRO-RBC</li>
          <li>MICRO-EPITHELIAL CELLS</li>
          <li>MICRO-NONSQUAMOUS EPITH CELLS</li>
          <li>MICRO-HYALIN CAST</li>
          <li>MICRO-PATHOLOGICAL CAST</li>
          <li>MICRO-CRYSTALS</li>
          <li>MICRO-BACTERIA</li>
          <li>MICRO-MUCUS STRANDS</li>
          <li>MICRO-YEAST</li>
        </ul>
      </div>
      
    
      <h6>ECG</h6>
      <br>
      <h6>CHEST X-RAY</h6>
      <h5>CONSULTATIONS</h5>
      <div class="p-2">
        <ul>
          <li>GENERAL MEDICINE</li>
        
        </ul>
      </div>
      
      <h5>COMPLIMENTERY BREAKFAST</h5>
     </div>
    
    
     
      
  
  </div>
</div>
