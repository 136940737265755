<!-- Heading -->
<!-- <div class="text-center nm-bg-primary text-white py-3">
  <h2 class=""><b>Management</b></h2>
</div> -->
<div class="nm-page-header">
  <h2>Management</h2>
</div>
<div class="p-5" style="background-color: #fbf8f8">
  <div class="row">
    <div class="col-lg mt-3">
      <div class="row">
        <div class="col-sm">
          <img
            src="assets/img/management/Dr-A-P_Majeedkhan.jpg"
            class="img-fluid nm-management__image"
            alt="Responsive image"
          />
        </div>
        <div class="col-sm">
          <div class="">
            <h4 class="nm-primary fw-bolder">Dr. A. P. Majeed Khan</h4>
            <h5 class="nm-position">Chairman</h5>
            <p class="nm-secondary fw-bolder">Noorul Islam Educational Trust</p>
          </div>
          <div class="nm-management__socials">
            <a href="#" class="fa fa-phone fa-flip-horizontal"></a>
            <a href="#" class="fa fa-envelope"></a>
            <a href="#" class="fa fa-globe"></a>
            <a href="#" class="fa fa-facebook"></a>
            <a a href="#" class="fa fa-twitter"></a>
          </div>
        </div>
      </div>
      <div class="p-2 nm-management__about border-rounded mt-4">
        <div>
          <p>
            Noorul Islam is successfully completing over 65 years now with the
            support and unending dedication of the staffs, students and the
            supportive society. It was a great dream of mine to bring education
            and healthcare for my neighborhood. In my childhood, we had to
            travel far distance for getting proper health treatment or for the
            higher education. My intention of bringing up NI Institute was
            arisen from this pain and sufferings my fellow beings had to endure
            with. In those days Super specialty hospital treatments were only
            meant for the financially wealthy people and from city area.
            Patients from rural area had to travel distances for getting proper
            medical treatment and by the time they reach hospital, patients may
            found already deceased.
          </p>
          <p>
            Serving God is through serving Humanity. I believe in this concept.
            Thus I made a solid decision of bringing to my people the primary
            requirements of Health and Education. NIMS provides quality
            treatment at the affordable rate. This will be done irrespective of
            Cast, Creed, Colour, Credulity, Origin, Sex, Language, Religion,
            Boundary and Class to develop-Health, Peace, Service, Culture and
            Cooperation.
          </p>
        </div>
      </div>
    </div>
    <div class="col-lg mt-3">
      <div class="row">
        <div class="col-sm">
          <img
            src="assets/img/management/faizal khan.jpg"
            class="img-fluid nm-management__image"
            alt="Responsive image"
          />
        </div>
        <div class="col-sm">
          <div class="">
            <h4 class="nm-primary fw-bolder">M.S. Faizal Khan</h4>
            <h5 class="nm-position">Managing Director</h5>
            <p class="nm-secondary fw-bolder">NIMS Medicity</p>
            <!-- <h5 class="chairmandesignation">President of India’s Nominee</h5>
              <p>“Member to the First Court” of Maulana Azad Urdu University, Hyderabad.</p> -->
            <div class="nm-management__socials">
              <a
                href="tel:+91-9447123944"
                class="fa fa-phone fa-flip-horizontal"
              ></a>
              <a href="mailto:md@nimsmedicity.org" class="fa fa-envelope"></a>
              <a href="https://www.faizalkhan.com" class="fa fa-globe"></a>
              <a
                href="https://www.facebook.com/faizal.khan.503092"
                class="fa fa-facebook"
              ></a>
              <a
                a
                href="https://twitter.com/msfaizalkhan2"
                class="fa fa-twitter"
              ></a>
            </div>
          </div>
        </div>
      </div>
      <div class="p-2 nm-management__about border-rounded mt-4">
        <div>
          <p>
            The world will be a better place if we give the deserved importance
            to social commitment. I feel it is each citizens responsibility to
            carry out his duty towards the society. We NIMS Medicity following
            the legacy of Noorul Islam University are committed to the society
            at large. Patient care is our choice of activity. In this era of
            materialism and commercialism we are seeking greener pastors of
            commitment towards society and humanity at large. Our facilities are
            equipped with most modern machines and technologies and our primary
            aim is to provide world class health care at an affordable cost even
            to the poor and needy.
          </p>
          <p>
            The life expectancy of an average Indian, at the time of our
            independence, was 35-36 years. Now it has increased manifold. But
            the question before us is that, whether the quality of life has been
            increased with the increase of life expectancy? Life style diseases
            like diabetics, cardiology, Nephrology problems, blood pressure has
            become an epidemic even among youngsters. Then onwards their lives
            are under the shadow of these diseases. Thus making the statement
            Some people die at the age of 25 and wait to be buried at 65 true.
            Quality of life comes from healthy living, good environment and
            clear awareness of health. Keeping this scary scenario in mind, we
            conduct various community programs like free medical camps and
            awareness seminars. On an average 14 camps are conducted every
            month. Seminars regarding various diseases, its preventions and its
            management are being conducted in various rural areas.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
