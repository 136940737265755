import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-liver-executive-checkup',
  templateUrl: './liver-executive-checkup.component.html',
  styleUrls: ['./liver-executive-checkup.component.scss']
})
export class LiverExecutiveCheckupComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
