<app-hero-section></app-hero-section>

<!-- <div
  class="modal"
  tabindex="-1"
  role="dialog"
  [ngStyle]="{ display: displayStyle }"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <img
          src="assets/img/home/free heart campaign.jpeg"
          class="img-fluid"
          alt="Responsive image"
        />
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="closePopup()">
          Close
        </button>
      </div>
    </div>
  </div>
</div> -->
<app-our-hospital></app-our-hospital>
<app-our-speciality></app-our-speciality>
<app-our-team></app-our-team>
<app-recent-activites></app-recent-activites>
<app-our-testimonials></app-our-testimonials>
<app-home-departments></app-home-departments>
