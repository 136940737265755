<!-- Heading -->
<!-- <div class="col-sm-12 text-center nm-bg-primary mb-3 text-white py-3">
  <h2 class="doctor"><b>CAMP</b></h2>
</div> -->
<!-- Heading -->
<div class="nm-page-header">
  <h2>CAMP</h2>
</div>

<!-- content  -->
<div class="container">
  <div class="">
    <div class="container" *ngFor="let item of camp">
      <div>
        <h4
          class="text-capitalize fw-bold fs-3 nm-primary"
          style="text-align: center"
        >
          <b>{{ item.heading }}</b>
        </h4>
      </div>
      <div class="row">
        <div class="col-sm-4" *ngFor="let val of item.carddetails">
          <div class="card cd">
            <img
              class="card-img-top cdimptop"
              src="{{ val.cardImage }}"
              alt="Card image cap"
            />
            <p class="newstime bg-success">
              <i class="fa fa-calendar" aria-hidden="true"></i>
              {{ val.date }} &nbsp;&nbsp;&nbsp;| &nbsp;&nbsp;&nbsp;<i
                class="fa fa-map-marker"
                aria-hidden="true"
              ></i>
              {{ val.location }}
            </p>
            <div class="card-body cdbody">
              <h5 class="card-title cdtitle">{{ val.header }}</h5>
              <p class="card-text cdtext">{{ val.discription }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
