<div class="nm-page-header">
  <h2>NIMS-DEPARTMENT OF REHABILITATION</h2>
</div>
<div class="container">
  <p class="py-2">
    NIMS-Department of Rehabilitation, NIMS hospital Established by Sri.Faizal
    Khan ,Managing Director,NIMS-hospital on 2nd April 2019 at Neyyatinkara,
    Trivandrum under the unit of N.I Educational Trust (Regd.) Dedicated for the
    Welfare of Differently abled children . Sri.Faizal Khan, managing Director
    is the Source of Inspiration behind the establishment of NIMS-Department of
    Rehabilitation which renders rehabilitative services to the person with
    disabilities through a holistic approach of , Medical Care, , Child Guidance
    and Counseling, Theraputical & Rehab. Centre for CWSN, Special Education and
    family counseling . It is a non profit organization strives to access the
    capabilities of individuals with special needs and their families
  </p>
  <h4>VISION</h4>
  <p class="py-2">Give them Opportunities, Make them Independent.</p>
  <h4>MISSION</h4>
  <p class="py-2">
    To make them Independent and Responsible Citizen of the Country by giving
    Equal Opportunities, Full participation and Protection of Rights, through
    Special Education, and Therapeutic Services according to their Abilities and
    Interest.
  </p>
  <h4>GOAL</h4>
  <p class="py-2">
    The goal of each of the programs is to help the person with special needs
    learn to the best of his/her abilities, building on strengths and working on
    the difficulties. The focus of the teaching is multi dimensional and
    encompasses all important skill areas like communication, play and leisure,
    self-care routines ,personal ,social &cognitive development amongst others.
  </p>
  <h4>OUR PROGRAM</h4>
  <p class="py-2">
    The rehabilitation Program at NIMS Hospital follows best practices and is
    one of the most comprehensive, high volume programs for children with
    special needs and their families .NIMS-Department of rehabilitation is the
    leading in this region for providing comprehensive services and training
    program in the field of disability. This program provides coordinated,
    multidisciplinary care focused on early identification and theraputical
    treatment
  </p>
  <div>
    <div class="row">
      <div class="col-sm">
        <h4>DIAGNOSTIC EVALUATION:</h4>
        <p class="py-2">
          The multidisciplinary rehabilitation team at NIMS Hospital consists of
          expert clinicians trained in using standard evaluation tools:
        </p>
        <div class="p-3">
          <ul>
            <li>Developmental Pediatrician</li>
            <li>Orthopaedician</li>
            <li>N.T specialists</li>
            <li>Psychologists</li>
            <li>Psychiatrist</li>
            <li>Physiatrist</li>
            <li>Audiologist</li>
            <li>Physiotherapist</li>
            <li>Speech-language pathologist</li>
            <li>Occupational therapist</li>
            <li>behavioral rehabilitation professional</li>
            <li>Pediatric nurse practitioners</li>
          </ul>
        </div>
        <p class="py-2">
          Evaluations may include neurodevelopmental, psychology,
          speech-language,physiotherapy and occupational therapy and academic
          skill assessments.
        </p>
      </div>
      <div class="col-sm">
        <h4>TREATMENT APPROACH</h4>
        <p class="py-2">
          Once a child is diagnosed, an individualized treatment plan is
          developed that may include:
        </p>
        <div class="p-3">
          <ul>
            <li>Referrals provided for</li>
            <li>Psychological Testing</li>
            <li>Guidance and Counseling</li>
            <li>Applied Behavioural Analysis(ABA)</li>
            <li>Early Intervention</li>
            <li>Sensory Integration</li>
            <li>Picture exchange communication system for autism</li>
            <li>Mirror therapy</li>
            <li>Functional mobility</li>
            <li>Special education</li>
            <li>Self care</li>
            <li>Academic and psychologic counselling</li>
            <li>Recreational therapy</li>
            <li>Training and counselling to the family of special child</li>
          </ul>
        </div>
        <p class="py-2">Inclusive co-curriculur activities</p>
        <div class="p-3">
          <ul>
            <li>Special training for children with:</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="py-3">
    <div class="row">
      <div class="col-sm">
        <p>1.delayed development</p>
        <p>2.attention deficit hyperactive disorder</p>
        <p>3.down syndrome</p>
        <p>4.cerebral palsy</p>
      </div>
      <div class="col-sm">
        <p>5.speech and language difficulty</p>
        <p>6.sensory impairment</p>
        <p>7.learning diffilulties</p>
        <p>8.learning disabilities</p>
        <p>9.autism spectrum disorder</p>
      </div>
    </div>
  </div>
  <h4>SPECIAL EDUCATION</h4>
  <p class="py-2">
    Special education helps children who may be having difficulty due to a
    learning disability, speech delay, problem with attention, or some other
    disability to become successful in school.
  </p>
  <p class="py-2">
    All the Educational Programs start with an initial assessment of the child.
    Based on the child’s strengths, behaviors, learning patterns, and the
    teaching and learning methods best suited to him/her, an Individualized
    Educational Plan is made keeping in mind the child’s current needs, as well
    as the parents’ immediate concerns.
  </p>
  <h4>ASSESSMENT</h4>
  <p class="py-2">
    Assessment is the first step to identifying what services would help the
    individual seeking our services best .At NIMS-Department of Rehabilitation,
    we conduct different types of assessments based on the requirment of the
    children with special needs .We provide screening and Diagnostic Assessments
    on Functional Skills , motor skills, communication and audiology and
    psychological area .Following the assessment, parents are provided with
    feedback about the process used to reach the diagnostic decision and
    information on children with special needsand services necessary to start
    intervention.
  </p>
  <h4>OCCUPATIONAL & SENSORY INTERVENTIONS</h4>
  <p class="py-2">
    Many children with special needs have difficulty processing and integrating
    everyday sensory information such as sounds, sights, smells etc. i.e., they
    have different ways of ‘sensing’ their world. Some may not like being
    touched gently but prefer a firm hold. Others may have difficulty in
    brushing their teeth or having a haircut. Some will have extreme likes and
    dislikes in their diet. Many will have difficulty in tolerating some
    everyday sounds. These are just a few examples.
  </p>
  <p class="py-2">
    These ‘differences’ in sensing the world often have a profound effect on the
    person’s life.
  </p>
  <p class="py-2">
    In addition a lot of people with special needs may have difficulties in
    motor skills and coordination, which also may come in the way of learning.
    We provide appropriate strategies to deal with these sensory and motor
    difficulties can go a long way in improving learning and general functioning
    in the lives of individuals with special needs. The sessions comprise a
    combination of activities to help in integrating the senses, developing
    motor coordination.
  </p>
  <h4>HANDWRITING PROGRAMME</h4>
  <p class="py-2">
    The premise for the handwriting programme is combining sensory activities
    with direct instructions to help the student learn to write.Groups of 3-4
    children along with their parents participate in activities related to
    developing prewriting & writing skills under the guidance of a special
    educator and an occupational therapist.
  </p>
  <h4>FAMILY COUNSELING</h4>
  <p class="py-2">
    Family counseling, especially after receiving a diagnosis, can offer parents
    options of how best to support their child. It also improves parent’s
    abilities to make positive, long-term decisions for their child by providing
    factual information. Counseling also provided to family members to deal with
    issues related to the pressures and stress of parenting a child with special
    needs.
  </p>
  <h4>Contact details:</h4>
  <p class="py-2">
    NIMS-DEPARTMENT OF REHABILITATION NIMS hospital, <br />
    NIMS medicity, Aralumoodu<br />
    Neyyattinkara Thiruvananthapuram.<br />
    Pin :695123 <br />
    Telephone:0471–2222115,2223542<br />
    Email:spledutrainer@nimsmedicity.org, info@nimsmedicity.org <br />
    Website:www.nimshospital.com <br />
    Mobile:8248841513,9745586411 <br />
    Watsapp :9442065146
  </p>

  <p></p>
</div>
