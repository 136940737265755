import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-hospital',
  templateUrl: './our-hospital.component.html',
  styleUrls: ['./our-hospital.component.scss']
})
export class OurHospitalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
