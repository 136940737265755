<!-- Heading -->
<!-- <div class="col-sm-12 text-center nm-bg-primary mb-3 text-white py-3">
  <h2 class="doctor">
    <b>{{ wholedata.heading }}</b>
  </h2>
</div> -->
<div class="nm-page-header">
  <h2>{{ wholedata.heading }}</h2>
</div>

<!-- content  -->
<div class="container p-3">
  <div class="row">
    <div class="col-sm-3 text-center">
      <img
        src="assets/img/testimonials/1.jpg"
        class="rounded-circle p-3"
        alt="Cinque Terre"
        width="250"
        height="250"
      />
      <div>
        <b>{{ wholedata.heading }}</b>
      </div>
    </div>
    <div class="col-sm-9">
      <h4>{{ testimonialsubcategory.start1 }}</h4>
      <h5>{{ testimonialsubcategory.start2 }}</h5>
      <div class="p-1">
        <p>{{ testimonialsubcategory.para1 }}</p>
        <p>{{ testimonialsubcategory.para2 }}</p>
        <p>{{ testimonialsubcategory.para3 }}</p>
        <p>{{ testimonialsubcategory.para4 }}</p>
        <p>{{ testimonialsubcategory.para5 }}</p>
      </div>
      <div class="p-3">
        <p>{{ testimonialsubcategory.ending }}</p>
        <p>{{ testimonialsubcategory.ending1 }}</p>
        <p>{{ testimonialsubcategory.ending2 }}</p>
        <p>{{ testimonialsubcategory.ending3 }}</p>
        <p>{{ testimonialsubcategory.ending4 }}</p>
        <p>{{ testimonialsubcategory.ending5 }}</p>
        <p>{{ testimonialsubcategory.ending6 }}</p>
      </div>
    </div>
  </div>
</div>
