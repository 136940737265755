import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AppComponent } from './app.component';
import { HeaderComponent } from './_feature-components/header/header.component';
import { FooterComponent } from './_feature-components/footer/footer.component';
import { HomeComponent } from './_pages/home/home.component';
import { OurTeamComponent } from './_feature-components/our-team/our-team.component';
import { DoctorsDetailComponent } from './components/doctors-detail/doctors-detail.component';
import { TestimonialCardComponent } from './components/testimonial-card/testimonial-card.component';
import { OurTestimonialsComponent } from './_feature-components/our-testimonials/our-testimonials.component';
import { OurHospitalComponent } from './_feature-components/our-hospital/our-hospital.component';
import { OurSpecialityComponent } from './_feature-components/our-speciality/our-speciality.component';
import { HomeDepartmentsComponent } from './_feature-components/home-departments/home-departments.component';
import { TabDetailsComponent } from './components/tab-details/tab-details.component';
import { ListCardComponent } from './components/list-card/list-card.component';
import { RecentNewsComponent } from './components/recent-news/recent-news.component';
import { HeroSectionComponent } from './_feature-components/hero-section/hero-section.component';
import { ServiceCardComponent } from './components/service-card/service-card.component';
import { DoctorsComponent } from './_pages/doctors/doctors.component';
import { DoctorsSubcategoryComponent } from './_pages/doctors-subcategory/doctors-subcategory.component';
import { ServicespageComponent } from './_pages/servicespage/servicespage.component';
import { CoursesComponent } from './_pages/courses/courses.component';
import { CoursesSubcategoryComponent } from './_pages/courses-subcategory/courses-subcategory.component';
import { NewsEventsComponent } from './_pages/news-events/news-events.component';
import { AmbulanceServicesComponent } from './_pages/ambulance-services/ambulance-services.component';
import { VideoGalleryComponent } from './_pages/video-gallery/video-gallery.component';
import { TestimonialsComponent } from './_pages/testimonials/testimonials.component';
import { DepartmentDocdetailComponent } from './_feature-components/department-docdetail/department-docdetail.component';
import { AboutdetailpageComponent } from './_feature-components/aboutdetailpage/aboutdetailpage.component';
import { ManagementComponent } from './_feature-components/management/management.component';
import { NiTrustComponent } from './_feature-components/ni-trust/ni-trust.component';
import { AboutusComponent } from './_pages/aboutus/aboutus.component';
import { CampComponent } from './_pages/camp/camp.component';
import { MedicaldictionaryComponent } from './_pages/medicaldictionary/medicaldictionary.component';
import { BlogComponent } from './_pages/blog/blog.component';
import { BlogSubcategoryComponent } from './_pages/blog-subcategory/blog-subcategory.component';
import { OurDepartmentMainComponent } from './_pages/departments/our-department-main/our-department-main.component';
import { OurDepartmentsSubComponent } from './_pages/departments/our-departments-sub/our-departments-sub.component';
import { DepartmentSubheaderComponent } from './_feature-components/department-subheader/department-subheader.component';
import { NewsEventssubcategoryComponent } from './_pages/news-eventssubcategory/news-eventssubcategory.component';
import { ContactUsComponent } from './_pages/contact-us/contact-us.component';
import { SpectrumComponent } from './_pages/spectrum/spectrum.component';
import { ResearchDevelopmentComponent } from './_pages/research-development/research-development.component';
import { ResearchDevelopmentsubComponent } from './_pages/research-developmentsub/research-developmentsub.component';
import { CorporateInsuranceComponent } from './_pages/corporate-insurance/corporate-insurance.component';
import { DifferentlyAbledComponent } from './_pages/differently-abled/differently-abled.component';
import { RehabilitationComponent } from './_pages/rehabilitation/rehabilitation.component';
import { ExecutiveCheckupComponent } from './_pages/executive-checkup/executive-checkup.component';
import { BasicExecutiveCheckupComponent } from './_pages/basic-executive-checkup/basic-executive-checkup.component';
import { NormalExecutiveCheckupComponent } from './_pages/normal-executive-checkup/normal-executive-checkup.component';
import { KidneyExecutiveCheckupComponent } from './_pages/kidney-executive-checkup/kidney-executive-checkup.component';
import { WellWomenCheckupComponent } from './_pages/well-women-checkup/well-women-checkup.component';
import { CardiacExecutiveCheckupComponent } from './_pages/cardiac-executive-checkup/cardiac-executive-checkup.component';
import { DiabeticCheckupComponent } from './_pages/diabetic-checkup/diabetic-checkup.component';
import { RespiratoryExecutiveCheckupComponent } from './_pages/respiratory-executive-checkup/respiratory-executive-checkup.component';
import { PediatricExecutiveCheckupComponent } from './_pages/pediatric-executive-checkup/pediatric-executive-checkup.component';
import { ComprehensiveBasicExecutiveCheckupComponent  } from './_pages/comprehensive-basic-executive-checkup/comprehensive-basic-executive-checkup.component';
import { ComprehensiveMaleCheckupComponent  } from './_pages/comprehensive-male-checkup/comprehensive-male-checkup.component';
import { ComprehensiveFemaleCheckupComponent  } from './_pages/comprehensive-female-checkup/comprehensive-female-checkup.component';
import { LiverExecutiveCheckupComponent } from './_pages/liver-executive-checkup/liver-executive-checkup.component';
import { PostcovidExecutiveCheckupComponent } from './_pages/postcovid-executive-checkup/postcovid-executive-checkup.component';
import { RecentActivitesComponent } from './_feature-components/recent-activites/recent-activites.component';
import { GalleryComponent } from './_feature-components/gallery/gallery.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    OurTeamComponent,
    DoctorsDetailComponent,
    TestimonialCardComponent,
    OurTestimonialsComponent,
    OurHospitalComponent,
    OurSpecialityComponent,
    TabDetailsComponent,
    HeroSectionComponent,
    RecentNewsComponent,
    ServiceCardComponent,
    HomeDepartmentsComponent,
    DoctorsDetailComponent,
    DoctorsComponent,
    ServicespageComponent,
    CoursesComponent,
    CoursesSubcategoryComponent,
    NewsEventsComponent,
    NewsEventssubcategoryComponent,
    DoctorsSubcategoryComponent,
    DepartmentDocdetailComponent,
    AmbulanceServicesComponent,
    VideoGalleryComponent,
    GalleryComponent,
    TestimonialsComponent,
    AboutdetailpageComponent,
    AboutusComponent,
    ManagementComponent,
    ListCardComponent,
    NiTrustComponent,
    CampComponent,
    MedicaldictionaryComponent,
    BlogComponent,
    BlogSubcategoryComponent,
    OurDepartmentMainComponent,
    OurDepartmentsSubComponent,
    DepartmentSubheaderComponent,
    ContactUsComponent,
    SpectrumComponent,
    ResearchDevelopmentComponent,
    CorporateInsuranceComponent,
    DifferentlyAbledComponent,
    RehabilitationComponent,
    ExecutiveCheckupComponent,
    BasicExecutiveCheckupComponent,
    NormalExecutiveCheckupComponent,
    KidneyExecutiveCheckupComponent,
    WellWomenCheckupComponent,
    CardiacExecutiveCheckupComponent,
    DiabeticCheckupComponent,
    RespiratoryExecutiveCheckupComponent,
    ComprehensiveBasicExecutiveCheckupComponent,
    ComprehensiveMaleCheckupComponent,
    ComprehensiveFemaleCheckupComponent,
    LiverExecutiveCheckupComponent,
    PostcovidExecutiveCheckupComponent,
    PediatricExecutiveCheckupComponent,
    ResearchDevelopmentsubComponent,
    RecentActivitesComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CarouselModule,
    BrowserAnimationsModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
