<div class="nm-page-header">
  <h2>NIMS-EXECUTIVE CHECKUPS</h2>
</div>
<div class="container">
  <p class="pb-2">
    The typical modern executive lives a very stressful life with demanding work
    schedules, Missed meals and late hours. Frequent being of rich food, alcohol
    and smoking add to the stress levels and chronic physical and mental
    fatigue. This check up program is specially designed to detect those
    diseases, which are by-products of the contemporary executive life style.
  </p>
  <p class="pb-2">
    There may be potentially dangerous diseases of the heart, brain, eyes,
    kidney and general metabolism. Early detection and timely intervention will
    save unnecessary hospitalization and medical complications. This program is
    recommended for executive of either sex irrespective of age.
  </p>
  <h5 class="p-2">For more details call at +91 80750 26226</h5>
  <h6 class="py-2"><b>Packages</b></h6>
  <div class="p-3">
    <ul>
      <li>
        <a routerLink="/basic-executive-checkup">BASIC EXECUTIVE CHECK UP</a>
      </li>
      <li>
        <a routerLink="/normal-executive-checkup">NORMAL EXECUTIVE CHECK UP</a>
      </li>
      <li>
        <a routerLink="/kidney-executive-checkup">EXECUTIVE KIDNEY CHECK UP</a>
      </li>
      <li><a routerLink="/cardiac-executive-checkup">CARDIAC EXECUTIVE CHECK UP</a>
      </li>
      <li>
        <a routerLink="/respiratory-executive-checkup">RESPIRATORY EXECUTIVE  CHECK UP</a>
      </li>
      <li>
        <a routerLink="/liver-executive-checkup">LIVER EXECUTIVE CHECK UP</a>
      </li>
      <li>
        <a routerLink="/comprehensive-basic-executive-checkup">COMPREHENSIVE BASIC EXECUTIVE CHECK UP</a>
      </li>
      <li>
        <a routerLink="/comprehensive-male-checkup">COMPREHENSIVE MASTER EXECUTIVE CHECK UP(MALE)</a>
      </li>
      <li>
        <a routerLink="/comprehensive-female-checkup">COMPREHENSIVE MASTER EXECUTIVE CHECK UP(FEMALE)</a>
      </li>
      <li><a routerLink="/diabetic-checkup">DIABETIC CHECK UP PACKAGES</a></li>
      <li><a routerLink="/well-women-checkup">WELL WOMEN CHECK UP PACKAGES</a></li>
      <li><a routerLink="/pediatric-executive-checkup">PEDIATRIC EXECUTIVE CHECK UP PACKAGES</a></li>
      <li><a routerLink="/post-covid-executive-checkup">POST COVID EXECUTIVE CHECK UP</a></li>
    </ul>
  </div>
  
  <h6 class="py-2"><b>Instructions</b></h6>
  <div class="p-3">
    <ol>
      <li>Executive opting for the check up should take prior appointment</li>
      <li>Report at the reception between 8.00 to 8.30 am on empty stomach</li>
      <li>Breakfast will be provided by the hospital</li>
      <li>Concerned check up will take 6-8 hours</li>
      <li>Come on any day except Sunday</li>
      <li>
        Incase you are on medication kindly bring your medicines/previous report
        with you.
      </li>
    </ol>
  </div>
</div>
