<!-- Heading -->
<!-- <div class="col-sm-12 text-center nm-bg-primary mb-3 text-white py-3">
  <h2 class="doctor"><b>OUR DOCTORS</b></h2>
</div> -->
<div class="nm-page-header">
  <h2>OUR DOCTORS</h2>
</div>
<!-- Body -->
<div class="container mt-4 mb-4" *ngFor="let dep of department">
  <div class="row">
    <h3 class="text-center mb-4 mt-4">{{dep.name}}</h3>

    <ng-container *ngFor="let item of doctors">
      <div class="col-sm-3" *ngIf="item.department == dep.name"  >
        <div class="_ngcontent-tfd-c17"  >
          <div class="card text-center mb-2"[routerLink]="item.name">
              <img src="https://nims.prismaticsoft.com{{ item.profileimage }}" alt="" />
              <div class="pb-4 pt-4 custom-height-doc" >
              <h5 class="fw-bolder">{{ item.name }}</h5>
              <small class="nm-primary fw-bold ">{{ item.qualification }}</small>
              <br />
              <small >{{ item.time_description }}</small>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>


