import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DoctorsListService } from 'src/app/_services/doctors-list.service';

@Component({
  selector: 'app-blog-subcategory',
  templateUrl: './blog-subcategory.component.html',
  styleUrls: ['./blog-subcategory.component.scss'],
})
export class BlogSubcategoryComponent implements OnInit {
  blogdata: any = [];
  blogsubcategory: any = [];
  pathFldr: any;

  constructor(
    private blogsubcategoryService: DoctorsListService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getBlogsubcategoryList();
  }

  getBlogsubcategoryList() {
    const pathFldr = this.router.url;
    console.log(pathFldr);
    this.blogsubcategoryService.getblogList().subscribe((data) => {
      this.blogdata = data.data;
      console.log(this.blogdata);
      for (var val of this.blogdata) {
        if (pathFldr == val.url) {
          console.log('checkign');
          this.blogsubcategory = val;
          console.log(this.blogsubcategory);
        }
      }
    });
  }
}
