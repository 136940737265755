import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aboutdetailpage',
  templateUrl: './aboutdetailpage.component.html',
  styleUrls: ['./aboutdetailpage.component.scss'],
})
export class AboutdetailpageComponent implements OnInit {
  constructor() {}
  aboutHospital: any = [];

  ngOnInit() {
    this.aboutHospital = [
      {
        image: 'assets/img/aboutusicon/area.png',
        title: 'Area',
        description: '24 Acres',
        rounded: true,
      },
      {
        image: 'assets/img/aboutusicon/hospital.png',
        title: 'Category',
        description: 'Medical Institutions',
        rounded: true,
      },
      {
        image: 'assets/img/aboutusicon/buildinarea.png',
        title: 'Built In area',
        description: '4 Lakh Square Feet (4,00,000sq. ft)',
        rounded: true,
      },
      {
        image: 'assets/img/aboutusicon/department.png',
        title: 'Departments',
        description: '40',
        rounded: true,
      },
      {
        image: 'assets/img/aboutusicon/clinic.png',
        title: 'Clinics',
        description: '20',
        rounded: true,
      },
      {
        image: 'assets/img/aboutusicon/superspeciality.png',
        title: 'Specialties proposed',
        description: 'All (Super-Specialty Hospital)',
        rounded: true,
      },
    ];
  }
}
