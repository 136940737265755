<div class="nm-page-header">
  <h2>CARDIAC EXECUTIVE CHECK UP</h2>
</div>

<div class="container">
  <div style="text-align:center">
  <h4>CARDIAC EXECUTIVE HEALTH CHECK UP</h4>
  <h4>Amount:3500/-</h4>
  <h4>37 TESTS & 2 CONSULTATIONS</h4>
  </div>
  <br>
  <br>
  <div class="row py-3">
        <div class="col-sm">
          <h5>HAEMATOLOGY</h5>
          <br>
          <h6>BLOOD GROUP & RH FACTOR</h6>
          <br>
          <h6>COMPLETE BLOOD COUNT</h6>
            <div class="p-2">
              <ul>
                <li>HAEMOGLOBIN</li>
                <li>TOTAL COUNT</li>
              </ul>
            </div>
        
        
         
            <div class="p-1">
              <h5>DIFFERENTIAL COUNT</h5>
              <ul>
                <li>POLYMORPHS</li>
                <li>LYMPHOCYTES</li>
                <li>MONOCYTES</li>
                <li>EOSINOPHILS</li>
                <li>BASOPHILS</li>
                <li>PLATELET COUNT</li>
                <li>RBC</li>
                <li>PCV</li>
                <li>MCV</li>
                <li>MCH</li>
                <li>MCHC</li>
                <li>ESR</li>
                <li>RED CELL DISTRIBUTION</li>
                <li>ABSOLUTE EOSINOPHIL COUNT</li>
                <li>ABSOLUTE LYMPHOCYTE COUNT</li>
                <li>ABSOLUTE NEUTROPHIL COUNT</li>
                <li>MEAN PLATELET VOLUME</li>
              </ul>
            </div>
        </div>
        <div class="col-sm">
         <div class="p-2">
          <h5>BIOCHEMISTRY</h5>
            <ul>
              <li>FASTING BLOOD SUGAR/RANDOM BLOOD SUGAR</li>
              <li>HbA1c</li>
            </ul>
          </div> 
          
          <div class="p-2">
            <h5>LIPID PROFILE</h5>
            <ul>
              <li>S.CHOLESTROL</li>
              <li>S.TRYGLYCERIDES</li>
              <li>HDL-CHOLESTROL</li>
              <li>LDL-CHOLESTROL</li>
              <li>VLDL-CHOLESTROL</li>
            </ul>
          </div>
        
          <div class="p-2">
            <h5>RENAL FUNCTION TEST</h5>
            <ul>
              <li>BLOOD UREA NITROGEN</li>
              <li>S.CREATINE</li>
              <li>URIC ACID</li>
            </ul>
          </div>

          <div class="p-2">
            <h5>ELECTROLYTES</h5>
            <ul>
              <li>S.SODIUM</li>
              <li>S.POTASSIUM</li>
            </ul>
          </div>

          <div class="p-2">
            <h5>IMMUNOLOGY</h5>
            <li>TSH</li>
          </div>
        </div>
      
    
        <div class="col-sm">
         
          <h5>ECG</h5>
          <br>
          <h5>ECHO</h5>
          <br>
          <h5>CHEST XRAY</h5>
          <br>
          <h5>TMT (TREADMILL TEST)</h5>
          <br>
          <h5>AUDIOGRAM</h5>
          <br>
          <h5>CONSULTATIONS</h5>
          <div class="p-2">
            <ul>
              <li>CARDIOLOGY</li>
              <li>DIETARY ADVICE</li>
            </ul>
          </div>

      
      <h6 class="pt-3">COMPLIMENTARY BREAKFAST</h6>
    </div>
  </div>

