import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ni-trust',
  templateUrl: './ni-trust.component.html',
  styleUrls: ['./ni-trust.component.scss'],
})
export class NiTrustComponent implements OnInit {
  trusts: any = [];
  constructor() {}

  ngOnInit() {
    this.trusts = [
      {
        image: 'assets/img/nimsgroup/college-of-higher-education.jpg',
        description: ' Noorul Islam College Of Higher Education ',
      },
      {
        image: 'assets/img/nimsgroup/college-of-arts-and-science.jpg',
        description: '  Noorul Islam college Of Arts and Science  ',
      },
      {
        image: 'assets/img/nimsgroup/college-of-dental-science.jpg',
        description: '  Noorul Islam college Of Dental Science  ',
      },
      {
        image: 'assets/img/nimsgroup/ncab.jpg',
        description: '  NCAB  ',
      },
      {
        image: 'assets/img/nimsgroup/civil-service-academy.jpg',
        description: '  N I Civil Service Academy  ',
      },
      {
        image: 'assets/img/nimsgroup/polytechnic-college.jpg',
        description: '  Noorul Islam Polytechnic College  ',
      },
      {
        image: 'assets/img/nimsgroup/college-of-medical-science.jpg',
        description: '  Noorul Islam college Of Medical Science  ',
      },
      {
        image: 'assets/img/nimsgroup/dummy.jpg',
        description: ' N.I Institute of Engineering, Amaravila, Kerala  ',
      },
      {
        image: 'assets/img/nimsgroup/dummy.jpg',
        description: '  N.I Polytechnic College, Thiruvithamcode, Tamil Nadu  ',
      },
      {
        image: 'assets/img/nimsgroup/dummy.jpg',
        description:
          '  N.I College of Engineering, Kumaracoil(NICE), Tamilnadu  ',
      },
      {
        image: 'assets/img/nimsgroup/dummy.jpg',
        description: '  N.I Community College, Kumaracoil, Tamil Nadu  ',
      },
      {
        image: 'assets/img/nimsgroup/dummy.jpg',
        description: '  N.I Arts and Science College, Kumaracoil, Tamil Nadu  ',
      },
      {
        image: 'assets/img/nimsgroup/dummy.jpg',
        description:
          '  N.I College of Aeronautics and Marine Engineering, Tamilnadu  ',
      },
      {
        image: 'assets/img/nimsgroup/dummy.jpg',
        description:
          '  N.I Institute of Medical Science(NIMS) & Research Foundation  ',
      },
      {
        image: 'assets/img/nimsgroup/dummy.jpg',
        description:
          '  N.I College of Dental Science & Super Speciality Dental Hospital  ',
      },
      {
        image: 'assets/img/nimsgroup/dummy.jpg',
        description:
          '  N.I School of Business Management and Computer Application  ',
      },
      {
        image: 'assets/img/nimsgroup/dummy.jpg',
        description:
          '  N.I Centre for Satellite-Space Application and Research, NIUSAT  ',
      },
      {
        image: 'assets/img/nimsgroup/dummy.jpg',
        description: '  N.I Research & Development (Doctoral) Programme  ',
      },
      {
        image: 'assets/img/nimsgroup/dummy.jpg',
        description: '  NIMS Heart Foundation, NIMS MEDICITY  ',
      },
      {
        image: 'assets/img/nimsgroup/dummy.jpg',
        description: '  NIMS Centre for Advanced Biotechnology  ',
      },
      {
        image: 'assets/img/nimsgroup/dummy.jpg',
        description:
          '   N.I Institute of Medical Science(NIMS) College of Nursing   ',
      },
      {
        image: 'assets/img/nimsgroup/dummy.jpg',
        description: '   N.I Civil Service Academy, Kerala   ',
      },
    ];
  }
}
