<!-- Heading -->
<!-- <div class="col-sm-12 text-center nm-bg-primary mb-3 text-white py-3">
  <h2 class="doctor"><b>COURSES</b></h2>
</div> -->
<!-- Heading -->
<div class="nm-page-header">
  <h2>COURSES</h2>
</div>

<!-- Body -->
<div class="container">
  <div class="row rowstyle">
    <div class="col-md-6 colstyle" *ngFor="let item of courses">
      <div class="card cardstyle" [routerLink]="item.url">
        <div class="card-body card-style">
          <h5 class="card-title text-center">
            <b>{{ item.course }}</b>
          </h5>
          <p class="card-text">{{ item.content }}</p>
          <p class="card-text">{{item.content1 }}</p>
          <p class="card-text">{{item.content2 }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
