import { Component, OnInit } from '@angular/core';
import { DoctorsListService } from 'src/app/_services/doctors-list.service';


@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss'],
})
export class TestimonialsComponent implements OnInit {
  testimonial: any = [];
  constructor(private TestmonialService: DoctorsListService) {}

  ngOnInit() {
    this.gettestimonialList();
  }

  gettestimonialList() {
    this.TestmonialService.gettestimonialList().subscribe((data) => {
      this.testimonial = data.data;
      console.log(this.testimonial);
    });
  }

  passid(val) {
    console.log(val);
    this.TestmonialService.setMessage(val);
  }
}
