<!-- Heading -->
<div class="text-center nm-bg-primary mb-3 text-white py-3">
  <h2 class=""><b>Gallery</b></h2>
</div>
<div class="container">
  <div class="row justify-content-around pb-3" *ngFor="let item of image">
    <div class="col-10 p-2">
      <div class="card">
        <div class="row g-0">
          <div class="col-md-6">
            <img
              src="{{ item.image1 }}"
              class="img-fluid rounded-start"
              alt="..."
            />
          </div>
          <div class="col-md-6 text-center m-auto">
            <div class="card-body">
              <h5 class="card-title">
                <b>{{ item.title1 }}</b>
              </h5>
              <p class="card-text">
                {{ item.content1 }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-10 p-2 {{ item.disp }}">
      <div class="card">
        <div class="row g-0">
          <div class="col-md-6 text-center m-auto">
            <div class="card-body">
              <h5 class="card-title">
                <b>{{ item.title2 }}</b>
              </h5>
              <p class="card-text">
                {{ item.content2 }}
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <img
              src="{{ item.image2 }}"
              class="img-fluid rounded-start"
              alt="..."
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
