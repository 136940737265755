<!-- Heading -->
<!-- <div class="col-sm-12 text-center nm-bg-primary mb-3 text-white py-3">
  <h2 class="doctor"><b>TESTIMONIALS</b></h2>
</div> -->
<div class="nm-page-header">
  <h2>TESTIMONIALS</h2>
</div>

<!-- contents -->
<div class="container">
  <div class="row">
    <div class="col-sm-3 p-3" *ngFor="let item of testimonial">
      <div
        id="{{ item.id }}"
        class="card bg-light"
        (click)="passid(item.id)"
        routerLink="{{ item.url }}"
      >
        <div class="card-body">
          <h5 class="card-title">
            <b>{{ item.heading }}</b>
          </h5>
          <p class="card-text">
            {{ item.body }}
          </p>
        </div>
        <button class="button">Read More</button>
      </div>
    </div>
  </div>
</div>
