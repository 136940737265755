<!-- Heading -->
<!-- <div class="text-center nm-bg-primary mb-3 text-white py-3">
  <h2 class=""><b>About Us</b></h2>
</div> -->
<div class="nm-page-header">
  <h2>About Us</h2>
</div>
<div class="container">
  <div class="row my-2">
    <div class="col-md-6 pt-3">
      <div class="nm-about">
        <div class="nm-about__detail">
          <!-- <img
        src="https://i1.wp.com/www.nimshospital.com/wp-content/uploads/2020/11/about-1.jpg?zoom=1.25&fit=660%2C390&ssl=1"
        alt=""
      /> -->
          <div class="nm-about__card">
            <h3 class="nm-about__title py-3">WHO WE ARE</h3>
            <div class="nm-about__description">
              <h5 class="my-3 fw-bold">NOORUL ISLAM EDUCATIONAL TRUST</h5>
              <p>
                Noorul Islam Educational Trust has completed 64 years of service
                in Education, which was established by Dr. A.P. Majeed Khan. The
                rare of credit of establishing the first technical institute
                (Industrial Training Institute) in 1955 in the State of Kerala
                belongs to Noorul Islam Educational Trust. This institution was
                established with the noble aim of uplifting the educationally
                downtrodden rural population in and around the District. The
                Trust owns and administers 17 Educational and other institutions
                which also include Noorul Islam University Accredited with “A”
                grade by NAAC. The Trust provides higher education and training
                in areas such as Engineering and Technology, Management, Applied
                Sciences, Humanities, Arts, Commerce, Social Science, Dental
                Science, Para-Medical Sciences etc.
              </p>
              <p class="my-3">
                The institutions of the Trust have distinguished themselves in
                many ways earning the reputation as well-managed institutions
                imparting excellent technical, science, management and medical
                education and healthcare activities. All these institutions have
                been registering creditable achievements in education and
                training leading to excellence and innovation in concerned
                areas. The infrastructural facilities and academic activities in
                all these institutions have been excellent and have won
                accolades from all the visiting dignitaries,
                University/Government authorities. The highly qualified teaching
                staff of all these institutions impart high quality teaching and
                research for the advancement of knowledge through various
                research programmes and publications.
              </p>
              <h5 class="my-3 fw-bold">
                NOORUL ISLAM INSTITUTE OF MEDICAL SCIENCE AND RESEARCH
                FOUNDATION (NIMS MEDICITY)
              </h5>
              <p class="my-4">
                NIMS Medicity, Aralumoodu, Neyyattinkara, Thiruvananthapuram,
                Kerala, India, popularly known as NIMS Medicity, is a Super
                specialty Hospital, which is one of the largest health care
                centers in South Kerala and South Tamilnadu. Its 40 departments
                include 20 specialties and 20 Super Specialties. The Major
                Departments are Cardiology and Cardiac Surgery, Nephrology,
                Endocrinology, Kidney Transplantation, advanced Gastro
                Interventions, Advanced Orthopaedic procedures and Joint
                replacement, Cardiothoracic, Critical care Unit, Gynecology,
                General Medicine, General Surgery, Infectious Diseases,
                Nephrology, Neonatology, Neurology, Naturopathy, Oral and maxilo
                facial surgery, Pediatrics, Plastic Surgery, Physiatric,
                psychology, Radiology, Urology, Tertiary care trauma care
                center, Round the clock blood bank with component separation, 24
                hours primary angioplasty facility, 24 hours stroke management
                facility, Advanced Neonatology facility etc. The Institution
                have 7 independent ICUs like, Medical ICU, Coronary ICU, Cardic
                Thoracic ICU, General Medicine ICU, Neonatal ICU, Surgical ICU,
                Nephrology ICU Etc. Civil service academy, college of Dental
                Sciences, college of Nursing and paramedical sciences are
                situated at the NIMS Medicity campus. NIMS constantly strives to
                adopt an innovative approach to everchanging advances in the
                field of Medical care. NIMS was the first healthcare institution
                in Kerala to install a fibro scan(liver scan machine), and also
                the 1st leuko reduction blood bank. Asia’s first hybrid green
                energy cardiac catheterization Lab was established in NIMS.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 pt-3">
      <h5 class="fw-bolder text-center">
        INSTITUTIONS OWNED BY NOORUL ISLAM EDUCATIONAL TRUST
      </h5>
      <p class="nm-secondary mb-4 text-center w-75 m-auto">
        The Noorul Islam Educational Trust own and manage the following
        educational institutions:
      </p>
      <ul class="list-unstyled nm-institute-list">
        <li>Noorul Islam Institute of Engineering, Amaravila, Kerala</li>
        <li>
          Noorul Islam Polytechnic College, Thiruvithamcode, Kanyakumari
          District
        </li>
        <li>
          Noorul Islam College of Engineering (NICE), Kumaracoil, Kanyakumari
          District, Tamilnadu.
        </li>
        <li>
          Noorul Islam College of Arts and Science, Kumaracoil, Kanyakumari
          District, Tamilnadu.
        </li>
        <li>
          Noorul Islam Centre for Satellite Technology and Applications ,
          Kanyakumari District, Tamilnadu.
        </li>
        <li>
          Noorul Islam Institute of Medical Science (NIMS) and Research
          Foundation, Neyyattinkara, Kerala
        </li>
        <li>
          Noorul Islam College of Dental Science and Super specialty Dental
          Hospital, Neyyattinkara
        </li>
        <li>
          Noorul Islam School of Business Management and Computer Applications,
          Kanyakumari District, Tamilnadu
        </li>
        <li>
          Noorul Islam College of Aeronautics and Marine Engineering ,
          Kanyakumari District, Tamilnadu
        </li>
        <li>
          Noorul Islam Research and Development (Doctoral Programmes),
          Kanyakumari District, Tamilnadu
        </li>
        <li>
          Noorul Islam Centre for Audiology and Speech language pathology,
          Kanyakumari District Tamilnadu
        </li>
        <li>NIMS Heart Foundation, NIMS Medicity, Neyyattinkara, Kerala</li>
        <li>NIMS College of Nursing, Neyyattinkara, Kerala</li>
        <li>NIMS Centre for Advanced Biotechnology, Neyyattinkara, Kerala.</li>
        <li>NIMS SPECTRUM Child Development Research Centre, Kerala</li>
        <li>Noorul Islam Civil Service Academy, Neyyattinkara, Kerala.</li>
      </ul>
    </div>
  </div>

  <div class="my-3">
    <header class="">
      <h4 class="fw-bold">Profile</h4>
    </header>
    <div class="row my-4">
      <app-list-card
        *ngFor="let item of aboutHospital"
        [card]="item"
        class="mb-3 col-sm-6 col-md-4"
      ></app-list-card>
    </div>
  </div>
</div>
