import { Component, OnInit } from '@angular/core';
import { DoctorsListService } from 'src/app/_services/doctors-list.service';


@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss'],
})
export class CoursesComponent implements OnInit {
  courses: any = [];

  constructor(private CoursesService: DoctorsListService) {}

  ngOnInit() {
    this.getCoursesList();
  }

  getCoursesList() {
    this.CoursesService.getCoursesList().subscribe((data) => {
      this.courses = data.data;
      console.log(this.courses);
    });
  }
}
