<div class="container pb-5 mt--3">
  <header class="text-center">
    <h3 data-aos="fade-right" data-aos-duration="6000" class="mb-1">
      Our Specialty
    </h3>
    <h5 data-aos="fade-right" data-aos-duration="6000" class="mb-4">
      With Executive Checkup
    </h5>
    <p
      data-aos="fade-left"
      data-aos-duration="6000"
      class="nm-secondary mb-2 m-auto w-75"
    >
      NIMS Heart foundation is one of the biggest Heart hospitals in South
      India. It has 40 Departments well with advance technology equipments. 
    </p>
    <div class="my-5">
      <app-tab-details></app-tab-details>
    </div>
  </header>
</div>
