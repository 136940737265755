import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-normal-executive-checkup',
  templateUrl: './normal-executive-checkup.component.html',
  styleUrls: ['./normal-executive-checkup.component.scss']
})
export class NormalExecutiveCheckupComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
