
  

    <div class=" nm-person mb-3 " *ngFor="let item of doctor" routerLink="{{item.url }}" >
     
        <img src="https://nims.prismaticsoft.com{{ item.profileimage }}"  alt=""   />
            <div class="nm-person__content px-4" >
              <p class="nm-person__name"  >{{item.name}}</p>
              <p class="nm-person__role fw-bold">{{item.department}}</p>
              <p class="nm-person__role">{{item.qualification}}</p>
            </div>
    </div>
    
      
<!-- Mobile slider starts -->

<!-- <div class="nm-person mb-3">
  <img src="{{ doctor.image }}" alt="" />
  <div class="nm-person__content px-3">
    <p class="nm-person__name">{{ doctor.name }}</p>
    <p class="nm-person__role fw-bold">{{ doctor.role }}</p>
    <p class="nm-person__role">{{ doctor.qualification }}</p>
  </div>
</div> -->
