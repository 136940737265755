<div class="nm-page-header">
  <h2>Differently Abled Friendly Hospital</h2>
</div>

<div class="container">
  <p class="pt-2">
    Neurodevelopmental disorders (NDDs) compromise the development and
    attainment of full social and economic potential at individual, family,
    community, and country levels. An INCLEN study covering a sample of 3,964
    children across five sites in India, estimated the prevalence of
    neurodevelopmental disorders as 9.2% among children of 2-6 years and 13.6%
    among children of 6-9 years. There was no significant difference according
    to gender, rural/urban residence, or religion and almost one-fifth of these
    children had more than one neurodevelopmental disorders. According to the
    World Bank, one in every 12 households in India has a person living with a
    disability. Hence, it is our endeavour to transform NIMS Medicity into a
    disabled-friendly zone, offering unparalleled early detection, early
    intervention and community rehabilitation facilities, which would be the
    first hospital of its kind in Kerala.
  </p>
  <p class="pt-2">
    We wish to achieve the same through a comprehensive plan of establishing the
    one and only “NIMS-SPECTRUM-Child Development Research Centre
    (NIMS-SPECTRUM-CDRC)” with three key divisions namely;
  </p>
  <h6 class="pt-2">
    I. Child Development Research Centre for Prevention, Early detection and
    Early intervention of developmental disorders with focus on;
  </h6>
  <p class="pt-1 ps-2">
    Neuro-developmental follow-up and early intervention for low birth weight
    babies
  </p>
  <p class="pt-1 ps-2">
    Assessment and early intervention for developmental delay (0 – 6 yrs)
  </p>
  <p class="pt-1 ps-2">
    Assessment and early language intervention (0 – 6 yrs)
  </p>
  <p class="pt-1 ps-2">
    Assessment and early intervention for Autism (0 – 6 yrs)
  </p>
  <p class="pt-1 ps-2">
    Assessment and intervention for Preschool children (4 – 6 yrs)
  </p>
  <p class="pt-1 ps-2">
    Assessment and intervention for Primary school children (6 – 9 yrs)
  </p>
  <h6 class="pt-2">
    II. Specialised Therapy units for developmental disorders
  </h6>
  <p class="pt-1 ps-2">1. Early stimulation unit for high-risk newborns</p>
  <p class="pt-1 ps-2">2. Developmental therapy unit for developmental delay</p>
  <p class="pt-1 ps-2">
    3. Audiology & Speech therapy unit for speech and hearing problems
  </p>
  <p class="pt-1 ps-2">
    4. Paediatric Physiotherapy unit for Cerebral Palsy and other neuromuscular
    disorders in children
  </p>
  <p class="pt-1 ps-2">
    5. Sensory integration unit for autism children and special education for
    the needy.
  </p>
  <p class="pt-1 ps-2">
    6. Occupational therapy unit promoting activities of daily living and
    appropriate special education techniques for the needy.
  </p>
  <p class="pt-1 ps-2">
    7. Learning disability Lab for children with learning problems.
  </p>
  <h6 class="pt-2">
    III. Comprehensive Health Care for Differently abled children
  </h6>
  <p class="pt-1 ps-2">
    1. Paediatric Neurology unit – neurological evaluation and medication
  </p>
  <p class="pt-1 ps-2">
    2. Paediatric Ophthalmology Unit – ROP Screening and ophthalmic evaluation
  </p>
  <p class="pt-1 ps-2">3. Paediatric ENT Unit – Cochlear implant surgery</p>
  <p class="pt-1 ps-2">
    4. Paediatric Orthopaedic Unit – Rehabilitation surgery
  </p>
  <p class="pt-1 ps-2">
    5. Paediatric Genetics diagnosis unit – Special genetic investigations
  </p>
  <p class="pt-1 ps-2">
    6. Paediatric Counselling/Education unit – Psychological interventions
  </p>
  <p class="pt-1 ps-2">
    7. Paediatric Dental Care unit – Dental care and hygiene education
  </p>
  <p class="pt-1 ps-2">
    8. Paediatric Nutrition & Dietetics unit – Management of specific feeding
    problems
  </p>
  <p class="pt-1 ps-2">
    9. Rehabilitation Nursing Unit – Comprehensive care counselling
  </p>
  <p class="pt-1 ps-2">
    10. Ayurveda & Naturopathy – Alternate systems interventions
  </p>
  <p class="pt-1 ps-2">
    11. Young adults PCOS clinic unit – Evaluation, medication and counselling
  </p>
  <p class="pt-2">
    The NIMS-SPECTRUM – CHILD DEVELOPMENT RESEARCH CENTRE at NIMS Medicity,
    Neyyattinkara, Thiruvananthapuram, is being established under the guidance
    of Prof.(Dr.) M. K. C. Nair D.Sc., the founder Director of CDC Kerala and
    formerly the Vice-Chancellor, Kerala University of Health Sciences. It is
    visualised as a comprehensive project, utilizing the already existing
    infrastructure and health care professionals of NIMS, and consolidating the
    18 established assessment and therapy units under one umbrella. The
    established mission of NIMS Medicity to provide high quality,
    cost-effective, state-of-the-art services to all needy without any
    discrimination based on paying capacity.
  </p>
</div>
