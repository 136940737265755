import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tab-details',
  templateUrl: './tab-details.component.html',
  styleUrls: ['./tab-details.component.scss'],
})
export class TabDetailsComponent implements OnInit, OnChanges {
  selected: any;
  speciality: any;

  constructor(private httpClient: HttpClient, private router: Router) {}

  ngOnInit(): void {
    this.httpClient.get('assets/data/speciality.json').subscribe((data) => {
      this.speciality = data;
      this.selected = data[0].name;
    });
  }
  ngOnChanges(): void {
    console.log(this.router.url);
  }

  updateActiveTab(tabName) {
    this.selected = tabName;
  }
}
