<div class="nm-page-header">
    <h2>PEDIATRIC EXECUTIVE CHECK UP</h2>
  </div>
  
  <div class="container">
    <div style="text-align:center">
      <h4>PEDIATRIC EXECUTIVE HEALTH CHECK UP</h4>
      <h4>Amount:4000/-</h4>
      <h4>68 TESTS & 4 CONSULTATIONS</h4>
    </div>
    <div class="row py-3">
      <div class="col-sm">
        <h5>HAEMATOLOGY</h5>
        <h5>BLOOD GROUP & RH FACTOR</h5>
        <h6>COMPLETE BLOOD COUNT</h6>
          <div class="p-3">
            <ul>
              <li>HAEMOGLOBIN</li>
              <li>TOTAL COUNT</li>
            </ul>
          </div>
          <h5>DIFFERENTIAL COUNT</h5>
            <div class="p-3">
              <ul>
                <li>POLYMORPHS</li>
                <li>LYMPHOCYTES</li>
                <li>MONOCYTES</li>
                <li>EOSINOPHILS</li>
                <li>BASOPHILS</li>
                <li>PLATELET COUNT</li>
                <li>RBC</li>
                <li>PCV</li>
                <li>MCV</li>
                <li>MCH</li>
                <li>MCHC</li>
                <li>ESR</li>
                <li>RED CELL DISTRIBUTION</li>
                <li>ABSOLUTE EOSINOPHIL COUNT</li>
                <li>ABSOLUTE LYMPHOCYTE COUNT</li>
                <li>ABSOLUTE NEUTROPHIL COUNT</li>
                <li>MEAN PLATELET VOLUME</li>
              </ul>
            </div>
          </div>
          
           
      
        <div class="col-sm">
            <h5>LIVER FUNCTION TEST</h5>
            <div class="p-2">
              <ul>
                <li>BILIRUBIN-TOTAL</li>
                <li>BILIRUBIN-DIRECT</li>
                <li>BILIRUBIN-INDIRECT</li>
                <li>SGOT/AST</li>
                <li>SGPT/ALT</li>
                <li>ALKALINE PHOSPHATASE</li>
                <li>TOTAL PROTEIN</li>
                <li>ALBUMIN</li>
                <li>GLOBULIN</li>
                <li>ALBUMIN/GLOBULIN RATIO</li>
              </ul>
            </div>
            <h6>RENAL FUNCTION TEST</h6>
            <div class="p-2">
                <ul>
                    <li>BLOOD UREA NITROGEN</li>
                    <li>S.CREATINE</li>
                    <li>URIC ACID</li>
                </ul>
            </div>
            <h5>IMMUNOLOGY</h5>
            <br>
            <h6>VITAMIN D LEVELS</h6>
            <br>
            <h6>THYROID PROFILE</h6>
            <div class="p-3">
                <ul>
                    <li>T3</li>
                    <li>T4</li>
                    <li>TSH</li>
                </ul>
            </div>
        </div>
        <div class="col-sm">
            <h5>CLINICAL PATHOLOGY</h5>
            <h6>URINE ROUTINE EXAMINATION</h6>   
            <div class="p-2">
              <ul>
                <li>COLOUR</li>
                <li>URINE REACTION</li>
                <li>APPEARANCE</li>
                <li>SPECIFIC GRAVITY-URINE</li>
                <li>URINE ALBUMIN</li>
                <li>URINE SUGAR</li>
                <li>ACETONE-URINE</li>
                <li>BILE SALT</li>
                <li>BILE PIGMENT</li>
                <li>UROBILINOGEN</li>
              </ul>
            </div>
            <h5>URINE MICROSCOPIC EXAMINATION</h5>
            <div class="p-2">
              <ul>
                <li>MICRO-PUS CELLS</li>
                <li>MICRO-RBC</li>
                <li>MICRO-EPITHELIAL CELLS</li>
                <li>MICRO-NONSQUAMOUS EPITH CELLS</li>
                <li>MICRO-HYALIN CAST</li>
                <li>MICRO-PATHOLOGICAL CAST</li>
                <li>MICRO-CRYSTALS</li>
                <li>MICRO-BACTERIA</li>
                <li>MICRO-MUCUS STRANDS</li>
                <li>MICRO-YEAST</li>
              </ul>
            </div>
          </div>
          
        <div class="col-sm">
            <h5>BIOCHEMISTRY</h5>
            <br>
            <h6>RANDOM BLOOD SUGAR</h6>
            <br>
            <h6>S.CALCIUM</h6>
            <br>
            <h6>S.PHOSPHOROUS</h6>
            <h6>LIPID PROFILE</h6>
            <div class="p-2">  
              <ul>
                <li>S.CHOLESTEROL</li>
                <li>S.TRYGLYCERIDES</li>
                <li>HDL.CHOLESTROL</li>
                <li>LDL.CHOLESTROL</li>
                <li>VLDL.CHOLESTROL</li>
              </ul>
            </div> 
            <h5>ECG</h5>
            <br>
            <h5>USG ABDOMEN AND PELVIS</h5>
            <br>
            <h5>VISION TEST</h5>
            <br>
            <h5>CONSULTATIONS</h5>
            <div class="p-2">
                <ul>
                <li>PEDIATRIC</li>
                <li>OPHTHALMOLOGY</li>
                <li>ENT</li>
                <li>DENTAL</li>
                </ul>
            </div>
            <h6 class="pt-3">COMPLIMENTARY BREAKFAST</h6>
        </div>
    </div>  
</div>