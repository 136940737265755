<section class="bg-secondary py-5">
  <div class="container">
    <div class="text-center">
      <h3 class="mb-1">Our Testimonials</h3>
      <h5 class="mb-2 fs-6 nm-secondary">What our patients say about us</h5>

      <div id="nmTestimonials" class="nm-testimonial-wrap mt-5">
        <div class="container">
          <div class="nm-service-wrap">
            <owl-carousel-o [options]="customOptions">
              <ng-template carouselSlide *ngFor="let item of testmonials">
                <app-testimonial-card
                  [testimonial]="item"
                ></app-testimonial-card>
              </ng-template>
            </owl-carousel-o>

            <!-- //Carousel to be implemented later -->
            <!-- <div
              id="carouselHomeTestimonial"
              class="carousel slide"
              data-bs-ride="carousel"
            >
              <ol class="carousel-indicators">
                <li
                  *ngFor="let item of testmonials; let index = index"
                  [ngClass]="{ active: item.id == 1 }"
                  data-target="#carouselHomeTestimonial"
                  [attr.data-slide-to]="index"
                ></li>
              </ol>
              <div class="carousel-inner">
                <div
                  class="carousel-item"
                  *ngFor="let item of testmonials"
                  [ngClass]="{ active: item.id == 1 }"
                >
                  <app-testimonial-card
                    [testimonial]="item"
                  ></app-testimonial-card>
                </div>
              </div>
              <a
                class="carousel-control-prev"
                href="#carouselHomeTestimonial"
                role="button"
                data-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Previous</span>
              </a>
              <a
                class="carousel-control-next"
                href="#carouselHomeTestimonial"
                role="button"
                data-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Next</span>
              </a>
            </div> -->
          </div>
        </div>
      </div>

      <div class="text-center mt-5">
        <button class="btn btn-primary" routerLink="testimonials">
          Read More
        </button>
      </div>
    </div>
  </div>
</section>
