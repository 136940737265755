import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OwlOptions } from 'ngx-owl-carousel-o';
import AOS from 'aos';
import { DoctorsListService } from 'src/app/_services/doctors-list.service';


@Component({
  selector: 'app-our-team',
  templateUrl: './our-team.component.html',
  styleUrls: ['./our-team.component.scss'],
})
export class OurTeamComponent implements OnInit {
  constructor(private httpClient: HttpClient,
    private doctorService: DoctorsListService,) {}
  doctors: any = [];
 
  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: [
      '<i class="fas fa-arrow-left"></i>',
      '<i class="fas fa-arrow-right"></i>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 4,
      },
    },
    nav: true,
  };

  ngOnInit() {
    let scrollRef = 0;
    this.getDoctorsHomeList() ; 

   /*  window.addEventListener('scroll', function () {
      // increase value up to 10, then refresh AOS
      scrollRef <= 10 ? scrollRef++ : AOS.refresh();
    }); */
  }
  getDoctorsHomeList() {
    this.doctorService.getDoctorsHomeList().subscribe((data) => {
      this.doctors = data.data;
      console.log(this.doctors);
     
    });
  }
}
