<!-- Heading -->
<!-- <div class="col-sm-12 text-center nm-bg-primary mb-3 text-white py-3">
  <h2 class="services"><b>SERVICES</b></h2>
</div> -->
<div class="nm-page-header">
  <h2>SERVICES</h2>
</div>

<!-- content  -->
<div class="container my-5">
  <div class="row">
    <div
      class="col-sm-3"
      *ngFor="let item of services"
      data-bs-toggle="offcanvas"
      [attr.data-bs-target]="'#' + item.id"
    >
      <div class="d-flex mb-3 nm-list-card">
        <img src="{{ item.icon }}" alt="" />
        <h6 class="fw-bolder">{{ item.head }}</h6>
      </div>
    </div>
  </div>
</div>

<div
  class="offcanvas offcanvas-bottom"
  id="{{ item.id }}"
  style="
    height: 50%;
    background-image: linear-gradient(90deg, #2f905c, #599476, #2f905c);
  "
  *ngFor="let item of canvas"
>
  <div class="offcanvas-header">
    <h4 class="offcanvas-title">
      <b>{{ item.head }}</b>
    </h4>
    <button
      type="button"
      class="btn-close text-reset"
      data-bs-dismiss="offcanvas"
    ></button>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm-3">
        <img src="{{ item.icon1 }}" alt="images" width="180px" />
      </div>
      <div class="col-sm-9 bg-light border rounded p-3">
        <p style="text-align: justify">
          {{ item.description }}
        </p>
        <br />
        <button class="btn btn-success {{ item.btnclass }} ">
          <a
            style="text-decoration: none"
            class="text-white"
            href="{{ item.url }}"
            >Read More</a
          >
        </button>
      </div>
    </div>
  </div>
</div>
