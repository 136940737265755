<div class="nm-page-header">
  <h2>POST COVID EXECUTIVE CHECK UP</h2>
</div>

<div class="container">
  <div style="text-align:center">
    <h4>POST COVID EXECUTIVE CHECK UP</h4>
    <h4>Amount:6000/-</h4>
    <h4>45 TESTS & 2 CONSULTATIONS</h4>
  </div>
  <div class="row py-3">
    <div class="col-sm">
      <h5>HAEMATOLOGY</h5>
      
      <h6>COMPLETE BLOOD COUNT</h6>
        <div class="p-3">
          <ul>
            <li>HAEMOGLOBIN</li>
            <li>TOTAL COUNT</li>
          </ul>
        </div>
        <h5>DIFFERENTIAL COUNT</h5>
          <div class="p-3">
            <ul>
              <li>POLYMORPHS</li>
              <li>LYMPHOCYTES</li>
              <li>MONOCYTES</li>
              <li>EOSINOPHILS</li>
              <li>BASOPHILS</li>
              <li>PLATELET COUNT</li>
              <li>RBC</li>
              <li>PCV</li>
              <li>MCV</li>
              <li>MCH</li>
              <li>MCHC</li>
              <li>ESR</li>
              <li>RED CELL DISTRIBUTION</li>
              <li>ABSOLUTE EOSINOPHIL COUNT</li>
              <li>ABSOLUTE LYMPHOCYTE COUNT</li>
              <li>ABSOLUTE NEUTROPHIL COUNT</li>
              <li>MEAN PLATELET VOLUME</li>
            </ul>
          </div>
        </div>
        <div class="col-sm">
          <h5>BIOCHEMISTRY</h5>
          <br>
          <h6>FASTING BLOOD SUGAR/RANDOM BLOOD SUGAR</h6>
          <br>
          <h6>HbA1C</h6>
          <br>
          <h6>C-REACTIVE PROTEIN(CRP)</h6>
          <h5>RENAL PROFILE</h5>
          <div class="p-2">
            <ul>
              <li>BLOOD UREA NITROGEN</li>
              <li>CREATINE</li>
              <li>URIC ACID</li>
            </ul>
          </div>
    
          <h5>ELECTROLYTES</h5>
          <div class="p-2">
            <ul>
              <li>S.SODIUM</li>
              <li>S.POTASSIUM</li>
            </ul>
          </div>
          <h5>IMMUNOLOGY</h5>
          <div class="p-2">
            <ul>
              <li>D-DIMER</li>
              <li>COVID IGG</li>
            </ul>
        </div>
      </div>
        <div class="col-sm">
        <h5>LIVER PROFILE</h5>
        <div class="p-2">
          <ul>
            <li>S. BILIRUBIN (TOTAL)</li>
            <li>S. BILIRUBIN (DIRECT)</li>
            <li>S. BILIRUBIN (INDIRECT)</li>
            <li>ALKALINE PHOSPHATE</li>
            <li>SGOT</li>
            <li>SGPT</li>
            <li>TOTAL PROTEIN</li>
            <li>ALBUMIN</li>
            <li>GLOBULIN</li>
            <li>ALBUMIN/GLOBULIN RATIO</li>
          </ul>
        </div>
        <h5>ECG</h5>
        <h5>ECHO</h5>
        <h5>CHEST XRAY</h5>
        <h5>USG ABDOMEN AND PELVIS</h5>
        <h5>PULMONARY FUNCTION TEST (PFT)</h5>
        <h5>PHYSIOTHERAPY – PULMONARY REHABILITATION</h5>
        <h5>CONSULTATIONS</h5>
        <div class="p-2">
          <ul>
            <li>PULMONOLOGY</li>
            <li>DIETARY ADVICE</li>
          </ul>
        </div>
        <h6 class="pt-3">COMPLIMENTARY BREAKFAST</h6>
    </div>
  </div>
</div>
