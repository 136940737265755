<!-- Heading -->
<!-- <div class="col-sm-12 text-center nm-bg-primary text-white py-3">
  <h2 class="contact"><b>CONTACT US</b></h2>
</div> -->
<!-- Heading -->
<div class="nm-page-header">
  <h2>CONTACT US</h2>
</div>
<div class="col-sm-12 text-center mapstyle container">
  <iframe
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3946.892484568711!2d77.06372971398395!3d8.41221949395014!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b05af08ada78f17%3A0x266275e9e01868be!2sNIMS%20Hospital!5e0!3m2!1sen!2sin!4v1603134079392!5m2!1sen!2sin"
    width="100%"
    height="400"
    frameborder="0"
    style="border: 0"
    allowfullscreen=""
    aria-hidden="false"
    tabindex="0"
  ></iframe>
</div>
<div class="container">
  <div class="row">
    <div class="col-sm-6">
      <div class="contactdesc">
        <h4 class="text-uppercase font-weight-bold">SEND US A MESSAGE</h4>
        <hr style="width: 10%; text-align: left; margin-left: 0" />
      </div>
      <div class="row p-3">
        <div class="col">
          <input
            type="text"
            class="form-control"
            placeholder="First name"
            aria-label="First name"
          />
        </div>
        <div class="col">
          <input
            type="text"
            class="form-control"
            placeholder="Last name"
            aria-label="Last name"
          />
        </div>
      </div>
      <div class="row p-3">
        <div class="col">
          <input
            type="text"
            class="form-control"
            placeholder="EMAIL"
            aria-label="email"
          />
        </div>
        <div class="col">
          <input
            type="text"
            class="form-control"
            placeholder="Phonenumber"
            aria-label="phonenumber"
          />
        </div>
      </div>
      <div class="row p-3">
        <div class="col">
          <textarea
            class="form-control"
            rows="5"
            id="comment"
            placeholder="Enter your Message"
          ></textarea>
        </div>
      </div>
      <div class="row p-3">
        <div class="col-sm-4">
          <button type="submit" class="btn btn-primary">
            Send your message
          </button>
        </div>
      </div>
    </div>
    <div class="col-sm-6 addressstyle">
      <div class="container">
        <div class="col d-flex bg-light border rounded my-2">
          <i
            class="fa fa-map-marker fa-lg icon fs-6 nm-secondary"
            aria-hidden="true"
          ></i>
          <h4 class="px-2"><b>Find us at the Office</b></h4>
        </div>
        <div class="col addressstyle">
          <h5>NIMS Medicity,</h5>
          <h6>Aralumoodu, Neyyattinkara</h6>
          <h6>Thiruvananthapuram</h6>
        </div>

        <div class="col d-flex bg-light border rounded my-2">
          <i
            class="fa fa-phone fa-lg fa-rotate-90 icon fs-6 nm-secondary"
            aria-hidden="true"
          ></i>
          <h4 class="px-2"><b> Give us a Ring</b></h4>
        </div>
        <div class="col addressstyle">
          <h6>0471 – 2222115</h6>
          <h6>0471 – 2223542</h6>
          <h6>0471 – 2223544</h6>
          <h6>0471 – 3951111</h6>
        </div>

        <div class="col d-flex py-3 bg-light border rounded my-2">
          <i
            class="fa fa-envelope fa-lg icon fs-6 nm-secondary"
            aria-hidden="true"
          ></i>
          <h5 class="px-2">info@nimsmedicity.org</h5>
        </div>
        <div class="col d-flex bg-light border rounded my-2">
          <i
            class="fa fa-globe fa-lg icon fs-6 nm-secondary"
            aria-hidden="true"
          ></i>
          <h5 class="px-2">
            <a
              href="https://faizalkhan.com/"
              style="text-decoration: none; color: black"
              >msfaizalkhan.in</a
            >
          </h5>
        </div>
      </div>
    </div>
  </div>
</div>
