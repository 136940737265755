<div class="nm-page-header">
  <h2>{{ researchsubcategory.name }}</h2>
</div>

<div class="container" *ngFor="let item of researchsubcategory.sub">
  <p>{{ item.para1 }}</p>
  <img
            src="{{ item.image1 }}"
            
            style="height: auto" 
            style="width:1200px"
            alt="..."
          />
  <p class="py-3">{{ item.para2 }}</p>
  <div *ngFor="let item1 of item.list1">
    {{ item1.heading }}
    <div class="container" *ngFor="let item2 of item1.listing1">
      {{ item2.li }}
    </div>
  </div>
  <p class="py-3">{{ item.para3 }}</p>
  <p class="py-3">{{ item.para4 }}</p>
</div>
