import { Component, OnInit , Input} from '@angular/core';
import { DoctorsListService } from 'src/app/_services/doctors-list.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-doctors-detail',
  templateUrl: './doctors-detail.component.html',
  styleUrls: ['./doctors-detail.component.scss']
})
export class DoctorsDetailComponent implements OnInit 
{

  doctor: any=[];
 
 
  
  constructor(
    private doctorService: DoctorsListService,
    private router: Router
  ) {}

 

    ngOnInit(): void 
    {
     this.getDoctorsHomeList() ; 
   /*  this.getCustomDoctorsList(); */
     
    }




    

 /*  getnewseventList() 
    {
      this.Newseventlist.getnewseventList().subscribe((data) => {
        this.newsevent = data.data;
        console.log(this.newsevent);
      });
    
    } */

    
    getDoctorsHomeList() {
      this.doctorService.getDoctorsHomeList().subscribe((data) => {
        this.doctor = data.data;
        console.log(this.doctor);
       
      });
    } 
   /*  getDoctorsList() {
      
      this.doctorService.getDoctorsList().subscribe((data) => {
        this.doctordetails = data.data;
        console.log(this.doctordetails);
       
       
      });
    }  */
  /* getCustomDoctorsList(){
    this.doctorService.getCustomDoctorsList().subscribe((data) => {
      this.doctors = data.data;
      console.log(this.doctors);
    });
  } */
}
