import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DoctorsListService } from 'src/app/_services/doctors-list.service';

@Component({
  selector: 'app-department-docdetail',
  templateUrl: './department-docdetail.component.html',
  styleUrls: ['./department-docdetail.component.scss'],
})
export class DepartmentDocdetailComponent implements OnInit {
  doctors: any = [];
  departments: any = [];
  doctordetails: any = [];
  pathFldr: any;

  constructor(
    private doctorService: DoctorsListService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getDoctorsList();
  }

  getDoctorsList() {
    const pathFldr = this.router.url;
    this.doctorService.getDoctorsList().subscribe((data) => {
      this.doctors = data.data;
      for (var val of this.doctors) {
        this.departments = val;
        if (pathFldr == this.departments.departmenturl) {
          this.doctordetails = this.departments.info;
        }
      }
    });
  }
}
