<!-- Heading -->
<!-- <div class="col-sm-12 text-center nm-bg-primary mb-3 text-white py-3">
  <h2 class="doctor"><b>NEWS & EVENTS</b></h2>
</div> -->
<div class="nm-page-header">
  <h2>NEWS & EVENTS</h2>
</div>

<!-- contents -->
<div class="container">
  <div class="row">
    <div class="col-sm-4 divstyle check" *ngFor="let item of newsevents">
      <div class="card" [routerLink]="item.title">
        <div style="position: relative">
          <img src="https://nims.prismaticsoft.com{{ item.image }}" class=" imgsize card-img-top" alt="..." />
          <button class="btn">Read More</button>
        </div>
        <div class="card-body nm-bg-primary text-white">
          <button class="btn text-black"><br><br>{{item.date}}</button>
          <h5 class="boxheight card-title">
            <b>{{ item.title }}</b>
          </h5>
          <!--  <div id="demo" class="collapse card-text">{{item.main_description}}
          </div> -->
           <!--  {{item.main_description}} -->
          
        </div>
      </div>
    </div>
  </div>
</div>
