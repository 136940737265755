<div class="container py-5 mb-4">
  <div class="row">
    <div class="col-md-6 mt-3">
      <!-- remove style when real image is added -->
      <img
        src="/assets/img/home/nims-medicity.jpeg"
        alt=""
        style="
          width: 100%;
          margin: auto;
          display: flex;
          align-self: center;
          justify-self: center;
        "
      />
    </div>
    <div class="col-md-6">
      <div class="text-center mb-4 mt-3">
        <h3 class="mb-1">
          NIMS Medicity, part of the 69 year old Noorul Islam Educational Trust
        </h3>
        <h5 class="mb-2 fs-6 nm-secondary">Best Hospital in trivandrum</h5>
      </div>
      <p style='text-align: justify'>
        NIMS is a 228 bedded NAAC A and NABH Accredited super speciality 
        tertiary care teaching hospital.It has 40 Departments and around
        200 consultants round the clock totally dedicated to complete health 
        restoration along with more than 1500 nursing and paramedical staffs.
        It is one of the largest health care centers in South Kerala and 
        South Tamilnadu.
      </p>
      <div class="mt-5">
        <button class="btn btn-primary" routerLink="/about-us">
          Read More
        </button>
      </div>
    </div>
  </div>
</div>
