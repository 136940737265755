<div class="container">
  <header class="text-center pt-5">
    <h3 data-aos="zoom-in" data-aos-duration="3000" class="mb-1">Our Team</h3>
    <h5 data-aos="zoom-in" data-aos-duration="3000" class="mb-3">
      Meet Our Doctors
    </h5>
    <p
      data-aos="zoom-in"
      data-aos-duration="3000"
      class="nm-secondary mb-2 nm-secondary mb-2 m-auto w-75"
    >
      NIMS Heart foundation is one of the biggest Heart hospitals in South
      India. The hospital is well equipped with all the modern equipments to
      ensure treatment for the patients
    </p>
    <p class="nm-primary fw-bolder">View all Doctors</p>

    <div id="nmDoctorTeam" class=" pt-4 pb-5 d-none d-lg-grid" >
      <app-doctors-detail
    
      ></app-doctors-detail>
    </div>
    <div class="d-lg-none  ">
      <owl-carousel-o [options]="customOptions">
        <ng-template carouselSlide   >
          <app-doctors-detail [doctor]="doctors"></app-doctors-detail>
        </ng-template>
      </owl-carousel-o>
    </div> 
  </header>
</div>
<!-- <div id="nmDoctorTeam" class="nm-doctors pt-4 pb-5 d-none d-lg-grid">
  <app-doctors-detail
    *ngFor="let item of doctors"
    [doctor]="item"
  ></app-doctors-detail>
</div>
<div class="d-lg-none">
  <owl-carousel-o [options]="customOptions">
    <ng-template carouselSlide *ngFor="let item of doctors">
      <app-doctors-detail [doctor]="item"></app-doctors-detail>
    </ng-template>
  </owl-carousel-o>
</div> -->