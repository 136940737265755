import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DepartmentListService } from 'src/app/_services/department-list.service';

@Component({
  selector: 'app-department-subheader',
  templateUrl: './department-subheader.component.html',
  styleUrls: ['./department-subheader.component.scss'],
})
export class DepartmentSubheaderComponent implements OnInit {
  departments: any = [];
  pathFldr: any;
  departmentUrl: any;
  bannerImg: any;

  departmentName: string;
  subtitle: String;
  departmentIcon: String;

  constructor(
    private departmentList: DepartmentListService,
    private router: Router
  ) {}

  ngOnInit() {
    this.pathFldr = this.router.url;
    console.log('from subheader', this.pathFldr);
    this.getDepartmentList();
  }
  getDepartmentList() {
    this.departmentList.getDDepartmentList().subscribe((data) => {
      this.departments = data.data;
      for (var val of this.departments) {
        if (this.pathFldr === val.url) {
          this.departmentName = val.departmentname;
          this.subtitle = val.subtext;
          this.departmentIcon = val.departmenticon1;
          this.departmentUrl = val.subheaderimg;
          this.bannerImg = val.subheaderimg;
          console.log('check', this.departmentUrl);
          console.log('check', this.bannerImg);
        }
      }
    });
  }
}
