<!-- Heading -->
<!-- <div class="col-sm-12 text-center nm-bg-primary mb-3 text-white py-3">
  <h2 class="medical"><b>MEDICAL DICTIONARY</b></h2>
</div> -->
<!-- Heading -->
<div class="nm-page-header">
  <h2>MEDICAL DICTIONARY</h2>
</div>
<!-- content  -->
<div class="container p-3">
  <div class="row">
    <div class="col-sm-1">
      <div class="accordion" id="dictionary" style="margin: auto; padding: 0%">
        <div
          class="p-1"
          *ngFor="let item of dictionary"
          style="display: inline-block"
        >
          <button
            style="width: 38px; height: 38px"
            class="btn btn-success"
            type="button"
            data-bs-toggle="collapse"
            [attr.data-bs-target]="'#' + item.id"
            aria-expanded="true"
            aria-controls="{{ item.id }}"
            (click)="getDictionaryList(item.id)"
          >
            {{ item.id }}
          </button>
        </div>
      </div>
    </div>
    <div class="col-sm-11">
      <div
        id="{{ item.id }}"
        class=" collapse {{ item.class }}"
        aria-labelledby="headingOne"
        data-bs-parent="#dictionary"
        *ngFor="let item of dictionary"
      >
        <div class="card rounded cardstyle">
          <div class="row accordion-body">
            <div class="col-sm-6 p-2" *ngFor="let val of words">
              <div>
                <h4>
                  <b> {{ val.words }}</b>
                </h4>
              </div>
              <div>
                <h6>{{ val.meaning }}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
