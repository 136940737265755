import { Component, OnInit } from '@angular/core';
import { DoctorsListService } from 'src/app/_services/doctors-list.service';


@Component({
  selector: 'app-medicaldictionary',
  templateUrl: './medicaldictionary.component.html',
  styleUrls: ['./medicaldictionary.component.scss'],
})
export class MedicaldictionaryComponent implements OnInit {
  dictionary: any = [];
  words: any = [];

  constructor(private Dictionarylist: DoctorsListService) {}

  ngOnInit() {
    var x = 'A';
    this.getDictionaryList(x);
  }

  getDictionaryList(x) {
    this.Dictionarylist.getDictionaryList().subscribe((data) => {
      this.dictionary = data.data;
      for (var val of this.dictionary) {
        if (val.id == x) {
          this.words = val.content;
        }
      }
    });
  }
}
