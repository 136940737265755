<!-- Heading -->
<!-- <div class="col-sm-12 text-center nm-bg-primary mb-3 text-white py-3">
  <h2 class="doctor"><b>VIDEO GALLERY</b></h2>
</div> -->
<div class="nm-page-header">
  <h2>VIDEO GALLERY</h2>
</div>
<!-- content -->
<div class="container">
  <div class="row">
    <div class="col-sm-4 p-3">
      <iframe
        src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fanoop.nair.52056%2Fvideos%2F522617452890209%2F&show_text=false&width=560&t=0"
        width="560"
        height="314"
        style="border: none; overflow: hidden"
        scrolling="no"
        frameborder="0"
        allowfullscreen="true"
        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
        allowFullScreen="true"
      ></iframe>
    </div>
  </div>
</div>
<div class="container">
  <div class="row">
    <div class="col-sm-4 p-3">
      <div class="card">
        <div class="card-body">
          <iframe
            class="mobiframe"
            width="375px"
            height="270px"
            src="https://www.youtube.com/embed/WVWxOpq9j-M"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
    <div class="col-sm-4 p-3">
      <div class="card">
        <div class="card-body">
          <iframe
            class="mobiframe"
            width="375"
            height="270"
            src="https://www.youtube.com/embed/JYDdM2xXNvs"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
    <div class="col-sm-4 p-3">
      <div class="card">
        <div class="card-body">
          <iframe
            class="mobiframe"
            width="375"
            height="270"
            src="https://www.youtube.com/embed/nBDw4o1fEIo"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
    <div class="col-sm-4 p-3">
      <div class="card">
        <div class="card-body">
          <iframe
            class="mobiframe"
            width="375"
            height="270"
            src="https://www.youtube.com/embed/hZhKmDHoGeU"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
    <div class="col-sm-4 p-3">
      <div class="card">
        <div class="card-body">
          <iframe
            class="mobiframe"
            width="375"
            height="270"
            src="https://www.youtube.com/embed/ayXvXkmqcQ8"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
    <div class="col-sm-4 p-3">
      <div class="card">
        <div class="card-body">
          <iframe
            class="mobiframe"
            width="375"
            height="270"
            src="https://www.youtube.com/embed/mSBodJ_YJUg"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
    <div class="col-sm-4 p-3">
      <div class="card">
        <div class="card-body">
          <iframe
            class="mobiframe"
            width="375"
            height="270"
            src="https://www.youtube.com/embed/j23_Yhgk0rM"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
    <div class="col-sm-4 p-3">
      <div class="card">
        <div class="card-body">
          <iframe
            class="mobiframe"
            width="375"
            height="270"
            src="https://www.youtube.com/embed/fvWLWU2nkZ0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
    <div class="col-sm-4 p-3">
      <div class="card">
        <div class="card-body">
          <iframe
            class="mobiframe"
            width="375"
            height="270"
            src="https://www.youtube.com/embed/VwiJZL5DSOY"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
    <div class="col-sm-4 p-3">
      <div class="card">
        <div class="card-body">
          <iframe
            class="mobiframe"
            width="375"
            height="270"
            src="https://www.youtube.com/embed/MluKfcijLxs"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
    <div class="col-sm-4 p-3">
      <div class="card">
        <div class="card-body">
          <iframe
            class="mobiframe"
            width="375"
            height="270"
            src="https://www.youtube.com/embed/NA10Cq5g5zA"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
    <div class="col-sm-4 p-3">
      <div class="card">
        <div class="card-body">
          <iframe
            class="mobiframe"
            width="375"
            height="270"
            src="https://www.youtube.com/embed/GgPGHMhmYGE"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
    <div class="col-sm-4 p-3">
      <div class="card">
        <div class="card-body">
          <iframe
            class="mobiframe"
            width="375"
            height="270"
            src="https://www.youtube.com/embed/ApqgS4-zA0k"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
    <div class="col-sm-4 p-3">
      <div class="card">
        <div class="card-body">
          <iframe
            class="mobiframe"
            width="375"
            height="270"
            src="https://www.youtube.com/embed/8zoNsMGg6WY"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
    <div class="col-sm-4 p-3">
      <div class="card">
        <div class="card-body">
          <iframe
            class="mobiframe"
            width="375"
            height="270"
            src="https://www.youtube.com/embed/IhPNU1_ozrE"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
    <div class="col-sm-4 p-3">
      <div class="card">
        <div class="card-body">
          <iframe
            class="mobiframe"
            width="375"
            height="270"
            src="https://www.youtube.com/embed/OEK80YZzPoU"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
    <div class="col-sm-4 p-3">
      <div class="card">
        <div class="card-body">
          <iframe
            class="mobiframe"
            width="375"
            height="270"
            src="https://www.youtube.com/embed/1QYMPQoqdbA"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
    <div class="col-sm-4 p-3">
      <div class="card">
        <div class="card-body">
          <iframe
            class="mobiframe"
            width="375"
            height="270"
            src="https://www.youtube.com/embed/2elIj4VQWJQ"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
    <div class="col-sm-4 p-3">
      <div class="card">
        <div class="card-body">
          <iframe
            class="mobiframe"
            width="375"
            height="270"
            src="https://www.youtube.com/embed/XsztI4K_XYM"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
    <div class="col-sm-4 p-3">
      <div class="card">
        <div class="card-body">
          <iframe
            class="mobiframe"
            width="375"
            height="270"
            src="https://www.youtube.com/embed/JzQfzFe8r-c"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
    <div class="col-sm-4 p-3">
      <div class="card">
        <div class="card-body">
          <iframe
            class="mobiframe"
            width="375"
            height="270"
            src="https://www.youtube.com/embed/UIphxZxr_58"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</div>
