<div class="nm-testimonial">
    <div class="nm-testimonial__head text-start">
        <div class="nm-testimonial__img ">
            <img src="{{testimonial.image}}" class="card-img-top" alt="...">
        </div>
       <div class=" ms-3">
        <h5 class="nm-testimonial__title fw-bolder fs-6">{{testimonial.name}}</h5>
        <small class="nm-secondary nm-testimonial__role">{{testimonial.role}}</small>
       </div>
    </div>
    <div class="nm-testimonial__body pt-2">
      <p class="nm-testimonial__text pt-5 pb-4 nm-secondary">{{testimonial.comment}}</p>
    </div>
  </div>
