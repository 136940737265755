import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-home-departments',
  templateUrl: './home-departments.component.html',
  styleUrls: ['./home-departments.component.scss'],
})
export class HomeDepartmentsComponent implements OnInit {
  department: any;

  constructor(private httpClient: HttpClient) {}

  ngOnInit() {
    this.httpClient.get('assets/data/department.json').subscribe((data) => {
      this.department = data;
    });
  }
}
