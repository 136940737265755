import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-our-testimonials',
  templateUrl: './our-testimonials.component.html',
  styleUrls: ['./our-testimonials.component.scss'],
})
export class OurTestimonialsComponent implements OnInit {
  constructor(private httpClient: HttpClient) {}
  testmonials: any = [];
  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: [
      '<i class="fas fa-arrow-left"></i>',
      '<i class="fas fa-arrow-right"></i>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 4,
      },
    },
    nav: true,
  };

  ngOnInit(): void {
    this.httpClient.get('assets/data/testimonials.json').subscribe((data) => {
      this.testmonials = data;
    });
  }
}
