<div
  id="carouselExampleControls"
  class="carousel slide"
  data-bs-ride="carousel"
>
<marquee class="marcol">NIMS Medicity Campus Marching towards the status of " Nation's first Net Zero Emission  Medical Campus"  by 2026 .  Enabling large scale Clean Energy Generation through 1MW Solar Power Plant project, 100 ton capacity Waste to Energy Conversion project, 5lakh litre  Water Recycling project, 
  Medical Oxygen Generation Plant, 60KW solar Powered EV Charging Station, 10lakh litre rain water harvesting project etc...</marquee>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img
        src="assets/img/home/website-nims.jpg"
        class="d-block w-100"
        alt="..."
      />
    </div>
    <div class="carousel-item">
      <img
        src="assets/img/home/NimsHospitalWeb4.jpg"
        class="d-block w-100"
        alt="..."
      />
    </div>
    <div class="carousel-item">
      <img
        src="assets/img/home/NimsHospitalWeb5.jpg"
        class="d-block w-100"
        alt="..."
      />
    </div>
    <div class="carousel-item">
      <img
        src="assets/img/home/NimsHospitalWeb6.jpg"
        class="d-block w-100"
        alt="..."
      />
    </div>
    <div class="carousel-item">
      <img
        src="assets/img/home/NimsHospitalWeb7.jpg"
        class="d-block w-100"
        alt="..."
      />
    </div>
    <div class="carousel-item">
      <img
        src="assets/img/home/NimsHospitalWeb8.jpg"
        class="d-block w-100"
        alt="..."
      />
    </div>
    <div class="carousel-item">
      <img
        src="assets/img/home/NimsHospitalWeb9.jpg"
        class="d-block w-100"
        alt="..."
      />
    </div>
    <div class="carousel-item">
      <img
        src="assets/img/home/NimsHospitalWeb10.jpg"
        class="d-block w-100"
        alt="..."
      />
    </div>
  </div>
  <button
    class="carousel-control-prev"
    type="button"
    data-bs-target="#carouselExampleControls"
    data-bs-slide="prev"
  >
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button
    class="carousel-control-next"
    type="button"
    data-bs-target="#carouselExampleControls"
    data-bs-slide="next"
  >
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
<div class="container">
  <div class="nm-service-wrap">
    <owl-carousel-o [options]="customOptions">
      <ng-template carouselSlide *ngFor="let item of speciality">
        <app-service-card
          [serviceCard]="item"
          routerLink="{{ item.url }}"
        ></app-service-card>
      </ng-template>
    </owl-carousel-o>
  </div>
</div>

<div class="container">
  <div class="nm-service-wrap">
    <!-- <div
      id="carouselServiceControl"
      class="carousel slide"
      data-bs-ride="carousel"
    >
      <div class="carousel-inner">
        <div
          class="carousel-item"
          *ngFor="let item of services"
          [ngClass]="{ active: item.id == 1 }"
        >
          <app-service-card [serviceCard]="item"></app-service-card>
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselServiceControl"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselServiceControl"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div> -->

    <div class="container text-center my-3">
      <div class="row mx-auto my-auto justify-content-center d-none d-md-flex">
        <div
          id="carouselServiceControl1"
          class="carousel slide"
          data-bs-ride="carousel"
        >
          <div class="carousel-inner" role="listbox">
            <div class="carousel-item active">
              <app-service-card
                *ngFor="let item of services"
                [serviceCard]="item"
              ></app-service-card>
            </div>
            <div class="carousel-item">
              <app-service-card
                *ngFor="let item of services1"
                [serviceCard]="item"
              ></app-service-card>
            </div>
          </div>
          <a
            class="carousel-control-prev bg-transparent w-aut"
            href="#carouselServiceControl1"
            role="button"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          </a>
          <a
            class="carousel-control-next bg-transparent w-aut"
            href="#carouselServiceControl1"
            role="button"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
