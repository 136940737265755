<div class="container">
  <div class="container">
    <div class="text-center mb-4 mt-3">
      <h3 data-aos="zoom-in" data-aos-duration="3000" class="mb-1">
        Latest News
      </h3>
      <h5 data-aos="zoom-in" data-aos-duration="3000" class="mb-3">
        NIMS recent events
      </h5>
    </div>
    <div class="row eventsrow">
      <div class="col-sm-6 d-none d-lg-block">
        <div
          id="carouselExampleCaptions"
          class="carousel slide carousel-fade"
          data-bs-ride="carousel"
        >
          <div class="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="0"
              class="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img
                src="https://nims.prismaticsoft.com{{ recentnews[0]['image']}}"
                class="img-gradient d-block w-100 img-fluid firstColEvents"
                alt="..."
              />
              <div class="carousel-caption d-none d-md-block">
                <h5>{{recentnews[0]['title']}}</h5>
                <!-- <p>
                  Some representative placeholder content for the first slide.
                </p> -->
                <span>NIMS Medicity</span><br />
                <span>{{recentnews[0]['date']}}</span><br />
                <span
                  class="btn1"
                  routerLink="news-events/{{recentnews[0]['title']}}"
                  >Read More</span
                >
              </div>
            </div>
            <div class="carousel-item active ">
              <img
                src="https://nims.prismaticsoft.com{{ recentnews[1]['image']}}"
                class="d-block w-100 img-fluid firstColEvents"
                alt="..."
              />
              <div class="carousel-caption d-none d-md-block">
                <h5>
                  {{recentnews[1]['title']}}
                </h5>

                <span>NIMS Medicity</span><br />
                <span>{{recentnews[1]['date']}}</span><br />
                <span class="btn1" routerLink="news-events/{{recentnews[1]['title']}}"
                  >Read More</span
                >
              </div>
            </div>
            <!--<div class="carousel-item">
              <img
                src="assets\img\news-events\one baby one tree\home1.jpg"
                class="d-block w-100 img-fluid firstColEvents"
                alt="..."
              />
              <div class="carousel-caption d-none d-md-block">
                <h5>
                  'ഒരു കുഞ്ഞു ഒരു മരം' എന്ന പദ്ധതിയിൽ ഒരു കുഞ്ഞു നിംസിൽ
                  ജനിക്കുമ്പോൾ ഒരു മരം ഭൂമിക്ക് എന്ന പദ്ധതി ആവിഷ്കരിച്ച്‌ ഈ
                  ഭൗമദിനത്തിൽ 2885 മത്തെ കുഞ്ഞിന് വൃക്ഷ തൈ മാജിഷ്യൻ ശ്രീ
                  ഗോപിനാഥ് മുത്തുകാട് കൈമാറിയപ്പോൾ
                </h5>

                <span>NIMS Medicity</span><br />
                <span>22-04-2022</span><br />
                <span class="btn1" routerLink="news-events/ഒരു-കുഞ്ഞു-ഒരു-മരം"
                  >Read More</span
                >
              </div>
            </div> -->
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      <div class="col-sm-6 d-none d-lg-block">
        <div
          id="carouselExampleCaptions2"
          class="carousel slide carousel-fade"
          data-bs-ride="carousel"
        >
          <div class="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions2"
              data-bs-slide-to="0"
              class="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions2"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions2"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active ">
              <img
                src="https://nims.prismaticsoft.com{{ recentnews[2]['image']}}"
                class="d-block w-100 img-fluid pb-2 secondColEvents"
                alt="..."
              />
              <div class="carousel-caption d-none d-md-block">
                <h5>{{recentnews[2]['title']}}</h5>
                <!-- <p>
                  Some representative placeholder content for the first slide.
                </p> -->
                <span>NIMS Medicity</span><br />
                <span>{{recentnews[2]['date']}}</span><br />
                <span class="btn1" routerLink="news-events/{{recentnews[2]['title']}}"
                  >Read More</span
                >
              </div>
            </div>
            <div class="carousel-item active ">
              <img
                src="https://nims.prismaticsoft.com{{ recentnews[3]['image']}}"
                class="d-block w-100 img-fluid pb-2 secondColEvents"
                alt="..."
              />
              <div class="carousel-caption d-none d-md-block">
                <h5>{{recentnews[3]['title']}}</h5>
                <!-- <p>
                  Some representative placeholder content for the first slide.
                </p> -->
                <span>NIMS Medicity</span><br />
                <span>{{recentnews[3]['date']}}</span><br />
                <span class="btn1" routerLink="news-events/{{recentnews[3]['title']}}"
                  >Read More</span
                >
              </div>
            </div>
              <!--<div class="carousel-item">
              <img
                src="assets\img\news-events\library\home1.jpg"
                class="d-block w-100 img-fluid pb-2 secondColEvents"
                alt="..."
              />
              <div class="carousel-caption d-none d-md-block">
                <h5>
                  ലോക പുസ്തക ദിനത്തിൽ നിംസ് മെഡിസിറ്റി ലൈബ്രറിയിൽ ആരംഭിച്ച
                  സാഹിത്യ ശാഖ രചന വേലപ്പൻനായർ ഉദ്ഘാടനം ചെയ്യുന്നു.
                </h5>

                <span>NIMS Medicity library</span><br />
                <span>23-04-2022</span><br />
                <span
                  class="btn1"
                  routerLink="/news-events/നിംസ്-മെഡിസിറ്റി-ലൈബ്രറിയിൽ-ആരംഭിച്ച-സാഹിത്യ-ശാഖ-രചന"
                  >Read More</span
                >
              </div>
            </div> 
             <div class="carousel-item">
              <img
                src="assets\img\news-events\library\home1.jpg"
                class="d-block w-100 img-fluid pb-2 secondColEvents"
                alt="..."
              />
              <div class="carousel-caption d-none d-md-block">
                <h5>
                  ലോക പുസ്തക ദിനത്തിൽ നിംസ് മെഡിസിറ്റി ലൈബ്രറിയിൽ ആരംഭിച്ച
                  സാഹിത്യ ശാഖ രചന വേലപ്പൻനായർ ഉദ്ഘാടനം ചെയ്യുന്നു.
                </h5>

                <span>NIMS Medicity library</span><br />
                <span>23-04-2022</span><br />
                <span
                  class="btn1"
                  routerLink="/news-events/നിംസ്-മെഡിസിറ്റി-ലൈബ്രറിയിൽ-ആരംഭിച്ച-സാഹിത്യ-ശാഖ-രചന"
                  >Read More</span
                >
              </div>
            </div>  --> 
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleCaptions2"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleCaptions2"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
        <div class="row eventsrow">
          <div class="col-sm-6">
            <div
              id="carouselExampleCaptions3"
              class="carousel slide carousel-fade"
              data-bs-ride="carousel"
            >
              <div class="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#carouselExampleCaptions3"
                  data-bs-slide-to="0"
                  class="active"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleCaptions3"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleCaptions3"
                  data-bs-slide-to="2"
                  aria-label="Slide 3"
                ></button>
              </div>
              <div class="carousel-inner">
                <div class="carousel-item active imgsizesmall">
                  <img
                    src="https://nims.prismaticsoft.com{{ recentnews[4]['image']}}"
                    class="d-block w-100 imgsizesmall"
                    alt="..."
                  />
                  <div class="carousel-caption d-none d-md-block">
                    <p class="smallBoxEventTitle">
                      {{recentnews[4]['title']}}
                    </p>
                    <span class="smallBoxEventLoc">NIMS Medicity</span><br />
                    <span>{{recentnews[4]['date']}}</span><br />
                    <span
                      class="btn1"
                      routerLink="news-events/{{recentnews[4]['title']}}"
                      >Read More</span
                    >
                  </div>
                </div>
                <!-- <div class="carousel-item">
                  <img
                    src="assets\img\news-events\16th dental batch\home1.jpg"
                    class="d-block w-100 img-fluid"
                    alt="..."
                  />
                  <div class="carousel-caption d-none d-md-block">

                    <p class="smallBoxEventTitle">
                      പതിനാറാമത് ബാച്ച് ബി ഡി എസ് പ്രവേശനോത്സവം റവ ഡോ. ശാന്തൻ
                      ചരുവിൽ ഉദ്ഘാടനം ചെയ്യുന്നു .
                    </p>
                    <span class="smallBoxEventLoc">NIMS Medicity</span><br />
                    <span>20-04-2022</span><br />
                    <span
                      class="btn2"
                      routerLink="news-events/പതിനാറാമത്-ബാച്ച്-ബി.ഡി.എസ്-പ്രവേശനോത്സവം"
                      >Read More</span
                    >
                  </div>
                </div>
                <div class="carousel-item">
                  <img
                    src="assets\img\news-events\16th dental batch\home1.jpg"
                    class="d-block w-100 img-fluid"
                    alt="..."
                  />
                  <div class="carousel-caption d-none d-md-block">

                    <p class="smallBoxEventTitle">
                      പതിനാറാമത് ബാച്ച് ബി ഡി എസ് പ്രവേശനോത്സവം റവ ഡോ. ശാന്തൻ
                      ചരുവിൽ ഉദ്ഘാടനം ചെയ്യുന്നു .
                    </p>
                    <span class="smallBoxEventLoc">NIMS Medicity</span><br />
                    <span>20-04-2022</span><br />
                    <span
                      class="btn2"
                      routerLink="news-events/പതിനാറാമത്-ബാച്ച്-ബി.ഡി.എസ്-പ്രവേശനോത്സവം"
                      >Read More</span
                    >
                  </div>
                </div> -->
              </div>
              <button
                class="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleCaptions3"
                data-bs-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button
                class="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleCaptions3"
                data-bs-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          <div class="col-sm-6">
            <div
              id="carouselExampleCaptions4"
              class="carousel slide carousel-fade"
              data-bs-ride="carousel"
            >
              <div class="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#carouselExampleCaptions4"
                  data-bs-slide-to="0"
                  class="active"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleCaptions4"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleCaptions4"
                  data-bs-slide-to="2"
                  aria-label="Slide 3"
                ></button>
              </div>
              <div class="carousel-inner">
                <div class="carousel-item active ">
                  <img
                    src="https://nims.prismaticsoft.com{{ recentnews[5]['image']}}"
                    class="d-block w-100 imgsizesmall"
                    alt="..."
                  />
                  <div class="carousel-caption d-none d-md-block">
                    <p class="smallBoxEventTitle">
                      {{recentnews[5]['title']}} 
                    </p>
                    <span class="smallBoxEventLoc">NIMS Medicity </span><br />
                    <span>{{recentnews[5]['date']}}</span><br />
                    <span
                      class="btn1"
                      routerLink="news-events/{{recentnews[5]['title']}}"
                      >Read More</span
                    >
                  </div>
                </div>
                <!-- <div class="carousel-item">
                  <img
                    src="assets\img\news-events\khadi\home2.jpg"
                    class="d-block w-100 img-fluid"
                    alt="..."
                  />
                  <div class="carousel-caption d-none d-md-block">
                    <p class="smallBoxEventTitle">
                      NIMS Khadi Movement was inaugurated by Mr. V.
                      Muraleedharan, the Union Minister of State for External
                      Affairs and Parliamentary Affairs at NIMS Medicity Campus
                      on 26th March 2022.
                    </p>
                    <span class="smallBoxEventLoc">NIMS Medicity</span><br />
                    <span>26-03-2022</span><br />
                    <span
                      class="btn2"
                      routerLink="news-events/NIMS-Khadi-Movement"
                      >Read More</span
                    >
                  </div>
                </div>
                <div class="carousel-item">
                  <img
                    src="assets\img\news-events\khadi\home3.jpg"
                    class="d-block w-100 img-fluid"
                    alt="..."
                  />
                  <div class="carousel-caption d-none d-md-block">
                    <p class="smallBoxEventTitle">
                      NIMS Khadi Movement was inaugurated by Mr. V.
                      Muraleedharan, the Union Minister of State for External
                      Affairs and Parliamentary Affairs at NIMS Medicity Campus
                      on 26th March 2022.
                    </p>
                    <span class="smallBoxEventLoc">NIMS Medicity</span><br />
                    <span>26-03-2022</span><br />
                    <span
                      class="btn2"
                      routerLink="news-events/NIMS-Khadi-Movement"
                      >Read More</span
                    >
                  </div>
                </div> -->
              </div>
              <button
                class="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleCaptions4"
                data-bs-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button
                class="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleCaptions4"
                data-bs-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Desktop slider ends -->
    <!-- Mobile Slider Starts -->
    <div class="d-block d-md-none">
      <div>
        <div
          id="carouselExampleIndicators"
          class="carousel slide"
          data-bs-ride="carousel"
        >
          <div class="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="0"
              class="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img
                src="https://nims.prismaticsoft.com{{ recentnews[0]['image']}}"
                class="imgmobsize d-block w-100"
                alt="..."
              />
              <div class="text-center p-3">
                <span>
                {{recentnews[0]['col_description']}}</span>
                <br />
                <span
                  class="btn1"
                  style="color: white"
                  routerLink="news-events/{{recentnews[0]['title']}}"
                  >Read More</span
                >
              </div>
            </div>
            <div class="carousel-item">
              <img
                src="https://nims.prismaticsoft.com{{ recentnews[1]['image']}}"
                class="imgmobsize d-block w-100"
                alt="..."
              />
              <div class="text-center p-3">
                <span>{{recentnews[1]['col_description']}}</span
                ><br />
                <span
                  class="btn1"
                  style="color: white"
                  routerLink="news-events/{{recentnews[1]['title']}}"
                  >Read More</span
                >
              </div>
            </div>
          </div>
         
      </div>
      <div>
        <div
          id="carouselExampleIndicators6"
          class="carousel slide"
          data-bs-ride="carousel"
        >
          <div class="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators6"
              data-bs-slide-to="0"
              class="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators6"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators6"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img
                src="https://nims.prismaticsoft.com{{ recentnews[2]['image']}}"
                class="imgmobsize d-block w-100"
                alt="..."
              />
              <div class="text-center p-3">
                <span>
                  {{ recentnews[2]['col_description']}}</span><br />
                <span
                  class="btn1"
                  style="color: white"
                  routerLink="news-events/{{recentnews[2]['title']}}"
                  >Read More</span>
                  
              </div> 
            </div>
            <div class="carousel-item">
              <img
                src="https://nims.prismaticsoft.com{{ recentnews[3]['image']}}"
                class="imgmobsize d-block w-100"
                alt="..."
              />
              <div class="text-center p-3">
                <span>
                  {{ recentnews[3]['col_description']}}</span
                ><br />
                <span
                  class="btn1"
                  style="color: white"
                  routerLink="news-events/{{recentnews[3]['title']}}"
                  >Read More</span>
                  
              </div> 
            </div>
          </div>
      </div>
      
      <div>
        <div
          id="carouselExampleIndicators"
          class="carousel slide"
          data-bs-ride="carousel"
        >
          <div class="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="0"
              class="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img
                src="https://nims.prismaticsoft.com{{ recentnews[4]['image']}}"
                class="imgmobsize d-block w-100"
                alt="..."
              />
              <div class="text-center p-3">
                <span>
                {{recentnews[0]['col_description']}}</span>
                <br />
                <span
                  class="btn1"
                  style="color: white"
                  routerLink="news-events/{{recentnews[4]['title']}}"
                  >Read More</span
                >
              </div>
            </div>
            <div class="carousel-item">
              <img
                src="https://nims.prismaticsoft.com{{ recentnews[5]['image']}}"
                class="imgmobsize d-block w-100"
                alt="..."
              />
              <div class="text-center p-3">
                <span>{{recentnews[1]['col_description']}}</span
                ><br />
                <span
                  class="btn1"
                  style="color: white"
                  routerLink="news-events/{{recentnews[5]['title']}}"
                  >Read More</span
                >
              </div>
            </div>
          </div>
         
      </div>
    </div>
  </div>
</div>
<br />
<br />
<br />
<br />
