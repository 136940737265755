<!-- Heading -->
<!-- <div class="col-sm-12 text-center nm-bg-primary mb-3 text-white py-3">
  <h2 class="blog"><b>BLOG & ARTICLES</b></h2>
</div> -->
<!-- Heading -->
<div class="nm-page-header">
  <h2>BLOG & ARTICLES</h2>
</div>

<!-- content  -->

<div class="container">
  <div class="row">
    <div class="col-sm-4 p-3" *ngFor="let item of blog">
      <div class="card m-pointer" [routerLink]="item.url">
        <img src="{{ item.image }}" alt="" class="image" />
        <div class="card-body bg-light">
          <h5 class="card-title nm-primary fw-bold">{{ item.title }}</h5>
          <p class="card-text contentstyle nm-secondary">
            {{ item.content }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
