<!-- Heading -->
<!-- <div class="col-sm-12 text-center nm-bg-primary mb-3 text-white py-3">
  <h2 class="blog">
    <b>{{ blogsubcategory.title }}</b>
  </h2>
</div> -->
<!-- Heading -->
<div class="nm-page-header">
  <h2>{{ blogsubcategory.title }}</h2>
</div>

<!-- content  -->
<div class="container d-lg-flex mb-4">
  <!-- first part  -->
  <div class="border" style="background-color: #2f905c7a">
    <img
      src="{{ blogsubcategory.image }}"
      alt="photos"
      class="card rounded mx-auto d-block"
      style="width: 300px; height: auto"
    />
  </div>

  <!-- second part  -->
  <div
    *ngFor="let item of blogsubcategory.innercontent"
    class="border rounded bg-light p-3"
  >
    <div>
      <h4>{{ item.subheading }}</h4>
    </div>
    <div class="">
      <p>{{ item.para1 }}</p>
      <p>{{ item.para2 }}</p>
      <p>{{ item.para3 }}</p>
      <p>{{ item.para4 }}</p>
      <p>{{ item.para5 }}</p>
      <p>{{ item.para6 }}</p>
      <p>{{ item.para7 }}</p>
      <p>{{ item.para8 }}</p>
      <p>{{ item.para9 }}</p>
    </div>
    <div class="p-3">
      <div class="{{ val.class }}" *ngFor="let val of item.list">
        <ul class="{{ val.classul }}">
          <li>
            <b>{{ val.list1 }}</b> {{ val.list11 }} <em>{{ val.list111 }}</em>
          </li>
        </ul>
        <ul class="{{ val.classol }}">
          <li *ngFor="let val1 of val.orderlist">
            <b>{{ val1.ol }}</b>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
