<div class="d-none d-lg-flex nm-tabs nm-tab--horizontal tab-right">
  <ul
    data-aos="fade-left"
    data-aos-duration="2000"
    class="nav nav-tabs border-0 bg-secondary"
    id="myTab"
    role="tablist"
  >
    <li class="nav-item" role="presentation" *ngFor="let item of speciality">
      <button
        class="nav-link"
        (click)="updateActiveTab(item.name)"
        [ngClass]="{ active: selected === item.name }"
        id="home-tab"
        data-bs-toggle="tab"
        [attr.data-bs-target]="'#home-' + item.id"
        type="button"
        role="tab"
        aria-controls="home"
        aria-selected="true"
      >
        <span class="fa {{ item.icon }}"></span
        ><span class="ms-2">{{ item.name }}</span>
      </button>
    </li>
  </ul>
  <div class="tab-content px-3" id="myTabContent">
    <div
      class="tab-pane fade show"
      id="home-{{ item.id }}"
      role="tabpanel"
      aria-labelledby="home-tab"
      *ngFor="let item of speciality"
      [ngClass]="{ active: item.id === 1 }"
    >
      <section class="text-start">
        <img
          data-aos="flip-left"
          data-aos-duration="2000"
          src="{{ item.image }}"
          alt=""
          class="mb-3 w-100"
        />
        <h4
          data-aos="fade-up"
          data-aos-duration="2000"
          class="mb-1 fw-bold mb-3 text-capitalize"
        >
          {{ item.name }}
        </h4>
        <p
          data-aos="fade-up"
          data-aos-duration="2000"
          class="nm-secondary mb-2"
        >
          {{ item.description }}
        </p>
      </section>
    </div>
  </div>
</div>

<div class="accordion d-lg-none nm-speciality-accordian" id="accordionExample">
  <div class="accordion-item" *ngFor="let item of speciality">
    <h2 class="accordion-header" id="headingOne">
      <button
        class="accordion-button text-white text-uppercase nm-bg-primary"
        type="button"
        [attr.data-bs-target]="'#collapse-' + item.id"
        data-bs-toggle="collapse"
        aria-expanded="true"
        aria-controls="collapseOne"
      >
        {{ item.name }}
      </button>
    </h2>
    <div
      id="collapse-{{ item.id }}"
      class="accordion-collapse collapse"
      aria-labelledby="headingOne"
      data-bs-parent="#accordionExample"
    >
      <div class="accordion-body">
        <img src="{{ item.image }}" alt="" class="mb-3" width="100" />
        <p class="nm-secondary mb-2">{{ item.description }}</p>
      </div>
      <button class="btn btn-primary" routerLink="{{item.url}}">
        Read More
      </button>
    </div>
  </div>
</div>

