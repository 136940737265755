import { Component, OnInit } from '@angular/core';
import { DepartmentListService } from 'src/app/_services/department-list.service';

@Component({
  selector: 'app-our-department-main',
  templateUrl: './our-department-main.component.html',
  styleUrls: ['./our-department-main.component.scss'],
})
export class OurDepartmentMainComponent implements OnInit {
  departments: any = [];

  constructor(private departmentList: DepartmentListService) {}

  ngOnInit() {
    this.getDepartmentList();
  }

  getDepartmentList() {
    this.departmentList.getDDepartmentList().subscribe((data) => {
      this.departments = data.data;
      console.log(this.departments);
    });
  }
}
