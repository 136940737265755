import { Component, OnInit } from '@angular/core';
import AOS from 'aos';

@Component({
  selector: 'app-our-speciality',
  templateUrl: './our-speciality.component.html',
  styleUrls: ['./our-speciality.component.scss'],
})
export class OurSpecialityComponent implements OnInit {
  constructor() {
    AOS.init();
  }

  ngOnInit() {
    let scrollRef = 0;

    window.addEventListener('scroll', function () {
      // increase value up to 10, then refresh AOS
      scrollRef <= 10 ? scrollRef++ : AOS.refresh();
    });
  }
}
