import { Component, OnInit } from '@angular/core';
import { DoctorsListService } from 'src/app/_services/doctors-list.service';


@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.css']
})
export class CareerComponent implements OnInit {
  /*  newsevent: any = []; */
  career: any =[];
  constructor(private CareerList: DoctorsListService) {}

    ngOnInit() 
    {
      //this.getnewseventList();
      this.getCareerList();
      
    }

 /*  getnewseventList() 
    {
      this.Newseventlist.getnewseventList().subscribe((data) => {
        this.newsevent = data.data;
        console.log(this.newsevent);
      });
    
    } */

    
    getCareerList() 
  {
    this.CareerList.getCareerList().subscribe((data) => {
      this.career = data.data;
      console.log(this.career);
    });
  }
}
 
