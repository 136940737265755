<div class="nm-home-department py-5">
  <div class="container">
    <div class="text-center text-white">
      <h3 class="mb-3">Our Departments and Doctors</h3>
      <h5 class="mb-2 fs-6">What we specialize in</h5>
    </div>

    <ul class="list-unstyled py-5">
      <li *ngFor="let item of department">
        <a href="{{ item.link }}" class="text-white">{{ item.name }} </a>
      </li>
    </ul>
  </div>
</div>
