import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DoctorsListService } from 'src/app/_services/doctors-list.service';


@Component({
  selector: 'app-doctors',
  templateUrl: './doctors.component.html',
  styleUrls: ['./doctors.component.scss'],
})
export class DoctorsComponent implements OnInit {
  department: any = [];
  doctors: any = [];
  doctor: any = [];
  doctorslist: any = [];
  temp: any = [];

  constructor(
    private doctorService: DoctorsListService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getDoctorsList();
    this.getCustomDoctorsList();
    this.getCustomDepartment();
  }

  getDoctorsList() {
    this.doctorService.getDoctorsList().subscribe((data) => {
      this.doctor = data.data;
      console.log(this.doctor);
      // for (var val of this.doctors) {
      //   this.temp = val.info;
      //   this.doctorslist = this.doctorslist.concat(this.temp);
      // }
      // console.log('list', this.doctorslist);
    });
  }
  getCustomDepartment(){
    this.doctorService.getCustomDepartment().subscribe((data) => {
      this.department = data.data;
      console.log(this.department);
      // for (var val of this.doctors) {
      //   this.temp = val.info;
      //   this.doctorslist = this.doctorslist.concat(this.temp);
      // }
      // co
  });
  }
  getCustomDoctorsList(){
    this.doctorService.getCustomDoctorsList().subscribe((data) => {
      this.doctors = data.data;
      console.log(this.doctors);
      // for (var val of this.doctors) {
      //   this.temp = val.info;
      //   this.doctorslist = this.doctorslist.concat(this.temp);
      // }
      // co
  });
}
  
}
